import { Text, View } from '@react-pdf/renderer'
import InvoiceTableHeader from './invoiceTableHeader'
import InvoiceTableRow from './invoiceTableRow'
// import InvoiceTableRow from './tablerow'
import { getTotalQty } from 'components/other/calculation'
import { getInvoiceSettings, HIDE_PRICES } from '../utils'
import { getTotalAlternateQty, getTotalPrimaryQty, has_multiple_units } from '../export_condition'
import InvoiceHsnTable from '../invoiceHsnTable'
import InvoiceTableBlankSpace from './invoiceTableBlankSpace'
import InvoiceTableFooter from './invoiceTableFooter'
import InvoiceNotes from './invoiceNotes'
import { mergeItemsWithHeaders } from '../itemsHeader'
import { styles as getStyles } from './styles'

const InvoiceItemsTable = ({ invoice, type }) => {
  var styles = getStyles(invoice)
  let tableRowsCount = 9
  let currency_symbol = invoice.company.currency_symbol
  let conversion_factor = 1
  const show_3_decimals = invoice.invoice_settings.qty_decimals_3 == 1
  if (
    (invoice.is_export == 1 || invoice.is_multi_currency == 1) &&
    invoice.invoice_settings.show_both_currencies != true
  ) {
    currency_symbol = invoice.export_details[0].currency_symbol
    conversion_factor = invoice.export_details[0].conversion_factor
  }
  const {
    has_multiple_unit_products,
    has_multiple_primary_units,
    has_multiple_alternate_units,
  } = has_multiple_units(invoice.items)
  if (Object.keys(invoice).includes('einvoice') && invoice.einvoice.length > 0) {
    tableRowsCount = 7
  }

  invoice.itemsWithHeaders = mergeItemsWithHeaders(invoice)
  const { hide_qty, show_free_qty, show_discount_col, hide_hsn } = getInvoiceSettings(invoice)

  const no_of_default_cols =
    10 + (show_free_qty ? 0.9 : 0) - hide_qty - (hide_hsn ? 0.85 : 0) + show_discount_col
  const no_of_dc_cols = 3 - hide_qty
  const no_of_with_out_tax_cols =
    6 + (show_free_qty ? 1 : 0) - hide_qty - (hide_hsn ? 0.85 : 0) + show_discount_col

  return (
    <>
      <View
        style={{
          ...styles.invoiceItemsTable_tableContainer,
          ...styles.global_borderTop,
          ...(invoice.invoice_settings.full_page == true &&
            styles.invoiceItemsTable_tableContainerGrow),
        }}
      >
        <InvoiceTableHeader
          invoice={invoice}
          type={type}
          show_free_qty={show_free_qty}
          show_discount_col={show_discount_col}
          no_of_default_cols={no_of_default_cols}
          no_of_dc_cols={no_of_dc_cols}
          no_of_with_out_tax_cols={no_of_with_out_tax_cols}
        />
        <InvoiceTableRow
          items={invoice.items}
          data={invoice}
          invoice={invoice}
          type={type}
          show_free_qty={show_free_qty}
          show_discount_col={show_discount_col}
          no_of_default_cols={no_of_default_cols}
          no_of_dc_cols={no_of_dc_cols}
          no_of_with_out_tax_cols={no_of_with_out_tax_cols}
        />

        <View style={invoice.invoice_settings.full_page == true && { flexGrow: 1 }}>
          <InvoiceTableBlankSpace
            rowsCount={tableRowsCount - invoice.items.length}
            invoice={invoice}
            type={type}
            show_free_qty={show_free_qty}
            show_discount_col={show_discount_col}
            no_of_default_cols={no_of_default_cols}
            no_of_dc_cols={no_of_dc_cols}
            no_of_with_out_tax_cols={no_of_with_out_tax_cols}
          />
        </View>

        {type != 'Delivery Challan' && type != HIDE_PRICES && (
          <>
            {invoice.document_type != 'estimates' && (
              <InvoiceTableFooter
                items={invoice.tax}
                total={invoice.total}
                netAmount={invoice.net_amount}
                invoice={invoice}
              />
            )}
            {invoice.document_type == 'estimates' && invoice.hide_totals == 0 && (
              <InvoiceTableFooter
                items={invoice.tax}
                total={invoice.total}
                netAmount={invoice.net_amount}
                invoice={invoice}
              />
            )}
          </>
        )}

        <View>
          {type != 'Delivery Challan' &&
            type != HIDE_PRICES &&
            invoice.is_export == 0 &&
            invoice.invoice_settings.show_hsn_details == 1 &&
            invoice.with_tax == 1 &&
            invoice.hide_totals == 0 && <InvoiceHsnTable invoice={invoice} type={type} />}

          {(type == 'Delivery Challan' || type == HIDE_PRICES) && !has_multiple_unit_products && (
            <View style={styles.invoiceItemsTable_borderTop}>
              <Text style={[styles.invoiceItemsTable_dc_totals, styles.invoiceItemsTable_bold]}>
                Total Items / Qty : {invoice.items.length} /{' '}
                {getTotalQty(invoice.items, true).toLocaleString('en-IN', {
                  maximumFractionDigits: 3,
                  minimumFractionDigits: show_3_decimals ? 3 : 0,
                })}{' '}
              </Text>
            </View>
          )}
          {(type == 'Delivery Challan' || type == HIDE_PRICES) &&
            has_multiple_unit_products &&
            !has_multiple_alternate_units &&
            !has_multiple_primary_units && (
              <View style={styles.invoiceItemsTable_borderTop}>
                <Text style={[styles.invoiceItemsTable_dc_totals, styles.invoiceItemsTable_bold]}>
                  Total Items / Qty : {invoice.items.length} /{' '}
                  {getTotalAlternateQty(invoice.items).toLocaleString('en-IN', {
                    maximumFractionDigits: 3,
                    minimumFractionDigits: show_3_decimals ? 3 : 0,
                  })}{' '}
                  {invoice.items[0]?.item_unit}
                  {' ('}
                  {parseFloat(getTotalPrimaryQty(invoice.items)).toLocaleString('en-IN', {
                    maximumFractionDigits: 3,
                    minimumFractionDigits: show_3_decimals ? 3 : 0,
                  })}{' '}
                  {invoice.items[0]?.unit}
                  {')'}
                </Text>
              </View>
            )}
        </View>
        <InvoiceNotes invoice={invoice} type={type} />
      </View>
    </>
  )
}

export default InvoiceItemsTable
