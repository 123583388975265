import React from 'react'
import InfoIcon from 'components/info/info_icon'
import { Tooltip } from 'antd'

interface ConvertedQtyProps {
  isConversionTracked: boolean
  data: {
    converted_qty?: { qty: number; doc_type: string }[]
    total_qty?: number
    qty: number
    conversion_rate: number
    unit: string
  }
  showTooltip?: boolean
}

interface QtyConversionDetailsProps {
  convertedQty: number
  totalQty: number
  conversionRate: number
  unit: string
  showTooltip?: boolean
  hideInfo?: boolean
}

const formatQty = (qty: number, conversion_rate: number) => {
  const converted = qty * conversion_rate
  return converted % 1 === 0 ? converted : converted.toFixed(2)
}

const QtyConversionDetails: React.FC<QtyConversionDetailsProps> = ({
  convertedQty,
  totalQty,
  conversionRate,
  unit,
  showTooltip,
  hideInfo,
}) => {
  const isOverConverted = convertedQty > totalQty

  return (
    <p
      className={`font-size-9 w-100p ${isOverConverted ? 'text-red-600' : 'text-green-600'} ${
        showTooltip ? 'ml-0.5 ' : 'm-0 p-0 right-zero absolute'
      }`}
    >
      {formatQty(convertedQty, conversionRate)} / {formatQty(totalQty, conversionRate)} {unit}{' '}
      converted
      {isOverConverted && !hideInfo && (
        <InfoIcon
          text={'The total converted quantity exceeds the total quantity given in this document.'}
        />
      )}
    </p>
  )
}

const ConvertedQtyStrip: React.FC<ConvertedQtyProps> = ({
  isConversionTracked,
  data,
  showTooltip,
}) => {
  if (!isConversionTracked) return null

  if (showTooltip && data?.converted_qty && data?.converted_qty?.length > 1) {
    return (
      <Tooltip
        title={
          <div className="p-1">
            {data?.converted_qty?.map((item, index) => (
              <div className="flex items-center justify-between">
                <span className="text-black mr-2">
                  {item.doc_type}
                  {': '}
                </span>
                <QtyConversionDetails
                  key={index}
                  convertedQty={item?.qty ?? 0}
                  totalQty={data?.total_qty ?? data?.qty}
                  conversionRate={data.conversion_rate}
                  unit={data.unit}
                  showTooltip={showTooltip}
                  hideInfo
                />
              </div>
            ))}
          </div>
        }
        color="white"
      >
        <div>
          <QtyConversionDetails
            convertedQty={data?.converted_qty?.[0]?.qty ?? 0}
            totalQty={data?.total_qty ?? data?.qty}
            conversionRate={data.conversion_rate}
            unit={data.unit}
            showTooltip={showTooltip}
          />
        </div>
      </Tooltip>
    )
  } else {
    return (
      <QtyConversionDetails
        convertedQty={data?.converted_qty?.[0]?.qty ?? 0}
        totalQty={data?.total_qty ?? data?.qty}
        conversionRate={data.conversion_rate}
        unit={data.unit}
        showTooltip={showTooltip}
      />
    )
  }
}

export default ConvertedQtyStrip
