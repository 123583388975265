import store from 'store'
import { HIDE_PAYOUTS_COMPANY_ID } from 'utils/constants'
import { Report, ReportListProps } from './types'

export const getReportList = (props: ReportListProps) => {
  let reportsNew: Report[] = [
    {
      name: 'Day Book',
      disabled: 0,
      menu_name: 'day_book',
      type: 'day_book',
      index: 0,
      new: false,
      main_menu: true,
    },
    {
      name: 'Transaction Reports',
      disabled: 1,
      children: [
        {
          name: 'Sales',
          disabled: 0,
          menu_name: 'sales',
          type: 'transaction_report',
          party_type: 'customer',
          index: 1,
          new: false,
          document_type: 'invoice',
        },
        {
          name: 'Purchases',
          disabled: 0,
          menu_name: 'purchases',
          type: 'transaction_report',
          party_type: 'vendor',
          index: 2,
          new: false,
          document_type: 'purchase',
        },
        {
          name: 'Sale Returns / Credit Notes',
          disabled: 0,
          menu_name: 'sales_returns',
          type: 'transaction_report',
          party_type: 'customer',
          index: 3,
          new: false,
          document_type: 'sales_return',
        },
        {
          name: 'Purchase Returns / Debit Notes',
          disabled: 0,
          menu_name: 'purchase_returns',
          type: 'transaction_report',
          party_type: 'vendor',
          index: 4,
          new: false,
          document_type: 'purchase_return',
        },
        // {
        //   name: "Daybook",
        //   disabled: 0
        // },
        // {
        //   name: "Profit & Loss",
        //   disabled: 0
        // },
        {
          name: 'Estimates',
          disabled: 0,
          menu_name: 'estimates',
          type: 'transaction_report',
          party_type: 'customer',
          index: 5,
          new: false,
          document_type: 'estimate',
        },
        {
          name: 'Sales Orders',
          disabled: 0,
          menu_name: 'sales_orders',
          type: 'transaction_report',
          party_type: 'customer',
          index: 55,
          new: true,
          document_type: 'sales_order',
        },
        {
          name: 'Delivery Challans',
          disabled: 0,
          menu_name: 'delivery_challans',
          type: 'transaction_report',
          party_type: 'customer',
          index: 6,
          new: false,
          hide: props?.user?.selectedCompany?.country_code != 'IN',
          document_type: 'delivery_challan',
        },
        {
          name: 'Pro Forma Invoices',
          disabled: 0,
          menu_name: 'pro_forma_invoices',
          type: 'transaction_report',
          party_type: 'customer',
          index: 7,
          new: false,
          document_type: 'pro_forma_invoice',
        },
        {
          name: 'Purchase Orders',
          disabled: 0,
          menu_name: 'purchase_orders',
          type: 'transaction_report',
          party_type: 'vendor',
          index: 8,
          new: false,
          document_type: 'purchase_order',
        },
        {
          name: 'Expense',
          disabled: 0,
          menu_name: 'expense_report',
          type: 'expense_report',
          party_type: 'vendor',
          document_type: 'expense',
          index: 20,
          new: false,
        },
        {
          name: 'Indirect Income',
          disabled: 0,
          menu_name: 'income_report',
          type: 'income_report',
          party_type: 'vendor',
          document_type: 'indirect_income',
          index: 21,
          new: false,
        },
        {
          name: 'Category-Wise Expense',
          disabled: 0,
          menu_name: 'expense_report',
          type: 'expense_report',
          is_category_group: true,
          party_type: 'vendor',
          document_type: 'expense',
          index: 47,
          new: false,
        },
        {
          name: 'Category-Wise Indirect Income',
          disabled: 0,
          menu_name: 'expense_report',
          type: 'income_report',
          is_category_group: true,
          party_type: 'vendor',
          document_type: 'indirect_income',
          index: 48,
          new: false,
        },
      ],
    },
    {
      name: 'Bill-wise Item Reports',
      disabled: 1,
      children: [
        {
          name: 'Sales',
          disabled: 0,
          menu_name: 'sales',
          type: 'transaction_wise_item_report',
          party_type: 'customer',
          index: 9,
          new: false,
          document_type: 'invoice',
        },
        {
          name: 'Purchases',
          disabled: 0,
          menu_name: 'purchases',
          type: 'transaction_wise_item_report',
          party_type: 'vendor',
          index: 10,
          new: false,
          document_type: 'purchase',
        },
        {
          name: 'Sale Returns / Credit Notes',
          disabled: 0,
          menu_name: 'sales_returns',
          type: 'transaction_wise_item_report',
          party_type: 'customer',
          index: 11,
          new: false,
          document_type: 'sales_return',
        },
        {
          name: 'Purchase Returns / Debit Notes',
          disabled: 0,
          menu_name: 'purchase_returns',
          type: 'transaction_wise_item_report',
          party_type: 'vendor',
          index: 12,
          new: false,
          document_type: 'purchase_return',
        },
        {
          name: 'Estimates',
          disabled: 0,
          menu_name: 'estimates',
          type: 'transaction_wise_item_report',
          party_type: 'customer',
          index: 13,
          new: false,
          document_type: 'estimate',
        },
        {
          name: 'Sales Orders',
          disabled: 0,
          menu_name: 'sales_orders',
          type: 'transaction_wise_item_report',
          party_type: 'customer',
          index: 113,
          new: true,
          document_type: 'sales_order',
        },
        {
          name: 'Delivery Challans',
          disabled: 0,
          menu_name: 'delivery_challans',
          type: 'transaction_wise_item_report',
          party_type: 'customer',
          index: 14,
          new: false,
          hide: props?.user?.selectedCompany?.country_code != 'IN',
          document_type: 'delivery_challan',
        },
        {
          name: 'Pro Forma Invoices',
          disabled: 0,
          menu_name: 'pro_forma_invoices',
          type: 'transaction_wise_item_report',
          party_type: 'customer',
          index: 15,
          new: false,
          document_type: 'pro_forma_invoice',
        },
        {
          name: 'Purchase Orders',
          disabled: 0,
          menu_name: 'purchase_orders',
          type: 'transaction_wise_item_report',
          party_type: 'vendor',
          index: 16,
          new: false,
          document_type: 'purchase_order',
        },
        // {
        //   name: "Expenses",
        //   disabled: 0
        // },
      ],
    },

    {
      name: 'Item Reports',
      disabled: 1,
      children: [
        {
          name: 'Stock Summary',
          disabled: 0,
          type: 'stock_report',
          menu_name: 'item_stock_summary',
          index: 17,
          new: false,
        },

        {
          name: 'Item-wise Discount',
          disabled: 0,
          type: 'item_wise_discount',
          index: 19,
          new: false,
        },
        {
          name: 'Stock Value',
          disabled: 0,
          type: 'stock_value',
          index: 20,
          new: false,
        },
        {
          name: 'Inventory Timeline',
          disabled: 0,
          type: 'daily_stock_report',
          index: 50,
          new: false,
        },
        {
          name: 'Low Stock',
          disabled: 0,
          type: 'low_stock_report',
          index: 51,
          new: true,
        },
        // {
        //   name: 'Stock Transactions Summary',
        //   disabled: 0,
        //   type: 'stock_transactions_report',
        //   index: 51,
        //   new: false,
        // },
      ],
    },
    {
      name: 'Party Reports',
      disabled: 1,
      type: 'party_report',
      children: [
        {
          name: 'All Customers',
          disabled: 0,
          type: 'party_report',
          party_type: 'customer',
          menu_name: 'customer',
          index: 21,
          new: false,
        },
        {
          name: 'All Vendors',
          disabled: 0,
          type: 'party_report',
          party_type: 'vendor',
          menu_name: 'vendor',
          index: 22,
          new: false,
        },
        {
          name: 'Customer Statement',
          disabled: 0,
          type: 'ledger',
          party_type: 'customer',
          menu_name: 'customer_statement',
          index: 23,
          new: false,
        },
        {
          name: 'Vendor Statement',
          disabled: 0,
          type: 'ledger',
          party_type: 'vendor',
          menu_name: 'vendor_statement',
          index: 24,
          new: false,
        },
        {
          name: 'Customer wise Items',
          disabled: 0,
          type: 'party_wise_items',
          menu_name: 'customer',
          party_type: 'customer',
          index: 25,
          new: false,
        },
        {
          name: 'Vendor wise Items',
          disabled: 0,
          type: 'party_wise_items',
          menu_name: 'vendor',
          party_type: 'vendor',
          index: 26,
          new: false,
        },
      ],
    },
    {
      name: 'Profit & Loss(PL) Reports',
      disabled: 1,
      children: [
        {
          name: 'P&L Statement',
          disabled: 0,
          type: 'pl_report',
          index: 36,
          new: false,
        },
        {
          name: 'Sales P&L Report',
          disabled: 0,
          menu_name: 'sale_profit_loss',
          type: 'profit_loss',
          party_type: 'customer',
          index: 37,
          new: false,
        },
        {
          name: 'Sale Items P&L Report',
          disabled: 0,
          menu_name: 'sale_item_profit_loss',
          type: 'profit_loss',
          party_type: 'customer',
          index: 38,
          new: false,
        },
        {
          name: 'Item-wise P&L (Purchases)',
          disabled: 0,
          type: 'item_wise_profit_loss',
          menu_name: 'item',
          index: 18,
          new: false,
        },
        {
          name: 'Item-wise P&L Report (Purchase Price)',
          disabled: 0,
          type: 'item_wise_profit_loss_purchase_price',
          menu_name: 'item',
          index: 42,
          new: false,
        },
        {
          name: 'Category-wise P&L Report (Purchase Price)',
          disabled: 0,
          type: 'category_wise_profit_loss_purchase_price',
          index: 43,
          new: false,
        },
        {
          name: 'Customer-wise P&L Report',
          disabled: 0,
          type: 'party_profit_loss_report',
          party_type: 'customer',
          index: 44,
          new: false,
        },
      ],
      new: false,
    },
    {
      name: 'Payments Reports',
      disabled: 1,
      children: [
        {
          name: 'Payment Timeline',
          disabled: 0,
          type: 'payment_timeline',
          index: 45,
          new: false,
          searchType: 'party',
          party_type: 'customer',
        },
        {
          name: 'Payment Settlements',
          disabled: 0,
          type: 'payment_settlements',
          index: 46,
          new: false,
          searchType: 'party',
          party_type: 'customer',
        },
        {
          name: 'Payouts',
          disabled: 0,
          type: 'settlement_payouts',
          index: 47,
          new: false,
          hide:
            store.get('is_payment_gateway') == 0 ||
            props.company_details?.company_id > HIDE_PAYOUTS_COMPANY_ID,
        },
      ],
      new: false,
    },
  ]

  let tax_reports = {
    name: 'Taxes',
    disabled: 1,
    children: [
      store.get('company_details')?.is_composite == 1
        ? {}
        : {
            name: 'GSTR - 1',
            type: 'gstr_1',
            disabled: 0,
            index: 26,
            new: false,
          },
      store.get('company_details')?.is_composite == 1
        ? {}
        : {
            name: 'GSTR - 2B',
            type: 'gstr_2',
            disabled: 0,
            index: 27,
            new: false,
          },
      store.get('company_details')?.is_composite == 1
        ? {}
        : {
            name: 'GSTR - 3B',
            type: 'gstr_3',
            disabled: 0,
            index: 28,
            new: false,
          },
      store.get('company_details')?.is_composite == 1
        ? {}
        : {
            name: 'GSTR - 7',
            type: 'gstr_7',
            disabled: 0,
            index: 53,
            new: false,
          },
      store.get('company_details')?.is_composite == 1
        ? {
            name: 'GST - CMP-08',
            type: 'gst_cmp',
            disabled: 0,
            index: 45,
            new: false,
          }
        : {},
      store.get('company_details')?.is_composite == 1
        ? {
            name: 'GSTR - 4',
            type: 'gstr_4',
            disabled: 0,
            index: 46,
            new: false,
          }
        : {},
      {
        name: 'Sale Summary by HSN',
        type: 'hsn_summary',
        disabled: 0,
        index: 30,
        new: false,
      },
      {
        name: 'TDS Receivable',
        type: 'tds_report',
        menu_name: 'receivable',
        disabled: 0,
        payment_type: 'in',
        index: 31,
        new: false,
      },
      {
        name: 'TDS Payable',
        type: 'tds_report',
        menu_name: 'payable',
        disabled: 0,
        payment_type: 'out',
        index: 32,
        new: false,
      },
      {
        name: 'TCS Receivable',
        type: 'tcs_report',
        menu_name: 'receivable',
        disabled: 0,
        payment_type: 'in',
        index: 33,
        new: false,
      },
      {
        name: 'TCS Payable',
        type: 'tcs_report',
        menu_name: 'payable',
        disabled: 0,
        payment_type: 'out',
        index: 34,
        new: false,
      },
    ],
  }

  if (props?.user?.selectedCompany?.country_code == 'IN') {
    reportsNew.splice(1, 0, tax_reports)
  }

  if (props.integrations?.batching == 1 || props.integrations?.serial_number == 1) {
    reportsNew.splice(6, 0, {
      name: 'Batch Reports',
      disabled: 1,
      children: [
        {
          name: 'Batch Expiry',
          disabled: 0,
          type: 'batch_expiry_report',
          index: 10,
          isCommon: true,
          searchType: 'products',
        },
        {
          name: 'Batch Stock Summary',
          disabled: 0,
          type: 'stock_report',
          menu_name: 'batch_stock_summary',
          index: 11,
          has_batches: true,
        },
        {
          name: 'Batch-wise P&L (Purchases)',
          disabled: 0,
          type: 'item_wise_profit_loss',
          menu_name: 'batch',
          index: 18,
          has_batches: true,
        },
        {
          name: 'Batch-wise P&L Report (Purchase Price)',
          disabled: 0,
          type: 'item_wise_profit_loss_purchase_price',
          menu_name: 'batch',
          index: 12,
          has_batches: true,
        },
      ],
    })
  }

  if (props.warehouses?.length > 0) {
    reportsNew.splice(5, 0, {
      name: 'Warehouse Reports',
      disabled: 1,
      children: [
        {
          name: 'Stock Summary',
          disabled: 0,
          type: 'stock_report',
          menu_name: 'warehouse_stock_summary',
          index: 52,
          is_warehouse: true,
        },
      ],
    })
  }

  // filter out empty children from taxes
  reportsNew[1].children = reportsNew[1].children?.filter(child => {
    return Object.keys(child).length !== 0
  })

  return reportsNew
}
