import {
  Button,
  Checkbox,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Radio,
  Select,
} from 'antd'
import classNames from 'classnames'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { getAPI } from 'services/jwt'
// import './index.css'

const { Option } = Select
export class Addresses extends Component {
  formRefAddress = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      data: '',
      visible: false,
      selected_addr_id: '',
      type: 'to',
      addressDrawerVisible: false,
    }
  }
  componentDidMount() {
    this.props.onRef(this)
  }

  showModal = (data, shipping_addr_id, type) => {
    let addr_id = data['shipping_address'].filter(item => item.addr_id == shipping_addr_id)
    this.setState({
      visible: true,
      data: data,
      type: type,
      selected_addr_id: addr_id.length > 0 ? addr_id[0].addr_id : '',
    })
  }
  closeModal = () => {
    this.setState({
      visible: false,
      data: '',
      selected_addr_id: '',
    })
  }

  render() {
    const formItemLayout = {
      labelCol: {
        sm: { span: 8 },
        md: { span: 8 },
      },
      wrapperCol: {
        sm: { span: 16 },
        md: { span: 16 },
      },
    }

    return (
      <>
        {this.state.data['shipping_address'] && !this.state.addressDrawerVisible ? (
          <Modal
            title="Change Address"
            open={this.state.visible}
            onCancel={() => this.setState({ visible: false, data: '', shipping_addr_id: '' })}
            closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
            footer={
              <div className="col-12 m-3 ">
                {' '}
                <Button
                  type="primary"
                  onClick={() => this.props.onAddressChange(this.state.selected_addr_id)}
                >
                  Save
                </Button>
              </div>
            }
            bodyStyle={{
              maxHeight: 500,
              width: 500,
              overflowY: 'auto',
            }}
          >
            {this.state.data['shipping_address'].length > 0 ? (
              <div>
                <Radio.Group
                  defaultValue={this.state.selected_addr_id}
                  size="large"
                  onChange={e => {
                    this.setState({ selected_addr_id: e.target.value })
                  }}
                >
                  {this.state.data &&
                    this.state.data['shipping_address'].map((item, i) => (
                      <div key={i}>
                        <Radio value={item.addr_id}>
                          <div
                            className={classNames(
                              this.state.selected_addr_id == item.addr_id
                                ? ' shadow-sm rounded border border-gray  bg-gray-50'
                                : 'shadow-sm rounded bg-white',
                              'p-2 m-2 w-full address-radio-btn',
                            )}
                            style={{
                              width: '390px !important',
                              borderRadius: '5px',
                            }}
                          >
                            <span className="font-size-14">
                              <span>
                                <p className="font-weight-bold mb-0">
                                  {this.state.data['company_name']}
                                </p>
                                <p className="font-weight-bold mb-0">{this.state.data['gstin']}</p>
                                <p className="mb-0">{item['line1']}</p>
                                <p className="mb-0">{item['line2']}</p>
                                <p className="mb-0">{item['city']}</p>
                                <p className="mb-0">{item['pincode']}</p>
                                <p className="font-weight-bold mb-0">{item['state']}</p>
                              </span>
                            </span>
                            {item.is_delete == 1 && (
                              <span className="font-size-14 text-danger">
                                This Address is added/deleted manually and not in company
                                profile.Still you can use this address.
                              </span>
                            )}
                          </div>
                        </Radio>
                      </div>
                    ))}
                </Radio.Group>
              </div>
            ) : (
              <> No Addresses Found</>
            )}
          </Modal>
        ) : (
          <></>
        )}
        {/* <div>
          <Drawer
            title={'Add Company Shipping Address'}
            width={isMobile ? '100%' : '50%'}
            height={isMobile ? '90%' : '100%'}
            placement={isMobile ? 'top' : 'left'}
            maskClosable={false}
            closable={true}
            closeIcon={<i className="fa-solid mr-2 fa-xmark"></i>}
            onClose={() => this.setState({ addressDrawerVisible: false })}
            visible={this.state.addressDrawerVisible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Form
              {...formItemLayout}
              labelAlign="left"
              ref={this.formRefAddress}
              name="addressForm"
              onFinish={this.onAddressFinish}
              size="default"
            >
              <Form.Item
                name={'line1'}
                label={<FormattedMessage id="form.field.addressLine1" />}
                rules={[{ required: false, message: 'Please fill Address Line 1' }]}
              >
                <Input placeholder="Address Line 1" />
              </Form.Item>
              <Form.Item name={'line2'} label={<FormattedMessage id="form.field.addressLine2" />}>
                <Input placeholder="Address Line 2" />
              </Form.Item>
              <Form.Item name={'city'} label={<FormattedMessage id="form.field.city" />}>
                <Input placeholder="City" />
              </Form.Item>
              <Form.Item
                name={'state'}
                label={<FormattedMessage id="form.field.state" />}
                extra="Billing State (like 36-Telangana) is responsible for deciding CGST + SGST/UTGST or IGST calculation on the invoice. Please ignore this, if you do not have GST."
              >
                <Select
                  showSearch
                  placeholder="Select State"
                  optionFilterProp="children"
                  style={{ width: '100%' }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.props?.states?.map((item, i) => (
                    <Option key={i} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name={'pincode'} label={<FormattedMessage id="form.field.pincode" />}>
                <InputNumber placeholder="Pincode" style={{ width: '100%' }} />
              </Form.Item>

              {
                <Form.Item
                  name={'is_delete'}
                  valuePropName="checked"
                  extra="If selected, this address will be added to the company shipping addresses."
                >
                  <Checkbox>Add in company shipping addresses</Checkbox>
                </Form.Item>
              }
            </Form>
            <span className="mb-1">
              <Popconfirm
                title="Check the address details before saving. You can't edit the address later if add in company addresses is not selected."
                onConfirm={() => this.formRefAddress.current.submit()}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  // onClick={() =>
                  //   this.setState({ loading: true }, () => this.formRefAddress.current.submit())
                  // }
                  type="primary"
                  loading={this.state.loading}
                  className="float-right"
                >
                  <FormattedMessage id="button.save&close" />
                </Button>
              </Popconfirm>
              <Button
                onClick={() => this.setState({ addressDrawerVisible: false })}
                style={{ marginRight: 8 }}
                className="float-right ml-2"
              >
                <FormattedMessage id="button.cancel" />
              </Button>
            </span>
          </Drawer>
        </div> */}
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    permissions: state.permissions.permission,
    states: state.document.states,
  }
}

class EnhancedAddresses extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <Addresses {...this.props} />
  }
}

export default connect(mapStateToProps)(EnhancedAddresses)
