import { Card, Checkbox } from 'antd'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import React, { Component } from 'react'

import { connect } from 'react-redux'

// import './index.css'

export class ManageColumns extends Component {
  formRefAddress = React.createRef()
  subscriptionPlanRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    // this.props.onRef(this)
  }

  render() {
    const { columns } = this.props
    return (
      <>
        <h6 className="text-gray-600 font-weight-medium mb-0">Manage Columns</h6>
        <div className="columns mt-2" style={{ maxHeight: '60vh', overflowY: 'scroll' }}>
          <Checkbox.Group
            options={columns}
            defaultValue={columns.map((item, index) => {
              return item.active && item.value
            })}
            value={columns.map((item, index) => {
              return item.active && item.value
            })}
            onChange={e => {
              if (this.props.user.paid == 0) {
                this.subscriptionPlanRef.current.openModal('manage_columns')

                return
              }
              this.props.onChange(e)
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {columns.map((item, index) => (
              <Checkbox key={item.value} value={item.value}>
                {item.label}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </div>

        <SubscriptionPlanModal ref={this.subscriptionPlanRef} />
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    permissions: state.permissions.permission,
    states: state.document.states,
    user: state.user,
  }
}

export default connect(mapStateToProps)(ManageColumns)
