import {
  GET_ALL_DOC_COUNTS,
  SET_MOST_RECENT_DOC_CREATED,
  GET_BATCH_SETTINGS,
  GET_BUSINESS_ANALYTICS,
  GET_CATEGORIES,
  GET_COUNTRIES,
  GET_CUSTOM_COLUMNS,
  GET_INVOICE_SETTINGS,
  GET_PAYMENT_MODES,
  GET_PORT_CODES,
  GET_POS_SETTINGS,
  GET_PRODUCT_OPTIONS,
  GET_STATES,
  GET_TCS_SECTIONS,
  GET_TDS_SECTIONS,
  SET_ALL_DOC_COUNTS,
  SET_API_LOADING,
  SET_BANK_DETAILS,
  SET_BATCH_SETTINGS,
  SET_BUSINESS_ANALYTICS,
  SET_CATEGORIES,
  SET_COUNTRIES,
  SET_CUSTOM_COLUMNS,
  SET_INVOICE_SETTINGS,
  SET_PAYMENT_MODES,
  SET_PORT_CODES,
  SET_POS_SETTINGS,
  SET_PRODUCT_OPTIONS,
  SET_STATES,
  SET_TCS_SECTIONS,
  SET_TDS_SECTIONS,
  ADD_INVOICES_DATA,
  UPDATE_INVOICES_DATA,
  REMOVE_INVOICES_DATA,
  REMOVE_ALL_INVOICES_DATA,
  GET_UNITS,
  SET_UNITS,
  GET_TOP_CUSTOM_FIELDS,
  SET_TOP_CUSTOM_FIELDS,
  GET_NOTIFICATION_SETTINGS,
  SET_NOTIFICATION_SETTINGS,
  UPDATE_NOTIFICATION_COUNT,
  SET_NOTIFICATION_COUNT,
  GET_PRICING_PLANS,
  SET_PRICING_PLANS,
  SET_PREFIXES,
  SET_BARCODE_SETTINGS,
  GET_BARCODE_SETTINGS,
  SET_INVOICE_JUST_CREATED,
  SET_DOCUMENTS_CREATED_COUNT,
  GET_HSN_SUGGESTION,
  GET_CUSTOM_COLUMNS_SUGGESTION,
} from './types'

export const getStates = () => {
  return {
    type: GET_STATES,
  }
}
export const setStates = payload => {
  return {
    type: SET_STATES,
    payload,
  }
}
export const getCountries = () => {
  return {
    type: GET_COUNTRIES,
  }
}
export const setCountries = payload => {
  return {
    type: SET_COUNTRIES,
    payload,
  }
}
export const setApiLoading = payload => {
  return {
    type: SET_API_LOADING,
    payload,
  }
}
export const getTdsSections = () => {
  return {
    type: GET_TDS_SECTIONS,
  }
}
export const setTdsSections = payload => {
  return {
    type: SET_TDS_SECTIONS,
    payload,
  }
}
export const get_categories = () => {
  return {
    type: GET_CATEGORIES,
  }
}
export const set_categories = payload => {
  return {
    type: SET_CATEGORIES,
    payload,
  }
}
export const get_custom_columns = () => {
  return {
    type: GET_CUSTOM_COLUMNS,
  }
}
export const set_custom_columns = payload => {
  return {
    type: SET_CUSTOM_COLUMNS,
    payload,
  }
}
export const get_payment_modes = () => {
  return {
    type: GET_PAYMENT_MODES,
  }
}
export const set_payment_modes = payload => {
  return {
    type: SET_PAYMENT_MODES,
    payload,
  }
}
export const set_bank_details = payload => {
  return {
    type: SET_BANK_DETAILS,
    payload,
  }
}

export const get_invoice_settings = () => {
  return {
    type: GET_INVOICE_SETTINGS,
  }
}

export const get_notification_settings = () => {
  return {
    type: GET_NOTIFICATION_SETTINGS,
  }
}

export const update_notification_count = count => {
  return {
    type: UPDATE_NOTIFICATION_COUNT,
    payload: {
      notification_count: count,
    },
  }
}

export const set_notification_count = count => {
  return {
    type: SET_NOTIFICATION_COUNT,
    payload: {
      notification_count: count,
    },
  }
}

export const set_invoice_settings = payload => {
  return {
    type: SET_INVOICE_SETTINGS,
    payload,
  }
}

export const set_notification_settings = payload => {
  return {
    type: SET_NOTIFICATION_SETTINGS,
    payload,
  }
}

export const get_port_codes = () => {
  return {
    type: GET_PORT_CODES,
  }
}
export const set_port_codes = payload => {
  return {
    type: SET_PORT_CODES,
    payload,
  }
}
export const get_tcs_sections = () => {
  return {
    type: GET_TCS_SECTIONS,
  }
}
export const set_tcs_sections = payload => {
  return {
    type: SET_TCS_SECTIONS,
    payload,
  }
}
export const get_product_options = () => {
  return {
    type: GET_PRODUCT_OPTIONS,
  }
}
export const set_product_options = payload => {
  return {
    type: SET_PRODUCT_OPTIONS,
    payload,
  }
}

export const get_all_doc_counts = () => {
  return {
    type: GET_ALL_DOC_COUNTS,
  }
}
export const set_all_doc_counts = payload => {
  return {
    type: SET_ALL_DOC_COUNTS,
    payload,
  }
}
export const get_batch_settings = () => {
  return {
    type: GET_BATCH_SETTINGS,
  }
}
export const set_batch_settings = payload => {
  return {
    type: SET_BATCH_SETTINGS,
    payload,
  }
}

export const get_pos_settings = () => {
  return {
    type: GET_POS_SETTINGS,
  }
}
export const set_pos_settings = payload => {
  return {
    type: SET_POS_SETTINGS,
    payload,
  }
}
export const get_business_analytics = () => {
  return {
    type: GET_BUSINESS_ANALYTICS,
  }
}
export const set_business_analytics = payload => {
  return {
    type: SET_BUSINESS_ANALYTICS,
    payload,
  }
}
export const add_invoice_data = payload => {
  return {
    type: ADD_INVOICES_DATA,
    payload,
  }
}
export const update_invoice_data = payload => {
  return {
    type: UPDATE_INVOICES_DATA,
    payload,
  }
}
export const remove_invoice_data = payload => {
  return {
    type: REMOVE_INVOICES_DATA,
    payload,
  }
}
export const remove_all_invoices_data = () => ({
  type: REMOVE_ALL_INVOICES_DATA,
})
export const get_units = () => {
  return {
    type: GET_UNITS,
  }
}
export const set_units = payload => {
  return {
    type: SET_UNITS,
    payload,
  }
}
export const get_top_custom_fields = () => {
  return {
    type: GET_TOP_CUSTOM_FIELDS,
  }
}
export const set_top_custom_fields = payload => {
  return {
    type: SET_TOP_CUSTOM_FIELDS,
    payload,
  }
}
export const get_pricing_plans = () => {
  return {
    type: GET_PRICING_PLANS,
  }
}
export const set_pricing_plans = payload => {
  return {
    type: SET_PRICING_PLANS,
    payload,
  }
}

export const set_prefixes = payload => {
  return {
    type: SET_PREFIXES,
    payload,
  }
}

export const set_barcode_settings = payload => {
  return {
    type: SET_BARCODE_SETTINGS,
    payload,
  }
}

export const get_barcode_settings = () => {
  return {
    type: GET_BARCODE_SETTINGS,
  }
}

export const set_invoice_just_created = payload => {
  return {
    type: SET_INVOICE_JUST_CREATED,
    payload,
  }
}

export const set_documents_created_count = payload => {
  return {
    type: SET_DOCUMENTS_CREATED_COUNT,
    payload,
  }
}

export const get_custom_column_suggestions = payload => {
  return {
    type: GET_CUSTOM_COLUMNS_SUGGESTION,
    payload,
  }
}

export const get_hsn_suggestions = payload => {
  return {
    type: GET_HSN_SUGGESTION,
    payload,
  }
}

export const set_most_recent_doc_created = payload => {
  return {
    type: SET_MOST_RECENT_DOC_CREATED,
    payload,
  }
}
