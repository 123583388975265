import { Address } from 'redux/types'

export const renderAddress = (
  address: Address | undefined,
  selectedAddressId: number | undefined,
) => {
  if (address == null) return null

  const isSelected = address?.addr_id === selectedAddressId

  const addressContent = (
    <div className="flex items-center gap-4">
      {isSelected ? (
        <span>
          <div className="bg-gray-700 rounded-full flex items-center justify-center w-5 h-5">
            <i className="fa-solid fa-check text-white text-xs"></i>
          </div>
        </span>
      ) : (
        <span>
          <div className="border border-black rounded-full flex items-center justify-center w-5 h-5"></div>
        </span>
      )}
      <div className="flex flex-col text-sm font-regular gap-1 py-2">
        {address?.title && <span className="font-semibold">{address.title}</span>}
        <span>{address?.line1}</span>
        <span>
          {address?.city}
          {address?.city && ','} {address?.state} {address?.pincode}
        </span>
        {address?.line2 && <span>{address?.line2}</span>}
      </div>
    </div>
  )

  return <>{addressContent}</>
}

export function formatAddress(address: Address): string[] {
  const parts = []
  if (address.title?.trim()) {
    parts.push(address.title.trim())
  }
  if (address.line1?.trim()) {
    parts.push(address.line1.trim())
  }
  if (address.line2?.trim()) {
    parts.push(address.line2.trim())
  }
  if (address.city?.trim()) {
    parts.push(address.city.trim())
  }
  const invalidStates = ['97-OTHER TERRITORY', 'OTHERTERRITORY']
  if (address.state?.trim() && !invalidStates.includes(address.state.trim())) {
    parts.push(address.state.trim())
  }
  if (address.pincode && address.pincode !== 'None') {
    parts.push(address.pincode.toString().trim())
  }
  return parts
}
