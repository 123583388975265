import { Tooltip } from 'antd'
import React from 'react'

export default function InfoIcon(props) {
  return (
    <span>
      <Tooltip
        trigger={['click', 'hover']}
        title={
          <span>
            <div className="pb-2">{props.text}</div>
            {props.image && (
              <span>
                <img src={props.image} className="w-full rounded-md" />
              </span>
            )}
          </span>
        }
        placement="right"
      >
        <i className="fa-solid fa-circle-question text-gray-400 ml-1" />
      </Tooltip>
    </span>
  )
}
