import React from 'react'
import { Link, Image, View, StyleSheet } from '@react-pdf/renderer'

const getStyles = (invoice, borderRadius) => {
  const PAPER_SIZE = invoice.invoice_settings?.paper_size === 'A4' ? 1 : 0.8
  return StyleSheet.create({
    index_bannerContainer: {
      width: '100%',
      textAlign: 'left',
    },
    global_banner: {
      maxHeight: 144 * PAPER_SIZE,
      borderRadius: borderRadius * PAPER_SIZE,
    },
  })
}

const Banner = ({
  invoice,
  count,
  borderSides = 0,
  borderBottom = 0,
  borderTop = 0,
  paddingHorizontal = 0,
  paddingVertical = 4.5,
  borderRadius = 4.5,
}) => {
  const styles = getStyles(invoice, borderRadius)
  const bannerImageKey = `banner_image_${count}`
  const bannerImageSrc = invoice.invoice_settings?.[bannerImageKey]
  return (
    <View
      wrap={false}
      style={{
        ...styles.index_bannerContainer,
        borderLeftWidth: bannerImageSrc != '' ? borderSides : 0,
        borderRightWidth: bannerImageSrc != '' ? borderSides : 0,
        borderBottomWidth: bannerImageSrc != '' ? borderBottom : 0,
        borderTopWidth: bannerImageSrc != '' ? borderTop : 0,
      }}
    >
      {bannerImageSrc != '' && (
        <View style={{ paddingHorizontal, paddingVertical }}>
          <Image style={styles.global_banner} src={bannerImageSrc} />
        </View>
      )}
    </View>
  )
}

export default Banner
