import { Modal } from 'antd'
import NoAccess from 'components/noaccess'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReportsMenu from './ReportsMenu'

dayjs.extend(utc)

class Reports extends Component {
  dateFormat = 'DD-MM-YYYY'
  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }
  }

  showModal = () => {
    this.setState({
      open: true,
    })
  }

  render() {
    return (
      <>
        {this.props.permissions.reports == 1 && (
          <div>
            <Modal
              open={this.state.open}
              width="100%"
              onCancel={e => {
                // history.push('/list/sales')
                this.setState({
                  open: false,
                })
              }}
              closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
              destroyOnClose
              centered
              footer={null}
              maxHeight={100}
            >
              <ReportsMenu />
            </Modal>
          </div>
        )}
        {this.props.permissions.reports == 0 && <NoAccess />}
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    permissions: state.permissions.permission,
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(Reports)
