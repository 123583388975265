import { Button, Card, Col, Input, Modal, Row, Tag, Tooltip, message } from 'antd'
import React, { Component } from 'react'
import SectionHeader from 'components/other/sectionHeader'
import { connect } from 'react-redux'
import store from 'store'
import CustomerForm from 'components/forms/customerForm'
import EnhancedVendorForm from 'components/forms/vendorform'
import ProductForm from 'components/forms/productForm'
import PayInForm from 'components/forms/payForm'
import ExpenseForm from 'components/forms/expensesForm'
import InfoComponent from 'components/badges/InfoComponent'
import { history } from 'index'
import { changeCompany } from 'services/jwt'
import { LoadingOutlined } from '@ant-design/icons'
import BulkActionsHistory from 'components/history'

export default function(WrappedComponent) {
  class withGlobalShortcuts extends Component {
    historyModal = React.createRef()
    constructor(props) {
      super(props)
      this.state = {
        loading: '',
        showShortcutsModal: false,
        isAIModalVisible: false,
        shortcuts: [
          {
            action: 'Show Keyboard Shortcuts',
            redirect: '',
            binding: ['?'],
            key: '?',
            category: '',
          },

          // {
          //   action: 'Show History Modal',
          //   redirect: '',
          //   binding: ['Ctrl', 'h'],
          //   key: 'Ctrl+h',
          //   category: 'Application',
          // },
          {
            action: 'Global Search',
            redirect: '',
            binding: ['ctrl', 'k'],
            key: 'ctrl+k',
            category: 'Application',
          },
          {
            action: 'Submit Active Form',
            redirect: '',
            binding: ['ctrl', 'enter'],
            key: 'ctrl+enter',
            category: 'Application',
            elementID: 'save',
            isClick: true,
            isFocus: false,
          },
          {
            action: 'Sales',
            redirect: '/list/sales',
            binding: ['g', 's'],
            key: 'g+s',
            category: 'Navigation',
            permissionKey: 'sales',
          },
          {
            action: 'POS Billing',
            redirect: '/pos',
            binding: ['g', 'b'],
            key: 'g+b',
            category: 'Navigation',
            permissionKey: 'pos',
          },
          {
            action: 'Purchases',
            redirect: '/list/purchases',
            binding: ['g', 'p'],
            key: 'g+p',
            category: 'Navigation',
            permissionKey: 'purchases',
          },
          {
            action: 'Quotations',
            redirect: '/list/estimates',
            binding: ['g', 'q'],
            key: 'g+q',
            category: 'Navigation',
            permissionKey: 'estimates',
          },
          {
            action: 'Delivery Challans',
            redirect: '/list/delivery_challans',
            binding: ['g', 'd'],
            key: 'g+d',
            category: 'Navigation',
            permissionKey: 'delivery_challans',
          },
          {
            action: 'Purchase Order',
            redirect: '/list/purchase_orders',
            binding: ['g', 'o'],
            key: 'g+o',
            category: 'Navigation',
            permissionKey: 'purchase_orders',
          },
          {
            action: 'Pro Forma Invoice',
            redirect: '/list/pro_forma_invoices',
            binding: ['g', 'f'],
            key: 'g+f',
            category: 'Navigation',
            permissionKey: 'pro_forma_invoices',
          },
          {
            action: 'Sales Order',
            redirect: '/list/sales_orders',
            binding: ['g', 'r'],
            key: 'g+r',
            category: 'Navigation',
            permissionKey: 'sales_orders',
          },
          // {
          //   action: 'Sales Returns / Credit Notes',
          //   redirect: '/list/sales_returns',
          //   binding: ['c', 'c'],
          //   key: 'c+c',
          //   category: 'Navigation',
          //   permissionKey: 'sales_returns',
          // },
          {
            action: 'Purchase Returns / Debit Notes',
            redirect: '/list/purchase_returns',
            binding: ['g', 'r'],
            key: 'g+r',
            category: 'Navigation',
            permissionKey: 'purchase_returns',
          },
          {
            action: 'Customers',
            redirect: '/customer',
            binding: ['g', 'c'],
            key: 'g+c',
            category: 'Navigation',
            permissionKey: 'customers',
          },
          {
            action: 'Vendors',
            redirect: '/vendor',
            binding: ['g', 'v'],
            key: 'g+v',
            category: 'Navigation',
            permissionKey: 'vendors',
          },
          {
            action: 'Journals',
            redirect: '/journals',
            binding: ['g', 'j'],
            key: 'g+j',
            category: 'Navigation',
            permissionKey: 'payments',
          },
          {
            action: 'Expenses',
            redirect: '/expenses',
            binding: ['g', 'e'],
            key: 'g+e',
            category: 'Navigation',
            permissionKey: 'expenses',
          },
          {
            action: 'Products',
            redirect: '/products',
            binding: ['g', 'i'],
            key: 'g+i',
            category: 'Navigation',
            permissionKey: 'products',
          },
          {
            action: 'Bank Reconciliation',
            redirect: '/bankRecon',
            binding: ['g', 'b'],
            key: 'g+b',
            category: 'Navigation',
            permissionKey: 'bank_reconciliation',
          },
          {
            action: 'Sales',
            redirect: '/create/invoice',
            binding: ['c', 'i'],
            key: 'c+i',
            category: 'Create',
            permissionKey: 'invoice_add',
          },
          {
            action: 'Purchase',
            redirect: '/create/purchase',
            binding: ['c', 'p'],
            key: 'c+p',
            category: 'Create',
            permissionKey: 'purchase_add',
          },
          {
            action: 'Quotation',
            redirect: '/create/estimate',
            binding: ['c', 'q'],
            key: 'c+q',
            category: 'Create',
            permissionKey: 'estimate_add',
          },
          {
            action: 'Sales Order',
            redirect: '/create/sales_order',
            binding: ['c', 'r'],
            key: 'c+r',
            category: 'Create',
            permissionKey: 'sales_order_add',
          },
          {
            action: 'Purchase Order',
            redirect: '/create/purchase_order',
            binding: ['c', 'o'],
            key: 'c+o',
            category: 'Create',
            permissionKey: 'purchase_order_add',
          },
          {
            action: 'Delivery Challan',
            redirect: '/create/delivery_challan',
            binding: ['c', 'd'],
            key: 'c+d',
            category: 'Create',
            permissionKey: 'delivery_challan_add',
          },
          {
            action: 'Pro Forma Invoice',
            redirect: '/create/pro_forma_invoice',
            binding: ['c', 'f'],
            key: 'c+f',
            category: 'Create',
            permissionKey: 'pro_forma_invoice_add',
          },
          {
            action: 'Sales Returns / Credit Notes',
            redirect: '/create/sales_return',
            binding: ['c', 'c'],
            key: 'c+c',
            category: 'Create',
            permissionKey: 'sales_return_add',
          },
          {
            action: 'Purchase Returns / Debit Notes',
            redirect: '/create/purchase_return',
            binding: ['c', 'r'],
            key: 'c+r',
            category: 'Create',
            permissionKey: 'purchase_return_add',
          },
          {
            action: 'Expense',
            redirect: '',
            ref: 'expenseForm',
            method: 'onAdd',
            binding: ['c', 'e'],
            key: 'c+e',
            category: 'Create',
            permissionKey: 'expense_add',
          },
          {
            action: 'Add Product',
            redirect: '',
            ref: 'productForm',
            method: 'onAdd',
            binding: ['a', 'p'],
            key: 'a+p',
            category: 'Add',
            permissionKey: 'product_add',
          },
          {
            action: 'Add Customer',
            redirect: '',
            ref: 'customerForm',
            method: 'onAdd',
            binding: ['a', 'c'],
            key: 'a+c',
            category: 'Add',
            permissionKey: 'customer_add',
          },
          {
            action: 'Add Vendor',
            redirect: '',
            ref: 'vendorForm',
            method: 'onAdd',
            binding: ['a', 'v'],
            key: 'a+v',
            category: 'Add',
            permissionKey: 'vendor_add',
          },
          {
            action: 'Pay In',
            redirect: '',
            ref: 'paymentForm',
            method: 'onAddPayment',
            params: true,
            binding: ['p', 'i'],
            key: 'p+i',
            category: 'Add',
            permissionKey: 'payments',
          },
          {
            action: 'Pay Out',
            redirect: '',
            ref: 'paymentForm',
            method: 'onAddPayment',
            params: false,
            binding: ['p', 'o'],
            key: 'p+o',
            category: 'Add',
            permissionKey: 'payments',
          },
          {
            action: 'Select Customer/vendor in Create',
            redirect: '',
            binding: ['ctrl', 'shift', 'C'],
            key: 'ctrl+shift+C',
            category: 'While Creating document',
            elementID: 'addParty',
            parentComponent: 'create',
            isClick: false,
            isFocus: true,
          },
          {
            action: 'Select Item in Create',
            redirect: '',
            binding: ['ctrl', 'shift', 'I'],
            key: 'ctrl+shift+I',
            category: 'While Creating document',
            elementID: 'addProduct',
            parentComponent: 'create',
            isClick: false,
            isFocus: true,
          },
          {
            action: 'Select Bank in Create',
            redirect: '',
            binding: ['ctrl', 'shift', 'B'],
            key: 'ctrl+shift+B',
            category: 'While Creating document',
            elementID: 'selectBank',
            parentComponent: 'create',
            isClick: false,
            isFocus: true,
          },
          {
            action: 'Save Document in Create',
            redirect: '',
            binding: ['ctrl', 's'],
            key: 'ctrl+s',
            category: 'While Creating document',
            elementID: 'saveDocumentButton',
            parentComponent: 'create',
            isClick: true,
            isFocus: false,
          },
          {
            action: 'Save as Draft in Create',
            redirect: '',
            binding: ['ctrl', 'd'],
            key: 'ctrl+d',
            category: 'While Creating document',
            elementID: 'saveAsDraftButton',
            parentComponent: 'create',
            isClick: true,
            isFocus: false,
          },
          {
            action: 'Save and Print in Create',
            redirect: '',
            binding: ['ctrl', 'p'],
            key: 'ctrl+p',
            category: 'While Creating document',
            elementID: 'saveAndPrintDocumentButton',
            parentComponent: 'create',
            isClick: true,
            isFocus: false,
          },

          // Bank Reconciliation shortcuts
          {
            action: 'Import Statement',
            redirect: '',
            binding: ['ctrl', 'i'],
            key: 'ctrl+i',
            category: 'Bank Reconciliation',
            elementID: 'importStatement',
            parentComponent: 'bankRecon',
            isClick: true,
            isFocus: false,
          },

          ...[...Array(5).keys()].map(index => {
            return {
              action: `Select  nth statement row (n <= 5)`,
              redirect: '',
              binding: ['s', `${index + 1}`],
              common_binding: ['s', '{n}'],
              key: `s+${index + 1}`,
              category: 'Bank Reconciliation',
              elementID: `selectStatementRow${index + 1}`,
              parentComponent: 'bankRecon',
              isClick: true,
              isFocus: false,
              hide: index > 0,
              is_duplicate: true,
            }
          }),
          ...[...Array(5).keys()].map(index => {
            return {
              action: `Select nth payment row (n <= 5)`,
              redirect: '',
              binding: ['p', `${index + 1}`],
              common_binding: ['p', '{n}'],
              key: `p+${index + 1}`,
              category: 'Bank Reconciliation',
              elementID: `selectPaymentRow${index + 1}`,
              parentComponent: 'bankRecon',
              isClick: true,
              isFocus: false,
              hide: index > 0,
              is_duplicate: true,
            }
          }),
          {
            action: 'Create new payment for selected statement',
            redirect: '',
            binding: ['ctrl', 'p'],
            key: 'ctrl+p',
            category: 'Bank Reconciliation',
            elementID: 'createPayment',
            parentComponent: 'bankRecon',
            isClick: true,
            isFocus: false,
          },
          {
            action: 'Link seleceted statement to payment',
            redirect: '',
            binding: ['ctrl', 'l'],
            key: 'ctrl+l',
            category: 'Bank Reconciliation',
            elementID: 'linkSelectedPayments',
            parentComponent: 'bankRecon',
            isClick: true,
            isFocus: false,
          },
          {
            action: 'Skip selected statement',
            redirect: '',
            binding: ['ctrl', 'k'],
            key: 'ctrl+l',
            category: 'Bank Reconciliation',
            elementID: 'skipSelectedStatement',
            parentComponent: 'bankRecon',
            isClick: true,
            isFocus: false,
          },
          {
            action: 'Save Reconciliation',
            redirect: '',
            binding: ['ctrl', 's'],
            key: 'ctrl+s',
            category: 'Bank Reconciliation',
            elementID: 'saveReconciliation',
            parentComponent: 'bankRecon',
            isClick: true,
            isFocus: false,
          },
          // ...[...Array(5).keys()].map(index => {
          //   return {
          //     action: `Change to nth tab`,
          //     redirect: '',
          //     binding: ['ctrl', `${index + 1}`],
          //     common_binding: ['ctrl', '{n}'],
          //     key: `ctrl+${index + 1}`,
          //     category: 'Bank Reconciliation',
          //     elementID: `changeTab${index + 1}`,
          //     parentComponent: 'bankRecon',
          //     isClick: true,
          //     isFocus: false,
          //     hide: index > 0,
          //     is_duplicate: true,
          //   }
          // }),
        ],
        parentKeys: ['g', 'c', 'a', 'p'],
        parentKeyDescription: {
          g: 'Going to - ',
          c: 'Create -',
          // a: '',
        },
        currentBinding: [],
        waitingForChildKey: false,
        displayedText: '',
        textParts: [''],
        currentIndex: 0,
        renderingDisplayText: false,
      }
      this.globalSearch = React.createRef()
    }

    componentDidMount() {
      window.addEventListener('keydown', this.afterHandleKeyDown)
      window.addEventListener('keyup', this.handleKeyUp)
    }

    componentWillUnmount() {
      window.removeEventListener('keydown', this.afterHandleKeyDown)
      window.removeEventListener('keyup', this.handleKeyUp)
    }
    getCurrentComponent = () => {
      let currentComponent = history.location.pathname.split('/')[1]

      if (currentComponent === 'convert') {
        currentComponent = 'create'
      }

      return this.state.shortcuts.filter(shortcut => {
        return shortcut.parentComponent === currentComponent
      }).length > 0
        ? currentComponent
        : ''
    }

    isActionKeys = event => {
      if (
        (event.ctrlKey || event.metaKey || event.altKey || event.shiftKey) &&
        !(
          event.key === 'Control' ||
          event.key === 'Meta' ||
          event.key === 'Alt' ||
          event.key === 'Shift'
        )
      ) {
        return true
      }
      return false
    }

    afterHandleKeyDown = async event => {
      this.handleKeyDown(event)
      this.timeout = setTimeout(() => {
        this.handleKeyUp(event, true)
        clearTimeout(this.timeout)
      }, 2000)
    }

    handleKeyDown = event => {
      if (!this.props.company_details?.company_id) {
        return
      }

      // Check if keyboad is under use
      if (
        document.activeElement &&
        (['INPUT', 'TEXTAREA', 'SELECT'].includes(document.activeElement.tagName) ||
          document.activeElement?.role?.includes('textbox'))
      ) {
        // if the event key is esc then blur the input
        if (event.key === 'Escape') {
          document.activeElement.blur()
        }
        if (!this.isActionKeys(event) || event.key !== 'Shift') {
          return
        }
      }

      if (this.getCurrentComponent() == 'create' && event.key === 'Escape') {
        var elementRef = document.getElementById('leaveCreate')
        if (elementRef) {
          elementRef.click()
        }
      }

      // check for the authorization
      if ((store.get('accessToken') || false) == false) {
        return
      }

      // For Shortcuts modal to show
      if ((event.ctrlKey && event.key === '/') || event.key === '?') {
        event.preventDefault()
        this.setState({ showShortcutsModal: true })
        return
      }
      if (event.ctrlKey && event.key === 'h') {
        event.preventDefault()
        this.historyModal.current.showModal(true)
        return
      }

      if ((event.ctrlKey && event.key === 'k') || event.key === '?') {
        event.preventDefault()

        // this.setState({ isAIModalVisible: true })
        this.props.dispatch({
          type: 'settings/SET_STATE',
          payload: {
            globalSearchFocus: true,
          },
        })
        return
      }

      if (event.ctrlKey && event.key === 'Enter') {
        // var activeForm = document.querySelectorAll('form')
        // if (activeForm.length > 0) {
        //   activeForm = activeForm[activeForm.length - 1]
        //   activeForm.dispatchEvent(new Event('submit', { bubbles: true }))
        // }

        this.performAction({
          action: 'Submit Active Form',
          redirect: '',
          binding: ['ctrl', 'enter'],
          key: 'ctrl+enter',
          category: 'Application',
          elementID: 'save',
          isClick: true,
          isFocus: false,
        })
      }

      if (event.ctrlKey && event.keyCode > 48 && event.keyCode <= 57) {
        event.preventDefault()
        this.handleCompanyChange(event.keyCode - 49)
        return
      }

      let currentComponent = this.getCurrentComponent()
      if (this.isActionKeys(event)) {
        this.completeShortcut(event.key, event, currentComponent)
        return
      }

      // Check for the key bindings and redirect to the page
      if (this.state.parentKeys.includes(event.key) && this.state.currentBinding.length === 0) {
        event.preventDefault()
        this.setState({ currentBinding: [event.key], waitingForChildKey: true }, () =>
          this.getDisplayedText(),
        )
        return
      } else if (this.state.waitingForChildKey) {
        this.completeShortcut(this.state.currentBinding + '+' + event.key, event, currentComponent)
      } else {
        this.setState({ currentBinding: [event.key], waitingForChildKey: true }, () =>
          this.getDisplayedText(),
        )
      }

      // Write the logic for the key bindings here if no redirect is found
    }

    getShortCutObject = (key, parentComponent) => {
      let shortCutobj = this.state.shortcuts.filter(shortcut => {
        let hasPemission = shortcut?.permissionKey
          ? this.props.permissions?.[shortcut.permissionKey]
          : true
        return shortcut.key === key && shortcut.parentComponent === parentComponent && hasPemission
      })
      return shortCutobj.length > 0 ? shortCutobj[0] : null
    }

    performAction = shortcut => {
      var elementRef = document.getElementById(shortcut.elementID)
      if (elementRef) {
        if (shortcut.isClick) {
          elementRef.click()
        } else if (shortcut.isFocus) {
          elementRef.focus()
        }
      }
    }

    completeShortcut = (keyBind, event, currentComponent = '') => {
      // this.setState({ showShortcutsModal: false })

      // let currentComponent = this.getCurrentComponent()

      if (currentComponent != '') {
        keyBind = `${event.ctrlKey ? 'ctrl+' : ''}${event.shiftKey ? 'shift+' : ''}${
          event.altKey ? 'alt+' : ''
        }${event.metaKey ? 'meta+' : ''}${keyBind}`
        let shortcut = this.getShortCutObject(keyBind, currentComponent)
        if (shortcut) {
          this.setState(
            {
              displayedText: this.state.displayedText + ' ' + shortcut['action'],
              textParts: [...this.state.textParts, shortcut['action']],
            },
            () => this.renderNextPart(),
          )

          event.preventDefault()
          this.performAction(shortcut)
          this.clearShortcut()
          return
        }
      }

      // For current component is empty or no shortcut found for the current component
      let action = this.getShortCutObject(keyBind)

      if (action) {
        this.setState(
          {
            textParts: [...this.state.textParts, ' ' + action['action']],
            currentIndex: this.state.currentIndex + 1,
          },
          () => !this.state.renderingDisplayText && this.renderNextPart(),
        )
        event.preventDefault()
        if (action.ref) {
          if (action.params != undefined) {
            this[action.ref][action.method](action.params)
          } else {
            this[action.ref][action.method]()
          }
        }
        if (action.redirect) {
          history.push(action.redirect)
        }
        this.clearShortcut()
        return
      }

      // For the key binding is not found
      this.clearShortcut()
    }

    handleKeyUp = (event, clear = false) => {
      // if (this.state.currentBinding.includes(event.key)) {
      //   this.setState({ currentBinding: [] })
      // }

      // Check if keyboad is under use
      if (
        document.activeElement &&
        (['INPUT', 'TEXTAREA', 'SELECT'].includes(document.activeElement.tagName) ||
          document.activeElement?.role?.includes('textbox'))
      ) {
        // if the event key is esc then blur the input
        if (event.key === 'Escape') {
          document.activeElement.blur()
        }
        if (!this.isActionKeys(event) || event.key !== 'Shift') {
          return
        }
      }

      if (!this.state.waitingForChildKey || clear) {
        this.clearShortcut()
      }
    }

    clearShortcut() {
      this.setState(
        {
          currentBinding: [],
        },
        () =>
          setTimeout(() => {
            this.setState({
              displayedText: '',
              textParts: [''],
              currentIndex: 0,
              renderingDisplayText: false,
            })
          }, 2000),
      )
    }

    handleCompanyChange = async companyIndex => {
      // let companies = this.props.companies.filter(
      //   company => company.company_id != this.props.company_details.company_id,
      // )
      let companies = this.props.companies.sort((a, b) => {
        return a.company_id - b.company_id
      })

      if (
        companies.length > 0 &&
        companies[companyIndex]?.company_id != this.props.company_details?.company_id
      ) {
        this.setState(
          {
            loading: 'fullScreen',
            displayedText: '',
            textParts: [
              'Changing to ',
              companies[companyIndex]?.company_name ?? companies[companyIndex]?.organization_name,
            ],
            currentIndex: 0,
          },
          () => {
            this.renderNextPart()

            setTimeout(() => {
              changeCompany(companies[companyIndex])
              this.setState({ loading: false })
            }, 500)
          },
        )
      }
    }

    getDisplayedText() {
      if (this.state.currentBinding[0] in this.state.parentKeyDescription) {
        this.setState(
          {
            displayedText: '',
            textParts: [this.state.parentKeyDescription[this.state.currentBinding[0]]],
            currentIndex: 0,
          },
          () => this.renderNextPart(),
        )
      }
    }

    renderNextPart = () => {
      return
      while (this.state.renderingDisplayText) {}
      const { textParts, currentIndex, displayedText } = this.state

      if (currentIndex < textParts.length) {
        const nextPart = textParts[currentIndex]
        let currentPart = ''

        let i = 0
        const interval = setInterval(() => {
          this.setState({ renderingDisplayText: true })
          if (i < nextPart.length) {
            currentPart += nextPart[i]
            this.setState({ displayedText: displayedText + currentPart })
            i++
          } else {
            clearInterval(interval)
            this.setState({ renderingDisplayText: false })
            this.setState({ currentIndex: currentIndex + 1 }, this.renderNextPart)
          }
        }, 50) // Adjust the speed of the animation by changing the interval duration
      }
    }

    render() {
      return (
        <div>
          <div>
            {this.props.company_details?.company_id && (
              <Modal
                title={
                  <>
                    Keyboard Shortcuts
                    <div className="font-size-14 font-weight-medium text-gray-500 flex items-center">
                      {/* show shortcut to open this modal */}
                      <p className="flex items-center">
                        To show this modal press
                        <Button
                          type="shortcut-button"
                          size="small"
                          bordered={false}
                          className="cursor-pointer ml-2"
                          disabled
                        >
                          ?
                        </Button>
                      </p>
                    </div>
                  </>
                }
                open={this.state.showShortcutsModal}
                onOk={() => this.setState({ showShortcutsModal: false })}
                onCancel={() => this.setState({ showShortcutsModal: false })}
                footer={null}
                width={720}
                centered
              >
                <div
                  className="mb-4"
                  id="style-9"
                  style={{
                    maxHeight: '80vh',
                    overflow: 'auto',
                  }}
                >
                  <div className="mt-4 flex flex-col justify-start">
                    <SectionHeader title={'Dashboard'} />

                    <Row className="mb-2 items-center">
                      <Col span={8}>
                        <span className="flex  items-center">
                          <Button
                            size="small"
                            type="shortcut-button"
                            bordered={false}
                            className="mr-2 cursor-pointer "
                            disabled
                          >
                            F1
                          </Button>

                          <span className="font-size-14 text-muted">-</span>
                          <Button
                            type="shortcut-button"
                            size="small"
                            bordered={false}
                            className="mx-2 cursor-pointer"
                            disabled
                          >
                            F5
                          </Button>
                        </span>
                      </Col>

                      <Col span={8} className="font-size-14 font-weight-medium text-gray-500">
                        Opens the details drawer for the first five documents in the corresponding
                        dashboard.
                      </Col>
                    </Row>

                    <Row className="mb-2 items-center">
                      <Col span={8}>
                        <span className="flex  items-center">
                          <Button
                            size="small"
                            type="shortcut-button"
                            bordered={false}
                            className="mr-2 cursor-pointer "
                            disabled
                          >
                            F10
                          </Button>
                        </span>
                      </Col>

                      <Col span={8} className="font-size-14 font-weight-medium text-gray-500">
                        Opens thermal print modal [works in invoice details drawer]
                      </Col>
                    </Row>
                  </div>

                  {[
                    'Application',
                    'Create',
                    'Add',
                    'While Creating document',
                    'Navigation',
                    'Bank Reconciliation',
                  ].map(category => (
                    <div className="mt-4 flex flex-col justify-start">
                      <SectionHeader title={category} />
                      {Object.keys(this.state.shortcuts).map((key, index) => {
                        if (
                          this.state.shortcuts[key].category !== category ||
                          (Object.keys(this.props.permissions).length > 0 &&
                            this.state.shortcuts[key]?.permissionKey &&
                            !this.props.permissions?.[this.state.shortcuts[key].permissionKey])
                        ) {
                          return
                        }

                        return (
                          <>
                            {!this.state.shortcuts[key].hide && (
                              <Row
                                // gutter={[16, 16]}
                                // wrap={true}
                                className="mb-2 items-center"
                              >
                                <Col span={8}>
                                  <span className="flex  items-center">
                                    <Button
                                      size="small"
                                      type="shortcut-button"
                                      bordered={false}
                                      className="mr-2 cursor-pointer "
                                      disabled
                                    >
                                      {this.state.shortcuts[key].is_duplicate
                                        ? this.state.shortcuts[key].common_binding[0]
                                        : this.state.shortcuts[key].binding[0]}
                                    </Button>
                                    {(this.state.shortcuts[key].is_duplicate
                                      ? this.state.shortcuts[key].common_binding
                                      : this.state.shortcuts[key].binding
                                    )
                                      .slice(1)
                                      .map((binding, index) => (
                                        <>
                                          <span className="font-size-14 text-muted">then</span>
                                          <Button
                                            type="shortcut-button"
                                            size="small"
                                            bordered={false}
                                            className="mx-2 cursor-pointer"
                                            disabled
                                          >
                                            {binding}
                                          </Button>
                                        </>
                                      ))}
                                  </span>
                                </Col>

                                <Col
                                  span={8}
                                  className="font-size-14 font-weight-medium text-gray-500"
                                >
                                  {this.state.shortcuts[key].action}
                                </Col>
                              </Row>
                            )}
                          </>
                        )
                      })}
                    </div>
                  ))}

                  {/* POS BILLING */}
                  <div className="mt-4 flex flex-col justify-start">
                    {this.props.permissions.pos == 1 && this.props.permissions.is_pos && (
                      <>
                        <SectionHeader title={'POS Billing'} />
                        <Row className="mb-2 items-center">
                          <Col span={8}>
                            <span className="flex  items-center">
                              <Button
                                size="small"
                                type="shortcut-button"
                                bordered={false}
                                className="mr-2 cursor-pointer "
                                disabled
                              >
                                F2
                              </Button>
                            </span>
                          </Col>

                          <Col span={8} className="font-size-14 font-weight-medium text-gray-500">
                            Checkout
                          </Col>
                        </Row>
                        <Row className="mb-2 items-center">
                          <Col span={8}>
                            <span className="flex  items-center">
                              <Button
                                size="small"
                                type="shortcut-button"
                                bordered={false}
                                className="mr-2 cursor-pointer "
                                disabled
                              >
                                F12
                              </Button>
                            </span>
                          </Col>

                          <Col span={8} className="font-size-14 font-weight-medium text-gray-500">
                            Discard
                          </Col>
                        </Row>

                        <Row className="mb-2 items-center">
                          <Col span={8}>
                            <span className="flex  items-center">
                              <Button
                                size="small"
                                type="shortcut-button"
                                bordered={false}
                                className="mr-2 cursor-pointer "
                                disabled
                              >
                                Alt
                              </Button>

                              <span className="font-size-14 text-muted">then</span>
                              <Button
                                type="shortcut-button"
                                size="small"
                                bordered={false}
                                className="mx-2 cursor-pointer"
                                disabled
                              >
                                S
                              </Button>
                            </span>
                          </Col>

                          <Col span={8} className="font-size-14 font-weight-medium text-gray-500">
                            Saved Carts
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                </div>

                <InfoComponent
                  description={
                    <span>
                      Note: The shortcuts are subjected to the role & permissions of the user.
                    </span>
                  }
                  type="info"
                />
              </Modal>
            )}
            <WrappedComponent {...this.props} />
          </div>
          {this.state.displayedText && (
            <Tooltip title={'Shortcut'}>
              <div
                className="shortcut-tooltip"
                onClick={() =>
                  this.setState({
                    currentBinding: [],
                    displayedText: '',
                    textParts: [''],
                    currentIndex: 0,
                    renderingDisplayText: false,
                  })
                }
              >
                {this.state.displayedText}
              </div>
            </Tooltip>
          )}

          {this.state.loading == 'fullScreen' && (
            <div
              style={{
                minHeight: '50vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 9999,
                backgroundColor: 'rgba(0,0,0,0.1)',
                backdropFilter: 'blur(3px)',
              }}
            >
              <LoadingOutlined
                style={{
                  fontSize: 30,
                }}
                spin
              />
            </div>
          )}

          {this.props.company_details?.company_id && (
            <>
              <ProductForm
                onRef={ref => (this.productForm = ref)}
                onAPICalled={() => {}}
                dispatch={this.props.dispatch}
              />

              <EnhancedVendorForm
                onRef={ref => (this.vendorForm = ref)}
                onAPICalled={() => {}}
                onExistingVendor={() => {
                  this.vendorForm.onClose()
                }}
              />
              <CustomerForm
                onRef={ref => (this.customerForm = ref)}
                onAPICalled={() => {}}
                onExistingCustomer={() => {
                  this.customerForm.onClose()
                }}
              />
              <BulkActionsHistory ref={this.historyModal} agTheme={this.props.agTheme} />
              <PayInForm onRef={ref => (this.paymentForm = ref)} show_party_selection={true} />
              <ExpenseForm
                onRef={ref => (this.expenseForm = ref)}
                document_type="expense"
                onAPICalled={() => {}}
              />
            </>
          )}
        </div>
      )
    }
  }

  const mapStateToProps = (state, ownProps) => {
    return {
      invoice_settings: state.document.invoiceSettings,
      company_details: state.permissions.company_details,
      permissions: state.permissions.permission,
      companies: state.user.companies,
      settings: state.settings,
      agTheme: state.settings.theme,
    }
  }

  return connect(mapStateToProps)(withGlobalShortcuts)
}
