import { Document, Font, Page, View } from '@react-pdf/renderer'
import { registerFont } from 'components/fonts'
import { get_invoice_title, get_invoice_type } from '../export_condition'
import BillTo from './billTo_2'
import CompanyDetails from './company'
import InvoiceTitle from './invoiceTitle'
import InvoiceFooter from './invoiceFooter.js'
import InvoiceItemsTable from './invoiceItemsTable'
import InvoiceNotes from './invoiceNotes'
import Banner from '../banner'
import { styles as getStyles } from './styles'
export default function Invoice({ invoice }) {
  const hyphenationCallback = word => {
    return word.split('')
  }

  registerFont(invoice)
  Font.registerHyphenationCallback(hyphenationCallback)

  var styles = getStyles(invoice)
  var PAPER_SIZE = invoice.invoice_settings.paper_size == 'A4' ? 1 : 0.8
  return (
    <Document>
      {invoice.type.map((item, index) => (
        <Page
          orientation={invoice.invoice_settings.pdf_orientation}
          wrap
          size={invoice.invoice_settings.paper_size}
          key={index}
          style={{
            ...styles.index_page,
            paddingTop:
              18 * PAPER_SIZE +
              (!!invoice.invoice_settings.margin_top ? invoice.invoice_settings.margin_top : 0),
            paddingBottom:
              36 * PAPER_SIZE +
              (!!invoice.invoice_settings.margin_bottom
                ? invoice.invoice_settings.margin_bottom
                : 0) +
              (invoice.invoice_settings.invoice_footer_image != '' ? 72 : 0),
            // paddingLeft: !!invoice.invoice_settings.margin_left
            //   ? invoice.invoice_settings.margin_left
            //   : 10,
            // paddingRight: !!invoice.invoice_settings.margin_right
            //   ? invoice.invoice_settings.margin_right
            //   : 10,
          }}
        >
          <InvoiceTitle
            invoice={invoice}
            title={get_invoice_title(
              invoice.document_type,
              invoice.document_title,
              invoice.with_tax,
              invoice.company.gstin,
              item,
              invoice,
            )}
            type={get_invoice_type(invoice.document_type, item)}
            color={invoice.company.color}
          />
          <View
            style={{
              // paddingTop: 18 * PAPER_SIZE,
              //  + (!!invoice.invoice_settings.margin_top ? invoice.invoice_settings.margin_top : 0),
              // paddingBottom:
              //   36 * PAPER_SIZE +
              //   (!!invoice.invoice_settings.margin_bottom
              //     ? invoice.invoice_settings.margin_bottom
              //     : 0) +
              //   (invoice.invoice_settings.invoice_footer_image != '' ? 72 : 0),
              // paddingHorizontal: 9 * PAPER_SIZE,
              paddingLeft: !!invoice.invoice_settings.margin_left
                ? invoice.invoice_settings.margin_left
                : 10,
              paddingRight: !!invoice.invoice_settings.margin_right
                ? invoice.invoice_settings.margin_right
                : 10,
            }}
          >
            <CompanyDetails
              invoice={invoice}
              title={get_invoice_title(
                invoice.document_type,
                invoice.document_title,
                invoice.with_tax,
                invoice.company.gstin,
                item,
                invoice,
              )}
              type={get_invoice_type(invoice.document_type, item)}
              color={invoice.company.color}
            />

            <BillTo invoice={invoice} type={item} />

            <Banner invoice={invoice} count={1} />
            <InvoiceItemsTable invoice={invoice} type={item} />
            <Banner invoice={invoice} count={2} />

            {/* Hiding banners for now */}
            {/* <Banner invoice={invoice} /> */}

            <InvoiceNotes invoice={invoice} type={item} />
          </View>

          <InvoiceFooter invoice={invoice} />
        </Page>
      ))}
    </Document>
  )
}
