import { View } from '@react-pdf/renderer'
import { getInvoiceSettings, HIDE_PRICES } from '../utils'
import InvoiceTableFooter from './invoiceTableFooter'
import InvoiceTableHeader from './invoiceTableHeader'
import InvoiceTableRow from './invoiceTableRow'
import { mergeItemsWithHeaders } from '../itemsHeader'
import { styles as getStyles } from './styles'

const tableRowsCount = 3

const InvoiceItemsTable = ({ invoice, type }) => {
  var styles = getStyles(invoice)
  const options = { maximumFractionDigits: 2, minimumFractionDigits: 2 }
  let conversion_factor = 1
  let currency_symbol = invoice.company.currency_symbol
  const is_export = invoice.is_export == 1
  if (
    (invoice.is_export == 1 || invoice.is_multi_currency == 1) &&
    invoice.invoice_settings.show_both_currencies != true
  ) {
    currency_symbol = invoice.export_details[0].currency_symbol
    conversion_factor = invoice.export_details[0].conversion_factor
  }

  invoice.itemsWithHeaders = mergeItemsWithHeaders(invoice)
  const { hide_qty, show_free_qty, show_discount_col } = getInvoiceSettings(invoice)

  const no_of_default_cols = 6 + (show_free_qty ? 0.8 : 0) - hide_qty + show_discount_col
  const no_of_dc_cols = 3 - hide_qty
  const no_of_with_out_tax_cols = 5 + (show_free_qty ? 1 : 0) - hide_qty + show_discount_col

  return (
    <>
      <View
        style={{ ...styles.invoiceItemsTable_tableContainer, borderColor: invoice.company.color }}
      >
        <InvoiceTableHeader
          invoice={invoice}
          type={type}
          show_free_qty={show_free_qty}
          show_discount_col={show_discount_col}
          no_of_default_cols={no_of_default_cols}
          no_of_dc_cols={no_of_dc_cols}
          no_of_with_out_tax_cols={no_of_with_out_tax_cols}
        />
        <InvoiceTableRow
          items={invoice.items}
          data={invoice}
          invoice={invoice}
          type={type}
          show_free_qty={show_free_qty}
          show_discount_col={show_discount_col}
          no_of_default_cols={no_of_default_cols}
          no_of_dc_cols={no_of_dc_cols}
          no_of_with_out_tax_cols={no_of_with_out_tax_cols}
        />
        {type != 'Delivery Challan' && type != HIDE_PRICES && (
          <>
            {invoice.document_type != 'estimates' && (
              <InvoiceTableFooter
                items={invoice.tax}
                total={invoice.total}
                netAmount={invoice.net_amount}
                invoice={invoice}
              />
            )}

            {invoice.document_type == 'estimates' && invoice.hide_totals == 0 && (
              <InvoiceTableFooter
                items={invoice.tax}
                total={invoice.total}
                netAmount={invoice.net_amount}
                invoice={invoice}
              />
            )}
          </>
        )}
      </View>
    </>
  )
}

export default InvoiceItemsTable
