import { message } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import React from 'react'

const defaultDuration = 2

const showToast = (
    type: 'success' | 'error' | 'info' | 'warning',
    text: string,
    duration: number = defaultDuration,
  ) => {
    const close = () => {
      message.destroy() // Manually close the message
    }

    message.open({
      type,
      content: (
        <div className="flex justify-between items-center">
          <span>{text}</span>
          <CloseOutlined onClick={close} className="ml-2 cursor-pointer scale-90" />
        </div>
      ),
      duration,
    })
  }

  // Extend `message` with custom functions
;(message as any).successWithClose = (text: string, duration: number = defaultDuration) =>
  showToast('success', text, duration)
;(message as any).errorWithClose = (text: string, duration: number = defaultDuration) =>
  showToast('error', text, duration)
;(message as any).infoWithClose = (text: string, duration: number = defaultDuration) =>
  showToast('info', text, duration)
;(message as any).warningWithClose = (text: string, duration: number = defaultDuration) =>
  showToast('warning', text, duration)

export default message
