import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react'
import AgGridTable from 'components/agGrid/agGridTable'
import { Button, Input, Modal, Tooltip, Tag, message } from 'antd'
import AutoFinish from 'components/autoFinish'
import ProductForm from 'components/forms/productForm'
import { SyncOutlined } from '@ant-design/icons'
import { getAPI } from 'services/jwt'

interface Product {
  id: number
  item_name: string
  product_name?: string
  qty: number
  tax: number
  unit_price: number
  purchase_unit_price: number
  purchase_price: number
  price_with_tax: number
  discount_amount: number
  discount: number
  description: string
  status: string
  product_id: number
  variant_name?: string
  net_amount: number
  tax_amount: number
  total_amount: number
  price: number
  hsn_code: string
  unit: string
  item_type: string
  is_delete: string
}

interface ProductsTableProps {
  initialData: Product[]
  currencySymbol: string
  taxes: string[]
  onAddItemsToBill: (products: any[]) => void
  onCancel: () => void
  warehouse_id: number
}

const UploadedProductsTable = forwardRef<any, ProductsTableProps>((props, ref) => {
  const [products, setProducts] = useState<Product[]>(props.initialData)
  const [selectedRows, setSelectedRows] = useState<Product[]>([])
  const [confirmModal, setConfirmModal] = useState<boolean>(false)
  const [selectedProduct, setSelectedProduct] = useState<number>(-1)
  const gridRef = useRef<any>()
  const autoFinishRef = useRef<any>()
  const productFormRef = useRef<any>()
  const productsRef = useRef(products)

  useEffect(() => {
    setProducts(props.initialData)
  }, [props.initialData])

  useEffect(() => {
    productsRef.current = products
  }, [products])

  const onRowSelected = (event: any) => {
    const selectedNodes = event.api.getSelectedNodes()
    const selectedData = selectedNodes.map((node: any) => node.data)
    setSelectedRows(selectedData)
  }

  const onUpdateValue = (data: Product, key: string, newValue: number | string | boolean) => {
    // @ts-ignore
    data[key] = newValue
    if (
      [
        'tax',
        'unit_price',
        'price_with_tax',
        'purchase_unit_price',
        'purchase_price',
        'discount',
        'qty',
      ].includes(key)
    ) {
      newValue = parseFloat(newValue as string)

      if (key == 'tax') {
        data['price_with_tax'] = data['unit_price'] * (1 + newValue / 100)
        if (data['purchase_unit_price']) {
          data['purchase_price'] = data['purchase_unit_price'] * (1 + newValue / 100)
        }
      } else if (key == 'unit_price') {
        data['price_with_tax'] = newValue * (1 + data['tax'] / 100)
        data['price'] = data['unit_price']
      } else if (key == 'price_with_tax') {
        data['unit_price'] = (newValue * 100) / (100 + data['tax'])
      } else if (key == 'purchase_unit_price') {
        data['purchase_price'] = newValue * (1 + data['tax'] / 100)
      } else if (key == 'purchase_price') {
        data['purchase_unit_price'] = (newValue * 100) / (100 + data['tax'])
      }

      data['discount_amount'] = data['unit_price'] * (data['discount'] / 100)
      data['net_amount'] = data['unit_price'] * data['qty'] - data['discount_amount']
      data['tax_amount'] = data['net_amount'] * (data['tax'] / 100)
      data['total_amount'] = data['net_amount'] + data['tax_amount'] - data['discount_amount']
    }

    if (key == 'product_id') {
      data['status'] = 'update'
    }

    gridRef?.current?.updateRowValue(data)
    if (key == 'is_delete') {
      setProducts(prev => [...prev].filter((product: Product) => product.id != data.id))
      return
    } else {
      setProducts(prev =>
        [...prev].map((product: Product) => {
          if (product.id == data.id) {
            return data
          }
          return product
        }),
      )
    }
  }

  const columns = [
    {
      title: 'Product Name',
      dataIndex: 'item_name',
      editable: false,
      minWidth: 400,
      suppressHeaderKeyboardEvent: (params: any) => {
        var key = params.event.key
        return (
          key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Enter' || params.event.ctrlKey
        )
      },
      suppressKeyboardEvent: (params: any) => {
        var key = params.event.key
        return (
          key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Enter' || params.event.ctrlKey
        )
      },
      cellRenderer: (params: any) => {
        return (
          <div className="flex flex-col w-full  my-2 items-center">
            <div className="flex justify-between items-center w-full">
              <Tooltip title="Remove this item">
                <button
                  className="w-6 h-6 rounded-full bg-red-200 hover:bg-red-300 flex items-center justify-center focus:outline-none transition-colors duration-200 ease-in-out"
                  onClick={() => {
                    onUpdateValue(params.data, 'is_delete', 'true')
                  }}
                >
                  <svg
                    className="w-4 h-4 text-red-600"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </button>
              </Tooltip>

              <div className="w-full items-center">
                <div className="flex w-full">
                  <AutoFinish
                    className=" mx-2"
                    style={{ width: '70%' }}
                    placeholder={'Search Products'}
                    searchType={'product'}
                    ref={autoFinishRef}
                    callData={(value: any, option: any) => {
                      onUpdateValue(params.data, 'product_id', option.product_id)
                      onUpdateValue(params.data, 'item_name', option.product_name)
                      onUpdateValue(params.data, 'tax', option.tax)

                      if (option.variant_name) {
                        onUpdateValue(params.data, 'variant_name', option.variant_name)
                        onUpdateValue(params.data, 'variant_id', option.variant_id)
                      }
                    }}
                    dropdownMatchSelectWidth={true}
                    autoFetchInitialData={false}
                    searchText={params.value ?? ''}
                  />
                  <div className="mt-2">
                    {params.data.product_id == -1 ? (
                      params.data.isAdding ? (
                        <Tag color="green" className="ml-1" icon={<SyncOutlined spin />}>
                          Adding
                        </Tag>
                      ) : (
                        <Tooltip title="A New Product will be created for this.">
                          <Tag color="green" className="ml-1">
                            New
                          </Tag>
                        </Tooltip>
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <div className="italic text-gray-500 font-size-12 mx-2">
                  {params.data.product_id == -1 ? (
                    params.data.isAdding ? (
                      <span>
                        Adding Product to swipe <span className="blink_me">.....</span>
                      </span>
                    ) : (
                      <span>New product? Click here to select an existing one.</span>
                    )
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      },
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      editable: true,
      minWidth: 150,
      cellEditor: 'agTextCellEditor',
      cellEditorParams: {
        maxLength: 20,
      },
      // cellClassRules: {
      //   'bg-gray-75': 'data.variant_name != null && data.variant_name != ""',
      // },
    },
    {
      title: 'Tax',
      dataIndex: 'tax',
      valueFormatter: (params: any) => `${params.value} %`,
      // cellClassRules: {
      //   'bg-gray-75': 'data.variant_name && data.variant_name != null && data.variant_name != ""',
      // },
      minWidth: 150,
      editable: (params: any) => params.data.product_id == -1,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: props.taxes,
        valueListGap: 10,
        valueListMaxHeight: 200,
        allowTyping: true,
        filterList: true,
        highlightMatch: true,
      },
    },
    {
      title: 'Unit Price',
      dataIndex: 'unit_price',
      valueFormatter: (params: any) =>
        `${props.currencySymbol} ${(parseFloat(params.value) || 0).toFixed(2)}`,
      editable: true,
    },

    {
      title: 'Price With Tax',
      dataIndex: 'price_with_tax',
      valueFormatter: (params: any) =>
        `${props.currencySymbol} ${(parseFloat(params.value) || 0).toFixed(2)}`,
    },
    {
      title: 'Discount(%)',
      dataIndex: 'discount',
      editable: true,
      valueFormatter: (params: any) => `${params.value} %`,
    },
    {
      title: 'Net Amount',
      dataIndex: 'net_amount',
      editable: false,
      valueFormatter: (params: any) =>
        `${props.currencySymbol} ${(parseFloat(params.value) || 0).toFixed(2)}`,
    },
    {
      title: 'Tax Amount',
      dataIndex: 'tax_amount',
      editable: false,
      valueFormatter: (params: any) =>
        `${props.currencySymbol} ${(parseFloat(params.value) || 0).toFixed(2)}`,
    },
    {
      title: 'Total Amount',
      dataIndex: 'total_amount',
      editable: false,
      valueFormatter: (params: any) =>
        `${props.currencySymbol} ${(parseFloat(params.value) || 0).toFixed(2)}`,
    },

    {
      title: 'Description',
      dataIndex: 'description',
      editable: true,
      cellEditor: 'richTextEditor',
      cellEditorPopup: true,
      cellEditorPopupPosition: 'under',
      cellEditorParams: {
        maxLength: 100,
      },
      cellRenderer: (params: any) => (
        <div
          style={{ width: '100%', height: '30px', overflow: 'clip' }}
          dangerouslySetInnerHTML={{ __html: params.value }}
        />
      ),
    },
    {
      dataIndex: 'is_delete',
      hide: true,
      filter: true,
    },
  ]

  useImperativeHandle(ref, () => ({
    getSelectedRows: () => selectedRows,
    getGridApi: () => gridRef.current?.api,
  }))

  const addItemsToBill = async (ignoreNewProducts: boolean) => {
    const addedProducts = productsRef.current.filter((product: Product) => product.product_id != -1)
    if (addedProducts.length <= products.length && !ignoreNewProducts) {
      setConfirmModal(true)
      return
    }

    let dataProducts = addedProducts.map((product: Product, index: number) => {
      return {
        ...product,
        key: index + 1,
        qty: product?.qty ?? 1,
        discount_value: (product.total_amount * product.discount) / 100,
        discount_net_value: (product.net_amount * product.discount) / 100,
        discount_unit_price_value: product.discount_amount,
        discount_price_with_tax_value: (product.price_with_tax * product.discount) / 100,
        is_discount_percent: 1,
        price: 0,
        unit: product?.unit,
        hsn_code: product?.hsn_code,
        product_type: product?.item_type,
        master_data: {
          price_with_tax: 0,
          unit_price: 0,
          discount: product.discount,
          cess: 0,
          tax: product.tax,
          purchase_price: product.purchase_price,
          purchase_unit_price: product.purchase_unit_price,
        },
        selected_discount_type: 'discount',
      }
    })

    props.onAddItemsToBill(dataProducts)

    setConfirmModal(false)

    setProducts([])
  }

  const saveNewItems = async () => {
    const newProducts = products.filter((product: Product) => product.product_id == -1)

    if (newProducts.length > 0) {
      for (const product of newProducts) {
        try {
          onUpdateValue(product, 'isAdding', true)

          const response = await productFormRef.current.addAndSubmit(product.item_name, 1, {
            ...product,
            upload_id: product.id,
            purchase_unit_price: product.unit_price,
            purchase_price: product.price_with_tax,
            product_name: product.item_name,
            is_price_with_tax: false,
            unit_price: 0,
            price_with_tax: 0,
            price: 0,
          })

          await new Promise(resolve => setTimeout(resolve, 1000))
        } catch (error) {
          onUpdateValue(product, 'isAdding', false)
        }
      }
    }
  }

  const saveAndAddItemsToBill = async (ignoreNewProducts: boolean) => {
    if (!ignoreNewProducts) {
      const newProducts = products.filter((product: Product) => product.product_id == -1)

      if (newProducts.length > 0) {
        var addedProducts: string[] = []
        var duplicateProducts: string[] = []
        for (const product of newProducts) {
          try {
            if (addedProducts.includes(product.item_name)) {
              duplicateProducts.push(product.item_name)
              continue
            } else {
              addedProducts.push(product.item_name)
            }
            onUpdateValue(product, 'isAdding', true)
            const response = await productFormRef?.current?.addAndSubmit(product.item_name, 1, {
              ...product,
              upload_id: product.id,
              purchase_unit_price: product.unit_price,
              purchase_price: product.price_with_tax,
              product_name: product.item_name,
              is_price_with_tax: false,
              unit_price: 0,
              price_with_tax: 0,
              price: 0,
              unit: product.unit,
              hsn_code: product.hsn_code,
              product_type: product.item_type,
            })

            await new Promise(resolve => setTimeout(resolve, 1000))
          } catch (error) {
            onUpdateValue(product, 'isAdding', false)
          }
        }
      }
    }
    const products_length = products.length
    const seconds_saved = products_length * 40
    const minutes = Math.ceil(seconds_saved / 60)
    if (minutes > 0) {
      message.success(
        `Awesome! You've just saved ${minutes} minute${minutes > 1 ? 's' : ''} with SwipeAI. 🎉`,
      )
    }

    addItemsToBill(true)
    props.onCancel()
    // Add items to the bill
  }

  const setProductID = (product_details: any) => {
    setProducts(prev => {
      return [...prev].map((product: Product) => {
        if (
          product.id == product_details.upload_id ||
          product.item_name == product_details.product_name
        ) {
          let data = {
            ...product_details,
            ...product,
            product_id: product_details.product_id,
            tax: product_details.tax,
            item_name: product_details.product_name ?? product.item_name,
            price: 0,
            discount_value: (product.total_amount * product.discount) / 100,
            discount_net_value: (product.net_amount * product.discount) / 100,
            discount_unit_price_value: product.discount_amount / product.qty,
            discount_price_with_tax_value: (product.price_with_tax * product.discount) / 100,
            is_discount_percent: 1,
            selected_discount_type: 'discount',
            isAdding: false,
            status: 'update',
            purchase_unit_price: product_details.purchase_unit_price,
            purchase_price: product_details.purchase_price,
            master_data: {
              price_with_tax: 0,
              unit_price: 0,
              discount: product.discount,
              cess: 0,
              tax: product.tax,
              purchase_price: product.purchase_price,
              purchase_unit_price: product.purchase_unit_price,
              unit: product_details.unit,
              hsn_code: product_details.hsn_code,
              product_type: product_details.item_type,
            },
          }

          // props.onAddItemsToBill([{ ...data }])
          return data
        }

        return { ...product }
      })
    })
  }

  return (
    <div>
      <div className="">
        <AgGridTable
          dataSource={products}
          onReady={async (event: any) => {
            gridRef.current.setFilter({
              is_delete: {
                type: 'set',
                values: ['false', '0', null, undefined],
              },
            })
          }}
          columns={columns}
          ref={gridRef}
          onUpdateValue={onUpdateValue}
          // onRowSelected={(event: any) => {
          //   onRowSelected(event)
          // }}
          isRowSelectable={(params: any) => {
            return params.data.product_id == -1
          }}
          readOnlyEdit={true}
          singleClickEdit={true}
          // rowHeight={70}
          // getRowHeight={(params: any) => {
          //   if (params.data.product_id == -1) {
          //     return 70
          //   }
          //   return 30
          // }}
        />
      </div>
      <div className="flex flex-column mt-2">
        <div>
          {/* <span className="font-weight-bolder">Selected Records:</span> {selectedRows.length} */}
          <span className=" text-gray-700 1 mr-2">
            <div
              className="bg-card-forest mr-2 inline-block border-gray-2"
              style={{ width: '10px', height: '10px' }}
            />{' '}
            {'New '}
          </span>
          <span className=" text-gray-700 1 mr-2 ">
            <div
              className="bg-warning mr-2 inline-block border-gray-2"
              style={{ width: '10px', height: '10px' }}
            />{' '}
            {'Existing '}
          </span>
        </div>

        <div className="flex ml-auto justify-around">
          <Button type="danger" onClick={props.onCancel} className="mr-2">
            Cancel
          </Button>

          <Button type="success" onClick={() => saveAndAddItemsToBill(false)}>
            Add Items to Bill
          </Button>
        </div>
      </div>

      {/* Confirmation modal */}
      <Modal
        title={'Confirm Changes ? '}
        open={confirmModal}
        onCancel={() => setConfirmModal(false)}
        width={640}
        centered
        closeIcon={<i className="fa-solid fa-xmark"></i>}
        footer={
          <>
            <Button key="back" onClick={() => setConfirmModal(false)}>
              Close
            </Button>
            <Button
              className="font-weight-bold"
              // @ts-ignore
              type={'danger-3'}
              onClick={() => {
                addItemsToBill(true)
              }}
            >
              Proceed Anyway
            </Button>
          </>
        }
      >
        <>
          <span>
            You have new products in the list, only existing products will be added to bill.
            <br />
            <span className="text-danger font-weight-bolder">Do you want to proceed anyway ?</span>
          </span>
        </>
      </Modal>

      <ProductForm
        ref={productFormRef}
        onAPICalled={(newData: Product) => {
          setProductID(newData)
        }}
      />
    </div>
  )
})

export default React.memo(UploadedProductsTable)
