import { Button, Form, Modal, Watermark } from 'antd'
import { formatNumber, getTotalQty } from 'components/other/calculation'
import { EnhancedQRCode } from 'components/other/qrCodeFilePosBill'
import { EnhancedQRCodeGoogle } from 'components/other/qrCodeGoogleReviews'
import parse from 'html-react-parser'
import React, { Component } from 'react'
import ReactToPrint from 'react-to-print'
import store from 'store'
import styled from 'styled-components'
import '../../kit/core/css/thermalprint.scss'
import { connect } from 'react-redux'

const MyTable = styled.table`
  td,
  th,
  tr,
  table {
    border-top: 1px dashed black;
    border-bottom: 1px dashed black !important;
    border-collapse: collapse;
  }

  thead tr th {
    padding: 2px 0px !important;
  }

  th {
    display: table-cell;
    vertical-align: inherit;
    font-weight: normal !important;
    text-align: -internal-center;
    padding: none;
  }

  tbody tr {
    border-top: 0px dashed black;
    page-break-inside: avoid;
  }

  tbody td {
    padding: 2px 0px !important;
  }

  td.description,
  th.description {
    width: 36%;
  }

  td.paid_amount,
  th.paid_amount {
    width: 27%;
  }

  td.quantity,
  th.quantity {
    width: 27%;
    word-break: break-all;
  }

  td.price,
  th.price {
    width: 50%;
    word-break: break-all;
    text-align: left;
  }

  td.amount,
  th.amount {
    width: 50%;
    word-break: break-all;
    text-align: right;
  }

  td.description2,
  th.description2 {
    width: 40%;
  }

  td.quantity2,
  th.quantity2 {
    width: 12%;
    word-break: break-all;
  }

  td.price2,
  th.price2 {
    width: 18%;
    word-break: break-all;
  }

  td.amount2,
  th.amount2 {
    width: 20%;
    word-break: break-all;
  }

  td.discount2,
  th.discount2 {
    width: 20%;
    word-break: break-all;
  }
`

function truncateString(str, num) {
  return str.length > num ? str.substring(0, num) + '...' : str
}

class ThermalPrint extends Component {
  constructor(props) {
    super(props)
    this.state = {
      directPrint: false,
    }
  }

  componentDidMount = () => {
    this.props.onRef && this.props.onRef(this)
  }

  showModal = () => {
    this.setState({ directPrint: true, open: true }, () => {
      let id = null

      id = setTimeout(() => {
        if (this.props?.isDirectPrint) {
          document.getElementById('thermal-print-button').click()
        }
        clearTimeout(id)
      }, 100)
    })
  }

  render() {
    const { invoice_settings } = this.props.invoiceData ? this.props.invoiceData : {}

    return (
      <div>
        <Modal
          title={this.props.type == 'expense' ? 'Expense' : this.props.type}
          centered
          open={this.props.flag}
          style={{ top: 18 }}
          onCancel={() => this.props.onClose()}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          className="thermal-print"
          width={640}
          footer={[
            <ReactToPrint
              key={1}
              trigger={() => (
                <button style={{ visibility: 'hidden', display: 'none' }} id="thermal-print-button">
                  Print
                </button>
              )}
              content={() => this.componentRef}
            ></ReactToPrint>,

            <Form
              key="submit"
              onFinish={() => document.getElementById('thermal-print-button').click()}
            >
              <Button htmlType="submit" type="primary">
                Print
              </Button>
            </Form>,
          ]}
        >
          {this.props.type != 'expense' && this.props.type != undefined && (
            <div>
              <ComponentToPrint
                ref={el => (this.componentRef = el)}
                flagDiscount={this.props.flagDiscount}
                data={this.props.invoiceData}
                type={this.props.type}
                user={this.props.user}
                invoice_settings={invoice_settings}
              />
            </div>
          )}
          {this.props.type == 'expense' && (
            <div>
              <ComponentToPrintExpense
                ref={el => (this.componentRef = el)}
                data={this.props.expenseData}
                type={this.props.type}
                user={this.props.user}
              />
            </div>
          )}
        </Modal>
      </div>
    )
  }
}

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const customer_pending_balance =
      this.props.data.customer.balance + this.props.data.customer.opening_balance

    const dotOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    return (
      <div id="thermal-print">
        {store.get('printer_type') == 1 && (
          <Watermark
            height={50}
            width={135}
            gap={[50, 50]}
            image={store.get('paid') == 0 ? '/resources/images/watermark_10.png' : ''}
          >
            <div className="ticket1 font-size-16">
              <div className="flex flex-wrap">
                {store.get('show_company_details') && (
                  <div id="printable" className="w-full text-center font-size-16">
                    {store.get('logo') != '' && store.get('logo') != undefined && (
                      <div className="flex justify-center">
                        <img
                          src={store.get('logo')}
                          className="mb-2 height-100 thermal-print-img"
                          alt="Swipe"
                        />
                      </div>
                    )}
                    {store.get('organization_name')?.toLowerCase() !=
                    store.get('company_name')?.toLowerCase() ? (
                      <span>
                        <h5
                          className="font-weight-bold"
                          style={{ fontSize: `${store.get('organization_name_font_size')}px` }}
                        >
                          {store.get('organization_name')}
                        </h5>
                        <h6
                          className="font-weight-bold"
                          style={{ fontSize: `${store.get('company_name_font_size')}px` }}
                        >
                          {store.get('company_name')}
                        </h6>
                      </span>
                    ) : (
                      <h6
                        className="font-weight-bold"
                        style={{ fontSize: `${store.get('company_name_font_size')}px` }}
                      >
                        {store.get('company_name')}
                      </h6>
                    )}
                    <h6 className="font-size-18 font-weight-bold mb-0">{store.get('address_1')}</h6>
                    <h6 className="font-size-18 font-weight-bold mb-0">{store.get('address_2')}</h6>
                    <h6 className="font-size-18 font-weight-bold">
                      {store.get('company_city')}, {store.get('company_pincode')}{' '}
                    </h6>
                    {store.get('gstin').length == 15 && (
                      <h6 className="font-size-18 font-weight-bold mb-0">
                        GSTIN: {store.get('gstin')}
                      </h6>
                    )}
                    <h6 className="font-size-18 font-weight-bold">
                      Phone: {store.get('company_mobile')}
                    </h6>
                    {/*<h6 className="print-font-3">**** Duplicate Copy ****</h6>*/}
                  </div>
                )}

                <div className="w-full text-left font-size-18">
                  {this.props.data.customer.name != null && this.props.data.customer.name != '' && (
                    <>
                      <p className="borderdashed mb-1"></p>
                      <h6 className="font-size-18">Name: {this.props.data.customer.name}</h6>
                    </>
                  )}
                  {this.props.data.customer.phone != null &&
                    this.props.data.customer.phone.length == 10 && (
                      <>
                        <h6 className="font-size-18">Phone: {this.props.data.customer.phone}</h6>
                      </>
                    )}
                  {this.props.data.customer.gstin != '' &&
                  this.props.data.customer.gstin.length == 15 ? (
                    <>
                      <h6 className="font-size-18">GSTN: {this.props.data.customer.gstin}</h6>

                      <p className="borderdashed mb-1"></p>
                    </>
                  ) : (
                    <p className="borderdashed mb-1"></p>
                  )}
                </div>

                {this.props.type != 'Purchase' &&
                  this.props.type != 'Purchase Order' &&
                  this.props.type != 'Purchase Return / Debit Note' && (
                    <div className="w-full text-left">
                      {this.props.data.customer.shipping.address_1 != null &&
                        this.props.data.customer.shipping.address_1 != '' && (
                          <h6 className="font-size-18">
                            {this.props.data.customer.shipping.address_1}
                          </h6>
                        )}
                      {this.props.data.customer.shipping.address_2 != null &&
                        this.props.data.customer.shipping.address_2 != '' && (
                          <h6 className="font-size-18">
                            {this.props.data.customer.shipping.address_2}
                          </h6>
                        )}
                      {this.props.data.customer.shipping.city != null &&
                        this.props.data.customer.shipping.city != '' && (
                          <>
                            <h6 className="font-size-18">
                              {this.props.data.customer.shipping.city}{' '}
                              {this.props.data.customer.shipping.pincode}
                            </h6>
                            <p className="borderdashed mb-1"></p>{' '}
                          </>
                        )}
                    </div>
                  )}

                <div className="w-full text-left font-size-18">
                  {this.props.data.notes != '' && this.props.data.notes != 'None' ? (
                    <>Instructions: {this.props.data.notes}</>
                  ) : (
                    ''
                  )}

                  {this.props.data.notes != '' && this.props.data.notes != 'None' && (
                    <p className="borderdashed mb-1"></p>
                  )}
                </div>

                <div className="w-1/2 text-left">
                  {this.props.data.payment != null ||
                    (this.props.data.payment != '' && (
                      <h6 className="font-size-18">
                        Payment mode:{' '}
                        {Array.from(
                          new Set(this.props.data.payments.map(item => item.payment_mode)),
                        ).join(',')}
                      </h6>
                    ))}

                  {this.props.id != '' && (
                    <h6 className="font-size-18">Bill No: {this.props.data.serial_number}</h6>
                  )}
                </div>
                <div className="w-1/2 text-left">
                  <h6 className="font-size-18">Date: {this.props.data.document_date}</h6>
                  <h6 className="font-size-18">Time: {this.props.data.invoice_time}</h6>
                </div>

                <div className="w-full mt-2">
                  <MyTable className="table print-table-1 mb-1">
                    <thead className="py-1">
                      <tr className="font-size-16 text-left">
                        <th colSpan="4" className="description">
                          Item Name
                        </th>
                      </tr>
                      <tr className="font-size-16">
                        <th className="price">Qty x Price</th>
                        <th className="amount">
                          Amount {this.props.data.total_discount > 0 ? '(Savings)' : ''}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.data.items.map((item, i) => {
                        return (
                          <React.Fragment key={i}>
                            <tr>
                              <td colSpan="4" className="description font-size-21">
                                {item.product_name.length > 100
                                  ? item.product_name.substring(0, 100)
                                  : item.product_name}{' '}
                                {item.variant_name}
                                <span className="font-size-14">
                                  <>
                                    {item.discount != 0 &&
                                      '[' +
                                        this.props?.user?.selectedCompany?.labels?.mrp +
                                        ' ' +
                                        item.price_with_tax +
                                        ']'}
                                    {item.hsn_code != '' && '[HSN : ' + item.hsn_code + ' ]'}
                                  </>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="price font-size-21">
                                {(item.qty * item.conversion_rate).toLocaleString('en-IN', {
                                  maximumFractionDigits: 3,
                                })}
                                {item.item_unit != '' &&
                                item.item_unit != 'NA' &&
                                item.item_unit != 'NONE' &&
                                item.item_unit != 'None' &&
                                item.item_unit != 'OTH' &&
                                item.item_unit != undefined ? (
                                  <>({item.item_unit == '' ? item.unit : item.item_unit})</>
                                ) : (
                                  <></>
                                )}
                                {' x '}
                                {(item.price_with_tax * (1 - item.discount / 100)).toFixed(2)}
                              </td>
                              <td className="amount font-size-21">
                                {item.total_amount}
                                {this.props.data.total_discount != 0 && item.discount != 0
                                  ? ' (' +
                                    (
                                      (item.qty * item.price_with_tax * item.discount) /
                                      100
                                    ).toFixed(2) +
                                    ')'
                                  : ''}
                              </td>
                            </tr>
                            {item.description != '' &&
                              item.description != '<p><br></p>' &&
                              store.get('show_product_description') && (
                                <tr className="font-size-16">
                                  <td colSpan="4" className="py-1">
                                    {parse(item.description)}
                                  </td>
                                </tr>
                              )}

                            {item.tax_amount != 0 && (
                              <tr className="font-size-16">
                                {this.props.data.tax_type == 'CGST' ? (
                                  <>
                                    <td colSpan="1" className="py-1">
                                      {this.props.data.tax_labels.CGST} {item.tax / 2}%{' '}
                                      {Number(Math.round(item.tax_amount / 2 + 'e2') + 'e-2')}
                                    </td>
                                    <td colSpan="1" className="py-1 text-right">
                                      {this.props.data.tax_labels.SGST} {item.tax / 2}%{' '}
                                      {Number(Math.round(item.tax_amount / 2 + 'e2') + 'e-2')}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td colSpan="2" className="py-1">
                                      {this.props.data.tax_labels.IGST} {item.tax}%{' '}
                                      {Number(Math.round(item.tax_amount + 'e2') + 'e-2')}
                                    </td>
                                  </>
                                )}
                              </tr>
                            )}
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                  </MyTable>
                </div>

                <div className="flex flex-wrap">
                  <div className="w-1/2 text-right py-0 ">
                    <h6 className="pl-2 mb-2 font-size-16">Items/Qty :</h6>
                  </div>
                  <div className="w-1/2 pr-2">
                    <h6 className="mb-2 font-size-16">
                      {this.props.data.items.length +
                        ' / ' +
                        formatNumber(getTotalQty(this.props.data.items))}
                    </h6>
                  </div>

                  {this.props.data.document_custom_additional_charges &&
                    this.props.data.document_custom_additional_charges.map((item, i) => (
                      <>
                        <div className="w-1/2 text-left">
                          <h6 className="font-size-21 pl-2 mb-0">
                            <strong>{item.name}:</strong>
                          </h6>
                        </div>
                        <div className="w-1/2 pr-2 text-right">
                          <h6 className="font-size-21 mb-0">
                            {item.type == -1 ? '(-) ' : ''}
                            <strong>
                              <span className="mr-1">
                                {this.props?.user?.selectedCompany?.currency_symbol}
                              </span>
                              {item.total_amount}
                            </strong>
                          </h6>
                        </div>
                      </>
                    ))}

                  <div className="w-1/2 text-left">
                    <h6 className="font-size-21 pl-2 mb-0">
                      <strong>Subtotal:</strong>
                    </h6>
                  </div>
                  <div className="w-1/2 pr-2 text-right">
                    <h6 className="font-size-21 mb-0">
                      <strong>
                        <span className="mr-1">
                          {this.props?.user?.selectedCompany?.currency_symbol}
                        </span>
                        {Number(
                          this.props.data.total_discount + this.props.data.total_amount,
                        ).toFixed(2)}
                      </strong>
                    </h6>
                  </div>

                  {this.props.data.total_discount > 0 && (
                    <>
                      <div className="w-1/2 text-left">
                        <h6 className="font-size-21 pl-2 mb-0">
                          <strong>Discount:</strong>
                        </h6>
                      </div>
                      <div className="w-1/2 pr-2 text-right">
                        <h6 className="font-size-21 mb-0">
                          <strong>
                            -{' '}
                            <span className="mr-1">
                              {this.props?.user?.selectedCompany?.currency_symbol}
                            </span>
                            {this.props.data.total_discount}
                          </strong>
                        </h6>
                      </div>
                    </>
                  )}

                  <div id="printable" className="w-full text-left">
                    <p className="borderdashed mb-2"></p>
                  </div>

                  <div className="w-1/2 text-left">
                    <h6 className="font-size-22 pl-2 mb-0">
                      <strong>Total Amount:</strong>
                    </h6>
                  </div>
                  <div className="w-1/2 pr-2 text-right">
                    <h6 className="font-size-22 mb-0">
                      <strong>
                        <span className="mr-1">
                          {this.props?.user?.selectedCompany?.currency_symbol}
                        </span>
                        {this.props.data.total_amount.toLocaleString('en-IN', dotOptions)}
                      </strong>
                    </h6>
                  </div>
                  {customer_pending_balance < 0 &&
                    this.props.invoice_settings?.show_net_balance == 1 && (
                      <>
                        <div className="w-1/2 text-left">
                          <h6 className="font-size-21 pl-2 mb-1 ">
                            <strong>Total due:</strong>
                          </h6>
                        </div>
                        <div className="w-1/2 pr-2 text-right">
                          <h6 className="font-size-21 mb-1">
                            <strong>
                              <span className="mr-1">
                                {this.props?.user?.selectedCompany?.currency_symbol}
                              </span>
                              {Math.abs(customer_pending_balance.toFixed(2)).toLocaleString(
                                'en-IN',
                                dotOptions,
                              )}
                            </strong>
                          </h6>
                        </div>
                      </>
                    )}

                  <div id="printable" className="w-full text-left">
                    <p className="borderdashed mb-2"></p>
                  </div>
                  {store.get('gstin').length == 15 &&
                    store.get('show_taxable_amount') == 1 &&
                    this.props.data.net_amount > 0 && (
                      <>
                        <div className="w-1/2 text-left py-0">
                          <h6 className="font-size-21 pl-2 mb-0">Taxable Amount</h6>
                        </div>
                        <div className="w-1/2 pr-2 text-right">
                          <h6 className="font-size-21 mb-0">
                            <span className="mr-1">
                              {this.props?.user?.selectedCompany?.currency_symbol}
                            </span>
                            {this.props.data.net_amount.toLocaleString('en-IN', dotOptions)}
                          </h6>
                        </div>
                      </>
                    )}
                  {store.get('gstin').length == 15 && this.props.data.tax_amount > 0 && (
                    <>
                      {this.props.data.tax.map(
                        (item, i) =>
                          this.props.data.company.gstin.length == 15 &&
                          item.tax_amount != 0 && (
                            <>
                              <div className="w-1/2 text-left py-0">
                                <h6 className="font-size-21 pl-2 mb-0 font-weight-medium">
                                  {item.tax_type}
                                  {'\u00A0'}
                                  {item.tax_percent}
                                </h6>
                              </div>
                              <div className="w-1/2 pr-2 text-right">
                                <h6 className="font-size-21 mb-0 font-weight-medium">
                                  <span className="mr-1">
                                    {this.props?.user?.selectedCompany?.currency_symbol}
                                  </span>
                                  {item.tax_amount.toLocaleString('en-IN', dotOptions)}
                                </h6>
                              </div>
                            </>
                          ),
                      )}
                    </>
                  )}

                  {this.props.data.amount_pending > 0 && (
                    <>
                      <div className="w-1/2 text-left">
                        <h6 className="text-gray font-size-21 pl-2 mb-1 ">
                          <strong>Amount Payable:</strong>
                        </h6>
                      </div>
                      <div className="w-1/2 pr-2 text-right">
                        <h6 className="text-gray font-size-21 mb-1">
                          <strong>
                            <span className="mr-1">
                              {this.props?.user?.selectedCompany?.currency_symbol}
                            </span>
                            {this.props.data.amount_pending}
                          </strong>
                        </h6>
                      </div>
                    </>
                  )}
                </div>

                <div id="printable" className="w-full mt-1 mb-1 text-center">
                  <p className="borderdashed mb-1"></p>
                  {this.props.data.upi != '' && this.props.invoice_settings?.show_payment_qr == 1 && (
                    <div className="flex justify-center">
                      <EnhancedQRCode
                        className="mt-2"
                        onRef={ref => (this.table = ref)}
                        value={this.props.data.upi}
                      />
                    </div>
                  )}
                  {store.get('pos_notes') != '' ? store.get('pos_notes') : ''}
                  {store.get('pos_notes') != '' && <p className="borderdashed mb-1"></p>}

                  {store.get('place_id') != '' && store.get('show_google_reviews_qr') == 1 && (
                    <div className="">
                      <h6 className="font-size-21">Review us on Google!</h6>
                      <div className="flex justify-center">
                        <EnhancedQRCodeGoogle
                          height={100}
                          className="mt-2"
                          onRef={ref => (this.table = ref)}
                          value={
                            'https://search.google.com/local/writereview?placeid=' +
                            store.get('place_id')
                          }
                        />
                      </div>
                    </div>
                  )}
                  {store.get('show_thermalprint_terms') && (
                    <div className="w-full mt-2">
                      <h6 className="text-center text-lg font-semibold text-gray-700 ">
                        Terms and Conditions
                      </h6>
                      <div className="p-4 bg-white border-t border-b border-gray-300 rounded-lg text-gray-800 whitespace-pre-line">
                        {this.props.data.terms}
                      </div>
                    </div>
                  )}
                  <h6 className="font-size-18 mt-2">Thank you visit again!</h6>

                  <h6>-------*--------*-------</h6>
                  <h6 className="mb-2 font-size-16" style={{ textTransform: 'none' }}>
                    {this.props.data.company.pos_footer}
                  </h6>
                  {store.get('paid') == 0 && (
                    <img
                      src="/resources/images/logo-w.svg"
                      className="mb-2 height-50 thermal-print-img"
                      alt="Swipe"
                    />
                  )}
                </div>
              </div>
            </div>
          </Watermark>
        )}

        {store.get('printer_type') == 2 && (
          <div className="ticket2 font-size-16">
            <div className="flex flex-wrap mt-2 mb-2">
              {store.get('show_company_details') && (
                <>
                  <div id="printable" className="w-full text-left">
                    <h6 className="font-size-24 mb-2 ml-1">
                      {this.props.data.company.is_composite == 1
                        ? 'BILL OF SUPPLY'
                        : store.get('gstin').length == 15
                        ? 'TAX INVOICE'
                        : 'INVOICE'}
                    </h6>
                  </div>
                  <div id="printable" className="w-3/12 text-left">
                    {store.get('logo') != '' && store.get('logo') != undefined && (
                      <div className="flex justify-center">
                        <img
                          src={store.get('logo')}
                          className="mb-2 thermal-print-img"
                          style={{
                            maxHeight: '12.6rem',
                            maxWidth: '12.6rem',
                            borderRadius: '1rem',
                          }}
                          alt="Swipe"
                        />
                      </div>
                    )}
                  </div>
                  <div id="printable" className="w-9/12 text-left">
                    {store.get('organization_name') != store.get('company_name') ? (
                      <span>
                        <h5
                          className="font-weight-bold"
                          style={{ fontSize: `${store.get('organization_name_font_size')}px` }}
                        >
                          {store.get('organization_name')}
                        </h5>
                        <h6
                          className="font-weight-bold"
                          style={{ fontSize: `${store.get('company_name_font_size')}px` }}
                        >
                          {store.get('company_name')}
                        </h6>
                      </span>
                    ) : (
                      <h6
                        className="font-weight-bold"
                        style={{ fontSize: `${store.get('company_name_font_size')}px` }}
                      >
                        {store.get('company_name')}
                      </h6>
                    )}
                    <h6 className="font-size-18 mb-0">{store.get('address_1')}</h6>
                    <h6 className="font-size-18 mb-0">{store.get('address_2')}</h6>
                    <h6 className="font-size-18">
                      {store.get('company_city')}, {store.get('company_pincode')}{' '}
                    </h6>
                    {store.get('gstin').length == 15 && (
                      <h6 className="font-size-21 mb-0">GST: {store.get('gstin')}</h6>
                    )}
                    <h6 className="font-size-21">Phone: {store.get('company_mobile')}</h6>
                  </div>
                </>
              )}

              <div className="w-full text-left">
                <p className="borderdashed mb-2"></p>
                {this.props.data.customer.name != null && this.props.data.customer.name != '' && (
                  <h6 className="font-size-21 mt-2 mb-2 pl-2">
                    Customer Name: {this.props.data.customer.name}
                  </h6>
                )}
                {this.props.data.customer.phone != null &&
                  this.props.data.customer.phone.length == 10 && (
                    <h6 className="font-size-21 mb-2 pl-2">
                      Customer Phone: {this.props.data.customer.phone}
                    </h6>
                  )}
                {this.props.data.customer.gstin != '' &&
                  this.props.data.customer.gstin.length == 15 && (
                    <h6 className="font-size-18 mb-2 pl-2">
                      GSTIN: {this.props.data.customer.gstin}
                    </h6>
                  )}
                <p className="borderdashed mb-1"></p>
              </div>

              <div className="flex flex-wrap">
                <div className="w-1/2 text-left font-size-21">
                  {this.props.data.customer.billing.address_1 != null &&
                    this.props.data.customer.billing.address_1 != '' && (
                      <>
                        <h6 className="font-size-16 font-weight-bold pl-2">Bill to:</h6>
                        <h6 className="font-size-21 pl-2">
                          {this.props.data.customer.billing.address_1}
                        </h6>
                      </>
                    )}
                  {this.props.data.customer.billing.address_2 != null &&
                    this.props.data.customer.billing.address_2 != '' && (
                      <h6 className="font-size-21 pl-2">
                        {this.props.data.customer.billing.address_2}
                      </h6>
                    )}
                  {this.props.data.customer.billing.city != null &&
                    this.props.data.customer.billing.city != '' && (
                      <>
                        <h6 className="font-size-21 pl-2">
                          {this.props.data.customer.billing.city}{' '}
                          {this.props.data.customer.billing.pincode}
                        </h6>
                      </>
                    )}
                </div>

                {this.props.type != 'Purchase' &&
                  this.props.type != 'Purchase Order' &&
                  this.props.type != 'Purchase Return / Debit Note' && (
                    <div className="w-1/2 text-left font-size-21">
                      {this.props.data.customer.shipping.address_1 != null &&
                        this.props.data.customer.shipping.address_1 != '' && (
                          <>
                            <h6 className="font-size-16 font-weight-bold pl-2">Ship to:</h6>
                            <h6 className="font-size-21 pl-2">
                              {this.props.data.customer.shipping.address_1}
                            </h6>
                          </>
                        )}
                      {this.props.data.customer.shipping.address_2 != null &&
                        this.props.data.customer.shipping.address_2 != '' && (
                          <h6 className="font-size-21 pl-2">
                            {this.props.data.customer.shipping.address_2}
                          </h6>
                        )}
                      {this.props.data.customer.shipping.city != null &&
                        this.props.data.customer.shipping.city != '' && (
                          <>
                            <h6 className="font-size-21 pl-2">
                              {this.props.data.customer.shipping.city}{' '}
                              {this.props.data.customer.shipping.pincode}
                            </h6>
                          </>
                        )}
                    </div>
                  )}
                {/*<div className="w-full text-left font-size-21 ">
                    <p className="borderdashed mb-1"></p>
                  </div>*/}
              </div>

              <div className="w-full text-left font-size-21 ">
                {this.props.data.notes != '' && this.props.data.notes != 'None' ? (
                  <span className="pl-2">Instructions: {this.props.data.notes}</span>
                ) : (
                  ''
                )}

                {this.props.data.notes != '' && this.props.data.notes != 'None' && (
                  <p className="borderdashed mb-1"></p>
                )}
              </div>

              <div className="w-1/2 text-left font-size-16 mt-2">
                <h6 className="font-size-21 pl-2">
                  Payment mode:{' '}
                  {Array.from(
                    new Set(this.props.data.payments.map(item => item.payment_mode)),
                  ).join(',')}
                </h6>
                {this.props.id != '' && (
                  <h6 className="font-size-21 pl-2">Bill No: {this.props.data.serial_number}</h6>
                )}
              </div>
              <div className="w-1/2 text-left font-size-16 mt-2">
                <h6 className="font-size-21">Date: {this.props.data.document_date}</h6>
                <h6 className="font-size-21">Time: {this.props.data.invoice_time}</h6>
              </div>

              <div className="w-full mt-2">
                <MyTable className="table print-table-2 mb-1">
                  <thead className="py-1 mb-5">
                    <tr className="font-size-21">
                      <th className="description2 font-size-21 text-left">Item x Qty</th>
                      {/*<th className="quantity2">Qty</th>*/}
                      <th className="price2 font-size-21 text-left">Price</th>
                      {(this.props.flagDiscount || this.props.data.total_discount != 0) && (
                        <th className="discount2 font-size-21">Disc</th>
                      )}
                      <th className="amount2 font-size-21 text-right">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.data.items.map((item, i) => {
                      return (
                        <React.Fragment key={i}>
                          <tr>
                            <td className="description2 font-size-24">
                              {item.product_name} {item.variant_name} X {item.qty}
                              {/* <br /> */}
                              {item.hsn_code != '' && (
                                <span className="font-size-14">
                                  <br />
                                  HSN : {item.hsn_code}
                                </span>
                              )}
                            </td>
                            {/*<td className="quantity font-size-18-2"></td>*/}
                            <td className="price2 font-size-24">{item.price_with_tax}</td>
                            {(this.props.flagDiscount || this.props.data.total_discount != 0) && (
                              <td className="discount2 font-size-24">
                                {((item.qty * item.price_with_tax * item.discount) / 100).toFixed()}{' '}
                                (-{item.discount}%)
                              </td>
                            )}
                            <td className="amount2 font-size-24 text-right">{item.total_amount}</td>
                          </tr>
                          {item.description != '' &&
                            item.description != '<p><br></p>' &&
                            store.get('show_product_description') && (
                              <tr className="font-size-15">
                                <td colSpan="5" className="py-1">
                                  {parse(item.description)}
                                </td>
                              </tr>
                            )}
                          {item.tax_amount != 0 && (
                            <tr className="font-size-16 w-full">
                              {this.props.data.tax_type == 'CGST' ? (
                                <>
                                  <td colSpan="1" className="py-1">
                                    {this.props.data.tax_labels.CGST} {item.tax / 2}%{' '}
                                    {Number(Math.round(item.tax_amount / 2 + 'e2') + 'e-2')}
                                  </td>
                                  <td colSpan="4" className="py-1 text-right">
                                    {this.props.data.tax_labels.SGST} {item.tax / 2}%{' '}
                                    {Number(Math.round(item.tax_amount / 2 + 'e2') + 'e-2')}
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td colSpan="2" className="py-1">
                                    {this.props.data.tax_labels.IGST} {item.tax}%{' '}
                                    {Number(Math.round(item.tax_amount + 'e2') + 'e-2')}
                                  </td>
                                </>
                              )}
                            </tr>
                          )}
                        </React.Fragment>
                      )
                    })}
                  </tbody>
                </MyTable>
              </div>

              <div className="w-10/12 text-right py-0 ">
                <h6 className="pl-2 mb-2 font-size-18">Items/Qty :</h6>
              </div>
              <div className="w-2/12 pr-4 text-right">
                <h6 className="mb-2 font-size-18">
                  {this.props.data.items.length +
                    ' / ' +
                    formatNumber(getTotalQty(this.props.data.items))}
                </h6>
              </div>

              <div className="w-8/12 text-right">
                <h6 className="text-gray-3 font-size-22 pl-2">
                  <strong>Subtotal:</strong>
                </h6>
              </div>
              <div className="w-4/12 pr-4 text-right">
                <h6 className="text-gray-3 font-size-22">
                  <strong>
                    <span className="mr-1">
                      {this.props?.user?.selectedCompany?.currency_symbol}
                    </span>
                    {Number(this.props.data.total_discount + this.props.data.total_amount).toFixed(
                      2,
                    )}
                  </strong>
                </h6>
              </div>

              {this.props.data.document_custom_additional_charges &&
                this.props.data.document_custom_additional_charges.map((item, i) => (
                  <>
                    <div className="w-8/12 text-right py-0">
                      <h6 className="text-gray-3 font-size-22 pl-2 ">
                        <strong>{item.name}:</strong>
                      </h6>
                    </div>
                    <div className="w-4/12 pr-4 text-right">
                      <h6 className="text-gray-3 font-size-22">
                        {item.type == -1 ? '(-) ' : ''}
                        <strong>
                          <span className="mr-1">
                            {this.props?.user?.selectedCompany?.currency_symbol}
                          </span>
                          {item.total_amount}
                        </strong>
                      </h6>
                    </div>
                  </>
                ))}

              {(this.props.flagDiscount || this.props.data.total_discount != 0) && (
                <>
                  <div className="w-8/12 text-right">
                    <h6 className="text-gray-3 font-size-22 pl-2 mb-1 ">
                      <strong>Total Discount:</strong>
                    </h6>
                  </div>
                  <div className="w-4/12 pr-4 text-right">
                    <h6 className="text-gray-3 font-size-22 mb-1">
                      -{' '}
                      <strong>
                        <span className="mr-1">
                          {this.props?.user?.selectedCompany?.currency_symbol}
                        </span>
                        {this.props.data.total_discount}
                      </strong>
                    </h6>
                  </div>
                </>
              )}

              <div id="printable" className="w-full text-left">
                <p className="borderdashed mb-2"></p>
              </div>
              <div className="w-8/12 text-right">
                <h6 className="font-size-25 pl-2 mb-2 ">
                  <strong>Total Amount:</strong>
                </h6>
              </div>
              <div className="w-4/12 pr-4 text-right">
                <h6 className="font-size-25 mb-2">
                  <strong>
                    <span className="mr-1">
                      {this.props?.user?.selectedCompany?.currency_symbol}
                    </span>
                    {this.props.data.total_amount}
                  </strong>
                </h6>
              </div>
              {customer_pending_balance < 0 && this.props.invoice_settings?.show_net_balance == 1 && (
                <>
                  <div className="w-8/12 text-right">
                    <h6 className="font-size-22 pl-2 mb-2 ">
                      <strong>Total due:</strong>
                    </h6>
                  </div>
                  <div className="w-4/12 pr-4 text-right">
                    <h6 className="font-size-22 mb-2">
                      <strong>
                        <span className="mr-1">
                          {this.props?.user?.selectedCompany?.currency_symbol}
                        </span>
                        {Math.abs(customer_pending_balance.toFixed(2)).toLocaleString(
                          'en-IN',
                          dotOptions,
                        )}
                      </strong>
                    </h6>
                  </div>
                </>
              )}

              <div id="printable" className="w-full text-left">
                <p className="borderdashed mb-2"></p>
              </div>

              {store.get('gstin').length == 15 &&
                store.get('show_taxable_amount') == 1 &&
                this.props.data.net_amount > 0 && (
                  <>
                    <div className="w-8/12 text-right py-0">
                      <h6 className="font-size-21 pl-2 mb-0">Taxable Amount</h6>
                    </div>

                    <div className="w-4/12 pr-4 text-right">
                      <h6 className="font-size-21 mb-0">
                        <span className="mr-1">
                          {this.props?.user?.selectedCompany?.currency_symbol}
                        </span>
                        {this.props.data.net_amount.toLocaleString('en-IN', dotOptions)}
                      </h6>
                    </div>
                  </>
                )}

              {store.get('gstin').length == 15 && this.props.data.tax_amount > 0 && (
                <>
                  {this.props.data.tax.map(
                    (item, i) =>
                      this.props.data.company.gstin.length == 15 &&
                      item.tax_amount != 0 && (
                        <>
                          <div className="w-8/12 text-right py-0">
                            <h6 className="font-size-21 pl-2 mb-0">
                              {item.tax_type}
                              {'\u00A0'}
                              {item.tax_percent}
                            </h6>
                          </div>
                          <div className="w-4/12 pr-4 text-right">
                            <h6 className="font-size-21 mb-0">
                              <span className="mr-1">
                                {this.props?.user?.selectedCompany?.currency_symbol}
                              </span>
                              {item.tax_amount.toLocaleString('en-IN', dotOptions)}
                            </h6>
                          </div>
                        </>
                      ),
                  )}
                </>
              )}

              <div id="printable2" className="w-full mt-1 mb-1 text-center">
                <p className="borderdashed mb-1 font-size-16"></p>
                {this.props.upi != '' && this.props.invoice_settings?.show_payment_qr == 1 && (
                  <div className="flex justify-center">
                    <EnhancedQRCode
                      className="mt-2"
                      onRef={ref => (this.table = ref)}
                      value={this.props.data.upi}
                    />
                  </div>
                )}

                {store.get('pos_notes') != '' ? store.get('pos_notes') : ''}
                {store.get('pos_notes') != '' && <p className="borderdashed mb-1 ml-2"></p>}
                {store.get('show_thermalprint_terms') && (
                  <div className="w-full">
                    <h6 className="text-center text-lg font-semibold text-gray-700 ">
                      Terms and Conditions
                    </h6>
                    <div className="p-4 bg-white border-t border-b border-gray-300 rounded-lg text-gray-800 whitespace-pre-line">
                      {this.props.data.terms}
                    </div>
                  </div>
                )}

                <h6>-------*--------*-------</h6>
                <h6 className="mb-2 font-size-13 ml-2">{this.props.data.company.pos_footer}</h6>
                {store.get('paid') == 0 && (
                  <img
                    src="/resources/images/logo-w.svg"
                    className="mb-2 height-50 thermal-print-img"
                    alt="Swipe"
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {store.get('printer_type') == 3 && (
          <div className="ticket3 font-size-16">
            <div className="flex flex-wrap">
              {store.get('show_company_details') && (
                <div id="printable" className="w-full text-center font-size-16">
                  <div className="flex justify-center">
                    {store.get('logo') != '' && store.get('logo') != undefined && (
                      <img
                        src={store.get('logo')}
                        className="mb-2 height-100 thermal-print-img"
                        alt="Swipe"
                      />
                    )}
                  </div>
                  {store.get('organization_name') != store.get('company_name') ? (
                    <span>
                      <h5
                        className="font-weight-bold"
                        style={{ fontSize: `${store.get('organization_name_font_size')}px` }}
                      >
                        {store.get('organization_name')}
                      </h5>
                      <h6
                        className="font-weight-bold"
                        style={{ fontSize: `${store.get('company_name_font_size')}px` }}
                      >
                        {store.get('company_name')}
                      </h6>
                    </span>
                  ) : (
                    <h6
                      className="font-weight-bold"
                      style={{ fontSize: `${store.get('company_name_font_size')}px` }}
                    >
                      {store.get('company_name')}
                    </h6>
                  )}
                  <h6 className="font-size-16 mb-0">{store.get('address_1')}</h6>
                  <h6 className="font-size-16 mb-0">{store.get('address_2')}</h6>
                  <h6 className="font-size-16">
                    {store.get('company_city')}, {store.get('company_pincode')}{' '}
                  </h6>
                  {store.get('gstin').length == 15 && (
                    <h6 className="font-size-16 mb-0">GST: {store.get('gstin')}</h6>
                  )}
                  <h6 className="font-size-16">Phone: {store.get('company_mobile')}</h6>
                  {/*<h6 className="print-font-3">**** Duplicate Copy ****</h6>*/}
                </div>
              )}

              <div className="w-full text-left font-size-18">
                {this.props.data.customer.name != null && this.props.data.customer.name != '' && (
                  <>
                    <p className="borderdashed mb-1"></p>
                    <h6 className="font-size-16">Name: {this.props.data.customer.name}</h6>
                  </>
                )}
                {this.props.data.customer.phone != null &&
                  this.props.data.customer.phone.length == 10 && (
                    <h6 className="font-size-16">Phone: {this.props.data.customer.phone}</h6>
                  )}
                {this.props.data.customer.gstin.length == 15 && (
                  <h6 className="font-size-16">GSTN: {this.props.data.customer.gstin}</h6>
                )}
                <p className="borderdashed mb-1"></p>
              </div>
              {this.props.type != 'Purchase' &&
                this.props.type != 'Purchase Order' &&
                this.props.type != 'Purchase Return / Debit Note' && (
                  <div className="w-full text-left">
                    {this.props.data.customer.shipping.address_1 != null &&
                      this.props.data.customer.shipping.address_1 != '' && (
                        <h6 className="font-size-16">
                          {this.props.data.customer.shipping.address_1}
                        </h6>
                      )}
                    {this.props.data.customer.shipping.address_2 != null &&
                      this.props.data.customer.shipping.address_2 != '' && (
                        <h6 className="font-size-16">
                          {this.props.data.customer.shipping.address_2}
                        </h6>
                      )}
                    {this.props.data.customer.shipping.city != null &&
                      this.props.data.customer.shipping.city != '' && (
                        <>
                          <h6 className="font-size-16">
                            {this.props.data.customer.shipping.city}{' '}
                            {this.props.data.customer.shipping.pincode}
                          </h6>
                          <p className="borderdashed mb-1"></p>{' '}
                        </>
                      )}
                  </div>
                )}

              <div className="w-full text-left font-size-16">
                {this.props.data.notes != '' && this.props.data.notes != 'None' ? (
                  <>
                    <p className="borderdashed mb-1"></p>
                    <p>Notes: </p>
                    <p className="">{this.props.data.notes}</p>
                  </>
                ) : (
                  ''
                )}

                {this.props.data.notes != '' && this.props.data.notes != 'None' && (
                  <p className="borderdashed mb-1"></p>
                )}
              </div>

              <div className="w-full text-left">
                {this.props.data.payment != null ||
                  (this.props.data.payment != '' && (
                    <h6 className="font-size-16">
                      Payment mode:{' '}
                      {Array.from(
                        new Set(this.props.data.payments.map(item => item.payment_mode)),
                      ).join(',')}
                    </h6>
                  ))}

                {this.props.id != '' && (
                  <h6 className="font-size-16">Bill No: {this.props.data.serial_number}</h6>
                )}
              </div>
              <div className="w-full text-left font-size-16">
                <h6 className="font-size-16">Date: {this.props.data.document_date}</h6>
                <h6 className="font-size-16">Time: {this.props.data.invoice_time}</h6>
              </div>

              <div className="w-full mt-2">
                <MyTable className="table print-table-3 mb-1">
                  <thead className="py-1">
                    <tr className="font-size-13x">
                      <th colSpan="4" className="description text-left">
                        Item Name
                      </th>
                    </tr>
                    <tr className="font-size-13x">
                      <th className="price">Qty x Price</th>
                      <th className="amount">
                        Amt {this.props.data.total_discount > 0 ? '(Disc)' : ''}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="font-size-16">
                    {this.props.data.items.map((item, i) => {
                      return (
                        <React.Fragment key={i}>
                          <tr>
                            <td colSpan="2" className="description">
                              {item.product_name.length > 100
                                ? item.product_name.substring(0, 100)
                                : item.product_name}{' '}
                              {item.variant_name}
                              <span className="font-size-14">
                                <>{item.hsn_code != '' && '[HSN : ' + item.hsn_code + ' ]'}</>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="price">
                              {(item.qty * item.conversion_rate).toLocaleString('en-IN', {
                                maximumFractionDigits: 3,
                              })}
                              {item.item_unit != '' &&
                              item.item_unit != 'NA' &&
                              item.item_unit != 'NONE' &&
                              item.item_unit != 'OTH' &&
                              item.item_unit != undefined ? (
                                <>({item.item_unit == '' ? item.unit : item.item_unit})</>
                              ) : (
                                <></>
                              )}
                              {' x '}
                              {item.price_with_tax}
                            </td>
                            <td className="amount">
                              {item.total_amount}{' '}
                              {this.props.data.total_discount != 0
                                ? '(' +
                                  ((item.qty * item.price_with_tax * item.discount) / 100).toFixed(
                                    2,
                                  ) +
                                  ')'
                                : ''}
                            </td>
                          </tr>
                          {item.tax_amount != 0 && (
                            <tr className="font-size-16">
                              {this.props.data.tax_type == 'CGST' ? (
                                <>
                                  <td colSpan="1" className="py-1">
                                    {this.props.data.tax_labels.CGST} {item.tax / 2}%{' '}
                                    {Number(Math.round(item.tax_amount / 2 + 'e2') + 'e-2')}
                                  </td>
                                  <td colSpan="1" className="py-1 text-right">
                                    {this.props.data.tax_labels.SGST} {item.tax / 2}%{' '}
                                    {Number(Math.round(item.tax_amount / 2 + 'e2') + 'e-2')}
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td colSpan="2" className="py-1">
                                    {this.props.data.tax_labels.IGST} {item.tax}%{' '}
                                    {Number(Math.round(item.tax_amount + 'e2') + 'e-2')}
                                  </td>
                                </>
                              )}
                            </tr>
                          )}
                        </React.Fragment>
                      )
                    })}
                  </tbody>
                </MyTable>
              </div>

              <div className="w-1/2 text-left py-0 ">
                <h6 className="pl-2 mb-2 font-size-14">Items/Qty:</h6>
              </div>
              <div className="w-1/2 pr-2 text-right">
                <h6 className="mb-2 font-size-14">
                  {this.props.data.items.length +
                    ' / ' +
                    formatNumber(getTotalQty(this.props.data.items))}
                </h6>
              </div>

              {this.props.data.document_custom_additional_charges &&
                this.props.data.document_custom_additional_charges.map((item, i) => (
                  <>
                    <div className="w-1/2 text-left py-0">
                      <h6 className="font-size-16 pl-2 mb-0">{truncateString(item.name, 9)}</h6>
                    </div>
                    <div className="w-1/2 pr-2 text-right">
                      <h6 className="font-size-16 mb-0">
                        {item.type == -1 ? '(-) ' : ''}
                        <span className="mr-1">
                          {this.props?.user?.selectedCompany?.currency_symbol}
                        </span>
                        {item.total_amount.toLocaleString('en-IN', dotOptions)}
                      </h6>
                    </div>
                  </>
                ))}

              {this.props.data.total_discount > 0 && (
                <>
                  <div className="w-1/2 text-left">
                    <h6 className="font-size-16 pl-2 mb-0">
                      <strong>Total {this.props?.user?.selectedCompany?.labels?.mrp}:</strong>
                    </h6>
                  </div>
                  <div className="w-1/2 pr-2 text-right">
                    <h6 className="font-size-16 mb-0">
                      <strong>
                        <span className="mr-1">
                          {this.props?.user?.selectedCompany?.currency_symbol}
                        </span>
                        {Number(this.props.data.total_discount) +
                          Number(this.props.data.total_amount)}
                      </strong>
                    </h6>
                  </div>
                  <div className="w-1/2 text-left">
                    <h6 className="font-size-16 pl-2 mb-0">
                      <strong>Discount:</strong>
                    </h6>
                  </div>
                  <div className="w-1/2 pr-2 text-right">
                    <h6 className="font-size-16 mb-0">
                      <strong>
                        <span className="mr-1">
                          {this.props?.user?.selectedCompany?.currency_symbol}
                        </span>
                        {this.props.data.total_discount}
                      </strong>
                    </h6>
                  </div>
                </>
              )}
              <div id="printable" className="w-full text-left">
                <p className="borderdashed mb-1 mt-1"></p>
              </div>
              <div className="w-1/2 text-left">
                <h6 className="font-size-18 pl-2 mb-1 ">
                  <strong>Amount:</strong>
                </h6>
              </div>
              <div className="w-1/2 pr-2 text-right">
                <h6 className="font-size-18 mb-1">
                  <strong>
                    <span className="mr-1">
                      {this.props?.user?.selectedCompany?.currency_symbol}
                    </span>
                    {this.props.data.total_amount}
                  </strong>
                </h6>
              </div>
              <div id="printable" className="w-full text-left">
                <p className="borderdashed mb-2"></p>
              </div>

              {store.get('gstin').length == 15 &&
                store.get('show_taxable_amount') == 1 &&
                this.props.data.net_amount > 0 && (
                  <>
                    <div className="w-1/2 text-left py-0">
                      <h6 className="font-size-16 pl-2 mb-0">Taxable Amount</h6>
                    </div>

                    <div className="w-1/2 pr-2 text-right">
                      <h6 className="font-size-16 mb-0">
                        <span className="mr-1">
                          {this.props?.user?.selectedCompany?.currency_symbol}
                        </span>
                        {this.props.data.net_amount.toLocaleString('en-IN', dotOptions)}
                      </h6>
                    </div>
                  </>
                )}
              {store.get('gstin').length == 15 && this.props.data.tax_amount > 0 && (
                <>
                  {this.props.data.tax.map(
                    (item, i) =>
                      this.props.data.company.gstin.length == 15 &&
                      item.tax_amount != 0 && (
                        <>
                          <div className="w-1/2 text-left py-0">
                            <h6 className="font-size-16 pl-2 mb-0">
                              {item.tax_type}
                              {'\u00A0'}
                              {item.tax_percent}
                            </h6>
                          </div>
                          <div className="w-1/2 pr-2 text-right">
                            <h6 className="font-size-16 mb-0">
                              <span className="mr-1">
                                {this.props?.user?.selectedCompany?.currency_symbol}
                              </span>
                              {item.tax_amount.toLocaleString('en-IN', dotOptions)}
                            </h6>
                          </div>
                        </>
                      ),
                  )}
                </>
              )}
              {customer_pending_balance < 0 && this.props.invoice_settings?.show_net_balance == 1 && (
                <>
                  <div className="w-1/2 text-left">
                    <h6 className="font-size-16 pl-2 mb-1 ">
                      <strong>Total due:</strong>
                    </h6>
                  </div>
                  <div className="w-1/2 pr-2 text-right">
                    <h6 className="font-size-16 mb-1">
                      <strong>
                        <span className="mr-1">
                          {this.props?.user?.selectedCompany?.currency_symbol}
                        </span>
                        {Math.abs(customer_pending_balance.toFixed(2)).toLocaleString(
                          'en-IN',
                          dotOptions,
                        )}
                      </strong>
                    </h6>
                  </div>
                </>
              )}

              <div id="printable" className="w-full mt-1 mb-1 text-center">
                <p className="borderdashed mb-1"></p>
                {this.props.data.upi != '' && this.props.invoice_settings?.show_payment_qr == 1 && (
                  <div className="flex justify-center">
                    <EnhancedQRCode
                      className="mt-2"
                      onRef={ref => (this.table = ref)}
                      value={this.props.data.upi}
                    />
                  </div>
                )}
                {store.get('pos_notes') != '' ? store.get('pos_notes') : ''}
                {store.get('pos_notes') != '' && <p className="borderdashed mb-1"></p>}
                {store.get('show_thermalprint_terms') && (
                  <div className="w-full">
                    <h6 className="text-center text-lg font-semibold text-gray-700 ">
                      Terms and Conditions
                    </h6>
                    <div className="p-4 bg-white border-t border-b border-gray-300 rounded-lg text-gray-800 whitespace-pre-line">
                      {this.props.data.terms}
                    </div>
                  </div>
                )}

                <h6 className="font-size-18 mt-2">Thank you visit again!</h6>

                <h6>-------*--------*-------</h6>
                <h6 className="mb-2 font-size-16" style={{ textTransform: 'none' }}>
                  {this.props.data.company.pos_footer}
                </h6>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

class ComponentToPrintExpense extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const dotOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    return (
      <div id="thermal-print">
        {store.get('printer_type') == 1 && (
          <div className="ticket1 font-size-16">
            <div className="flex flex-wrap">
              {store.get('show_company_details') && (
                <div id="printable" className="w-full text-center font-size-16">
                  <div className="flex justify-center">
                    {store.get('logo') != '' && store.get('logo') != undefined && (
                      <img
                        src={store.get('logo')}
                        className="mb-2 height-100 thermal-print-img"
                        alt="Swipe"
                      />
                    )}
                  </div>
                  {store.get('organization_name') != store.get('company_name') ? (
                    <span>
                      <h5
                        className="font-weight-bold"
                        style={{ fontSize: `${store.get('organization_name_font_size')}px` }}
                      >
                        {store.get('organization_name')}
                      </h5>
                      <h6
                        className="font-weight-bold"
                        style={{ fontSize: `${store.get('company_name_font_size')}px` }}
                      >
                        {store.get('company_name')}
                      </h6>
                    </span>
                  ) : (
                    <h6
                      className="font-weight-bold"
                      style={{ fontSize: `${store.get('company_name_font_size')}px` }}
                    >
                      {store.get('company_name')}
                    </h6>
                  )}
                  <h6 className="font-size-18 mb-0">{store.get('address_1')}</h6>
                  <h6 className="font-size-18 mb-0">{store.get('address_2')}</h6>
                  <h6 className="font-size-18">
                    {store.get('company_city')}, {store.get('company_pincode')}{' '}
                  </h6>
                  {store.get('gstin')?.length == 15 && (
                    <h6 className="font-size-18 mb-0">GST: {store.get('gstin')}</h6>
                  )}
                  <h6 className="font-size-18">Phone: {store.get('company_mobile')}</h6>
                  {/*<h6 className="print-font-3">**** Duplicate Copy ****</h6>*/}
                </div>
              )}

              <div className="w-full text-left font-size-18">
                {this.props.data.user_details.name != null &&
                  this.props.data.user_details.name != '' && (
                    <>
                      <p className="borderdashed mb-1"></p>
                      <h6 className="font-size-18">
                        Created By: {this.props.data.user_details.name}
                      </h6>
                    </>
                  )}
                {this.props.data.user_details.mobile != null && (
                  <>
                    <h6 className="font-size-18">Phone: {this.props.data.user_details.mobile}</h6>

                    <p className="borderdashed mb-1"></p>
                  </>
                )}
              </div>

              <div className="w-full text-left font-size-18">
                {this.props.data.expense_details.description != '' &&
                this.props.data.expense_details.description != 'None' ? (
                  <>Instructions: {this.props.data.expense_details.description}</>
                ) : (
                  ''
                )}

                {this.props.data.expense_details.description != '' &&
                  this.props.data.expense_details.description != 'None' && (
                    <p className="borderdashed mb-1"></p>
                  )}
              </div>

              <div className="w-1/2 text-left">
                {this.props.id != '' && (
                  <h6 className="font-size-18">
                    Expense No: EXP-{this.props.data.expense_details.serial_number}
                  </h6>
                )}

                <p className="borderdashed mb-1"></p>

                {(this.props.data.expense_details.payment_mode != null ||
                  this.props.data.expense_details.payment_mode != '') && (
                  <h6 className="font-size-18">
                    Payment mode: {this.props.data.expense_details.payment_mode}
                  </h6>
                )}

                {(this.props.data.expense_details.category != null ||
                  this.props.data.expense_details.category != '') && (
                  <h6 className="font-size-18">
                    Category: {this.props.data.expense_details.category}
                  </h6>
                )}
              </div>
              <div className="w-1/2 text-left">
                <h6 className="font-size-18">
                  Date: {this.props.data.expense_details.document_date}
                </h6>
                <p className="borderdashed mb-1"></p>
              </div>

              {(this.props.data.expense_details.payment_serial_number != null ||
                this.props.data.expense_details.payment_serial_number != '') && (
                <div className="w-full text-left">
                  <h6 className="font-size-18">
                    Payment Serial Number: {this.props.data.expense_details.payment_serial_number}
                  </h6>
                </div>
              )}
              {this.props.data.expense_details.amount > 0 && (
                <>
                  <div className="w-1/2 text-left">
                    <h6 className="font-size-21 pl-2 mb-1 ">
                      <strong>Total:</strong>
                    </h6>
                  </div>
                  <div className="w-1/2 pr-2 text-right">
                    <h6 className="font-size-21 mb-1">
                      <strong>
                        <span className="mr-1">
                          {this.props?.user?.selectedCompany?.currency_symbol}
                        </span>
                        {this.props.data.expense_details.amount}
                      </strong>
                    </h6>
                  </div>
                </>
              )}

              <div id="printable" className="w-full text-center">
                <p className="borderdashed mb-2"></p>

                <h6 className="mb-2 font-size-13">{this.props.data.company_details.pos_footer}</h6>
                {store.get('paid') == 0 && (
                  <img
                    src="/resources/images/logo-w.svg"
                    className="mb-2 height-50 thermal-print-img"
                    alt="Swipe"
                  />
                )}
                <p className="borderdashed mb-2"></p>
              </div>
            </div>
          </div>
        )}

        {store.get('printer_type') == 2 && (
          <div className="ticket2 font-size-16">
            <div className="flex flex-wrap mt-2 mb-2">
              {store.get('show_company_details') && (
                <>
                  <div id="printable" className="w-full text-left">
                    <h6 className="font-size-24 mb-2 ml-1">
                      {this.props.data?.company?.is_composite == 1
                        ? 'BILL OF SUPPLY'
                        : store.get('gstin').length == 15
                        ? 'TAX INVOICE'
                        : 'INVOICE'}
                    </h6>
                  </div>
                  <div id="printable" className="w-3/12 text-left">
                    {store.get('logo') != '' && store.get('logo') != undefined && (
                      <div className="flex justify-center">
                        <img
                          src={store.get('logo')}
                          className="mb-2 thermal-print-img"
                          style={{
                            maxHeight: '12.6rem',
                            maxWidth: '12.6rem',
                            borderRadius: '1rem',
                          }}
                          alt="Swipe"
                        />
                      </div>
                    )}
                  </div>
                  <div id="printable" className="w-9/12 text-left">
                    {store.get('organization_name') != store.get('company_name') ? (
                      <span>
                        <h5
                          className="font-weight-bold"
                          style={{ fontSize: `${store.get('organization_name_font_size')}px` }}
                        >
                          {store.get('organization_name')}
                        </h5>
                        <h6
                          className="font-weight-bold"
                          style={{ fontSize: `${store.get('company_name_font_size')}px` }}
                        >
                          {store.get('company_name')}
                        </h6>
                      </span>
                    ) : (
                      <h6
                        className="font-weight-bold"
                        style={{ fontSize: `${store.get('company_name_font_size')}px` }}
                      >
                        {store.get('company_name')}
                      </h6>
                    )}
                    <h6 className="font-size-18 mb-0">{store.get('address_1')}</h6>
                    <h6 className="font-size-18 mb-0">{store.get('address_2')}</h6>
                    <h6 className="font-size-18">
                      {store.get('company_city')}, {store.get('company_pincode')}{' '}
                    </h6>
                    {store.get('gstin')?.length == 15 && (
                      <h6 className="font-size-21 mb-0">GST: {store.get('gstin')}</h6>
                    )}
                    <h6 className="font-size-21">Phone: {store.get('company_mobile')}</h6>
                  </div>
                </>
              )}

              <div className="w-full text-left">
                <p className="borderdashed mb-2"></p>
                {this.props.data.user_details.name != null &&
                  this.props.data.user_details.name != '' && (
                    <h6 className="font-size-21 mt-2 mb-2 pl-2">
                      Created By: {this.props.data.user_details.name}
                    </h6>
                  )}
                {this.props.data.user_details.mobile != null && (
                  <h6 className="font-size-21 mb-2 pl-2">
                    Phone: {this.props.data.user_details.mobile}
                  </h6>
                )}
                <p className="borderdashed mb-1"></p>
              </div>

              <div className="w-1/2 text-left font-size-16 mt-2 ">
                <h6 className="font-size-21">
                  Date: {this.props.data.expense_details.document_date}
                </h6>
                {this.props.data.expense_details.serial_number != '' && (
                  <h6 className="font-size-21">
                    Expense No: EXP-{this.props.data.expense_details.serial_number}
                  </h6>
                )}
                {this.props.data.expense_details.payment_serial_number != '' && (
                  <h6 className="font-size-21">
                    Payment Serial Number: {this.props.data.expense_details.payment_serial_number}
                  </h6>
                )}
              </div>

              <div className="w-1/2 text-left font-size-16 mt-2">
                <h6 className="font-size-21 pl-2">
                  Payment mode: {this.props.data.expense_details.payment_mode}
                </h6>

                {this.props.data.expense_details.category != '' && (
                  <h6 className="font-size-21 pl-2">
                    Category: {this.props.data.expense_details.category}
                  </h6>
                )}
              </div>

              <div id="printable" className="w-full text-left">
                <p className="borderdashed mb-2"></p>
              </div>
              <div className="w-8/12 text-right">
                <h6 className="font-size-25 pl-2 mb-2 ">
                  <strong>Total Amount:</strong>
                </h6>
              </div>
              <div className="w-4/12 pr-4 text-right">
                <h6 className="font-size-25 mb-2">
                  <strong>
                    <span className="mr-1">
                      {this.props?.user?.selectedCompany?.currency_symbol}
                    </span>
                    {this.props.data.expense_details.amount}
                  </strong>
                </h6>
              </div>
              <div className="w-full text-left font-size-21 ">
                {this.props.data.expense_details.description != '' &&
                this.props.data.expense_details.description != 'None' ? (
                  <span className="pl-2">
                    Description: {this.props.data.expense_details.description}
                  </span>
                ) : (
                  ''
                )}

                {this.props.data.expense_details.description != '' &&
                  this.props.data.expense_details.description != 'None' && (
                    <p className="borderdashed mb-1"></p>
                  )}
              </div>

              <div id="printable" className="w-full text-left">
                <p className="borderdashed mb-2"></p>
              </div>

              <div id="printable2" className="w-full mt-1 mb-1 text-center">
                <p className="borderdashed mb-1 font-size-16"></p>

                {store.get('pos_notes') != '' ? store.get('pos_notes') : ''}
                {store.get('pos_notes') != '' && <p className="borderdashed mb-1"></p>}

                <h6>-------*--------*-------</h6>
                <h6 className="mb-2 font-size-13">{this.props.data.company_details.pos_footer}</h6>
                {store.get('paid') == 0 && (
                  <img
                    src="/resources/images/logo-w.svg"
                    className="mb-2 height-50 thermal-print-img"
                    alt="Swipe"
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {store.get('printer_type') == 3 && (
          <div className="ticket3 font-size-16">
            <div className="flex flex-wrap">
              {store.get('show_company_details') && (
                <div id="printable" className="w-full text-center font-size-16">
                  {store.get('logo') != '' && store.get('logo') != undefined && (
                    <div className="flex justify-center">
                      <img
                        src={store.get('logo')}
                        className="mb-2 height-100 thermal-print-img"
                        alt="Swipe"
                      />
                    </div>
                  )}
                  {store.get('organization_name') != store.get('company_name') ? (
                    <span>
                      <h6
                        className="font-weight-bold"
                        style={{ fontSize: `${store.get('organization_name_font_size')}px` }}
                      >
                        {store.get('organization_name')}
                      </h6>
                      <h6
                        className="font-weight-bold"
                        style={{ fontSize: `${store.get('company_name_font_size')}px` }}
                      >
                        {store.get('company_name')}
                      </h6>
                    </span>
                  ) : (
                    <h6
                      className="font-weight-bold"
                      style={{ fontSize: `${store.get('company_name_font_size')}px` }}
                    >
                      {store.get('company_name')}
                    </h6>
                  )}
                  <h6 className="font-size-18 mb-0">{store.get('address_1')}</h6>
                  <h6 className="font-size-18 mb-0">{store.get('address_2')}</h6>
                  <h6 className="font-size-18">
                    {store.get('company_city')}, {store.get('company_pincode')}{' '}
                  </h6>
                  {store.get('gstin')?.length == 15 && (
                    <h6 className="font-size-18 mb-0">GST: {store.get('gstin')}</h6>
                  )}
                  <h6 className="font-size-18">Phone: {store.get('company_mobile')}</h6>
                  {/*<h6 className="print-font-3">**** Duplicate Copy ****</h6>*/}
                </div>
              )}

              <div className="w-full text-left font-size-18">
                {this.props.data.user_details.name != null &&
                  this.props.data.user_details.name != '' && (
                    <>
                      <p className="borderdashed mb-1"></p>
                      <h6 className="font-size-18">Name: {this.props.data.user_details.name}</h6>
                    </>
                  )}
                {this.props.data.user_details.mobile != null &&
                  this.props.data.user_details.mobile.length == 10 && (
                    <>
                      <h6 className="font-size-18">Phone: {this.props.data.user_details.mobile}</h6>
                    </>
                  )}
                {this.props.data.customer?.gstin != '' &&
                this.props.data.customer?.gstin.length == 15 ? (
                  <>
                    <h6 className="font-size-18">GSTN: {this.props.data.customer.gstin}</h6>

                    <p className="borderdashed mb-1"></p>
                  </>
                ) : (
                  <p className="borderdashed mb-1"></p>
                )}
              </div>

              <div className="w-full text-left">
                {this.props.id != '' && (
                  <h6 className="font-size-18">
                    Expense No: EXP-{this.props.data.expense_details.serial_number}
                  </h6>
                )}
              </div>
              <div className="w-full text-left font-size-16">
                <h6 className="font-size-18">
                  Date: {this.props.data.expense_details.document_date}
                </h6>
              </div>
              {(this.props.data.expense_details.payment_mode != null ||
                this.props.data.expense_details.payment_mode != '') && (
                <div className="w-full text-left font-size-16">
                  <h6 className="font-size-18">
                    Payment mode: {this.props.data.expense_details.payment_mode}
                  </h6>
                </div>
              )}

              {(this.props.data.expense_details.category != null ||
                this.props.data.expense_details.category != '') && (
                <div className="w-full text-left font-size-16">
                  <h6 className="font-size-18">
                    Category: {this.props.data.expense_details.category}
                  </h6>
                </div>
              )}
              {(this.props.data.expense_details.payment_serial_number != null ||
                this.props.data.expense_details.payment_serial_number != '') && (
                <div className="w-full text-left font-size-16">
                  <h6 className="font-size-18">
                    Payment Serial Number: {this.props.data.expense_details.payment_serial_number}
                  </h6>
                </div>
              )}

              <div className="w-1/2 text-left">
                <h6 className="font-size-24 pl-2 mb-1 ">
                  <strong>Total:</strong>
                </h6>
              </div>
              <div className="w-1/2 pr-2 text-right">
                <h6 className="font-size-24 mb-1">
                  <strong>
                    <span className="mr-1">
                      {this.props?.user?.selectedCompany?.currency_symbol}
                    </span>
                    {this.props.data.expense_details.amount}
                  </strong>
                </h6>
              </div>

              <div className="w-full text-left font-size-18">
                {this.props.data.expense_details.description != '' &&
                this.props.data.expense_details.description != 'None' ? (
                  <>
                    <p className="borderdashed mb-1"></p>
                    <p>Description: </p>
                    <p className="">{this.props.data.expense_details.description}</p>
                  </>
                ) : (
                  ''
                )}

                {this.props.data.expense_details.description != '' &&
                  this.props.data.expense_details.description != 'None' && (
                    <p className="borderdashed mb-1"></p>
                  )}
              </div>

              <div id="printable" className="w-full mt-1 mb-1 text-center">
                <p className="borderdashed mb-1"></p>

                {store.get('pos_notes') != '' ? store.get('pos_notes') : ''}
                {store.get('pos_notes') != '' && <p className="borderdashed mb-1"></p>}

                <h6>-------*--------*-------</h6>
                <h6 className="mb-2 font-size-16" style={{ textTransform: 'none' }}>
                  {this.props.data.company_details.pos_footer}
                </h6>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  }
}
export default connect(mapStateToProps)(ThermalPrint)
