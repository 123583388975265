import { Button, Tooltip } from 'antd'

interface Props {
  noOfDocsLeft: number
  onUpgradeClick: () => void
  free_limit: number
  type: 'einvoice' | 'ewaybill' | 'packinglist'
  size?: 'small' | 'middle' | 'large'
}

const typeConfig = {
  einvoice: {
    documentName: 'E-Invoices',
    planName: 'E-Invoicing Plan',
  },
  ewaybill: {
    documentName: 'E-Waybills',
    planName: 'JET Plan',
  },
  packinglist: {
    documentName: 'Packing Lists',
    planName: 'Packing List Add-on',
  },
}

export default function UpgradeWithCountButton({
  noOfDocsLeft,
  onUpgradeClick,
  free_limit,
  type,
  size = 'middle',
}: Props) {
  const { documentName, planName } = typeConfig[type]

  const tooltipText =
    noOfDocsLeft === 0
      ? `Free quota exceeded. Upgrade to ${planName} for unlimited access!`
      : `Only ${noOfDocsLeft} out of ${free_limit} ${documentName} remaining in your free plan. Upgrade to ${planName} for unlimited access!`

  const content = noOfDocsLeft === 0 ? 'Upgrade Now! 🚀' : `${noOfDocsLeft} / ${free_limit}`

  return (
    <Tooltip title={tooltipText} placement="top">
      <Button
        // @ts-ignore
        type="sign-up"
        size={size}
        className="font-semibold flex items-center gap-2 px-4 py-2 bg-amber-300 rounded-lg"
        onClick={onUpgradeClick}
      >
        <span className="font-semibold">{content}</span>
        {noOfDocsLeft !== 0 && <span className="ml-1 font-bold">Upgrade Now! 🚀</span>}
      </Button>
    </Tooltip>
  )
}
