import React, { forwardRef, useEffect } from 'react'
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  message,
  notification,
  Row,
  Select,
  Switch,
} from 'antd'
import PaymentModes from 'components/other/paymentModes'
import { formItemLayout } from 'components/other/utility'
import { Document_Redux, State } from 'redux/types'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import UploadFiles from 'components/other/uploadFiles'
import { getAPI } from 'services/jwt'
import BankForm from '../forms/onlyBankForm'
import { debounce } from 'components/other/loadashUtils'
const { TextArea } = Input
const { Option } = Select
dayjs.extend(utc)
interface SelectedData {
  total_amount: number
  net_amount: number
  amount_pending: number
  amount_paid: number
  customer: {
    customer_id: number
    vendor_id: number
    name: string
    phone: string
    email: string
  }
  type: string
  serial_number: string
  doc_count: number
  payment_date: Dayjs
  notes: string
  pay: number
  payment_details: any
}
interface Props {
  user: any
  payment_modes: Document_Redux['paymentModes']
  tds_sections: Document_Redux['tdsSections']
  banks: Document_Redux['bankDetails']
  selectedData: SelectedData
  typeFlag: boolean
  document_type: string
  payment_type: 'in' | 'out'
  attachments: any[]
  is_edit: boolean
  onApicalled: () => void
  setLoading: (loading: boolean) => void
}

const CreatePayment = forwardRef((props: Props, ref) => {
  const dateFormat = 'DD-MM-YYYY'
  const formRef = React.useRef<FormInstance>(null)
  const bankFormRef = React.useRef<any | undefined>(null)
  const [state, setPrimaryState] = React.useState({
    net_amount: 0,
    total_amount: 0,
    amountErrorMessage: '',
    type: '',
    tds_selected_section: 0,
    bank_id:
      props.banks.length > 0
        ? props.banks.filter(item => item.is_default == 1).length > 0
          ? props.banks.filter(item => item.is_default == 1)[0].id
          : props.banks[0].id
        : undefined,
    typeFlag: true,
    attachments: props.attachments ?? [],
    paymentModeChanged: false,
    view_document_details: false,
  })

  const maxAllowed = Number(props.selectedData.amount_pending)

  const setState = (obj: any) => {
    setPrimaryState({ ...state, ...obj })
  }

  const onFill = (data: SelectedData) => {
    formRef.current?.setFieldsValue({
      ...data,
      bank_id: props.is_edit
        ? data.payment_details.bank_id
        : state.bank_id == 0
        ? undefined
        : state.bank_id,
    })
    setState({
      bank_id: state.bank_id == 0 ? undefined : state.bank_id,
      total_amount: data.total_amount,
      net_amount: data.net_amount,
      type: data.type,
      typeFlag: props.typeFlag,
      attachments: props.attachments,
      payment_details: data.payment_details,
    })
  }

  const debouncedOnSubmit = React.useCallback(
    debounce(() => {
      let values = formRef.current?.getFieldsValue()
      values = {
        ...values,
        attachments: state.attachments,
        type: state.type,
        bank_id: state.bank_id,
      }

      onFinish(values)
    }, 500),
    [state],
  )

  const onFinish = async (values: any) => {
    if (state.bank_id == undefined && state.type != 'Cash' && state.type != 'TDS') {
      notification.error({
        message: 'Error',
        description: 'Please add bank for Non Cash Payment',
      })
      props.setLoading(false)
      return
    }
    const document_type = props.document_type
    const resArray = [
      {
        serial_number: props.selectedData.serial_number,
        amount_settled: values.pay,
        doc_count: props.selectedData.doc_count,
        document_type: document_type,
      },
    ]

    let payment_api = props.is_edit ? 'edit_payment' : 'create_payment'

    const req = {
      payments: [
        {
          documents: !props.is_edit ? resArray : undefined,
          payment_date: dayjs(values.payment_date).format(dateFormat),
          notes: values.notes == null ? '' : values.notes,
          party_id: props.selectedData.customer[state.typeFlag ? 'customer_id' : 'vendor_id'] ?? 0,
          party_type: state.typeFlag ? 'customer' : 'vendor',
          amount: values.pay,
          payment_mode: state.type,
          bank_id: state.type == 'Cash' ? 0 : state.type == 'TDS' ? -1 : state.bank_id,
          payment_type: props.payment_type,
          tds_details: {
            ...props.tds_sections[state.tds_selected_section],
            is_tds: state.type == 'TDS' ? 1 : 0,
          },
          attachments: state.attachments.filter((item: any) => !item.is_old),
          send_sms: state.type == 'TDS' ? 0 : values.send_sms,
          send_email: state.type == 'TDS' ? 0 : values.send_email,
          exclusive_notes: values.exclusive_notes,
          // Below key are only for edit drawer
          is_edit: props.is_edit,
          serial_number: props.is_edit ? props.selectedData.serial_number : undefined,
          doc_count: props.is_edit ? props.selectedData.doc_count : undefined,
        },
      ],
    }

    if (!props.is_edit) {
      // bank_id should not be - 1 if not cash or tds
      if (
        (req.payments[0].bank_id == 0 && state.type != 'Cash') ||
        (req.payments[0].bank_id == -1 && state.type != 'TDS')
      ) {
        notification.error({
          message: 'Error',
          description: 'Bank should be selected for payment type ' + req.payments[0].payment_mode,
        })

        return
      } else {
        if (state.type == 'Cash') {
          req.payments[0].bank_id = 0
        } else if (state.type == 'TDS') {
          req.payments[0].bank_id = -1
        }
      }

      if (req.payments[0].amount > props.selectedData.amount_pending) {
        notification.error({
          message: 'Error',
          description: 'Amount should not be greater than pending amount',
        })

        return
      }
      if (!req.payments[0].amount || req.payments[0].amount <= 0) {
        notification.error({
          message: 'Error',
          description: 'Amount should be greater than 0',
        })

        return
      }
    }
    if (req.payments[0].payment_mode == '' || req.payments[0].payment_mode == undefined) {
      notification.error({
        message: 'Error',
        description: 'Payment type is mandatory for payments',
      })

      return
    }

    props.setLoading(true)

    const data = await getAPI('v3/payments', payment_api, req)

    props.setLoading(false)
    if (data) {
      message.success(data.message)
      setState({ visible: false, attachments: [] })
      props.onApicalled()
    }
    setState({ view_document_details: false })
  }

  useEffect(() => {
    if (props.selectedData) {
      onFill(props.selectedData)
    }
  }, [props.selectedData])

  // expose on fill
  React.useImperativeHandle(ref, () => ({
    onFill,
    onSubmit: debouncedOnSubmit,
  }))

  const handleBankRef = (ref: any) => {
    bankFormRef.current = ref
  }

  return (
    <div>
      <Form
        {...formItemLayout}
        labelAlign="left"
        ref={formRef}
        name="paymentForm"
        layout="vertical"
        disabled={state.type == 'Credits'}
        // onFinish={props.onFinish}
      >
        <Card key="2" className="mb-4" bordered={false}>
          <>
            {state.type == 'Credits' && (
              <span className="my-2 text-primary">
                Note: This payment was created while settling the document with the credit/debit
                note that was issued earlier. You cannot edit the payment details, but you can
                delete the payment and record a new one instead.
              </span>
            )}
            <Row gutter={18}>
              <Col span={24}>
                <Form.Item
                  name="pay"
                  label={'Amount' + (props.is_edit ? '' : ' to be Recorded')}
                  className="mt-4"
                  validateStatus={state.amountErrorMessage ? 'error' : ''}
                  extra={
                    !props.is_edit && (
                      <div>
                        <>
                          {state.amountErrorMessage && (
                            <div className="text-danger py-[0.3rem]">
                              {state.amountErrorMessage}
                            </div>
                          )}

                          <div className="w-full flex flex-row justify-between">
                            <span>
                              Total Amount {props.user?.selectedCompany?.currency_symbol}{' '}
                              <strong>{props.selectedData.total_amount}</strong>
                            </span>
                            <span>
                              Amount {props.is_edit ? 'Remaining' : 'Pending'}{' '}
                              {props.user?.selectedCompany?.currency_symbol}{' '}
                              <strong>{props.selectedData.amount_pending}</strong>
                            </span>
                          </div>
                        </>
                      </div>
                    )
                  }
                  rules={[
                    { required: true, message: 'Fill Pay Amount' },
                    { type: 'number', min: 0.1, message: 'Amount should be greater than 0' },
                    {
                      validator: (_, value) => {
                        let amountErrorMessage = ''
                        if (Number(value) > maxAllowed) {
                          amountErrorMessage = `Amount must be less than or equal to ${maxAllowed}`
                        } else {
                          amountErrorMessage = ''
                        }

                        setState({ amountErrorMessage })
                        return Promise.resolve()
                      },
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Enter Amount to be recorded"
                    className="w-full font-weight-bold"
                    precision={2}
                    size="large"
                    prefix={
                      <span className="text-gray-500">
                        {props?.user?.selectedCompany?.currency_symbol}
                      </span>
                    }
                    suffix={
                      <>
                        <span></span>
                      </>
                    }
                    disabled={props.is_edit}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="payment_date" label="Payment Date">
                  <DatePicker
                    allowClear={false}
                    format={dateFormat}
                    disabledDate={current =>
                      current >
                      dayjs()
                        .add(1, 'month')
                        .endOf('month')
                    }
                    className="w-full height-32"
                    style={{
                      width: 'auto',
                      cursor: 'pointer',
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name="payment_type"
              label="Payment Type"
              className="mb-2"
              hidden={state.type == 'Credits'}
              extra={
                props.is_edit &&
                props?.selectedData?.payment_details?.reconciliation_status != 'pending' &&
                'Bank details cannot be edited once the payment is reconciled.'
              }
            >
              {''}
              {props.is_edit && state.type == 'TDS' ? (
                <p style={{ color: 'red' }}>
                  * For {state.type} payments, Payment type cannot be edited. In case of any
                  changes, kindly delete and record a new payment.
                </p>
              ) : (
                <>
                  {state.type != '' && state.type != 'Credits' && (
                    <PaymentModes
                      data={
                        state.type != 'TDS' && props.is_edit
                          ? props.payment_modes.filter(
                              (item: any) => item.value != 'TDS' && item.value != 'TCS',
                            )
                          : props.payment_modes
                      }
                      value={state.type}
                      onChange={(e: any) => {
                        if (e.target.value == 'TDS') {
                          formRef.current?.setFieldsValue({
                            pay:
                              (state[
                                props.tds_sections[state.tds_selected_section].apply_on ==
                                'total_amount'
                                  ? 'total_amount'
                                  : 'net_amount'
                              ] *
                                props.tds_sections[state.tds_selected_section].tax) /
                              100,
                          })
                        } else {
                          if (state.type == 'TDS') {
                            formRef.current?.setFieldsValue({
                              pay: props.selectedData.amount_pending,
                            })
                          }
                        }

                        let bank_id =
                          e.target.value == 'Cash'
                            ? 0
                            : e.target.value == 'TDS'
                            ? -1
                            : props.banks.length > 0
                            ? props.banks.filter(item => item.is_default == 1).length > 0
                              ? props.banks.filter(item => item.is_default == 1)[0].id
                              : props.banks[0].id
                            : undefined

                        setState({
                          type: e.target.value,
                          paymentModeChanged: true,
                          bank_id: bank_id,
                        })
                        formRef.current?.setFieldsValue({ bank_id: bank_id })
                      }}
                    />
                  )}
                </>
              )}
            </Form.Item>
            {state.type == 'TDS' && !props.is_edit && (
              <>
                <Form.Item name="section" label="TDS Section" className="pb-2">
                  <Select
                    suffixIcon={<i className="fa-regular fa-lg fa-chevron-down"></i>}
                    showSearch
                    placeholder="Select tds section"
                    optionFilterProp="children"
                    style={{ width: '100%', whiteSpace: 'nowrap' }}
                    value={state.tds_selected_section}
                    defaultValue={0}
                    filterOption={(input, option) =>
                      (
                        option?.props.children.props.children[0].props.children +
                        ' ' +
                        option?.props.children.props.children[1].props.children +
                        ' ' +
                        option?.props.children.props.children[2].props.children
                      )
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={e => {
                      setState({
                        tds_selected_section: e,
                      })
                      formRef.current?.setFieldsValue({
                        pay:
                          ((props.tds_sections[e].apply_on == 'net_amount'
                            ? state.net_amount
                            : state.total_amount) *
                            props.tds_sections[e].tax) /
                          100,
                      })
                    }}
                  >
                    {props.tds_sections.map((item, i) => (
                      <Option key={i} value={i}>
                        <div style={{ whiteSpace: 'normal' }}>
                          <span className="text-gray-700 font-weight-bold mr-2">
                            {item.tax + '%'}
                          </span>
                          <span className="mr-1 font-weight-bold text-gray-700">
                            {item.section}
                          </span>
                          <span className="mr-2 text-gray-500">{item.name}</span>
                        </div>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            )}

            {state.type != 'Cash' && state.type != 'TDS' && state.type != 'Credits' && (
              <Form.Item
                name="bank_id"
                label="Bank"
                className="pb-2"
                extra={
                  props.is_edit &&
                  props?.selectedData?.payment_details?.reconciliation_status != 'pending' &&
                  'Bank details cannot be edited once the payment is reconciled.'
                }
              >
                <Select
                  suffixIcon={<i className="fa-regular fa-lg fa-chevron-down"></i>}
                  placeholder="Select Bank"
                  optionFilterProp="children"
                  style={{ minWidth: '36' }}
                  onChange={e => setState({ bank_id: e })}
                  value={state.bank_id}
                  filterOption={(input, option) => {
                    // @ts-ignore
                    return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }}
                  dropdownRender={menu => (
                    <>
                      <div className="px-1 py-1">
                        {menu}
                        <Button
                          block
                          type="primary"
                          className="font-weight-bolder pb-1"
                          onClick={() => {
                            if (bankFormRef && bankFormRef.current) {
                              bankFormRef.current.onAddFromInvoicePage()
                            }
                          }}
                        >
                          <i className="fa fa-plus mr-1" />
                          Add New Bank
                        </Button>
                      </div>
                    </>
                  )}
                  disabled={
                    props.is_edit &&
                    props.selectedData.payment_details?.reconciliation_status != 'pending'
                  }
                >
                  {props.banks.map((item, i) => {
                    if (item.bank_name != 'Cash') {
                      return (
                        <Option value={item.id} key={i}>
                          {item.bank_name + ' (' + item.bank_no + ')'}
                        </Option>
                      )
                    }
                  })}
                </Select>
              </Form.Item>
            )}

            <Form.Item name="notes" label="Notes" className="pb-2">
              <TextArea placeholder="Your notes on the payment" />
            </Form.Item>
            {!props.is_edit && (
              <>
                {props?.user?.selectedCompany?.country_code == 'IN' && (
                  <Form.Item
                    name="send_sms"
                    hidden={(props.typeFlag ? false : true) || state.type == 'TDS'}
                    label={props.typeFlag ? 'SMS to Customers' : 'SMS to Vendors'}
                    valuePropName="checked"
                    extra={
                      props.selectedData?.customer?.phone != null &&
                      props.selectedData?.customer?.phone != '' ? (
                        <span className="text-gray-500">
                          {`SMS will be sent to the ${
                            props.typeFlag ? 'customer' : 'vendor'
                          } phone:`}
                          <span className="text-primary">
                            {props.selectedData?.customer?.phone}
                          </span>
                        </span>
                      ) : (
                        <span className="text-gray-500">
                          <span className="text-danger">
                            {`Please add ${
                              props.typeFlag ? 'customer' : 'vendor'
                            } phone number to enable this option`}
                          </span>
                        </span>
                      )
                    }
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                      checked
                      disabled={
                        props.selectedData?.customer?.phone == null ||
                        props.selectedData?.customer?.phone == ''
                      }
                    />
                  </Form.Item>
                )}

                <Form.Item
                  name="send_email"
                  hidden={(props.typeFlag ? false : true) || state.type == 'TDS'}
                  label={props.typeFlag ? 'E-Mail to Customers' : 'E-Mail to Vendors'}
                  valuePropName="checked"
                  extra={
                    props.selectedData?.customer?.email != null &&
                    props.selectedData?.customer?.email != '' ? (
                      <span className="text-gray-500">
                        {`E-Mail will be sent to the ${
                          props.typeFlag ? 'customer' : 'vendor'
                        } email:`}
                        <span className="text-primary">{props.selectedData?.customer?.email}</span>
                      </span>
                    ) : (
                      <span className="text-gray-500">
                        <span className="text-danger">
                          {`Please add ${
                            props.typeFlag ? 'customer' : 'vendor'
                          } email to enable this option`}
                        </span>
                      </span>
                    )
                  }
                >
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    checked
                    disabled={
                      props.selectedData?.customer?.email == null ||
                      props.selectedData?.customer?.email == ''
                    }
                  />
                </Form.Item>
              </>
            )}

            {/* Attachments */}
            <UploadFiles
              attachments={state.attachments}
              maxCount={3}
              multiple={true}
              onChange={(attachments: any) => setState({ attachments })}
              setLoading={(loading: boolean) => props.setLoading(loading)}
            />

            <Form.Item
              name="exclusive_notes"
              label={
                <span>
                  <i className="fa-solid fa-fingerprint mr-2"></i>Internal Notes
                </span>
              }
              className="mt-4 pb-2"
              extra={
                'This note is exclusively for internal reference and will not be shown elsewhere.'
              }
            >
              <TextArea placeholder="Enter notes here..." />
            </Form.Item>
          </>
        </Card>
      </Form>
      <BankForm onRef={handleBankRef} />
    </div>
  )
})

export default CreatePayment
