import React, { useEffect, useRef, useState } from 'react'
import { Card, Select, Button, message } from 'antd'
import SectionHeader from './sectionHeader'
import { EnhancedSignatureForm } from 'components/forms/onlySignatureForm'
import { getAPIData } from 'services/jwt'
import { Signature } from 'redux/types'
import { initialSignatures } from 'utils/constants'

const { Option } = Select

export interface Props {
  signatureData?: Signature[]
  selectedSignatureData: Signature
  onChange: (signature: Signature) => void
}

const SignatureSelector: React.FC<Props> = ({ signatureData, selectedSignatureData, onChange }) => {
  const signatureFormRef = useRef<any>(null)

  const [signatures, setSignatures] = useState<Signature[]>(signatureData ?? [])
  const [selectedSignature, setSelectedSignature] = useState<Signature>(selectedSignatureData)

  const refreshSignatures = async (newSignature = undefined) => {
    var data = await getAPIData('signature', 'get')
    if (!data?.success) {
      return
    }

    setSignatures([...initialSignatures, ...data.signatures])

    if (newSignature) {
      setSelectedSignature(newSignature)
      onChange(newSignature)
    }
  }

  const onChangeHandler = (value: any) => {
    const selected = signatures.find(item => item.id === value)

    if (selected) {
      setSelectedSignature(selected)
      onChange(selected)
    } else {
      message.error('Signature not found')
    }
  }

  useEffect(() => {
    if (!signatureData) {
      refreshSignatures()
    }
  }, [])

  useEffect(() => {
    setSignatures(signatureData ?? [])
  }, [signatureData])

  useEffect(() => {
    setSelectedSignature(selectedSignatureData)
  }, [selectedSignatureData])

  return (
    <>
      <SectionHeader
        title="Select Signature"
        link={signatures.length > 1}
        linkTo={() => signatureFormRef?.current?.onAdd()}
        linkText="Add New Signature"
        video_name="add_signature"
      />
      <Card size="default" bordered={false} className="border-radius-small bg-card-magenta mb-4">
        <div className="flex flex-wrap">
          <div className="w-full text-left">
            <div className="mb-3">
              {signatures.length > 1 ? (
                <Select
                  suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                  placeholder="Select Signature"
                  optionFilterProp="children"
                  style={{ width: '100%' }}
                  onChange={onChangeHandler}
                  value={selectedSignature?.id}
                  defaultValue={selectedSignature?.id}
                  filterOption={(input, option) =>
                    (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  dropdownRender={menu => (
                    <>
                      {menu}
                      <div className="bg-gray-75">
                        <Button
                          block
                          type="link-2"
                          className="font-weight-bolder text-left my-2 arrow-transition"
                          onClick={() => signatureFormRef?.current?.onAdd()}
                        >
                          <i className="fa-solid fa-circle-plus mr-2"></i>
                          Add New Signature
                          <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                        </Button>
                      </div>
                    </>
                  )}
                >
                  {signatures.map((item, i) => (
                    <Option key={i} value={item.id}>
                      {item.signature_name}
                    </Option>
                  ))}
                </Select>
              ) : (
                <Button
                  type="dashed"
                  size="large"
                  onClick={() => signatureFormRef?.current?.onAdd()}
                  block
                  style={{
                    color: '#FC3654',
                    whiteSpace: 'normal',
                    height: 'auto',
                  }}
                >
                  <br />
                  <i className="fa fa-pencil mr-2 font-size-18" style={{ color: '#FC3654' }} />
                  <span className="font-size-18 font-weight-bold" style={{ color: '#FC3654' }}>
                    {' '}
                    Add Signature to Invoice (Optional)
                  </span>
                  <br />
                  <br />
                </Button>
              )}
            </div>
            {selectedSignature && (
              <div className="text-right float-right">
                <h6>Signature on the document</h6>
                <img src={selectedSignature?.image} className="h-24" />
              </div>
            )}
          </div>
        </div>
      </Card>

      <EnhancedSignatureForm
        onRef={(ref: any) => (signatureFormRef.current = ref)}
        refreshSignatures={refreshSignatures}
      />
    </>
  )
}

export default SignatureSelector
