import OptionalBadge from 'components/badges/optional'
import TutorialVideoModal from 'components/modal/tutorial'
import React from 'react'
import PlayIcon from './PlayIcon'
import InfoIcon from 'components/info/info_icon'
import { Button } from 'antd'

SectionHeader.defaultProps = {
  icon: 'fa-circle-plus',
}

export default function SectionHeader(props) {
  const {
    title,
    optional,
    link,
    addButton,
    addButton_onClick,
    addButton_text,
    right,
    rightText,
    rightClassName = '',
    linkTo,
    linkText,
    infoContent,
    video_name,
    center,
  } = props
  const tutorialRef = React.createRef()
  return (
    <div className={`flex  items-center ${center ? 'justify-center' : 'justify-between'} mb-2`}>
      <h6 className="mb-0 pl-2 mr-2 text-gray-500 font-weight-bold">
        {title != '' && title}
        {video_name && (
          <span onClick={() => tutorialRef.current.showModal()}>
            <PlayIcon />
          </span>
        )}
        {infoContent && (
          <span className="ml-1">
            <InfoIcon text={infoContent} />
          </span>
        )}
        {addButton && (
          <Button
            type="link"
            size="header"
            onClick={addButton_onClick}
            className="font-weight-bold header-add-button ml-5"
          >
            <i className="fa-solid fa-circle-plus mr-2"></i>
            {addButton_text}
          </Button>
        )}
        {optional == true && <OptionalBadge />}
      </h6>

      {/* {addButton && (
        <Button
          type="link"
          size="header"
          onClick={addButton_onClick}
          className="font-weight-bold header-add-button"
        >
          <i className="fa-solid fa-circle-plus mr-2"></i>
          {addButton_text}
        </Button>
      )} */}

      {right && (
        <span className={`${rightClassName ? rightClassName : 'pr-2 text-gray-400'}`}>
          {rightText}
        </span>
      )}

      {link && (
        <a_link
          onClick={linkTo}
          className="font-weight-medium pr-2 "
          data-beacon-id={props.link_beacon_id ? props.link_beacon_id : ''}
        >
          <i className={`fa-sharp fa-regular ${props.icon} mr-2`} />
          <span className="">{linkText}</span>
        </a_link>
      )}
      {video_name && <TutorialVideoModal ref={tutorialRef} video_name={video_name} />}
    </div>
  )
}
