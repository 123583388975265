import { useState, forwardRef, useRef, useImperativeHandle } from 'react'
import { Button, Modal } from 'antd'
import EnhancedPartyAnalysis from 'components/other/PartyAnalysis'

const ExistingCustomerModal = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false)
  const [customerDetails, setCustomerDetails] = useState<{ [key: string]: string } | null>(null)
  const customerAnalysisRef = useRef<any | null>()

  useImperativeHandle(ref, () => ({
    showModal,
  }))

  const handleCustomerAnalysisRef = (ref: any) => {
    customerAnalysisRef.current = ref
  }

  const showModal = (customer_info: { [key: string]: string }) => {
    setVisible(true)
    setCustomerDetails(customer_info)
  }

  const onCancel = () => {
    setVisible(false)
    setCustomerDetails(null)
  }

  return (
    <>
      <Modal
        maskClosable={false}
        open={visible}
        closable
        title={
          <>
            <i className="fad fa-info-circle fa-beat mr-3 text-danger"></i>
            Warning
          </>
        }
        footer={
          <>
            <Button type="danger" onClick={onCancel}>
              Close
            </Button>
          </>
        }
        destroyOnClose
        onCancel={onCancel}
        closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
      >
        <p>
          <span className="text-gray-700 font-size-16">
            The customer with this name{' '}
            <span className="font-weight-bold">{customerDetails?.name}</span> and phone number{' '}
            <span className="font-weight-bold">{customerDetails?.phone}</span> already exists.{' '}
            <a
              className="hover-underline mr-2 block font-weight-bold text-primary mt-2"
              onClick={() => {
                customerAnalysisRef.current.showModal(
                  { ...customerDetails, id: customerDetails?.customer_id },
                  'customer',
                )
                setVisible(false)
              }}
            >
              View Customer
            </a>
          </span>{' '}
        </p>
      </Modal>

      <EnhancedPartyAnalysis onRef={handleCustomerAnalysisRef} />
    </>
  )
})

export default ExistingCustomerModal
