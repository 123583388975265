import {
  Button,
  Card,
  Checkbox,
  Collapse,
  DatePicker,
  Dropdown,
  Empty,
  Input,
  InputNumber,
  Menu,
  Modal,
  Popover,
  Select,
  Spin,
  Switch,
  Table,
  Tooltip,
  message,
  notification,
} from 'antd'
import { EnhancedSubscriptionModal } from 'components/modal/subscription'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import {
  formatNumber,
  getCessAmount,
  getDiscountNetValue,
  getDiscountPriceWithTaxValue,
  getDiscountUnitPriceValue,
  getDiscountValue,
  getDiscount_with_tax,
  getItemNetAmount,
  getNetAmount_unit_price,
  getPrice,
  getPriceWithTax,
  getRowTotal,
  getTaxAmount,
  getTaxRateByPrice,
  getTotalQty,
} from 'components/other/calculation'
import SectionHeader from 'components/other/sectionHeader'
import React, { PureComponent, useRef } from 'react'
import { getAPI, getMultipartAPI } from 'services/jwt'
const { TextArea } = Input

import { ExclamationCircleOutlined } from '@ant-design/icons'
import CustomColumnsForm from 'components/forms/customColumns'
import ProductForm from 'components/forms/productForm'
import ProductBarcode from 'components/modal/barcode'
import EnhancedProductAnalysis from 'components/modal/productAnalysis'
import MyEditor from 'components/other/editor'
import { EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import 'draft-js/dist/Draft.css'

import store from 'store'

const { Panel } = Collapse

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { AgGridReact } from '@ag-grid-community/react'
import '@ag-grid-community/styles/ag-grid.css'
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail'
import BatchingForm from 'components/forms/batchingForm'
import InfoIcon from 'components/info/info_icon'
import 'components/kit/vendors/ag-grid/productTableTheme.css'
import SelectBatchConvert from 'components/modal/SelectBatchConvert'
import AddHsn from 'components/modal/addHsn'
import ProductBatches from 'components/modal/batches'
import { EnhancedDelete } from 'components/modal/delete'
import TutorialVideoModal from 'components/modal/tutorial'
import dayjs from 'dayjs'
import update from 'immutability-helper'
import { debounce, isEmpty } from 'components/other/loadashUtils'
import { useDrag, useDrop } from 'react-dnd'
import { connect } from 'react-redux'
import { get_product_options } from 'redux/document/actions'
import { mergeItemsAndItemHeaders } from 'utils/utilFunctions'
import { EnhancedAdditionalCess } from './additionalCess'
import UploadedProductsTable from './documentUploadConfirmation'
import SelectItem from './selectItem'
import { capitalizeFirstLetters } from './utility'
import ConvertedQtyStrip from './ConvertedQtyStrip'
const type = 'DraggableBodyRow'

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = useRef()
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {}
      if (dragIndex === index) {
        return {}
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      }
    },
    drop: item => {
      if (moveRow) moveRow(item.index, index)
    },
  })
  const [, drag] = useDrag({
    type,
    canDrag: Array.isArray(restProps.children),
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })
  drop(drag(ref))

  return (
    <tr
      key={index}
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  )
}

export class ProductsTable extends PureComponent {
  tutorialRef = React.createRef()
  selectItemRef = React.createRef()
  listInnerRef = React.createRef()
  productBatchesRef = React.createRef()
  serialNumberRef = React.createRef()
  addHsn = React.createRef()
  dateFormat = 'DD-MM-YYYY'
  dateTimeFormat = 'DD-MM-YYYY hh:mm a'
  dateFormatRegex = /^\d{2}-\d{2}-\d{4}$/
  subscriptionPlanRef = React.createRef()
  itemHeaderFormRef = React.createRef()
  UploadedProductsTable = React.createRef()
  gridRef = React.createRef()
  inputRef = React.createRef()

  SHOW_ADD_ITEM_HEADER =
    store.get('paid') != 0 &&
    (this.props.document_settings?.enable_item_headers == 'all' ||
      this.props.document_settings?.enable_item_headers?.includes(this.props.document_type))

  constructor(props) {
    super(props)
    this.state = {
      count: 0,
      // products: [{ key: this.renderTitle('Add New Product'), value: 'Add New Product' }],
      products: [],
      last_updated_products: [],
      id: -1,
      selectedData: {},
      barcodeData: {},
      edit: false,
      selected: false,
      custom_columns: [],
      // customerDiscount: 0,
      editorState: EditorState.createEmpty(),
      type: 'sales',
      packingChargesTax: 0,
      packingCharges: 0,
      packingChargesWithoutTax: 0,
      packingChargesPer: 0,
      packingChargesTaxAmount: 0,
      transportChargesTax: 0,
      transportCharges: 0,
      transportChargesWithoutTax: 0,
      transportChargesPer: 0,
      transportChargesTaxAmount: 0,
      has_extra_charges: false,
      searchVisible: false,
      searchText: '',
      page: 0,
      num_records: 25,
      selected_category: 'All',
      showExportCurrency: false,
      columns_order: [],
      column_id_names: {},
      total_amount_name: 'Total Amount',
      net_amount_name: 'Net Amount',
      qty_name: 'Quantity',
      is_column_linking_failed: false,
      selected_discount_type: 'discount',
      is_discount_percent: 1,
      loading: false,
      product_search_loading: false,
      prevReq: {},
      search: '',
      default_charges_populated: false,
      purchase_warning_visible: false,
      currentKey: '',
      showSelectBatches: false,
      description:
        store.get('show_invoice_description') == undefined
          ? true
          : store.get('show_invoice_description'),
      items_reverse: store.get('items_reverse') ?? false,
      itemHeaderInput: '',
      isEditingItemHeader: false,
      editingItemHeaderKey: -1,
      responseText: '',
      progress: 1,
      uploadItemsModel: false,
      uploadedItems: [],
      itemsData: [],
      item_headers: [],
    }
    this.onChange = debounce(this._onChange, 500)
    this._onCustomChange = debounce(this.onCustomChange, 500, { trailing: true })
    this.onDescriptionChange = debounce(this._onDescriptionChange, 500, { trailing: true })
  }

  check_purchase_price_warning = (sale_price, type, data) => {
    var purchase_price = type == 'price_with_tax' ? data.purchase_price : data.purchase_unit_price
    purchase_price = (purchase_price / data.conversion_rate).toFixed(2)
    sale_price = parseFloat(sale_price).toFixed(2)
    let diff = purchase_price - sale_price

    if (
      this.props.document_type == 'invoice' &&
      this.props.document_settings?.restrict_invoice_price_less_than_purchase_price == 1 &&
      !this.props.edit
    ) {
      if (diff > 0 && this.state.purchase_warning_visible == false) {
        this.setState({ purchase_warning_visible: true })
        Modal.confirm({
          title: (
            <>
              <p>{data.product_name + ' ' + data.variant_name}</p>
            </>
          ),
          content: (
            <>
              <p>
                <span className="font-weight-medium mr-2">Selling Price:</span>
                <span className="font-weight-bolder font-size-18">
                  <span className="mr-1 font-size-12">
                    {this.props?.user?.selectedCompany?.currency_symbol}
                  </span>
                  {sale_price}
                </span>
              </p>
              <p>
                <span className="font-weight-medium mr-2">Purchase Price:</span>
                <span className="font-weight-bolder font-size-18">
                  <span className="mr-1 font-size-12">
                    {this.props?.user?.selectedCompany?.currency_symbol}
                  </span>
                  {purchase_price}
                </span>
              </p>
              <p className="text-danger">You are selling it lesser than the purchase price.</p>
              <p className="text-danger">Do you still want to proceed?</p>
            </>
          ),
          icon: <ExclamationCircleOutlined />,
          okText: 'Proceed',
          cancelButtonProps: { style: { display: 'none' } },
          onOk: () => {
            this.setState({ purchase_warning_visible: false })
          },
          onCancel: () => {},
        })
      }
    }
  }
  openSelectBatches = () => {
    this.setState({ showSelectBatches: true })
  }
  closeSelectBatches = () => {
    this.setState({ showSelectBatches: false })
  }

  async componentDidMount() {
    this.setState({
      loading: true,
      is_column_linking_failed: this.props.permissions.custom_columns_link == 0,
    })

    var type = 'sales'
    this.props.onRef(this)
    if (window.location.pathname.includes('purchase')) {
      type = 'purchases'
    }
    if (window.location.pathname.includes('estimate')) {
      type = 'estimates'
    }
    if (this.props.taxes.length == 0) {
      this.props.dispatch(get_product_options())
    }
    this.setState({
      type: type,
      custom_additional_charges: this.props.custom_additional_charges,
      itemsData: this.props.data,
      item_headers: this.props.item_headers,
    })

    let dataSource = [...this.props.document_custom_additional_charges]
    dataSource = dataSource?.filter(item => item.is_default == 1) || []
    if (this.props.document_custom_additional_charges?.length != dataSource.length) {
      this.props.updateExtraCharges?.({
        document_custom_additional_charges: dataSource,
        has_extra_charges: this.state.has_extra_charges,
      })
    }

    if (!this.props.edit && !this.props.convert && !this.props.duplicate) {
      this.setState({ loading: false })
    }
  }
  addProductHsn = async (product_id, hsn_code) => {
    var req = {
      product_id: product_id,
      hsn_code: hsn_code,
    }
    let dataSource = [...this.props.data]
    let index = dataSource.findIndex(item => item.product_id == product_id)
    dataSource[index].hsn_code = hsn_code

    this.applyTransaction({ update: [dataSource[index]] })

    var data = await getAPI('utils', 'add_hsn_code', req)

    message.success(data.message)
  }

  dataUpdate = (data, type = 'sales') => {
    data.map((item1, index1) => {
      if (item1.custom_column_values) {
        var custom_column_values = item1.custom_column_values.split('::::')
      } else {
        var custom_column_values = []
      }

      if (item1.custom_column_names) {
        var custom_column_names = item1.custom_column_names.split('::::')
      } else {
        var custom_column_names = []
      }

      this.props.custom_columns.forEach((item, index) => {
        if (item.default_value != '') {
          item.name in data[index1]
            ? ''
            : (data[index1][item.name] = data[index1][item.default_value])
        } else {
          if (custom_column_names.includes(item.name)) {
            data[index1][item.name] = custom_column_values[custom_column_names.indexOf(item.name)]
          } else {
            item.name in data[index1] ? console.log('') : (data[index1][item.name] = '')
          }
        }
      })

      try {
        data[index1]['editorState'] = EditorState.createWithContent(
          stateFromHTML(data[index1]['description']),
        )
      } catch (ee) {
        data[index1]['editorState'] = EditorState.createWithContent(stateFromHTML(''))
      }
    })
    if (store.get('item_reverse') || this.state.items_reverse) {
      data.reverse()
    }

    this.setState({ data: data, count: data.length, itemsData: data }, () => {
      // rowData={[...this.state.itemsData, ...this.state.item_headers].sort(
      //   (a, b) => a.key - b.key,
      // )}
      this.gridRef.current.api.setGridOption('rowData', this.state.itemsData)
    })
  }

  updateCustomColumns = data => {
    this.setState({ custom_columns: data })
  }

  edit = async is_edit => {
    this.setState({
      edit: is_edit,
      showExportCurrency: this.props.is_export == 1 || this.props.is_multi_currency == 1,
    })
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  renderTitle = title => {
    return <span onClick={() => this.form.onAdd()}>{title}</span>
  }

  refreshAutocompleteProducts = () => {
    this.selectItemRef?.current?.doSearch('')
  }

  updateRecord = record => {
    record.price_with_tax = getPriceWithTax(record)
    record.total_amount = getRowTotal(record)
    record.cess_amount = getCessAmount(record)
    record.tax_amount = getTaxAmount(record)

    record['discount_value'] = getDiscountValue(record)
    record['discount_net_value'] = getDiscountNetValue(record)
    record['discount_unit_price_value'] = getDiscountUnitPriceValue(record)
    record['discount_price_with_tax_value'] = getDiscountPriceWithTaxValue(record)

    // const dataSource = [...this.props.data]
    // const index = dataSource.findIndex(item => item.key === record.key)

    // dataSource[index] = record

    this.applyTransaction({ update: [record] })
  }

  updateProductInTable = product => {
    if (product?.variants?.length > 0) {
      return
    }
    Modal.confirm({
      title: 'Do you want to update the prices of this product in the bill?',
      content: 'This will update the prices of the all the occurences of this product in the bill',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',

      cancelText: 'Cancel',
      onOk: () => {
        this.props.onUpdateItems(0, 'price_change', product.product_id)
      },
    })
  }

  onInputFocus = e => {
    const length = (e.target.value ?? '').length
    e.target.select()
    e.target.setSelectionRange(length, length)
    e.target.focus({ preventScroll: false })
  }

  _onDescriptionChange = async (value, key) => {
    let newData = this.getRowDataForKey(key)
    newData.editorState = value
    newData.description = stateToHTML(value.getCurrentContent())

    this.applyTransaction({ update: [newData] })
  }

  getRowDataForKey = key => {
    // const rowNode = this.gridRef.current.api.getDisplayedRowAtIndex(key - 1)
    // const rowNode = this.gridRef.current.api.getRowNode(key)

    const products = this.getRowDataFromGrid(true)

    const rowNode = products.find(item => item.key == key)

    return rowNode
  }

  applyTransaction = rowTransactions => {
    const params = this.gridRef.current.api.applyTransaction({
      add: [...(rowTransactions.add ?? [])],
      update: [...(rowTransactions.update ?? [])],
      remove: [...(rowTransactions.remove ?? [])],
    })
    this.onGridRowDataUpdate(params)
  }

  onGridRowDataUpdate = params => {
    this.gridRef.current.api.refreshClientSideRowModel('sort')
    this.props.dataUpdate(this.getRowDataFromGrid(true))
  }

  onCustomChange = (value, key, name, id, productsData = []) => {
    if (name == 'Quantity') {
      name = this.props.qty_name
    }

    let ItemData

    if (productsData.length != 0) {
      const index = productsData.findIndex(item => key === item.key)

      ItemData = { ...productsData[index] }
    } else {
      ItemData = this.getRowDataForKey(key)
    }

    ItemData[name] = value

    let data = { ...ItemData, key }

    if (this.props.is_column_linking_failed || this.props.permissions.custom_columns_link == 0) {
      data['net_amount'] = getNetAmount_unit_price(data)
      data['total_amount'] = getRowTotal(data)
      data['tax_amount'] = getTaxAmount(data)
      ItemData = data
    } else {
      data[this.props.qty_name] = data['qty']
      for (var i = 0; i < this.props.columns_order.length; i++) {
        let selected_column = this.props.custom_columns.filter(
          item => String(item.id) == this.props.columns_order[i],
        )[0]

        let expression = selected_column.expression
        if (expression == '' || this.props.columns_order[i] == id) {
          continue
        }

        let matches = expression.match(/\{[^\}]+\}/g)
        matches.map(item => {
          let id = item.replace('{', '').replace('}', '')
          if (id == 'qty' || id == 'unit_price' || id == 'price_with_tax') {
            expression = expression.replace(item, data[id])
          } else if (id == 'total_amount') {
            let temp_total_amount = 0

            temp_total_amount = data.price_with_tax * data.qty

            expression = expression.replace(item, temp_total_amount)
          } else if (id == 'net_amount') {
            let temp_net_amount = 0

            temp_net_amount = data.unit_price * data.qty
            expression = expression.replace(item, temp_net_amount)
          } else {
            expression = expression.replace(item, data[this.props.column_id_names[id]])
          }
        })

        if (expression != '') {
          try {
            let evaluated_value = eval(expression)
            if (isNaN(evaluated_value)) {
              throw new Error('Invalid expression')
            }

            data[selected_column.name] = evaluated_value

            if (selected_column.name == this.props.qty_name) {
              data['qty'] = data[selected_column.name]
            }
          } catch (err) {
            if (selected_column.default_key == 'total_amount') {
              data[this.props.total_amount_name] =
                this.props.with_tax == 1 ? getRowTotal(data) : getNetAmount_unit_price(data)
              data[this.props.net_amount_name] =
                data[this.props.total_amount_name] / (1 + (data['tax'] + data['cess']) / 100)
            }

            if (selected_column.default_key == 'net_amount') {
              data[this.props.net_amount_name] = getNetAmount_unit_price(data)
              data[this.props.total_amount_name] =
                data[this.props.net_amount_name] * (1 + (data['tax'] + data['cess']) / 100)
            }
          }

          if (selected_column.default_key == 'total_amount') {
            data[this.props.net_amount_name] =
              data[this.props.total_amount_name] / (1 + (data['tax'] + data['cess']) / 100)
            data['tax_amount'] = (
              (data[this.props.net_amount_name] * (data['tax'] + data['cess'])) /
              100
            ).toFixed(2)
          }

          if (selected_column.default_key == 'net_amount') {
            data[this.props.total_amount_name] =
              data[this.props.net_amount_name] * (1 + (data['tax'] + data['cess']) / 100)
            data['tax_amount'] = (
              (data[this.props.net_amount_name] * (data['tax'] + data['cess'])) /
              100
            ).toFixed(2)
          }

          if (data['is_discount_percent'] == 0) {
            if (this.props.discount_type == 'net_amount') {
              data['discount'] =
                (data['discount_net_value'] / data[this.props.net_amount_name]) * 100
            } else if (this.props.discount_type == 'total_amount') {
              data['discount'] = (data['discount_value'] / data[this.props.total_amount_name]) * 100
            }
          } else {
            if (this.props.discount_type == 'net_amount') {
              data['discount_net_value'] =
                (data[this.props.net_amount_name] * data['discount']) / 100
            } else if (this.props.discount_type == 'total_amount') {
              data['discount_value'] = (data[this.props.total_amount_name] * data['discount']) / 100
            }
          }

          if (
            selected_column.default_key == 'net_amount' ||
            selected_column.default_key == 'total_amount'
          ) {
            data[this.props.net_amount_name] =
              data[this.props.net_amount_name] * (1 - data['discount'] / 100)

            data[this.props.total_amount_name] =
              data[this.props.net_amount_name] * (1 + (data['tax'] + data['cess']) / 100)

            data['tax_amount'] = (
              (data[this.props.net_amount_name] * (data['tax'] + data['cess'])) /
              100
            ).toFixed(2)
          }
        }
      }

      data['qty'] = data[this.props.qty_name]

      let is_total_amount = false
      let is_net_amount = false

      this.props.custom_columns.map(item => {
        if (item.name == this.props.total_amount_name && item.expression != '') {
          is_total_amount = true
        }
      })
      this.props.custom_columns.map(item => {
        if (item.name == this.props.net_amount_name && item.expression != '') {
          is_net_amount = true
        }
      })
      if (!is_total_amount && !is_net_amount) {
        data[this.props.total_amount_name] =
          this.props.with_tax == 1 ? getRowTotal(data) : getNetAmount_unit_price(data)
        data[this.props.net_amount_name] = getNetAmount_unit_price(data)
      }

      data['tax_amount'] =
        (data[this.props.net_amount_name] * (data['tax'] + data['cess'])) / 100 +
        (data['cess_on_qty_value'] ? data['cess_on_qty_value'] : 0)

      data['total_amount'] = data[this.props.total_amount_name].toFixed(2)
      data['net_amount'] = data[this.props.net_amount_name]
      ItemData = data
    }

    if (productsData.length == 0) {
      // this.gridRef.current.api.applyTransaction({ update: [data] }, this.onGridRowDataUpdate)
      this.applyTransaction({ update: [ItemData] })
    }

    return { ...ItemData, key: key }
  }

  additionalChargeCalculation = (item, name, value) => {
    const total_net_amount = this.getTotalNetAmount() || 1
    item[name] = parseFloat(value)

    let tax = item['tax'] || 0
    let netAmount = item['net_amount'] || 0
    if (name == 'percent') {
      netAmount = parseFloat((total_net_amount * item['percent']) / 100)
      item['net_amount'] = netAmount
    } else if (name == 'total_amount') {
      netAmount = parseFloat(item['total_amount'] / (1 + tax / 100))
      item['net_amount'] = netAmount
    }
    item['tax_amount'] = parseFloat((netAmount * tax) / 100)
    if (name != 'total_amount') {
      item['total_amount'] = parseFloat(netAmount * (1 + tax / 100))
    }
    if (name != 'percent') {
      item['percent'] = parseFloat((100 * netAmount) / total_net_amount)
    }

    return item
  }
  onChangeAdditionalCharge = (e, additional_charge_id, name) => {
    if (e == null || isNaN(e)) {
      message.error('Enter a valid Number')
      return
    }
    if (name == 'net_amount' || name == 'total_amount') {
      if (
        (this.props.is_export == 1 || this.props.is_multi_currency == 1) &&
        this.state.showExportCurrency
      ) {
        e = e * parseFloat(this.props.conversion_factor)
      }
    }

    const dataSource = [...this.props.document_custom_additional_charges]
    const index = dataSource.findIndex(item => additional_charge_id === item.additional_charge_id)
    dataSource[index] = this.additionalChargeCalculation(dataSource[index], name, e)

    this.props.updateExtraCharges?.({
      document_custom_additional_charges: dataSource,
      has_extra_charges: this.state.has_extra_charges,
    })
  }

  populateChargesDefaultValues = () => {
    const total_net_amount = this.getTotalNetAmount() || 1

    var document_custom_additional_charges = this.props.custom_additional_charges.map(
      (item, index) => {
        return {
          name: item.name,
          additional_charge_id: item.additional_charge_id,
          total_amount: item.amount,
          tax: item.tax,
          tax_amount: item.amount - item.unit_price,
          percent: parseFloat((100 * item.unit_price) / total_net_amount),
          net_amount: item.unit_price,
          sac_code: item.sac_code,
          type: item.type,
          is_active: item.is_active,
          is_default: item.is_default,
        }
      },
    )

    let active_charges = document_custom_additional_charges.filter(item => item.is_default == 1)
    this.setState(
      {
        default_charges_populated: true,
      },
      () => {
        if (active_charges && active_charges.length > 0) {
          this.props.updateExtraCharges?.({
            document_custom_additional_charges: active_charges,
            has_extra_charges: this.state.has_extra_charges,
          })
        }
      },
    )
  }

  updateBatchPrices = product => {
    if (product['has_batches'] == 2 && this.allowBatches(this.props.document_type)) {
      let serial_numbers = product['serial_numbers']?.map(item => item.batch_id)
      product['batches'].map(item => {
        if (serial_numbers.includes(item.batch_id)) {
          item.unit_price = product['unit_price']
          item.price = product['price']
          item.price_with_tax = product['price_with_tax']
        }
      })
    }

    if (product['has_batches'] == 1 && this.allowBatches(this.props.document_type)) {
      product['batches'].map(item => {
        if (product.batch_uid == item.batch_uid) {
          item.unit_price = product['unit_price']
          item.price = product['price']
          item.price_with_tax = product['price_with_tax']
          item.unit = product['unit']
          item.unit_id = product['unit_id']
          item.conversion_rate = product['conversion_rate']
        }
      })
    }

    return product
  }

  _onChange = async (value, key, name) => {
    if (isNaN(value)) {
      message.error('Enter a valid Number')
    } else {
      let newItemData = this.getRowDataForKey(key)

      if (name == 'qty') {
        if (newItemData['is_cess_on_qty_applicable'] && newItemData['cess_on_qty_value'] > 0) {
          notification.warning({
            message:
              'Additional Cess will be reset to 0 as quantity is changed. Please re-enter additional cess if applicable.',
            duration: 5,
          })
        }
        newItemData[name] = value
      } else {
        if (name == 'discount') {
          if (value > 100) {
            message.error('Discount cannot be greater than 100%')
            return
          }
        }
        if (name == 'discount_unit_price_value') {
          if (value > newItemData.unit_price) {
            message.error('Discount amount cannot be greater than ' + newItemData.unit_price)
            return
          }
        }
        if (name == 'discount_price_with_tax_value') {
          if (value > newItemData.price_with_tax) {
            message.error('Discount amount cannot be greater than ' + newItemData.price_with_tax)
            return
          }
        }

        newItemData[name] = Number(value)
      }

      if (name == 'price') {
        newItemData['tax'] = getTaxRateByPrice(newItemData)
        newItemData['price_with_tax'] = getPriceWithTax(newItemData)
        newItemData['discount_value'] = getDiscountValue(newItemData)
        newItemData['discount_net_value'] = getDiscountNetValue(newItemData)
        newItemData['discount_unit_price_value'] = getDiscountUnitPriceValue(newItemData)
        newItemData['discount_price_with_tax_value'] = getDiscountPriceWithTaxValue(newItemData)
        if (this.props.is_export == 1 || this.props.is_multi_currency == 1) {
          if (this.state.showExportCurrency) {
            newItemData['export_unit_price'] = Number(value)
            newItemData['unit_price'] = Number(value) * this.props.conversion_factor
            newItemData['price'] = Number(value) * this.props.conversion_factor
            newItemData['price_with_tax'] = getPriceWithTax(newItemData)
          } else {
            newItemData['unit_price'] = Number(value)
            newItemData['price'] = Number(value)
            newItemData['export_unit_price'] = Number(value) / this.props.conversion_factor
          }
          newItemData['export_price_with_tax'] =
            newItemData['price_with_tax'] / this.props.conversion_factor
        } else {
          newItemData['unit_price'] = Number(value)
          newItemData['price'] = Number(value)
        }
        newItemData = this.updateBatchPrices(newItemData)
      } else if (name == 'price_with_tax') {
        if (this.props.is_export == 1 || this.props.is_multi_currency == 1) {
          if (this.state.showExportCurrency) {
            newItemData['price_with_tax'] = Number(value) * this.props.conversion_factor
            newItemData['export_price_with_tax'] = Number(value)
          } else {
            newItemData['export_price_with_tax'] = Number(value) / this.props.conversion_factor
          }
        }

        newItemData['price'] = getPrice(newItemData)
        newItemData['unit_price'] = getPrice(newItemData)
        newItemData['discount_value'] = getDiscountValue(newItemData)
        newItemData['discount_net_value'] = getDiscountNetValue(newItemData)
        newItemData['discount_unit_price_value'] = getDiscountUnitPriceValue(newItemData)
        newItemData['discount_price_with_tax_value'] = getDiscountPriceWithTaxValue(newItemData)
        if (this.props.is_export == 1 || this.props.is_multi_currency == 1) {
          newItemData['export_unit_price'] =
            newItemData['unit_price'] / this.props.conversion_factor
        }

        newItemData = this.updateBatchPrices(newItemData)
      } else if (name == 'discount_value') {
        newItemData['discount'] = getDiscount_with_tax(newItemData, this.props.with_tax, name)
        newItemData['discount_net_value'] = getDiscountNetValue(newItemData)
        newItemData['discount_unit_price_value'] = getDiscountUnitPriceValue(newItemData)
        newItemData['discount_price_with_tax_value'] = getDiscountPriceWithTaxValue(newItemData)
      } else if (name == 'discount_net_value') {
        newItemData['discount'] = getDiscount_with_tax(newItemData, this.props.with_tax, name)
        newItemData['discount_value'] = getDiscountValue(newItemData)
        newItemData['discount_unit_price_value'] = getDiscountUnitPriceValue(newItemData)
        newItemData['discount_price_with_tax_value'] = getDiscountPriceWithTaxValue(newItemData)
        // newItemData['price_with_tax'] = getPriceWithTax(newItemData)
      } else if (name == 'discount_unit_price_value') {
        newItemData['tax'] = getTaxRateByPrice(newItemData)
        newItemData['price_with_tax'] = getPriceWithTax(newItemData)
        newItemData['discount'] = getDiscount_with_tax(newItemData, this.props.with_tax, name)
        newItemData['discount_value'] = getDiscountValue(newItemData)
        newItemData['discount_net_value'] = getDiscountNetValue(newItemData)
        newItemData['discount_price_with_tax_value'] = getDiscountPriceWithTaxValue(newItemData)
        // newItemData['price_with_tax'] = getPriceWithTax(newItemData)
      } else if (name == 'discount_price_with_tax_value') {
        newItemData['discount'] = getDiscount_with_tax(newItemData, this.props.with_tax, name)
        newItemData['discount_value'] = getDiscountValue(newItemData)
        newItemData['discount_unit_price_value'] = getDiscountUnitPriceValue(newItemData)
        newItemData['discount_net_value'] = getDiscountNetValue(newItemData)
        // newItemData['price_with_tax'] = getPriceWithTax(newItemData)
      } else if (name == 'discount') {
        newItemData['discount_unit_price_value'] = getDiscountUnitPriceValue(newItemData)
        newItemData['tax'] = getTaxRateByPrice(newItemData)
        newItemData['price_with_tax'] = getPriceWithTax(newItemData)

        newItemData['discount_value'] = getDiscountValue(newItemData)
        newItemData['discount_net_value'] = getDiscountNetValue(newItemData)
        newItemData['discount_price_with_tax_value'] = getDiscountPriceWithTaxValue(newItemData)
        // newItemData['price_with_tax'] = getPriceWithTax(newItemData)
      } else if (name == 'qty') {
        newItemData['discount_value'] = getDiscountValue(newItemData)
        newItemData['discount_net_value'] = getDiscountNetValue(newItemData)
        newItemData['qty'] = newItemData['qty'] ? newItemData['qty'] : 0
        newItemData[this.props.qty_name] = newItemData['qty']
        if (newItemData['is_cess_on_qty_applicable']) {
          newItemData['cess_on_qty_value'] = 0
          newItemData['cess_amount'] = getCessAmount(newItemData)
        }
        newItemData['price_with_tax'] = getPriceWithTax(newItemData)
      } else if (name == 'free_qty') {
        newItemData['free_qty'] = value
      } else if (name == 'unit_id') {
        let prev_conversion_rate = newItemData['conversion_rate']
        newItemData['unit_id'] = value
        if (newItemData['unit_id'] == 0 || newItemData['unit_id'] == null) {
          newItemData['conversion_rate'] = 1
        } else {
          newItemData['conversion_rate'] = newItemData['units']
            ? newItemData['units'].find(item => item.unit_id == value)?.conversion_rate
            : 1
        }
        if (newItemData['is_cess_on_qty_applicable']) {
          newItemData['cess_on_qty_value'] = 0
          newItemData['cess_amount'] = getCessAmount(newItemData)
        }
        newItemData['price'] =
          (newItemData['price'] * prev_conversion_rate) / newItemData['conversion_rate']
        newItemData['unit_price'] = newItemData['price']
        newItemData['price_with_tax'] = getPriceWithTax(newItemData)
        newItemData['discount_value'] = getDiscountValue(newItemData)
        if (this.props.is_export == 1 || this.props.is_multi_currency == 1) {
          newItemData['export_unit_price'] =
            newItemData['unit_price'] / this.props.conversion_factor
        }

        newItemData['unit'] = newItemData['units']
          ? newItemData['units'].filter(item => item.unit_id == newItemData['unit_id'])?.[0]
              .alternative_unit
          : newItemData['unit']

        newItemData = this.updateBatchPrices(newItemData)
      }

      newItemData = await this.onCustomChange(newItemData['qty'], key, this.props.qty_name, 'qty', [
        newItemData,
      ])
      this.applyTransaction({ update: [newItemData] })

      // await this.gridRef.current.api.applyTransaction({ update: [newItemData] })
    }
  }

  onTaxChange = (value, key) => {
    if (isNaN(value)) {
      message.error('Please select a valid tax')
      return
    }
    let newItemData = this.getRowDataForKey(key)

    if (isEmpty(newItemData)) {
      message.error('Something went wrong')
      return
    }

    newItemData['tax'] = Number(value)
    const currentPrice = newItemData['price']
    this.applyTransaction({ update: [newItemData] })
    this.onChange(currentPrice, key, 'price')
  }

  updateAdditionalCharges = () => {
    if (this.props.document_custom_additional_charges?.length > 0) {
      let change_detected = false
      let additionalChargesData = [...this.props.document_custom_additional_charges]
      additionalChargesData.map((item, i) => {
        if (item.total_amount != 0) {
          change_detected = true
          return {
            ...this.additionalChargeCalculation(item, 'total_amount', item.total_amount),
          }
        }
        return item
      })
      if (change_detected) {
        this.props.updateExtraCharges?.({
          document_custom_additional_charges: additionalChargesData,
          has_extra_charges: this.state.has_extra_charges,
        })
      }
    }
  }

  allowBatches = document_type => {
    return document_type == 'estimate' ||
      document_type == 'purchase_order' ||
      document_type == 'pro_forma_invoice' ||
      document_type == 'sales_order'
      ? false
      : true
  }

  onAddNew = item => {
    if (item?.variants?.length > 0) {
      this.selectItemRef?.current?.doSearch(item.product_name)
      return
    }

    this.selectItemRef?.current?.doSearch('')
    const { count } = this.state

    // when adding new product from purchases, change price with tax to purchase price
    item.actual_price_with_tax = item.price_with_tax
    item.actual_unit_price = item.unit_price
    if (this.state.type == 'purchases') {
      item.price_with_tax = item.purchase_price ? item.purchase_price : item.price_with_tax
      item.price = item.price_with_tax / (1 + (item.tax + item.cess) / 100)
      item.unit_price = item.price
    }
    if (this.props.with_tax == 0) {
      item.price_with_tax = item.unit_price
    }
    const final_discount =
      this.props.document_type.includes('purchase') && item.discount != 0
        ? 0
        : this.state.total_discount != 0 && this.state.total_discount != undefined
        ? this.state.total_discount
        : this.props.customerDiscount == 0
        ? item.discount
        : this.props.customerDiscount

    const newData = {
      ...item,
      key: this.gridRef.current.api.getDisplayedRowCount() + 1,
      id: String(item.product_id) + '_' + String(0),
      qty: 1,
      selling_price: item.actual_price_with_tax,
      product_discount: item.discount,
      variant_name: '',
      variant_id: 0,
      value: item.product_name,
      tax: item.tax,
      qtyinstock: item.qtyinstock,
      product_name: item.product_name,
      discount: final_discount,
      discount_value: ((item.price_with_tax * final_discount) / 100).toFixed(2),
      discount_price_with_tax_value: ((item.price_with_tax * final_discount) / 100).toFixed(2),
      discount_net_value: ((item.unit_price * final_discount) / 100).toFixed(2),
      discount_unit_price_value: ((item.unit_price * final_discount) / 100).toFixed(2),
      selected_discount_type: 'discount',
      is_discount_percent: 1,
      export_unit_price: item.unit_price / this.props.conversion_factor,
      is_cess_on_qty_applicable: false,
      master_data: {
        price_with_tax: item.actual_price_with_tax,
        unit_price: item.unit_price,
        purchase_price: item.purchase_price,
        purchase_unit_price: item.purchase_unit_price,
        tax: item.tax,
        cess: item.cess,
        discount: item.discount,
      },
      batches: [],
    }

    if (
      item.has_batches == 1 ||
      (this.props.document_type != 'purchase' &&
        item.has_batches == 2 &&
        this.allowBatches(this.props.document_type))
    ) {
      this.productBatchesRef.current.showModal({ ...newData, variant_id: 0 }, this.props.edit)
      return
    }
    if (
      item.has_batches == 2 &&
      this.props.document_type == 'purchase' &&
      this.allowBatches(this.props.document_type)
    ) {
      this.serialNumberRef.onAdd({ ...newData, variant_id: 0 }, true)
      return
    }

    this.setState(
      {
        count: count + 1,
        currentKey: count + 1,
        barcodeData: { ...item, key: count + 1, qty: 1 },
      },
      () => {
        // this.gridRef.current.api.applyTransaction({ add: [newData] }, this.onGridRowDataUpdate)
        this.applyTransaction({ add: [newData] })
      },
    )
  }

  onDelete = key => {
    let item = this.getRowDataForKey(key)

    if (item.has_batches == 2 && this.allowBatches(this.props.document_type)) {
      let serial_numbers = item.serial_numbers?.map(item => item.batch_id)
      item.batches = item.batches.map(batch => {
        if (serial_numbers.includes(batch.batch_id)) {
          batch.added_qty = 0
          batch.changed = false
        }
        return batch
      })
      this.onFinishBatchesQty(item, item.batches)
      return
    }

    if (item.has_batches == 1 && this.allowBatches(this.props.document_type)) {
      item.batches = item.batches.map(batch => {
        if (item.batch_id == batch.batch_id) {
          batch.added_qty = 0
          batch.changed = false
        }
        return batch
      })
      this.onFinishBatchesQty(item, item.batches)
      return
    }
    let newDataSource = this.getRowDataFromGrid(false)
    newDataSource = newDataSource
      .filter(item => item.key != key)
      .map((item, index) => {
        return { ...item, key: index + 1 }
      })

    this.setState({ count: newDataSource.length, itemsData: newDataSource }, () => {
      this.gridRef.current.api.setGridOption('rowData', this.state.itemsData)
      this.props.dataUpdate(this.state.itemsData)
    })
  }

  onSelectedDiscountChange = (key, value) => {
    let item = this.getRowDataForKey(key)

    item.selected_discount_type = value
    if (value == 'discount') {
      item.is_discount_percent = 1
    } else {
      item.is_discount_percent = 0
    }
    this.applyTransaction({ update: [item] })
  }

  onTotalDiscountChange = value => {
    const newData = this.getRowDataFromGrid(true)
    const dataSource = []
    newData.map(item => {
      item.discount = value == null ? 0 : value
      item.discount_value = getDiscountValue(item)
      item.discount_net_value = getDiscountNetValue(item)
      item.discount_unit_price_value = getDiscountUnitPriceValue(item)
      item.discount_price_with_tax_value = getDiscountPriceWithTaxValue(item)
    })
    newData.map((item, index) => {
      let data = this.onCustomChange(item['qty'], item['key'], this.props.qty_name, 'qty')
      dataSource.push(data)
    })

    this.setState({ total_discount: value })

    this.applyTransaction({ update: dataSource })
  }

  getQtyClass = data => {
    if (data < 0) {
      return '#e11900'
    } else if (data == 0 && data < 5) {
      return '#757575'
    } else {
      return '#09864A'
    }
  }

  getTotalNetAmount = () => {
    var sum = 0
    this.props.data.map(item => {
      sum = sum + getItemNetAmount(item)
    })
    return sum
  }

  getTotalCessAmount = () => {
    var cessSum = 0
    this.props.data.map(item => {
      cessSum = cessSum + getCessAmount(item)
    })
    return Math.round(cessSum)
  }

  onChangePackingChargesTax = e => {
    this.setState({ packingChargesTax: parseFloat(e) }, () => {
      this.setState(
        {
          packingCharges: (
            this.state.packingChargesWithoutTax *
            (1 + this.state.packingChargesTax / 100)
          ).toFixed(2),
        },
        () => {
          this.setState(
            {
              packingChargesTaxAmount: (
                (this.state.packingChargesWithoutTax * this.state.packingChargesTax) /
                100
              ).toFixed(2),
            },
            () => {
              this.props.updateExtraCharges?.(this.state)
            },
          )
        },
      )
    })
  }

  onChangeTransportChargesTax = e => {
    this.setState({ transportChargesTax: parseFloat(e) }, () => {
      this.setState(
        {
          transportCharges: (
            this.state.transportChargesWithoutTax *
            (1 + this.state.transportChargesTax / 100)
          ).toFixed(2),
        },
        () => {
          this.setState(
            {
              transportChargesTaxAmount: (
                (this.state.transportChargesWithoutTax * this.state.transportChargesTax) /
                100
              ).toFixed(2),
            },
            () => {
              this.props.updateExtraCharges?.(this.state)
            },
          )
        },
      )
    })
  }

  formatResult = item => {
    return (
      <div>
        {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.product_id}</span> */}
        <span style={{ display: 'block', textAlign: 'left' }}>{item.product_name}</span>
      </div>
    )
  }

  onFinishBatchesQty = async (product, batches) => {
    this.onFinishSerialNumbers(
      product,
      batches.filter(batch => batch.added_qty > 0),
      batches,
    )
  }

  onFinishSerialNumbers = async (product, added_batches, batches = []) => {
    const { count } = this.state
    const { data } = this.props
    const matchedItem = data.filter(
      item => item.product_id == product.product_id && item.variant_id == product.variant_id,
    )

    let updated_batches = [...batches]

    let old_item_dict = new Map()

    if (matchedItem.length > 0) {
      matchedItem.forEach(item => {
        let key = product.has_batches == 2 ? item.price_with_tax : item.batch_uid

        old_item_dict.set(key, item)

        if (product.has_batches == 1) {
          batches.forEach(async (batch, index2) => {
            if (item.batch_id == batch.batch_id) {
              old_item_dict.get(key).qty = batch.added_qty > 0 ? batch.added_qty : -1
              old_item_dict.get(key).free_qty = batch.added_free_qty > 0 ? batch.added_free_qty : -1

              await old_item_dict.get(key).batches.map((batchItem, index3) => {
                if (batchItem.batch_id == batch.batch_id) {
                  old_item_dict.get(key).batches[index3].added_qty = batch.added_qty
                  old_item_dict.get(key).batches[index3].added_free_qty = batch.added_free_qty
                }
              })
            }
          })
        }
      })
    }

    const final_discount =
      this.state.total_discount != 0 && this.state.total_discount != undefined
        ? this.state.total_discount
        : this.props.customerDiscount == 0
        ? product.discount
        : this.props.customerDiscount

    let batch_wise_items_grouped = new Map()
    added_batches.forEach(num => {
      let price_with_tax = this.props.document_type.includes('purchase')
        ? product.purchase_price
        : num.price_with_tax

      let key = product.has_batches == 2 ? price_with_tax : num.batch_uid
      if (batch_wise_items_grouped.has(key)) {
        batch_wise_items_grouped.get(key).push(num)
      } else {
        batch_wise_items_grouped.set(key, [num])
      }
    })

    let newProducts = []
    let items_count = 1

    for (const [key, serial_list] of batch_wise_items_grouped) {
      let qty =
        product.has_batches == 2
          ? serial_list.length
          : serial_list.reduce((a, b) => a + b.added_qty, 0)

      let free_qty =
        product.has_batches == 2 ? 0 : serial_list.reduce((a, b) => a + b.added_free_qty, 0)

      let unit_price =
        this.props.document_type.includes('purchase') && product.has_batches == 2
          ? product.purchase_unit_price == 0
            ? product.unit_price
            : product.purchase_unit_price
          : serial_list[0].unit_price

      let price_with_tax =
        this.props.document_type.includes('purchase') && product.has_batches == 2
          ? product.purchase_price == 0
            ? product.price_with_tax
            : product.purchase_price
          : serial_list[0].price_with_tax

      if (old_item_dict.has(key)) {
        old_item_dict.get(key).exists = true
        old_item_dict.get(key).qty = qty
        old_item_dict.get(key).free_qty = free_qty
        old_item_dict.get(key).price_with_tax = price_with_tax
        old_item_dict.get(key).price = unit_price
        old_item_dict.get(key).unit_price = unit_price
        old_item_dict.get(key).serial_numbers = product.has_batches == 2 ? serial_list : []
        old_item_dict.get(key).batches = updated_batches
        old_item_dict.get(key)[this.props.qty_name] = qty
        old_item_dict.get(key).unit_id = product.has_batches == 2 ? 0 : serial_list[0].unit_id
        old_item_dict.get(key).unit = product.has_batches == 2 ? '' : serial_list[0].unit
        old_item_dict.get(key).conversion_rate =
          product.has_batches == 2 ? 1 : serial_list[0].conversion_rate
        continue
      }

      let no_iterations = product.has_batches == 2 ? 1 : serial_list.length

      for (let i = 0; i < no_iterations; i++) {
        //BATCH CUSTOM COLUMNS
        let batch_custom_columns = serial_list[i].custom_fields.map(item_col => {
          return {
            name: item_col.name,
            value: item_col.value,
          }
        })

        const newData = {
          ...product,
          key: count + items_count,
          qty: qty,
          free_qty: free_qty,
          price_with_tax: price_with_tax,
          unit_price: unit_price,
          price: unit_price,
          discount: final_discount,
          discount_value: ((qty * price_with_tax * final_discount) / 100).toFixed(2),
          discount_price_with_tax_value: ((1 * price_with_tax * final_discount) / 100).toFixed(2),
          discount_net_value: ((qty * unit_price * final_discount) / 100).toFixed(2),
          discount_unit_price_value: ((1 * unit_price * final_discount) / 100).toFixed(2),
          description: product.description,
          editorState: EditorState.createWithContent(stateFromHTML(product.description)),
          expanded: true,
          cess_amount: qty * unit_price * (product.cess / 100),
          selected_discount_type: 'discount',
          is_discount_percent: 1,
          is_cess_on_qty_applicable: false,
          serial_numbers: product.has_batches == 2 ? serial_list : [],
          batches: updated_batches,
          batch_id: product.has_batches == 2 ? 0 : serial_list[i].batch_id,
          batch_uid: product.has_batches == 2 ? '' : serial_list[i].batch_uid,
          batch_no: product.has_batches == 2 ? '' : serial_list[i].batch_no,
          unit_id: product.has_batches == 2 ? 0 : serial_list[i].unit_id,
          unit: product.has_batches == 2 ? product.unit : serial_list[i].unit,
          conversion_rate: product.has_batches == 2 ? 1 : serial_list[i].conversion_rate,
          purchase_price:
            product.has_batches == 2 ? product.purchase_price : serial_list[i].purchase_price,
          purchase_unit_price:
            product.has_batches == 2
              ? product.purchase_unit_price
              : serial_list[i].purchase_unit_price,
          [this.props.qty_name]: qty,
          master_data: {
            price_with_tax: product.actual_price_with_tax || product.price_with_tax,
            unit_price: product.unit_price,
            purchase_price: product.purchase_price,
            purchase_unit_price: product.purchase_unit_price,
            tax: product.tax,
            cess: product.cess,
            discount: product.discount,
          },

          ...batch_custom_columns.reduce((a, b) => ({ ...a, [b.name]: b.value }), {}),
        }

        newData['net_amount'] = getNetAmount_unit_price(newData)
        newData['total_amount'] = getRowTotal(newData)
        newData['tax_amount'] = getTaxAmount(newData)
        newData['cess_amount'] = getCessAmount(newData)

        if (this.props.is_export == 1 || this.props.is_multi_currency == 1) {
          if (isNaN(parseFloat(this.props.conversion_factor))) {
            message.error('Enter conversion factor')
            return true
          }
          newData['export_unit_price'] =
            newData['unit_price'] / parseFloat(this.props.conversion_factor)
          newData['export_price_with_tax'] =
            newData['price_with_tax'] / parseFloat(this.props.conversion_factor)
        }

        newProducts.push(newData)
        items_count += 1
      }
    }

    let final_products = []

    if (product.has_batches == 1) {
      final_products = newProducts
    }

    let custom_column_keys = this.props.custom_columns
      .filter(item => item.applicable_for == 'batch')
      .map(item => item.name)

    if (product.has_batches == 2 && custom_column_keys.length > 0) {
      // group newProducts by custom fields
      newProducts.forEach(item => {
        let item_serial_numbers = [...item.serial_numbers]

        // group by serial number custom field values
        let groupedProducts = new Map()
        item_serial_numbers.forEach(serial => {
          let customFields = serial.custom_fields.reduce(
            (a, b) => ({ ...a, [b.name]: b.value }),
            {},
          )
          let key = JSON.stringify(customFields)
          if (groupedProducts.has(key)) {
            groupedProducts.get(key).push({
              ...serial,
              modified_custom_fields: customFields,
            })
          } else {
            groupedProducts.set(key, [
              {
                ...serial,
                modified_custom_fields: customFields,
              },
            ])
          }
        })

        groupedProducts.forEach((serials, key) => {
          if (serials?.length > 0) {
            let new_item = {
              ...item,
              ...serials[0]?.modified_custom_fields,
              qty: serials.length,
              serial_numbers: serials,
            }
            new_item.net_amount = getNetAmount_unit_price(new_item)
            new_item.cess_amount = getCessAmount(new_item)
            new_item.tax_amount = getTaxAmount(new_item)
            new_item.total_amount = getRowTotal(new_item)
            new_item['discount_value'] = getDiscountValue(new_item)
            new_item['discount_net_value'] = getDiscountNetValue(new_item)
            new_item['discount_unit_price_value'] = getDiscountUnitPriceValue(new_item)
            new_item['discount_price_with_tax_value'] = getDiscountPriceWithTaxValue(item)
            final_products.push(new_item)
          }
        })
      })
    } else {
      final_products = newProducts
    }

    let allproducts = [...this.props.data, ...final_products]

    for (const [price_incl_tax, item] of old_item_dict) {
      if (item.exists == undefined || !item.exists || added_batches.length == 0) {
        allproducts = allproducts.filter(
          item2 => item2.key != old_item_dict.get(price_incl_tax).key,
        )
      } else {
        let index = allproducts.findIndex(
          item3 => item3.key == old_item_dict.get(price_incl_tax).key,
        )
        allproducts[index] = old_item_dict.get(price_incl_tax)
        allproducts[index].net_amount = getNetAmount_unit_price(allproducts[index])
        allproducts[index].cess_amount = getCessAmount(allproducts[index])
        allproducts[index].tax_amount = getTaxAmount(allproducts[index])

        allproducts[index].total_amount = getRowTotal(allproducts[index])

        allproducts[index]['discount_value'] = getDiscountValue(allproducts[index])
        allproducts[index]['discount_net_value'] = getDiscountNetValue(allproducts[index])
        allproducts[index]['discount_unit_price_value'] = getDiscountUnitPriceValue(
          allproducts[index],
        )
        allproducts[index]['discount_price_with_tax_value'] = getDiscountPriceWithTaxValue(
          allproducts[index],
        )
        this.onCustomChange(
          allproducts[index]['qty'],
          allproducts[index]['key'],
          this.props.qty_name,
          'qty',
        )
      }
    }

    if (this.props.items_reverse) {
      allproducts = [...newProducts, ...this.props.data]
    }

    allproducts = allproducts.map((item, index) => ({
      ...item,
      key: index + 1,
      exists: false,
    }))
    let filterd_products = allproducts.filter(
      item =>
        (item.has_batches == 1 && item.batch_id == 0) ||
        (item.has_batches == 2 && item?.serial_numbers?.length == 0),
    )
    let showSelectBatches = this.state.showSelectBatches
    if (filterd_products.length == 0) {
      showSelectBatches = false
    }

    this.setState(
      {
        count: count + newProducts.length,
        selected: false,
        selectedData: {},
        searchText: '',
        page: 0,
        showSelectBatches: showSelectBatches,
        itemsData: allproducts,
      },
      async () => {
        this.selectItemRef?.current?.resetForm()

        this.gridRef.current.api.setGridOption('rowData', this.state.itemsData)
        this.props.dataUpdate(allproducts)
      },
    )

    this.selectItemRef?.current?.doSearch('')
  }

  updateInProductsTable = newData => {
    newData['key'] = this.state.items_reverse
      ? 1
      : this.gridRef.current.api.getDisplayedRowCount() + 1

    if (
      this.gridRef.current.api.getDisplayedRowCount() + 1 == 1 &&
      !this.props.edit &&
      !this.state.default_charges_populated &&
      !this.props.convert &&
      !this.props.duplicate
    ) {
      this.populateChargesDefaultValues()
    }

    const finalData = this.onCustomChange(
      newData['qty'],
      newData['key'],
      this.props.qty_name,
      'qty',
      [newData],
    )
    var dataSource = this.getRowDataFromGrid(false)
    if (this.state.items_reverse) {
      dataSource = [finalData, ...dataSource]
      dataSource = dataSource.map((item, index) => {
        item.key = index + 1
        return item
      })
    } else {
      dataSource.push(finalData)
    }

    this.gridRef.current.api.setGridOption('rowData', dataSource)
    this.props.dataUpdate(dataSource)

    var allproducts = this.getRowDataFromGrid()

    if (this.props.permissions.additional_cess == 1 && newData?.cess_non_advl_rate) {
      this.additional_cess.showModal(newData)
    }
    return finalData
  }

  changeBatchQty = (productData, is_edit) => {
    if (
      productData.has_batches == 1 ||
      (this.props.document_type != 'purchase' && productData.has_batches == 2)
    ) {
      this.productBatchesRef.current.showModal(productData, is_edit)
    } else {
      this.serialNumberRef.onAdd(productData, is_edit)
    }
  }

  handleItemHeaderSubmit = values => {
    if (!this.state.itemHeaderInput) {
      return
    }
    if (this.state.isEditingItemHeader) {
      const allItemHeaders = this.props.item_headers?.map(item =>
        item.key === this.state.editingItemHeaderKey
          ? { ...item, product_name: this.state.itemHeaderInput }
          : item,
      )
      this.props.itemHeadersUpdate(allItemHeaders)
      this.setState({
        isEditingItemHeader: false,
        editingItemHeaderKey: -1,
        itemHeaderInput: '',
      })

      return
    }

    /* what this does:
      Create the headers as invoice items itself (with everything empty) for antd table
    */
    const { count } = this.state
    const newData = {
      key: count + 1,
      value: this.state.itemHeaderInput,
      product_name: this.state.itemHeaderInput,
      is_item_header: true,
    }

    let allItemHeaders = [...this.props.item_headers, newData]

    this.setState(
      {
        count: count + 1,
        currentKey: count + 1,
        itemHeaderInput: '',
      },
      () => {
        this.props.itemHeadersUpdate(allItemHeaders)
      },
    )
  }

  handleItemHeaderInsertBefore = record => {
    if (store.get('paid') == 0) {
      this.subscriptionPlanRef.current.openModal('item_headers')
      return
    }

    // Insert a new item header before the selected item by pushing down the rest of the items
    const newMockHeader = {
      key: record.key,
      value: 'New Item Header',
      product_name: 'New Item Header',
      is_item_header: true,
    }

    // get index to insert the header
    var addIndex = 0

    var newDataSource = []
    var currentKey = record.key

    this.getRowDataFromGrid().forEach((item, index) => {
      if (item.key == record.key) {
        currentKey = newDataSource.length + 1
        newDataSource.push({ ...newMockHeader, key: newDataSource.length + 1 })
      }

      newDataSource.push({ ...item, key: newDataSource.length + 1 })
    })

    this.gridRef.current.api.setGridOption('rowData', newDataSource)

    this.setState(
      {
        count: newDataSource.length,
        isEditingItemHeader: true,
        editingItemHeaderKey: currentKey,
        itemHeaderInput: '',
      },
      () => {
        this.props.dataUpdate(newDataSource.filter(item => !item.is_item_header))
        this.props.itemHeadersUpdate(newDataSource.filter(item => item.is_item_header))
      },
    )
  }

  handleItemHeaderDelete = (record, skipConfirm = false) => {
    if (skipConfirm) {
      this.onItemHeaderDelete(record)
      return
    }
    Modal.confirm({
      title: 'Are you sure want to delete this Header?',
      onOk: () => {
        this.onItemHeaderDelete(record)
      },
      cancelButtonProps: {
        type: 'danger',
      },
      okText: 'Yes',
    })
  }

  onItemHeaderDelete = record => {
    const dataSource = this.props.item_headers
    const newData = dataSource.filter(item => item.key !== record.key)

    var finalData = mergeItemsAndItemHeaders(this.props.data, newData)
    // update the key of each item in table
    finalData = finalData.map((item, index) => ({
      ...item,
      key: index + 1,
    }))
    this.setState({ count: finalData.length }, () => {
      this.props.dataUpdate(finalData.filter(item => !item.is_item_header))
      this.props.itemHeadersUpdate(finalData.filter(item => item.is_item_header))
    })
  }

  handleItemHeaderEdit = record => {
    if (this.state.isEditingItemHeader) {
      this.setState({
        isEditingItemHeader: false,
        editingItemHeaderKey: -1,
        itemHeaderInput: '',
      })
      return
    }

    this.setState({
      isEditingItemHeader: true,
      editingItemHeaderKey: record.key,
      itemHeaderInput: record.product_name,
    })
  }

  async uploadInvoicePDF(files) {
    this.setState({
      loading: true,
      gettingAIResponse: true,
      responseText: 'Uploading file',
      progress: 0,
    })

    const textToShow = {
      1: 'Uploading file',
      2: 'Extracting data',
      3: 'Generating response',
    }
    let count = 1
    const progressInterval = setInterval(() => {
      this.setState({
        responseText: textToShow[count] ?? textToShow[3],
        progress: this.state.progress + 30,
      })
      count++
    }, 1000)

    const formData = new FormData()
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i])
    }
    formData.append('feature', 'document_upload')
    const data = await getMultipartAPI('swipe_ai', 'parse', formData)
    clearInterval(progressInterval)
    if (data) {
      if (data?.success) {
        this.setState(
          {
            responseText: 'Data retrieved',
            progress: 100,
          },
          () => {
            setTimeout(() => {
              this.setState(
                {
                  uploadedItems: data.data.items.map((item, index) => ({
                    ...item,
                    key: index + 1,
                    id: index + 1,
                    status: item.product_id != -1 ? 'update' : 'new',
                  })),
                  gettingAIResponse: false,
                  responseText: '',
                  progress: 0,
                  uploadItemsModel: true,
                },
                () => {},
              )
            }, 1000)
          },
        )
      }
    } else {
      this.setState({
        responseText: 'Something went wrong',
        progress: 100,
        loading: false,
        gettingAIResponse: false,
      })
    }

    this.setState({
      loading: false,
    })
  }

  addExistingAdditionalCharge = () => {
    const dataSource = [...this.props.document_custom_additional_charges]
    const newData = {
      key: dataSource.length + 1,
      additional_charge_id: 0, // temporary id
      total_amount: 0,
      tax: 0,
      tax_amount: 0,
      percent: 0,
      net_amount: 0,
      sac_code: '',
      type: 1,
      is_default: 0,
    }

    this.props.updateExtraCharges?.({
      document_custom_additional_charges: [...dataSource, newData],
    })
  }

  removeAdditionalCharge = data => {
    const updatedData = this.props.document_custom_additional_charges?.filter(
      mode => mode.additional_charge_id !== data.additional_charge_id,
    )
    this.props.updateExtraCharges?.({
      document_custom_additional_charges: updatedData,
    })
  }

  addChargeToDocument = charge_id => {
    var new_charge = this.props.custom_additional_charges?.filter(
      item => item.additional_charge_id == charge_id,
    )?.[0]
    if (!new_charge) {
      return
    }
    var charge_exists = this.props.document_custom_additional_charges?.filter(
      item => item.additional_charge_id == charge_id,
    )?.[0]
    if (charge_exists) {
      return
    }
    const total_net_amount = this.getTotalNetAmount() || 1

    var dataSource = [...this.props.document_custom_additional_charges]
    dataSource = dataSource.filter(item => item.additional_charge_id != 0)

    const newData = {
      name: new_charge.name,
      additional_charge_id: new_charge.additional_charge_id,
      total_amount: new_charge.amount,
      tax: new_charge.tax,
      tax_amount: new_charge.amount - new_charge.unit_price,
      percent: parseFloat((100 * new_charge.unit_price) / total_net_amount),
      net_amount: new_charge.unit_price,
      sac_code: new_charge.sac_code,
      type: new_charge.type,
      is_default: new_charge.is_default,
    }

    this.props.updateExtraCharges?.({
      document_custom_additional_charges: [...dataSource, newData],
    })
  }

  handleToggleChange = checked => {
    this.setState({ showExportCurrency: checked })
  }

  getRowDataFromGrid(onlyItems) {
    let data = []
    this.gridRef.current?.api?.forEachNode((rowNode, index) => {
      // rowNode.data.key = index + 1
      rowNode.data.position = data.length + 1
      if (onlyItems) {
        if (!rowNode.data.is_item_header) {
          data.push(rowNode.data)
        }
      } else {
        data.push(rowNode.data)
      }
    })
    return data
  }

  render() {
    const renderTax = record => {
      if (this.props.integrations?.multi_tax && record.has_multiple_tax == 1) {
        return (
          <Dropdown
            overlay={
              <Menu onClick={({ key }) => this.onTaxChange(key, record.key)}>
                {record?.multiple_tax_rates?.map((item, i) => (
                  <Menu.Item key={item.tax}>{item.tax}%</Menu.Item>
                ))}
              </Menu>
            }
            trigger={['click']}
          >
            <span className="text-blue cursor-pointer underline" onClick={e => e.preventDefault()}>
              {record.tax}%
            </span>
          </Dropdown>
        )
      } else {
        return <span>{record.tax}%</span>
      }
    }

    const noRowsOverley = () => {
      return (
        <div className="flex justify-center items-center w-full h-full">
          <Empty
            className="m-4"
            description={
              <span className="font-size-16 text-gray-500">
                Search existing products to add to this list or add new product to get started! 🚀
              </span>
            }
          >
            {/* <Button
              type="action"
              className="mr-2"
              onClick={() => this.form.onAdd(this.selectItemRef?.current?.getSearchText() ?? '')}
            >
              <i className="fa fa-plus mr-1" /> Add New Product
            </Button> */}
          </Empty>
        </div>
      )
    }

    const selectUnits = data => (
      <span className="select-borderless">
        <Select
          suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
          placeholder="Unit"
          optionFilterProp="children"
          style={{ maxWidth: '90px' }}
          dropdownStyle={{ minWidth: '300px' }}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          defaultValue={data.units?.filter(item => item?.unit_id == data?.unit_id)?.[0]?.unit_id}
          value={data?.unit_id == 0 || data?.unit_id == null ? 0 : data?.unit_id}
          onChange={value => {
            this.onChange(value, data.key, 'unit_id')
          }}
          // disabled={}
        >
          {data.units?.map((e, i) => (
            <Option key={i} value={e.unit_id}>
              {e.alternative_unit}{' '}
              {data.unit != '' && e.primary_unit == undefined
                ? ` - (1 ${data.units?.[0].alternative_unit} = ${e.conversion_rate} ${e.alternative_unit})`
                : ''}
            </Option>
          ))}
        </Select>
      </span>
    )

    var document_custom_additional_charges = [
      {
        title: '',
        width: '20%',
        dataIndex: 'name',
        render: (text, data) => {
          if (data.additional_charge_id == 0) {
            return (
              <Select
                suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                style={{ width: 150 }}
                dropdownStyle={{ minWidth: '300px' }}
                showSearch
                placeholder="Select"
                optionFilterProp="children"
                value={data.name}
                onSelect={charge_id => this.addChargeToDocument(charge_id)}
              >
                {this.props.custom_additional_charges
                  ?.filter(
                    item =>
                      !this.props.document_custom_additional_charges
                        ?.map(item => item.additional_charge_id)
                        ?.includes(item.additional_charge_id),
                  )
                  ?.map((charge, i) => (
                    <Option key={i} value={charge.additional_charge_id}>
                      {charge.name} {charge.type == -1 ? ' (-)' : ' (+)'}
                    </Option>
                  ))}
              </Select>
            )
          }
          return (
            <span className="flex items-center justify-between">
              <Tooltip title={data.type == -1 ? 'Deduction' : 'Addtion'} placement="top">
                <span className="font-weight-bold font-size-14">
                  {data.name} {data.type == -1 ? ' (-)' : ' (+)'}
                </span>
              </Tooltip>
              <span
                className="text-gray-600 cursor-pointer mr-1 "
                onClick={() => this.removeAdditionalCharge(data)}
              >
                <Tooltip title={`Remove ${data.name}?`}>
                  <i className="fa-solid fa-trash text-danger" />
                </Tooltip>
              </span>
            </span>
          )
        },
      },
      {
        title: 'Tax',
        width: '10%',
        align: 'right',
        render: (text, data) => (
          <Select
            suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
            style={{ minWidth: 80, maxWidth: 80 }}
            showSearch
            placeholder="Tax"
            optionFilterProp="children"
            value={data.tax}
            className="font-size-12"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onSelect={e => this.onChangeAdditionalCharge(e, data.additional_charge_id, 'tax')}
            disabled={!this.props.with_tax || data.additional_charge_id == 0}
          >
            {this.props.taxes.map((e, i) => (
              <Option key={i} value={e}>
                {e}
              </Option>
            ))}
          </Select>
        ),
      },
      {
        title: 'in (%)',
        width: '12%',
        minWidth: '100px',
        align: 'right',
        render: (text, data) => (
          <InputNumber
            onFocus={this.onInputFocus}
            type="text"
            style={{ width: '80%', marginRight: 0 }}
            onChange={e => this.onChangeAdditionalCharge(e, data.additional_charge_id, 'percent')}
            min={0}
            className=""
            value={data.percent ?? 0}
            disabled={data.additional_charge_id == 0}
          />
        ),
      },
      {
        title: `withoutTax in (${
          this.state.showExportCurrency
            ? this.props.currency_symbol
            : this.props.user.selectedCompany.currency_symbol
        })`,
        width: '18%',
        minWidth: '150px',
        align: 'right',
        render: (text, data) => (
          <InputNumber
            type="text"
            style={{ width: '80%', marginRight: 0 }}
            onFocus={this.onInputFocus}
            onChange={e =>
              this.onChangeAdditionalCharge(e, data.additional_charge_id, 'net_amount')
            }
            min={0}
            value={
              (this.props.is_export == 1 || this.props.is_multi_currency == 1) &&
              this.state.showExportCurrency
                ? data.net_amount / parseFloat(this.props.conversion_factor)
                : data.net_amount ?? 0
            }
            disabled={data.additional_charge_id == 0}
          />
        ),
      },
      {
        title: `withTax in (${
          this.state.showExportCurrency
            ? this.props.currency_symbol
            : this.props.user.selectedCompany.currency_symbol
        })`,
        width: '18%',
        minWidth: '150px',
        align: 'right',
        render: (text, data) => (
          <InputNumber
            onFocus={this.onInputFocus}
            type="text"
            style={{ width: '80%', marginRight: 0 }}
            onChange={e =>
              this.onChangeAdditionalCharge(e, data.additional_charge_id, 'total_amount')
            }
            min={0}
            value={
              (this.props.is_export == 1 || this.props.is_multi_currency == 1) &&
              this.state.showExportCurrency
                ? data.total_amount / parseFloat(this.props.conversion_factor)
                : data.total_amount ?? 0
            }
            disabled={data.additional_charge_id == 0}
          />
        ),
      },
    ]

    var tableColumn = [
      {
        title: '',
        field: 'prodct_name',
        rowDrag: true,
        maxWidth: 25,
        cellRenderer: params => {
          return <div className="drag-handle"></div>
        },
      },
      {
        headerComponent: ({ displayName }) => {
          return (
            <>
              <span className="font-weight-bold pl-1">Product Name</span>
              {/* Search */}
              <span className="ml-2">
                <Popover
                  trigger={['click']}
                  placement="top"
                  className="popover-child"
                  content={
                    <div
                      onClick={event => {
                        event.stopPropagation()
                      }}
                      className="flex items-center"
                    >
                      <span>
                        <Input
                          placeholder="Search Products"
                          onChange={e =>
                            this.gridRef.current.api.setGridOption(
                              'quickFilterText',
                              e.target.value,
                            )
                          }
                        />
                      </span>
                    </div>
                  }
                  onClick={e => e.stopPropagation()}
                >
                  <span>
                    <i className="fa-solid fa-search  ml-2" />
                  </span>
                </Popover>
              </span>
            </>
          )
        },
        minWidth: 270,
        field: 'product_name',
        cellRenderer: params => {
          const selectedData = params.data
          const text = params.value

          return (
            <div className="flex flex-wrap my-4 mx-1" id="product_name">
              <span className="w-full">
                {/* <div className="visible-on-ag-row-hover">
                  <Button
                    type="add-field"
                    className=" transition-all duration-300 absolute top-[-12px] left-0 px-2 py-1 text-sm font-weight-medium"
                    onClick={() => this.handleItemHeaderInsertBefore(selectedData)}
                    // hidden={!this.SHOW_ADD_ITEM_HEADER}
                  >
                    + Header
                    <BetaBadge />
                  </Button>
                </div> */}
                <span>
                  <span className="">
                    <span className="w-full">
                      <span className="flex flex-row items-center justify-between w-full">
                        <span className="mt-3">
                          <Tooltip
                            title={
                              <>
                                <span>
                                  {selectedData.product_name + ' ' + selectedData.variant_name}
                                </span>
                                <p>
                                  {selectedData.barcode_id != '' &&
                                    selectedData.barcode_id != undefined && (
                                      <Tooltip title="Print Barcode">
                                        <Button
                                          type="add-field"
                                          size="small"
                                          onClick={() => {
                                            this.barcode.showModal({
                                              ...selectedData,
                                              name: selectedData.variant_name,
                                            })
                                          }}
                                          className=""
                                        >
                                          <span>
                                            Print Barcode <i className="fa fa-barcode" />
                                          </span>
                                        </Button>
                                      </Tooltip>
                                    )}
                                </p>
                              </>
                            }
                            placement="topLeft"
                          >
                            <span
                              className="line-clamp-2 hover-underline cursor-pointer leading-tight font-weight-bold"
                              onClick={e => {
                                e.stopPropagation()
                                if (selectedData.new_variant_id != 0) {
                                  this.productAnalysis.showModal(
                                    selectedData,
                                    selectedData.product_id,
                                    selectedData,
                                    this.props?.PartyData,
                                  )
                                } else {
                                  this.productAnalysis.showModal(
                                    selectedData,
                                    selectedData.product_id,
                                    {},
                                    this.props?.PartyData,
                                  )
                                }
                              }}
                            >
                              {selectedData.product_name + ' ' + selectedData.variant_name}
                            </span>
                          </Tooltip>

                          {selectedData.batch_id > 0 ? (
                            <p className="m-0 p-0 font-size-11 text-gray-500 leading-none">
                              {selectedData.batch_no}
                            </p>
                          ) : (
                            ''
                          )}
                          <span className="mt-1 pl-1">
                            {this.state.description && (
                              <Button
                                type="add-description"
                                onClick={e => {
                                  e.stopPropagation()

                                  setTimeout(() => {
                                    params.api.setRowNodeExpanded(
                                      params.node,
                                      !params.node.expanded,
                                    )
                                  }, 100)
                                }}
                              >
                                <span className="flex justify-center items-center gap-1">
                                  {selectedData.description == '<p><br></p>' ? (
                                    <>
                                      <i className="fa-regular fa-plus mr-1" />
                                      Add
                                    </>
                                  ) : params.node.expanded ? (
                                    <>
                                      <i class="fa-regular fa-eye-slash" /> Hide
                                    </>
                                  ) : (
                                    <>
                                      <i class="fa-regular fa-eye" /> Show
                                    </>
                                  )}{' '}
                                  Description
                                </span>
                              </Button>
                            )}
                          </span>
                        </span>
                        <span className="absolute top-0 left-[15px] leading-none pl-1">
                          <span className="text-gray-500 mr-1 font-size-11">
                            #{params.node.rowIndex + 1}
                          </span>
                          {selectedData.hsn_code != '' && selectedData.hsn_code != undefined ? (
                            <span className="text-gray-500 mr-2 font-size-11">
                              {selectedData['product_type'] == 'Product' ? 'HSN ' : 'SAC '}

                              {selectedData.hsn_code}
                            </span>
                          ) : (
                            <Button
                              type="add-field"
                              className="mr-2 mt-0 pt-0"
                              onClick={e => {
                                e.stopPropagation()
                                this.addHsn.current.showModal(selectedData)
                              }}
                            >
                              <span className="hover-underline">
                                <i className="fa-regular fa-plus"></i> Add{' '}
                                {selectedData['product_type'] == 'Product' ? 'HSN ' : 'SAC '}
                              </span>
                            </Button>
                          )}
                        </span>

                        {/* <span className="absolute top-0 right-0 leading-none">
                          {selectedData.qtyinstock !== '' && selectedData.qtyinstock !== undefined && (
                            <span
                              className={`inline-flex items-center right-0 rounded-b-md px-2 text-xs font-medium ring-0 ring-inset ${
                                selectedData.qtyinstock > 0
                                  ? 'bg-green-100 text-green-700 ring-green-600/20'
                                  : 'bg-red-100 text-red-700 ring-red-600/20'
                              }`}
                            >
                              {((selectedData['product_type'] == 'Service' &&
                                this.props.document_settings.track_stock_for_services === 1) ||
                                selectedData['product_type'] != 'Service') && (
                                <span className="font-weight-bold">
                                  Stock {selectedData.qtyinstock.toFixed(2)}
                                  {selectedData.unit != 'OTH' &&
                                    selectedData.unit != 'NONE' &&
                                    ' ' + selectedData.unit}
                                </span>
                              )}
                            </span>
                          )}
                        </span> */}
                      </span>
                    </span>
                  </span>

                  <p>
                    {!this.props.edit && <span className="font-weight-bold font-size-11"></span>}
                  </p>
                </span>
              </span>
            </div>
          )
        },
        onCell: (record, index) => ({
          colSpan: record.is_item_header ? 3 : 1,
        }),
      },
      {
        headerName: 'Quantity',
        field: 'qty',
        responsive: ['md'],
        onCell: (record, index) => ({
          colSpan: record.is_item_header ? 0 : 1,
        }),
        cellRenderer: params => {
          const data = params.data
          const text = params.value
          return (
            data.is_item_header == undefined && (
              <span className="w-full" id={params.node.rowIndex}>
                <InputNumber
                  onFocus={this.onInputFocus}
                  ref={this.inputRef}
                  autoFocus={
                    params.node.rowIndex == this.gridRef.current?.api?.getFocusedCell()?.rowIndex &&
                    this.gridRef.current?.api?.getFocusedCell()?.column.colId == 'qty'
                  }
                  onChange={value => {
                    if (data.has_batches == 0 || !this.allowBatches(this.props.document_type)) {
                      this.onChange(value, data.key, 'qty')
                    } else {
                      if (
                        data.has_batches == 1 ||
                        (this.props.document_type != 'purchase' && data.has_batches == 2)
                      ) {
                        this.productBatchesRef.current.showModal(
                          data,
                          this.props.edit || this.props.convert || this.props.duplicate,
                        )
                      } else if (data.has_batches == 2 && this.props.document_type == 'purchase') {
                        this.serialNumberRef.onAdd(data, true, true)
                      }
                    }
                  }}
                  onBlur={value => {
                    if (data.has_batches) {
                      return
                    }
                    if (value.target.value == '') {
                      this.onChange(0, data.key, 'qty')
                    }
                    params.api?.clearFocusedCell()
                  }}
                  value={data.qty == null ? '' : Number(data?.qty?.toFixed(3))}
                  min={0}
                  disabled={this.props.permissions.product_edit == 0}
                  addonAfter={
                    data.has_alternative_units == 0
                      ? !data.unit || data.unit == ''
                        ? 'OTH'
                        : data.unit
                      : selectUnits(data)
                  }
                />
                <ConvertedQtyStrip
                  isConversionTracked={this.props.isConversionTracked}
                  data={data}
                />

                {data.qtyinstock !== '' && data.qtyinstock !== undefined && (
                  <span
                    className={`inline-flex items-center absolute bottom-1.5 left-3 rounded-b-md px-2 text-xs font-medium ${
                      data.qtyinstock > 0
                        ? 'text-green-700 ring-green-600/20'
                        : 'text-red-700 ring-red-600/20'
                    }`}
                  >
                    {((data['product_type'] == 'Service' &&
                      this.props.document_settings.track_stock_for_services === 1) ||
                      data['product_type'] != 'Service') && (
                      <span className="font-weight-bold">
                        Available {data.qtyinstock.toFixed(2)}
                        {data.unit != 'OTH' && data.unit != 'NONE' && ' ' + data.unit}
                      </span>
                    )}
                  </span>
                )}
              </span>
            )
          )
        },
      },
      {
        headerName: 'Free Qty',
        field: 'free_qty',
        responsive: ['md'],
        hide: !this.props.integrations.free_qty || !this.props.invoice_settings.free_qty,
        cellRenderer: params => {
          const data = params.data
          const text = params.value
          return (
            data.is_item_header == undefined && (
              <span className="w-full" id={params.node.rowIndex}>
                <InputNumber
                  onFocus={this.onInputFocus}
                  ref={this.inputRef}
                  autoFocus={
                    params.node.rowIndex == this.gridRef.current?.api?.getFocusedCell()?.rowIndex &&
                    this.gridRef.current?.api?.getFocusedCell()?.column.colId == 'free_qty'
                  }
                  onChange={value => {
                    if (data.has_batches == 0 || !this.allowBatches(this.props.document_type)) {
                      this.onChange(value, data.key, 'free_qty')
                    } else {
                      if (
                        data.has_batches == 1 ||
                        (this.props.document_type != 'purchase' && data.has_batches == 2)
                      ) {
                        this.productBatchesRef.current.showModal(
                          data,
                          this.props.edit || this.props.convert || this.props.duplicate,
                        )
                      } else if (data.has_batches == 2 && this.props.document_type == 'purchase') {
                        this.serialNumberRef.onAdd(data, true, true)
                      }
                    }
                  }}
                  onBlur={value => {
                    if (data.has_batches) {
                      return
                    }
                    if (value.target.value == '') {
                      this.onChange(0, data.key, 'free_qty')
                    }
                    params.api?.clearFocusedCell()
                  }}
                  value={data.free_qty == null ? '' : Number(data.free_qty.toFixed(3))}
                  min={0}
                  disabled={this.props.permissions.product_edit == 0 || data.has_batches == 2}
                  addonAfter={
                    data.has_alternative_units == 0
                      ? !data.unit || data.unit == ''
                        ? 'OTH'
                        : data.unit
                      : selectUnits(data)
                  }
                />
              </span>
            )
          )
        },
      },

      {
        headerComponent: ({ displayName }) => {
          return (
            <div className="custom-header mt-1">
              <span>
                Unit Price{' '}
                {((this.props.is_export == 1 && !this.props.export_type?.includes('SEZ')) ||
                  this.props.is_multi_currency == 1) && (
                  <Switch
                    size="small"
                    className="ml-2"
                    checked={this.state.showExportCurrency}
                    unCheckedChildren={this.props.user.selectedCompany.currency_symbol}
                    checkedChildren={this.props.currency_symbol}
                    onChange={value => this.setState({ showExportCurrency: value })}
                  />
                )}
              </span>
            </div>
          )
        },
        onCell: (record, index) => ({
          colSpan: record.is_item_header ? 0 : 1,
        }),
        field: 'price',
        cellRenderer: params => {
          const data = params.data
          const text = params.value

          return (
            data.is_item_header == undefined && (
              <>
                <div className="flex flex-row items-center w-full">
                  <div className="w-full">
                    <InputNumber
                      onFocus={this.onInputFocus}
                      ref={this.inputRef}
                      autoFocus={
                        params.node.rowIndex ==
                          this.gridRef.current?.api?.getFocusedCell()?.rowIndex &&
                        this.gridRef.current?.api?.getFocusedCell()?.column.colId == 'price'
                      }
                      onChange={value => {
                        this.onChange(value, data.key, 'price')
                      }}
                      style={{ minWidth: '100%' }}
                      value={
                        (this.props.is_export || this.props.is_multi_currency) &&
                        this.state.showExportCurrency
                          ? formatNumber(data.export_unit_price)
                          : formatNumber(data.price)
                      }
                      disabled={this.props.permissions.product_edit == 0}
                      min={0}
                      onBlur={e => {
                        this.check_purchase_price_warning(e.target.value, 'unit_price', data)
                        params.api?.clearFocusedCell()
                      }}
                    />
                  </div>

                  {/* Purchase Margin */}
                  {store.get('role') === 'admin' &&
                    this.props.document_type.includes('purchase') &&
                    this.props.document_type !== 'purchase_return' &&
                    (this.props.document_settings.show_selling_price === 1 ||
                      this.props.document_settings.show_purchase_margin === 1) && (
                      <div className="ml-2">
                        <Popover
                          trigger="hover" // Change this to 'click' if you want the popover to show on click
                          content={
                            <div className="w-64">
                              {/* Selling Price */}
                              {this.props.document_settings.show_selling_price === 1 && (
                                <span className="flex justify-between">
                                  <span className="font-weight-bold">Selling Price</span>
                                  <span>{data?.actual_price_with_tax?.toFixed(2) || 0}</span>
                                </span>
                              )}

                              {/* Purchase Margin */}
                              {this.props.document_settings.show_purchase_margin === 1 && (
                                <>
                                  <span
                                    className={`font-weight-bold mt-0 mb-0 ${
                                      parseFloat(data.actual_unit_price / data.conversion_rate) *
                                        parseFloat(data.qty) -
                                        parseFloat(getNetAmount_unit_price(data)) >=
                                      0
                                        ? 'text-forest'
                                        : 'text-danger'
                                    }`}
                                  >
                                    <span className="flex justify-between">
                                      <span>Margin</span>
                                      <span>
                                        {parseFloat(
                                          parseFloat(
                                            data.actual_unit_price / data.conversion_rate,
                                          ) *
                                            parseFloat(data.qty) -
                                            parseFloat(getNetAmount_unit_price(data)),
                                        ).toFixed(2)}
                                      </span>
                                    </span>
                                  </span>

                                  <span className="text-gray-500 bottom-0 font-size-12 ">
                                    <p className="mt-5">
                                      Margin is calculated on unit price excluding taxes. It is
                                      difference between selling price and purchase price without
                                      taxes.
                                    </p>
                                  </span>
                                </>
                              )}
                            </div>
                          }
                        >
                          <i className="fa-solid fa-bolt-lightning text-pink-500"></i>
                        </Popover>
                      </div>
                    )}

                  {/* Sales Margin */}
                  {store.get('role') === 'admin' &&
                    !this.props.document_type.includes('purchase') &&
                    this.props.document_type !== 'sales_return' &&
                    (this.props.document_settings.show_purchase_price === 1 ||
                      this.props.document_settings.show_sales_margin === 1) && (
                      <div className="ml-2">
                        <Popover
                          trigger="hover" // Change this to 'click' if you want the popover to show on click
                          content={
                            <div className="w-64">
                              {/* Purchase Price */}
                              {this.props.document_settings.show_purchase_price === 1 && (
                                <span className="flex justify-between">
                                  <span>Purchase Price</span>
                                  <span>{data.purchase_price?.toFixed(2)}</span>
                                </span>
                              )}

                              {/* Sales Margin */}
                              {this.props.document_settings.show_sales_margin === 1 && (
                                <>
                                  <p
                                    className={`font-weight-bold mt-0 mb-0 ${
                                      parseFloat(getNetAmount_unit_price(data)) -
                                        parseFloat(
                                          data.purchase_unit_price / data.conversion_rate,
                                        ) *
                                          parseFloat(data.qty) >=
                                      0
                                        ? 'text-forest'
                                        : 'text-danger'
                                    }`}
                                  >
                                    <span className="flex justify-between">
                                      <span>Margin</span>
                                      <span>
                                        {(
                                          parseFloat(getNetAmount_unit_price(data)) -
                                          parseFloat(
                                            data.purchase_unit_price / data.conversion_rate,
                                          ) *
                                            parseFloat(data.qty)
                                        ).toFixed(2)}
                                      </span>
                                    </span>
                                  </p>

                                  <span className="text-gray-500 bottom-0 left-0 font-size-12 mt-4">
                                    {/* <p className="font-weight-medium"></p> */}
                                    {/* <p>
                                      {(
                                        parseFloat(getNetAmount_unit_price(data)) -
                                        parseFloat(
                                          data.purchase_unit_price / data.conversion_rate,
                                        ) *
                                          parseFloat(data.qty)
                                      ).toFixed(2)}{' '}
                                      = (
                                      {parseFloat(getNetAmount_unit_price(data) / data.qty).toFixed(
                                        2,
                                      )}{' '}
                                      -{' '}
                                      {parseFloat(
                                        data.purchase_unit_price / data.conversion_rate,
                                      ).toFixed(2)}
                                      ) * {data.qty}
                                    </p> */}
                                    <p className="mt-2">
                                      Margin is calculated on unit price excluding taxes. It is
                                      difference between selling price and purchase price without
                                      taxes.
                                    </p>
                                  </span>
                                </>
                              )}
                            </div>
                          }
                        >
                          <i className="fa-solid fa-bolt-lightning text-pink-500"></i>
                        </Popover>
                      </div>
                    )}
                </div>

                {data.discount_unit_price_value != undefined &&
                  data.discount_unit_price_value != 0 && (
                    <p className="m-0 p-0 text-forest absolute text-xs w-100p bottom-1.5 font-medium left-5">
                      after disc. {this.props.user.selectedCompany.currency_symbol}
                      {(data.price - data.discount_unit_price_value).toFixed(2)}
                    </p>
                  )}

                {/* Old margin code to be deleted */}
                {/* {store.get('role') == 'admin' && (
                  <span className="font-weight- font-size-11 absolute bottom-0 left-5 leading-tight">
                    {this.props.document_type.includes('purchase') &&
                      this.props.document_type != 'purchase_return' &&
                      this.props.document_settings.show_selling_price == 1 && (
                        <span className="text-gray-500">
                          Selling @ {data.actual_price_with_tax ? data.actual_price_with_tax : 0}
                        </span>
                      )}
                    {this.props.document_type.includes('purchase') &&
                    this.props.document_type != 'purchase_return' &&
                    this.props.document_settings.show_purchase_margin ? (
                      <Tooltip
                        title={
                          <>
                            <p className="font-weight-bold">
                              Margin = (Selling Price - Purchase Price ) * Qty (Excl.Tax)
                            </p>
                            <p>
                              {parseFloat(
                                parseFloat(data.actual_unit_price / data.conversion_rate).toFixed(
                                  2,
                                ) *
                                  parseFloat(data.qty) -
                                  parseFloat(getNetAmount_unit_price(data)),
                              ).toFixed(2)}{' '}
                              = ({' '}
                              {(parseFloat(data.actual_unit_price) / data.conversion_rate).toFixed(
                                2,
                              )}
                              -{' '}
                              {parseFloat(getNetAmount_unit_price(selectedData)) /
                                parseFloat(data.qty)}{' '}
                              ) * {data.qty}
                            </p>
                          </>
                        }
                      >
                        <span
                          className={
                            parseFloat(data.actual_unit_price / data.conversion_rate) *
                              parseFloat(data.qty) -
                              parseFloat(getNetAmount_unit_price(data)) >=
                            0
                              ? 'text-forest'
                              : 'text-danger'
                          }
                        >
                          {' ( Margin:'}{' '}
                          {parseFloat(
                            parseFloat(data.actual_unit_price / data.conversion_rate) *
                              parseFloat(data.qty) -
                              parseFloat(getNetAmount_unit_price(data)),
                          ).toFixed(2)}{' '}
                          {')'}
                        </span>
                      </Tooltip>
                    ) : (
                      <></>
                    )}
                    {!this.props.document_type.includes('purchase') &&
                      this.props.document_type != 'sales_return' &&
                      this.props.document_settings.show_purchase_price == 1 && (
                        <span className="text-gray-500">Purchase @ {data.purchase_price}</span>
                      )}
                    {!this.props.document_type.includes('purchase') &&
                    this.props.document_type != 'sales_return' &&
                    this.props.document_settings.show_sales_margin ? (
                      <Tooltip
                        title={
                          <>
                            <p className="font-weight-bold">
                              Margin = ( Selling Price - Purchase Price ) * Qty (Excl.Tax)
                            </p>
                            <p>
                              {(
                                parseFloat(getNetAmount_unit_price(data)) -
                                parseFloat(data.purchase_unit_price / data.conversion_rate) *
                                  parseFloat(data.qty)
                              ).toFixed(2)}{' '}
                              = ({' '}
                              {(parseFloat(getNetAmount_unit_price(data)) / data.qty).toFixed(2)} -{' '}
                              {parseFloat(data.purchase_unit_price / data.conversion_rate).toFixed(
                                2,
                              )}{' '}
                              ) * {data.qty}
                            </p>
                          </>
                        }
                      >
                        <span
                          className={
                            parseFloat(getNetAmount_unit_price(data)) -
                              parseFloat(data.purchase_unit_price / data.conversion_rate) *
                                parseFloat(data.qty) >=
                            0
                              ? 'text-forest'
                              : 'text-danger'
                          }
                        >
                          {' (Margin:'}{' '}
                          {(
                            parseFloat(getNetAmount_unit_price(data)) -
                            parseFloat(data.purchase_unit_price / data.conversion_rate) *
                              parseFloat(data.qty)
                          ).toFixed(2)}{' '}
                          {')'}
                        </span>
                      </Tooltip>
                    ) : (
                      <></>
                    )}
                  </span>
                )} */}
              </>
            )
          )
        },
        responsive: ['md'],
      },
      {
        headerName: 'Price with Tax',
        hide: !this.props.with_tax,
        field: 'price_with_tax',
        // width: '12%',
        responsive: ['md'],
        cellRenderer: params => {
          const data = params.data
          const text = params.value

          return (
            data.is_item_header == undefined && (
              <span className="w-full">
                <InputNumber
                  onFocus={this.onInputFocus}
                  ref={this.inputRef}
                  autoFocus={
                    params.node.rowIndex == this.gridRef.current?.api?.getFocusedCell()?.rowIndex &&
                    this.gridRef.current?.api?.getFocusedCell()?.column.colId == 'price_with_tax'
                  }
                  onChange={value => {
                    this.onChange(value, data.key, 'price_with_tax')
                  }}
                  style={{ minWidth: '100%' }}
                  value={
                    (this.props.is_export || this.props.is_multi_currency) &&
                    this.state.showExportCurrency
                      ? formatNumber(data.export_price_with_tax)
                      : formatNumber(data.price_with_tax)
                  }
                  disabled={this.props.permissions.product_edit == 0}
                  min={0}
                  onBlur={e => {
                    this.check_purchase_price_warning(e.target.value, 'price_with_tax', data)
                    params.api?.clearFocusedCell()
                  }}
                />
                {data.discount_price_with_tax_value != undefined &&
                  data.discount_price_with_tax_value != 0 && (
                    <p className="m-0 p-0 text-forest absolute text-xs w-100p bottom-1.5 font-medium left-5">
                      after disc. {this.props.user.selectedCompany.currency_symbol}
                      {(data.price_with_tax - data.discount_price_with_tax_value).toFixed(2)}
                    </p>
                  )}
              </span>
            )
          )
        },
      },

      {
        headerValueGetter: params => {
          return (
            'Discount (' +
            capitalizeFirstLetters(this.props.discount_type.split('_').join(' ')) +
            ')'
          )
        },
        field: 'discount',
        // width: '7%',
        responsive: ['md'],
        cellRenderer: params => {
          const data = params.data
          const text = params.value

          if (data.is_item_header != undefined && data.is_item_header) {
            return <></>
          }
          return (
            <span className="w-full">
              <InputNumber
                onFocus={this.onInputFocus}
                autoFocus={
                  params.node.rowIndex == this.gridRef.current?.api?.getFocusedCell()?.rowIndex &&
                  this.gridRef.current?.api?.getFocusedCell()?.column.colId == 'discount'
                }
                addonAfter={
                  <span className="select-borderless">
                    <Select
                      suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                      value={data.selected_discount_type}
                      style={{ maxWidth: 125, minWidth: 55 }}
                      onChange={e => this.onSelectedDiscountChange(data.key, e)}
                    >
                      <Option value="discount">%</Option>
                      {this.props.discount_type == 'unit_price' && (
                        <Option value="discount_unit_price_value">
                          {this.props.user.selectedCompany.currency_symbol}
                        </Option>
                      )}
                      {this.props.discount_type == 'total_amount' && (
                        <Option value="discount_value">
                          {this.props.user.selectedCompany.currency_symbol}
                        </Option>
                      )}
                      {this.props.discount_type == 'net_amount' && (
                        <Option value="discount_net_value">
                          {this.props.user.selectedCompany.currency_symbol}
                        </Option>
                      )}
                      {this.props.discount_type == 'price_with_tax' && (
                        <Option value="discount_price_with_tax_value">
                          {this.props.user.selectedCompany.currency_symbol}
                        </Option>
                      )}
                    </Select>
                  </span>
                }
                onChange={value => this.onChange(value, data.key, data.selected_discount_type)}
                style={{ width: '150px' }}
                value={Number(data[data.selected_discount_type])}
                min={0}
                disabled={this.props.permissions.product_edit == 0}
                onBlur={() => {
                  params.api?.clearFocusedCell()
                }}
              />
            </span>
          )
        },
      },

      {
        headerName: 'Total',
        pinned: 'right',
        cellClass: 'text-right',
        type: 'rightAligned',
        responsive: ['md'],
        field: 'total_amount',
        cellRenderer: params => {
          const record = params.data
          const _ = params.value

          const net_amount_multi_currency =
            parseInt(String(record.id).split('_')[0]) > -1
              ? (
                  getNetAmount_unit_price(record) / parseFloat(this.props.conversion_factor)
                ).toFixed(2)
              : 0

          return (
            record.is_item_header == undefined && (
              <div className="flex flex-wrap items-center justify-end mt-3 float-end">
                <span className=" leading-tight absolute right-2">
                  <div className="w-full">
                    <span className="font-weight-bold font-size-16">
                      {/* Amount in INR */}
                      {(this.props.is_export == 0 || this.props.is_multi_currency == 0) &&
                        (parseInt(String(record.id).split('_')[0]) > -1
                          ? Object.keys(record).includes('total_amount')
                            ? parseFloat(record.total_amount).toFixed(2)
                            : parseFloat(getRowTotal(record)).toFixed(2)
                          : 0)}

                      {/* Multi currency amount */}
                      {(this.props.is_export == 1 || this.props.is_multi_currency == 1) &&
                        this.props.conversion_factor != '' &&
                        this.props.country.country_id != 75 && (
                          <>
                            <span className="mt-0 mb-0 text-gray-500 ml-2">
                              ({this.props.currency_symbol}
                              {this.props.with_tax == 0
                                ? net_amount_multi_currency
                                : parseInt(String(record.id).split('_')[0]) > -1
                                ? (
                                    getRowTotal(record) / parseFloat(this.props.conversion_factor)
                                  ).toFixed(2)
                                : 0}
                              )
                            </span>
                          </>
                        )}
                    </span>
                    <span></span>
                  </div>

                  {this.props.with_tax == 1 && (
                    <div className="w-full">
                      <span className="font-size-12">
                        {parseInt(String(record.id).split('_')[0]) > -1
                          ? Object.keys(record).includes('net_amount')
                            ? Number(record.net_amount).toFixed(2)
                            : getNetAmount_unit_price(record).toFixed(2)
                          : 0}

                        <span className="font-size-12 mt-0 mb-0 text-gray-500">
                          <span>
                            {' '}
                            +{' '}
                            {parseInt(String(record.id).split('_')[0]) > -1
                              ? Object.keys(record).includes('tax_amount')
                                ? parseFloat(record.tax_amount).toFixed(2)
                                : getTaxAmount(record).toFixed(2)
                              : 0}
                          </span>
                          <span className="text-gray-500">
                            {' '}
                            {record.cess == 0 && (
                              <span>
                                {parseFloat(record.cess_on_qty_value) ? (
                                  <span>
                                    ({renderTax(record)} + {record.cess_on_qty}/{record.unit})
                                  </span>
                                ) : (
                                  <span>({renderTax(record)})</span>
                                )}
                              </span>
                            )}
                            {record.cess != 0 && (
                              <span>
                                {parseFloat(record.cess_on_qty_value) ? (
                                  <span>
                                    ({renderTax(record)} + {record.cess}% + {record.cess_on_qty}/
                                    {record.unit})
                                  </span>
                                ) : (
                                  <span>
                                    ({renderTax(record)} + {record.cess}%)
                                  </span>
                                )}
                              </span>
                            )}
                          </span>
                        </span>
                      </span>
                    </div>
                  )}

                  {this.props.with_tax == 1 &&
                    (this.props.is_export == 1 || this.props.is_multi_currency == 1) &&
                    this.props.conversion_factor != '' && (
                      <div className="w-full">
                        <span className="font-size-12">
                          <span className="font-size-11 mt-0 mb-0 text-gray-500">
                            {this.props.currency_symbol}
                            {parseInt(String(record.id).split('_')[0]) > -1
                              ? (
                                  getNetAmount_unit_price(record) /
                                  parseFloat(this.props.conversion_factor)
                                ).toFixed(2)
                              : 0}
                          </span>

                          {this.props.with_tax == 1 && (
                            <span className="font-size-12 mt-0 mb-0 text-gray-500">
                              <span>
                                {' '}
                                +{' '}
                                {parseInt(String(record.id).split('_')[0]) > -1
                                  ? Object.keys(record).includes('tax_amount')
                                    ? (
                                        parseFloat(record.tax_amount) /
                                        parseFloat(this.props.conversion_factor)
                                      ).toFixed(2)
                                    : (
                                        getTaxAmount(record) /
                                        parseFloat(this.props.conversion_factor)
                                      ).toFixed(2)
                                  : 0}
                              </span>
                              <span className="text-gray-500">
                                {' '}
                                {record.cess == 0 && (
                                  <span>
                                    {parseFloat(record.cess_on_qty_value) ? (
                                      <span>
                                        ({renderTax(record)} + {record.cess_on_qty}/{record.unit})
                                      </span>
                                    ) : (
                                      <span>({renderTax(record)})</span>
                                    )}
                                  </span>
                                )}
                                {record.cess != 0 && (
                                  <span>
                                    {parseFloat(record.cess_on_qty_value) ? (
                                      <span>
                                        ({renderTax(record)} + {record.cess}% + {record.cess_on_qty}
                                        /{record.unit})
                                      </span>
                                    ) : (
                                      <span>
                                        ({renderTax(record)} + {record.cess}%)
                                      </span>
                                    )}
                                  </span>
                                )}
                              </span>
                            </span>
                          )}
                        </span>
                      </div>
                    )}
                </span>

                <div className="ml-4 absolute right-0 top-[-3px]">
                  {this.props.permissions.additional_cess == 1 && (
                    <span className="mr-2">
                      <Button
                        type="add-field"
                        onClick={() => this.additional_cess.showModal(record)}
                        className="font-size-12 text-primary"
                      >
                        {parseFloat(record.cess_on_qty_value) > 0 ? 'Change' : '+ Additional Cess'}
                      </Button>
                    </span>
                  )}

                  <span
                    type="danger"
                    size="small"
                    className="inline-flex cursor-pointer items-center rounded-bl-md px-2 py-1 text-xs font-medium ring-0 ring-inset bg-red-50 text-red-700 ring-red-600/20"
                    onClick={() => {
                      this.delete.showModal(record)
                    }}
                  >
                    <span>
                      <Tooltip title="Delete">
                        <i className="fa-solid fa-sm fa-trash" />
                      </Tooltip>
                    </span>
                  </span>
                </div>
              </div>
            )
          )
        },
      },
    ]

    const components = {
      body: {
        row: DraggableBodyRow,
      },
    }

    const moveRow = (dragIndex, hoverIndex) => {
      var arrangedData = mergeItemsAndItemHeaders(this.props.data, this.props.item_headers)
      const dragRow = arrangedData[dragIndex]

      if (dragRow != undefined) {
        let data = arrangedData

        data = update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })

        const reassignKeysData = data.map((item, index) => {
          return {
            ...item,
            key: index + 1,
          }
        })

        this.props.dataUpdate(reassignKeysData.filter(item => !item.is_item_header))
        this.props.itemHeadersUpdate(reassignKeysData.filter(item => item.is_item_header))
      }
    }

    var active_cols = []
    if (this.props.document_type == 'purchase') {
      if (
        this.props.document_settings?.purchase_update_columns.split(',').includes('price_with_tax')
      ) {
        active_cols.push({
          name: 'Selling Price',
          field_type: 'number',
          is_active: true,
          update_key: 'selling_price',
        })
      }
      if (this.props.document_settings?.purchase_update_columns.split(',').includes('discount')) {
        active_cols.push({
          name: 'Product Discount',
          field_type: 'number',
          is_active: true,
          update_key: 'product_discount',
        })
      }
    }
    active_cols = [
      ...active_cols,
      ...this.props.custom_columns.filter(
        obj =>
          obj.is_active &&
          (obj.document_types.includes('All') ||
            obj.document_types.includes(this.props.document_type)),
      ),
    ]

    var result = active_cols.map(item => ({
      title: item.name,
      dataIndex: item.update_key == undefined ? item.name : item.update_key,

      cellRenderer: params => {
        const data = params.data
        const text = params.value
        if (data.is_item_header != undefined && data.is_item_header) {
          return <></>
        }
        if (item.field_type == 'number' || item.field_type == 'currency') {
          return (
            <InputNumber
              onFocus={this.onInputFocus}
              ref={this.inputRef}
              autoFocus={
                params.node.rowIndex == this.gridRef.current?.api?.getFocusedCell()?.rowIndex &&
                this.gridRef.current?.api?.getFocusedCell()?.column.colId ==
                  (item.update_key == undefined ? item.name : item.update_key)
              }
              style={{ minWidth: '100px' }}
              placeholder={item.name}
              value={text}
              className="w-full"
              onChange={value => {
                if (item.update_key == 'product_discount' && value > 100) {
                  message.error("Discount can't be greater than 100")
                  return
                }
                this._onCustomChange(
                  value,
                  data.key,
                  item.update_key == undefined ? item.name : item.update_key,
                )
              }}
              onBlur={() => {
                params.api?.clearFocusedCell()
              }}
            />
          )
        } else if (item.field_type == 'date') {
          return (
            <DatePicker
              style={{ width: '100%', minWidth: '100px' }}
              placeholder={item.name}
              value={
                text != '' && this.dateFormatRegex.test(text)
                  ? dayjs(text, 'DD-MM-YYYY')
                  : undefined
              }
              format={this.dateFormat}
              onChange={value => {
                this._onCustomChange(
                  value == null ? '' : value.format(this.dateFormat),
                  data.key,
                  item.name,
                )
              }}
            />
          )
        } else if (item.field_type == 'datetime') {
          return (
            <DatePicker
              showTime
              style={{ width: '100%', minWidth: '100px' }}
              placeholder={item.name}
              format={this.dateTimeFormat}
              value={text != '' ? dayjs(text) : undefined}
              onChange={value => {
                this._onCustomChange(value.format('DD-MM-YYYY HH:mm:ss'), data.key, item.name)
              }}
            />
          )
        } else if (item.field_type == 'bigtext') {
          return (
            <TextArea
              placeholder={item.name}
              autoFocus={
                params.node.rowIndex == this.gridRef.current?.api?.getFocusedCell()?.rowIndex &&
                this.gridRef.current?.api?.getFocusedCell()?.column.colId ==
                  (item.update_key == undefined ? item.name : item.update_key)
              }
              onFocus={this.onInputFocus}
              defaultValue={text}
              type="text"
              rows={1}
              className="pr-4 mr-2"
              onChange={value => {
                this._onCustomChange(value.target.value, data.key, item.name)
              }}
              disabled={
                (this.props.permissions.product_edit == 0 || item.is_link == 1) &&
                !this.props.is_column_linking_failed
              }
              style={{ width: '100%', minWidth: '100px' }}
            />
          )
        } else if (item.field_type == 'dropdown') {
          return (
            <Select
              showSearch
              allowClear
              autoFocus={
                params.node.rowIndex == this.gridRef.current?.api?.getFocusedCell()?.rowIndex &&
                this.gridRef.current?.api?.getFocusedCell()?.column.colId ==
                  (item.update_key == undefined ? item.name : item.update_key)
              }
              onFocus={this.onInputFocus}
              placeholder={item.name}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={text}
              onChange={value => {
                this._onCustomChange(value, data.key, item.name)
              }}
              style={{ width: '100%', minWidth: '100px' }}
            >
              {item.dropdown_values.map((e, i) => (
                <Option key={i} value={e}>
                  {e}
                </Option>
              ))}
            </Select>
          )
        } else {
          return (
            <Input
              autoFocus={
                params.node.rowIndex == this.gridRef.current?.api?.getFocusedCell()?.rowIndex &&
                this.gridRef.current?.api?.getFocusedCell()?.column.colId ==
                  (item.update_key == undefined ? item.name : item.update_key)
              }
              onFocus={this.onInputFocus}
              onChange={value => {
                this._onCustomChange(value.target.value, data.key, item.name)
              }}
              placeholder={item.name}
              style={{ width: '100%', minWidth: '100px' }}
              disabled={
                (this.props.permissions.product_edit == 0 || item.is_link == 1) &&
                !this.props.is_column_linking_failed
              }
              defaultValue={text}
            />
          )
        }
      },

      responsive: ['md'],
    }))

    var insertionIndex = 2,
      startIndex = 0
    tableColumn = tableColumn
      .slice(startIndex, insertionIndex)
      .concat(result)
      .concat(tableColumn.slice(insertionIndex))

    const gridColumns = tableColumn.map((column, index) => {
      column = {
        ...column,
        flex: 0,
        minWidth: column.minWidth ?? 200,
        suppressMovable: true,
        resizable: false,
        sortable: column.sortable ?? false,
        field: column.field || column.dataIndex,
        headerName: column.title || column.headerName,
        suppressKeyboardEvent: params => {
          var key = params.event.key
          return (
            key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Enter' || params.event.ctrlKey
          )
        },
      }

      return column
    })

    return (
      <div className="mb-8">
        <SectionHeader
          title={
            <span>
              <span className="mr-1">Products & Services</span>
              <span className="mr-5">
                <InfoIcon
                  text={
                    <span>
                      <span>Search your existing products using the search box below.</span>
                      <br />
                      <span>
                        Please click on <strong className="text-success">Add New Product</strong> to
                        add a new product.
                      </span>
                      <br />
                    </span>
                  }
                />
              </span>
              <Button
                type="link"
                onClick={() => this.form.onAdd(this.selectItemRef?.current?.getSearchText())}
                className="text-sm font-bold p-0 header-add-button"
              >
                <i className="fa-solid fa-circle-plus mr-1"></i>
                Add new Product?
              </Button>
            </span>
          }
          right={true}
          rightText={
            <div className="flex">
              <div className="pl-2 ml-auto hidden sm:block">
                <Checkbox
                  className=""
                  checked={this.state.description}
                  onChange={e =>
                    this.setState({ description: e.target.checked }, async () => {
                      this.props.onChangeShowDescription(e.target.checked ? 1 : 0)

                      store.set('show_invoice_description', e.target.checked)
                    })
                  }
                >
                  <span className="font-size-14 text-gray-500 line-clamp-1">Show description</span>
                </Checkbox>
              </div>
              <div className="pl-2">
                <Checkbox
                  checked={this.state.items_reverse || store.get('items_reverse')}
                  onChange={e => {
                    store.set('items_reverse', e.target.checked)

                    this.setState(
                      {
                        items_reverse: e.target.checked,
                      },
                      () => {
                        let dataSource = this.getRowDataFromGrid()
                        dataSource = dataSource.reverse()
                        dataSource = dataSource.map((item, index) => {
                          return {
                            ...item,
                            key: index + 1,
                          }
                        })

                        this.applyTransaction({ update: dataSource })
                        this.props.dataUpdate(dataSource)
                      },
                    )
                  }}
                >
                  <span className="font-size-14 text-gray-500 line-clamp-1">
                    Show items in reverse order
                  </span>
                </Checkbox>
              </div>

              {this.props.warehouses != undefined &&
                this.props.document_type != 'online_order' &&
                this.props.allowWarehouses() &&
                this.props[
                  this.props.movement == 'in' ? 'stock_in_warehouses' : 'stock_out_warehouses'
                ]?.length > 0 && (
                  <div className="pl-2">
                    <span className="select-borderless px-0">
                      <Select
                        suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                        showSearch
                        size={'small'}
                        allowClear
                        style={{ maxWidth: 360 }}
                        className="font-size-14"
                        dropdownStyle={{ minWidth: '180px' }}
                        placeholder="Change Warehouse"
                        optionFilterProp="children"
                        onChange={this.props.onWarehouseChange}
                        value={this.props.warehouse_id}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.props[
                          this.props.movement == 'in'
                            ? 'stock_in_warehouses'
                            : 'stock_out_warehouses'
                        ].map((item, index) => (
                          <Option value={item.warehouse_id}>{item.name}</Option>
                        ))}
                      </Select>
                    </span>
                  </div>
                )}

              {this.props.price_lists?.length > 0 && this.props.document_type != 'online_order' && (
                <div className="pl-2">
                  <span className="select-borderless px-0">
                    <Select
                      suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                      showSearch
                      size={'small'}
                      style={{ maxWidth: 360 }}
                      dropdownStyle={{ minWidth: '180px' }}
                      className="font-size-14"
                      placeholder="Change PriceList"
                      optionFilterProp="children"
                      onChange={e => this.props.priceListChangeHandler(e, 'price_change')}
                      onFocus={() => {}}
                      value={
                        this.props.selectedPriceList == 0 ? undefined : this.props.selectedPriceList
                      }
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                    >
                      {this.props.price_lists?.map((item, index) => (
                        <Option value={item.price_list_id}>{item.name}</Option>
                      ))}
                    </Select>
                  </span>
                </div>
              )}

              <div className="pl-2">
                <span className="select-borderless px-0 hidden sm:block">
                  <Select
                    suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                    size={'small'}
                    allowClear={false}
                    style={{ maxWidth: 360 }}
                    dropdownStyle={{ minWidth: '180px' }}
                    placeholder="Change Discount Type"
                    onChange={e => {
                      const key = e
                      let discount_type = ''
                      if (key === '1') {
                        discount_type = 'price_with_tax'
                      } else if (key === '2') {
                        discount_type = 'unit_price'
                      } else if (key === '3') {
                        discount_type = 'net_amount'
                      } else if (key === '4') {
                        discount_type = 'total_amount'
                      }
                      this.props.onDiscountTypeChange(discount_type)
                    }}
                  >
                    {this.props.discount_type_items?.map((item, index) => (
                      <Option value={item.key}>{item.label}</Option>
                    ))}
                  </Select>
                </span>
              </div>
            </div>
          }
        />

        <Card
          size="document"
          bordered={false}
          className="border-radius-small bg-card-azure mb-4"
          id="product-table-card"
        >
          <div>
            <SelectItem
              ref={this.selectItemRef}
              product_categories={this.props.product_categories}
              selectedData={this.state.selectedData}
              serialNumberRef={this.serialNumberRef}
              document_type={this.props.document_type}
              productBatchesRef={this.productBatchesRef}
              edit={this.props.edit}
              convert={this.props.convert}
              duplicate={this.props.duplicate}
              allowBatches={this.allowBatches}
              productFormRef={this.form}
              selectedPriceList={this.props.selectedPriceList}
              warehouse_id={this.props.warehouse_id}
              document_settings={this.props.document_settings}
              data={this.props.data}
              custom_columns={this.props.custom_columns}
              is_export={this.props.is_export}
              is_multi_currency={this.props.is_multi_currency}
              currency_symbol={this.props.currency_symbol}
              conversion_factor={this.props.conversion_factor}
              onFinishBatchesQty={this.onFinishBatchesQty}
              onChange={this.onChange}
              export_type={this.props.export_type}
              updateInProductsTable={this.updateInProductsTable}
              total_discount={this.state.total_discount}
              customerDiscount={this.props.customerDiscount}
              with_tax={this.props.with_tax}
              onCustomChange={this.onCustomChange}
              qty_name={this.props.qty_name}
              count={this.state.count}
              menu_name={this.props.menu_name}
              onUploadItems={files => this.uploadInvoicePDF(files)}
              hideUploadItems={this.props.integrations.batching != 0}
            />
          </div>

          <AddHsn ref={this.addHsn} addProductHsn={this.addProductHsn} />

          <div
            style={{ height: '100%', boxSizing: 'border-box', position: 'relative' }}
            className={'ag-no-select loading-overlay-for-grid ' + this.props.theme}
            id="loading-overlay-for-grid"
          >
            {(this.state.loading || this.props.loadingSave) && (
              <div
                className="absolute inset-0 flex flex-col items-center  bg-white bg-opacity-75 z-10"
                style={{ width: '100%', height: '100%' }}
              >
                <span className="mt-10">
                  <Spin />
                </span>
              </div>
            )}
            <div
              className={`max-h-[${150}vh] h-full overflow-auto products-table`}
              style={{
                width: '100%',
                height: `${
                  this.props.data.length < 3
                    ? 320
                    : this.props.data.length <= 10
                    ? this.props.data.length * 120
                    : 1500
                }px`,
              }}
              id="products-table"
            >
              <AgGridReact
                // debug={true}
                // grid Props
                ref={this.gridRef}
                gridId="productsTable"
                // domLayout="autoHeight"
                suppressModelUpdateAfterUpdateTransaction={true}
                columnDefs={gridColumns}
                defaultColDef={
                  {
                    // autoHeight: true,
                    // autoHeaderHeight: true,
                    // wrapText: true,
                  }
                }
                rowHeight={100}
                getRowId={params => String(params.data['key'])}
                stopEditingWhenCellsLoseFocus={true}
                deltaSort={true}
                loading={this.state.loading || this.props.loadingSave}
                loadingOverlayComponent={() => {
                  return <Spin />
                }}
                rowDragManaged={true}
                // rowDragEntireRow={true}
                columnHoverHighlight={true}
                autoSizeStrategy={{
                  type: 'fitGridWidth',
                }}
                animateRows={false}
                // Performance Tweaks
                rowBuffer={5}
                blockLoadDebounceMillis={200}
                valueCache={true}
                valueCacheNeverExpires={true}
                debounceVerticalScrollbar={true}
                // Master Details
                masterDetail={true}
                detailRowHeight={250}
                detailCellRenderer={params => (
                  // RichTextEditor(
                  //   params,
                  //   0,
                  //   `w-11/12 h-[150px] overflow-auto `,
                  //   '',
                  //   true,
                  //   this.onDescriptionChange,
                  // )

                  // <MyEditor
                  //   ref={editRef}
                  //   editorState={editorState}
                  //   onChange={(text, editorState) => {
                  //     setEditorState(editorState)
                  //     setRecord({
                  //       ...record,
                  //       description: stateToHTML(editorState.getCurrentContent()),
                  //     })
                  //     // setDescription(editorState, record.key)
                  //   }}
                  //   editorClassName={className ?? ''}
                  //   showFromState={true}
                  //   showSaveButton={(title ?? '') == '' ? true : false}
                  //   onSave={editorState => {
                  //     if (isGridEditable == false) {
                  //       params.onValueChange(stateToHTML(editorState.getCurrentContent()))
                  //       params.stopEditing()
                  //     } else {
                  //       setDescription(editorState, record.key)
                  //     }
                  //   }}
                  //   onDiscard={editorState => {
                  //     if (isGridEditable == false) {
                  //       params.stopEditing()
                  //     } else {
                  //       setDescription(editorState, record.key)
                  //     }
                  //   }}
                  // />

                  <MyEditor
                    editorState={params.data.editorState ?? this.state.editorState}
                    onChange={(text, editorState) =>
                      this.onDescriptionChange(editorState, params.data.key)
                    }
                    disableDebounce
                    editorClassName={`w-11/12 h-[150px] overflow-auto `}
                  />
                )}
                // Events
                onGridReady={params => {
                  params.api.setGridOption('rowData', this.props.data)
                }}
                onFirstDataRendered={params => {
                  setTimeout(() => this.setState({ loading: false }), 1000)
                }}
                noRowsOverlayComponent={noRowsOverley}
                onBodyScroll={params => {
                  params.api?.clearFocusedCell()
                }}
                popupParent={document.querySelector('#products-table')}
                onRowDragEnd={params => {
                  this.props.dataUpdate(this.getRowDataFromGrid(true))
                }}
                isFullWidthRow={params => {
                  if (params.rowNode.data?.is_item_header) {
                    return true
                  }
                  return false
                }}
                fullWidthCellRenderer={params => {
                  if (params.data?.is_item_header) {
                    return (
                      <div className="w-full">
                        <div className="font-size-14 text-gray-800 font-weight-bold">
                          {params.data?.product_name}
                        </div>
                      </div>
                    )
                  }
                }}
                modules={[ClientSideRowModelModule, MasterDetailModule]}
              />
            </div>
          </div>

          <div className="flex flex-wrap bg-gray-75 justify-between items-center">
            <div className="w-4/12">
              <h6 className="font-size-12 mt-2 mb-2 ml-2">
                Apply discount(%) to all items?
                <span>
                  <InfoIcon
                    text={
                      <>
                        This <strong className="text-success">discount %</strong> will be applied to
                        all products and overwrite any individual product discount.
                      </>
                    }
                  />
                </span>
              </h6>

              <InputNumber
                value={this.state.total_discount}
                min={0}
                max={100}
                className="mb-2 ml-2 w-20p"
                style={{
                  borderColor: '#05a357',
                  borderWidth: '2px',
                }}
                onChange={e => this.onTotalDiscountChange(e)}
                disabled={this.props.permissions.product_edit == 0}
              />
            </div>
            <div className="w-8/12 flex flex-col items-end">
              <div className="mr-2">
                <span className="font-size-12 text-gray-500">
                  Items: {this.props.data.length}, Qty: {getTotalQty(this.props.data, true)}{' '}
                </span>
              </div>
              <div>
                <Button
                  type="action-o"
                  className="mt-2 mr-2 mb-2 float-right font-weight-bold"
                  onClick={() =>
                    this.setState({ has_extra_charges: !this.state.has_extra_charges }, () => {
                      if (!this.state.has_extra_charges)
                        this.setState({ transportCharges: 0, packingCharges: 0 })
                    })
                  }
                >
                  {this.state.has_extra_charges ? (
                    <i className="fa fa-minus-circle mr-2" />
                  ) : (
                    <i className="fa fa-plus-circle mr-2" />
                  )}{' '}
                  Additional Charges
                </Button>
              </div>
              {this.state.has_extra_charges == true && (
                <Table
                  size="small"
                  className="mb-1 pt-4 mr-2"
                  dataSource={this.props.document_custom_additional_charges}
                  columns={document_custom_additional_charges}
                  pagination={false}
                  footer={() => (
                    <div className="flex flex-col">
                      <div>
                        <Button
                          type="link"
                          className="font-size-14 cursor-pointer"
                          onClick={e => {
                            this.addExistingAdditionalCharge()
                          }}
                        >
                          <i className="fa-solid fa-plus-circle mr-2" />
                          Add from existing charges
                        </Button>
                      </div>
                      <div className="flex w-100 justify-end">
                        <Button
                          onClick={e => {
                            store.get('paid') == 0
                              ? this.subscriptionPlanRef.current.openModal('custom_fields')
                              : this.custom_column.onAddCustomAdditionalCharges()
                          }}
                          type="transparent"
                          className="pt-2 font-weight-medium text-right"
                        >
                          <i className="fa fa-lg fa-plus-circle mr-2" />
                          <span className="mr-2">Add New Charges</span>
                        </Button>
                      </div>
                    </div>
                  )}
                />
              )}
            </div>
          </div>
        </Card>

        <Modal
          title={'Confirm Items '}
          open={this.state.uploadItemsModel}
          onCancel={() => {
            this.setState({
              uploadItemsModel: false,
              uploadedItems: [],
            })
          }}
          width={'80%'}
          height={'auto'}
          footer={null}
        >
          <UploadedProductsTable
            ref={this.UploadedProductsTableRef}
            initialData={this.state.uploadedItems}
            currencySymbol={this.props.user.selectedCompany.currency_symbol}
            taxes={this.props.taxes}
            onAddItemsToBill={items => {
              this.props.dataUpdate(
                [...items, ...this.props.data].map((item, index) => {
                  item['key'] = index + 1
                  return item
                }),
              )
            }}
            warehouse_id={this.props.warehouse_id}
            onCancel={() => {
              this.setState(
                {
                  uploadItemsModel: false,
                  uploadedItems: [],
                },
                () => {
                  this.props.onUpdateItems(0, 'price_change', -1)
                },
              )
            }}
          />
        </Modal>

        <CustomColumnsForm
          onRef={ref => (this.custom_column = ref)}
          onAPICalled={() => {
            this.props.onAPICalled()
          }}
        />
        <EnhancedAdditionalCess
          onRef={ref => (this.additional_cess = ref)}
          updateRecord={record => this.updateRecord(record)}
        />

        <EnhancedProductAnalysis
          onRef={ref => (this.productAnalysis = ref)}
          menu="product"
          onProductUpdate={newData => this.updateProductInTable(newData)}
          onChangeCustomerPrice={(value, data, priceType) => {
            this.onChange(value, data.key, priceType)
          }}
        />
        <ProductBatches
          ref={this.productBatchesRef}
          onFinishBatchesQty={(product, batches) => this.onFinishBatchesQty(product, batches)}
          document_type={this.props.document_type}
          old_items_before_edit={this.props.old_items_before_edit}
          warehouse_id={this.props.warehouse_id}
        />
        <ProductForm
          onRef={ref => (this.form = ref)}
          onAPICalled={newData => this.onAddNew(newData)}
        />
        <ProductBarcode onRef={ref => (this.barcode = ref)} menu="barcode" />
        <EnhancedDelete
          onRef={ref => (this.delete = ref)}
          document_type={'product'}
          handleDelete={this.onDelete}
        />
        <EnhancedSubscriptionModal onRef={ref => (this.subscription = ref)} />
        <SubscriptionPlanModal ref={this.subscriptionPlanRef} />

        <BatchingForm
          onRef={ref => (this.serialNumberRef = ref)}
          onAPICalled={(product, serialNumbers) =>
            this.onFinishSerialNumbers(product, serialNumbers)
          }
          selectedWarehouse={this.props.warehouse_id}
        />
        <SelectBatchConvert
          products={this.props.data}
          changeBatchQty={this.changeBatchQty}
          open={this.state.showSelectBatches}
          onClose={this.closeSelectBatches}
        />
        <TutorialVideoModal ref={this.tutorialRef} video_name={'add_additional_charges'} />
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const stock_in_warehouses =
    state.warehouse.warehouses?.filter(
      item => state.warehouse.warehouse_permissions?.[item.warehouse_id]?.['stock_in'] == 1,
    ) ?? []
  const stock_out_warehouses =
    state.warehouse.warehouses?.filter(
      item => state.warehouse.warehouse_permissions?.[item.warehouse_id]?.['stock_out'] == 1,
    ) ?? []
  return {
    theme: state.settings.theme,
    companyDetailsAdded: state.welcome.company_details_added,
    bankDetailsAdded: state.welcome.bank_details_added,
    productsAdded: state.welcome.products_added,
    invoices_created: state.welcome.invoices_created,
    permissions: state.permissions.permission,
    document: state.document,
    custom_columns: state.document.custom_column_data.custom_columns,
    columns_order: state.document.custom_column_data.order,
    total_amount_name: state.document.custom_column_data.total_amount_name,
    net_amount_name: state.document.custom_column_data.net_amount_name,
    qty_name: state.document.custom_column_data.qty_name,
    is_column_linking_failed: state.document.custom_column_data.is_column_linking_failed,
    column_id_names: state.document.custom_column_data.column_id_names,
    failed_names: state.document.custom_column_data.failed_names,
    taxes: state.document.tax_rates,
    user: state.user,
    warehouses: state.warehouse.warehouses,
    stock_in_warehouses,
    stock_out_warehouses,
    integrations: state.permissions.integrations,
    invoice_settings: state.document.invoiceSettings,
  }
}
class AgEnhancedTable extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <ProductsTable {...this.props} />
  }
}

export default connect(mapStateToProps)(AgEnhancedTable)
