import React, { Component } from 'react'

import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { getAPI, getAPIData } from 'services/jwt'
import store from 'store'

import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Table,
  Upload,
} from 'antd'

dayjs.extend(utc)

import AutoFinish from 'components/autoFinish'
import InfoComponent from 'components/badges/InfoComponent'
import SectionHeader from 'components/other/sectionHeader'
import { connect } from 'react-redux'

const { Dragger } = Upload
const { RangePicker } = DatePicker
const party_type = {
  invoice: 'customer',
  purchase: 'vendor',
  sales_return: 'customer',
  purchase_return: 'vendor',
  purchase_order: 'vendor',
  estimate: 'customer',
  delivery_challan: 'customer',
  pro_forma_invoice: 'customer',
}

class BulkDownloads extends Component {
  dateFormat = 'DD-MM-YYYY'
  formRef = React.createRef()
  autoFinish = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      showDrawer: false,
      start_date: dayjs().format(this.dateFormat),
      end_date: dayjs().format(this.dateFormat),
      download_requests: [],
      pdf_types: [1],
      document_type: 'invoice',
      search: '',
      invoiceSettings: {},
      loading: false,
      is_ai: false,
    }
    this.showDrawer = this.showDrawer.bind(this)
  }

  getInvoiceSettings = async () => {
    var data1 = await getAPIData('utils', 'invoice_settings')
    this.setState({
      invoiceSettings: data1 ? data1.invoice_settings : {},
    })
  }

  updateState = newState => {
    this.setState(newState)
  }

  componentDidMount() {
    this.props.onRef(this)
    this.getInvoiceSettings()
  }
  componentWillUnmount() {
    this.props.onRef(null)
    this.setState({ is_ai: false })
  }

  showDrawer = (show = true) => {
    this.setState(
      {
        showDrawer: show,
      },
      () => {
        if (show) {
          this.getDownloadRequests()
        }
      },
    )
  }

  deleteDownloadRequest = async id => {
    this.setState({ loading: true })
    const data = await getAPI('drive', 'download_requests', { id, is_delete: true })
    if (data) {
      if (data?.success) {
        message.success(data.message)
        this.getDownloadRequests()
      }
    }
    this.setState({ loading: false })
  }

  getDownloadRequests = async () => {
    this.setState({ loading: true })
    const data = await getAPIData('drive', 'download_requests')
    if (data) {
      if (data?.success) {
        this.setState({ download_requests: data.download_requests })
      }
    }
    this.setState({ loading: false })
  }

  handleBulkDownload = async values => {
    if (!this.checkDateRange(dayjs(values.date_range[0]), dayjs(values.date_range[1]))) {
      return false
    }

    const req = {
      document_type: [values.document_type],
      date_range: values.date_range
        ? dayjs(values.date_range[0]).format(this.dateFormat) +
          ' - ' +
          dayjs(values.date_range[1]).format(this.dateFormat)
        : '',
      filters: values.filters?.filter(item => item != '') || [],
      party_id: this.state.party_id,
      pdf_types: values.pdf_types || [1],
      download_type: values.download_type,
    }

    const data = await getAPI('drive', 'bulk_download_requests', req)
    if (data) {
      if (data?.success) {
        message.success(data.message)
        this.setState({ bulkDownload: data.bulk_download }, () => {
          this.getDownloadRequests()
        })
        this.formRef.current?.resetFields()
      }
    }
  }

  checkDateRange = (start_date, end_date) => {
    const diff = end_date.diff(start_date, 'days')
    if (diff > 30) {
      message.error('Max date range is 1 Month.')
      return false
    }
    return true
  }

  render() {
    var options = [
      {
        label: 'Customer',
        value: 1,
      },
      {
        label: 'Transport',
        value: 2,
      },
      {
        label: 'Supplier',
        value: 3,
      },
    ]

    if (this.props?.user?.selectedCompany?.country_code == 'IN') {
      // not showing this option for countries other than IN
      options.push({ label: 'Delivery Challan', value: 4 })
    }

    const formItemLayout = {
      layout: 'vertical',
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
    }
    return (
      <Modal
        title="Bulk Downloads"
        placement="right"
        closable={true}
        onClose={() => this.showDrawer(false)}
        onCancel={() => this.showDrawer(false)}
        footer={null}
        open={this.state.showDrawer}
        width={'80%'}
        destroyOnClose={true}
      >
        <InfoComponent
          className="mb-4 font-size-13 text-muted"
          title="Note"
          description={
            <span className="mt-4">
              Requested files will be available in the below <b>Download Requests</b> section within{' '}
              <span className="text-danger font-weight-bold">24 - 48 hours.</span>
            </span>
          }
          type="info"
        />
        <Card className="mb-4">
          <Form
            {...formItemLayout}
            hideRequiredMark
            onFinish={this.handleBulkDownload}
            className="mb-4"
            ref={this.formRef}
            initialValues={{
              filters: [''],
              pdf_types: [1],
              download_type: 'zip',
              document_type: this.state.is_ai ? this.state.document_type : undefined,
              date_range: this.state.is_ai ? this.state.dates : undefined,
            }}
          >
            <Row gutter={24} wrap={true} className="mr-auto flex">
              <Col
                className="px-auto"
                xs={{
                  span: 24,
                }}
                lg={{
                  span: 18,
                }}
                xl={{
                  span: 12,
                }}
                xxl={{
                  span: 8,
                }}
              >
                <Form.Item
                  name="document_type"
                  label="Document Type"
                  autoFocus
                  rules={[{ required: true, message: 'Select document type' }]}
                >
                  <Select
                    // mode="multiple"
                    style={{ width: 400 }}
                    placeholder="Select document type"
                    onChange={value => {
                      this.setState({ document_type: value }, () => {
                        this.autoFinish.refreshComponent(party_type[value] || 'customer')
                      })
                      this.formRef.current.setFieldsValue({
                        document_type: value,
                      })
                    }}
                  >
                    <Select.Option value="invoice">Sales</Select.Option>
                    <Select.Option value="purchase">Purchase</Select.Option>
                    <Select.Option value="sales_return">Credit Note</Select.Option>
                    <Select.Option value="purchase_return">Debit Note</Select.Option>
                    <Select.Option value="purchase_order">Purchase Order</Select.Option>
                    <Select.Option value="estimate">Estimate</Select.Option>
                    <Select.Option value="sales_order">Sales Orders</Select.Option>
                    {this.props?.user?.selectedCompany?.country_code == 'IN' && (
                      <Select.Option value="delivery_challan">Delivery Challan</Select.Option>
                    )}
                    <Select.Option value="pro_forma_invoice">Pro Forma Invoice</Select.Option>
                    {/* <Select.Option value="expense">Payment</Select.Option> */}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                className="px-auto"
                xs={{
                  span: 24,
                }}
                lg={{
                  span: 18,
                }}
                xl={{
                  span: 12,
                }}
                xxl={{
                  span: 8,
                }}
              >
                <Form.Item
                  name="party_id"
                  label={
                    party_type[this.state.document_type].charAt(0).toUpperCase() +
                    party_type[this.state.document_type].slice(1) +
                    ' Name'
                  }
                  autoFocus
                  //   rules={[{ required: true, message: 'Select Party' }]}
                  extra={
                    'Select ' +
                    (party_type[this.state.document_type] || 'customer') +
                    ' to filter documents. Leave blank to download all.'
                  }
                >
                  <AutoFinish
                    style={{ width: 400 }}
                    placeholder={
                      'All ' + (party_type[this.state.document_type] || 'customer') + 's'
                    }
                    autoFocus={true}
                    searchType={party_type[this.state.document_type] || 'customer'}
                    onRef={ref => (this.autoFinish = ref)}
                    callData={(value, option) => {
                      this.setState({ party_id: option.id, search: option.name })
                    }}
                    dropdownMatchSelectWidth={true}
                    searchText={this.state.search}
                    onChange={value => {
                      if (value == '') {
                        this.setState({ party_id: -1 })
                      }
                      this.setState({ search: value })
                    }}
                  />
                </Form.Item>
              </Col>

              <Col
                className="px-auto"
                xs={{
                  span: 24,
                }}
                lg={{
                  span: 18,
                }}
                xl={{
                  span: 12,
                }}
                xxl={{
                  span: 8,
                }}
              >
                <Form.Item name="filters" label="Payment Status" autoFocus>
                  <Select
                    mode="multiple"
                    style={{ width: 400 }}
                    placeholder="Select document type"
                    autoFocus={true}
                    defaultValue={['']}
                  >
                    <Select.Option value="">All</Select.Option>
                    <Select.Option value="paid">Paid</Select.Option>
                    <Select.Option value="pending">Pending</Select.Option>
                    {/* <Select.Option value="cancelled">Cancelled</Select.Option> */}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                className="px-auto"
                xs={{
                  span: 24,
                }}
                lg={{
                  span: 18,
                }}
                xl={{
                  span: 12,
                }}
                xxl={{
                  span: 8,
                }}
              >
                <Form.Item
                  name="date_range"
                  label="Date Range"
                  rules={[{ required: true, message: 'Please select Date Range' }]}
                  extra={'Max date range is 1 Month.'}
                >
                  <RangePicker
                    allowClear={false}
                    // ranges={ranges}
                    className=""
                    onChange={(dates, dateStrings) => {
                      if (!this.checkDateRange(dates[0], dates[1])) {
                        return false
                      }
                      store.set('page', 0)
                      store.set(
                        'transactions_default_dates_' + this.props.company_details.company_id,
                        dateStrings[0] + ' - ' + dateStrings[1],
                      )
                      this.setState({
                        dates: dateStrings[0] + ' - ' + dateStrings[1],
                        start_date: dateStrings[0],
                        end_date: dateStrings[1],
                      })
                    }}
                    value={
                      this.state.is_ai
                        ? [
                            dayjs(this.state.start_date, this.dateFormat),
                            dayjs(this.state.end_date, this.dateFormat),
                          ]
                        : store.get(
                            'transactions_default_dates_' + this.props.company_details.company_id,
                          )
                        ? [
                            dayjs(
                              store
                                .get(
                                  'transactions_default_dates_' +
                                    this.props.company_details.company_id,
                                )
                                .split(' - ')[0],
                              this.dateFormat,
                            ),
                            dayjs(
                              store
                                .get(
                                  'transactions_default_dates_' +
                                    this.props.company_details.company_id,
                                )
                                .split(' - ')[1],
                              this.dateFormat,
                            ),
                          ]
                        : [
                            dayjs(this.state.start_date, this.dateFormat),
                            dayjs(this.state.end_date, this.dateFormat),
                          ]
                    }
                    format={this.dateFormat}
                    onOpenChange={open => {
                      if (open && this.state.is_ai) {
                        this.setState({ is_ai: false })
                      }
                    }}
                    //style={{ borderTopRightRadius: '5px', borderBottomRightRadius: '5px' }}
                  />
                </Form.Item>
              </Col>

              <Col
                className="px-auto"
                xs={{
                  span: 24,
                }}
                lg={{
                  span: 18,
                }}
                xl={{
                  span: 12,
                }}
                xxl={{
                  span: 8,
                }}
              >
                <Form.Item
                  name="pdf_types"
                  label="PDF Types"
                  rules={[{ required: true, message: 'Please select PDF Types' }]}
                  value={[1]}
                >
                  <Checkbox.Group
                    onChange={checkedValues => {
                      this.setState({ pdf_types: checkedValues })
                    }}
                    options={options}
                    value={[1]}
                    className="mb-3"
                  ></Checkbox.Group>
                </Form.Item>
              </Col>

              <Col
                className="px-auto"
                xs={{
                  span: 24,
                }}
                lg={{
                  span: 18,
                }}
                xl={{
                  span: 12,
                }}
                xxl={{
                  span: 8,
                }}
              >
                <Form.Item
                  name="download_type"
                  label="Download Type"
                  rules={[{ required: true, message: 'Please select Download Type' }]}
                  value={'zip'}
                  extra={
                    this.state.download_type == 'pdf'
                      ? 'Note: PDF download will be available for the selected document type and date range and limited to 500 records.'
                      : 'Note: ZIP download will be available for the selected document type and date range.'
                  }
                >
                  <Radio.Group
                    onChange={e => {
                      this.setState({ download_type: e.target.value })
                    }}
                    defaultValue="zip"
                    className="mb-3"
                    value={'zip'}
                  >
                    <Radio.Button value="zip">ZIP</Radio.Button>
                    <Radio.Button value="pdf">PDF</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Button
              type="primary"
              className="font-weight-bold text-center w-90 mt-3"
              htmlType="submit"
            >
              Request Download
            </Button>
          </Form>
          {/* <p className="font-size-13 text-muted">
            Note: Requested files will be available in the <b>Download Requests</b> section below
            within <span className="text-danger font-weight-medium">48 hours.</span>
          </p> */}
        </Card>
        <div className="mt-4">
          <SectionHeader title="Download Requests" />
          <Table
            dataSource={this.state.download_requests}
            loading={this.state.loading}
            columns={[
              {
                title: 'Document Type',
                dataIndex: 'type',
                key: 'type',
                width: '20%',
                render: (text, record) => {
                  return (
                    <div>
                      <div className="font-weight-medium">
                        {record.type.charAt(0).toUpperCase() + record.type.slice(1)}{' '}
                      </div>
                      <span className="text-muted font-size-14">
                        {record.party_name || 'All ' + party_type[record.document_type] + 's'}
                        <span classname="ml-2">
                          {' '}
                          ({record.download_type == 'pdf' ? 'PDF' : 'ZIP'})
                        </span>
                      </span>
                    </div>
                  )
                },
              },
              {
                title: 'Payment Status',
                dataIndex: 'filters',
                key: 'filters',
                width: '10%',
                render: (text, record) => {
                  return record.filters
                },
              },
              {
                title: 'Date Range',
                dataIndex: 'date_range',
                key: 'date_range',
                width: '20%',
                render: (text, record) => {
                  return record.date_range
                },
              },
              {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                width: '10%',
                render: (text, record) => {
                  return (
                    <div>
                      {(record.status === 'pending' || record.status == 'processing') && (
                        <span className="font-weight-medium yellow">pending</span>
                      )}
                      {record.status === 'completed' && (
                        <span className="font-weight-medium text-success">completed</span>
                      )}
                      {record.status === 'failed' && (
                        <span className="font-weight-medium text-danger">failed</span>
                      )}
                    </div>
                  )
                },
              },
              {
                title: 'Action',
                dataIndex: 'download',
                key: 'download',
                width: '50%',
                render: (text, record) => {
                  return (
                    <div>
                      {record.status === 'completed' && (
                        <span className="text-primary cursor-pointer">
                          {record.remarks != '' ? (
                            <Popconfirm
                              title={
                                <p className="font-weight-bold font-size-16">{record.remarks}</p>
                              }
                              onConfirm={event => {
                                event.stopPropagation()
                              }}
                              okText=" Ok"
                              placement="leftBottom"
                            >
                              <Button type="" size="small" className="font-weight-bold ml-2">
                                <i className="far fa-arrow-down fa-fw mr-1" />
                                Download
                              </Button>
                            </Popconfirm>
                          ) : (
                            <span
                              onClick={() => {
                                this.showDrawer(false)
                                this.props.goToDownloads(record.download_url)
                              }}
                            >
                              <Button
                                type="action-3"
                                size="small"
                                className="font-weight-bold ml-2"
                              >
                                <i className="far fa-arrow-down fa-fw mr-1" />
                                Download
                              </Button>
                            </span>
                          )}
                        </span>
                      )}
                      {record.status === 'failed' && <span className="text-danger">Failed</span>}
                      {record.status === 'deleted' && <span className="text-danger">Deleted</span>}
                      <Popconfirm
                        title={
                          <p className="font-weight-bold font-size-16">
                            Are you sure you want to delete?
                          </p>
                        }
                        onConfirm={event => {
                          this.deleteDownloadRequest(record.id)
                        }}
                        okText=" Ok"
                        placement="leftBottom"
                      >
                        <Button type="danger" size="small" className="font-weight-bold ml-2">
                          <i className="fa fa-trash-o fa-fw mx-1" />
                        </Button>
                      </Popconfirm>
                    </div>
                  )
                },
              },
            ]}
            pagination={
              this.state.download_requests.length > 5
                ? {
                    pageSize: 5,
                    position: 'bottom',
                  }
                : false
            }
          />
        </div>
      </Modal>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    company_details: state.permissions.company_details,
    user: state.user,
  }
}
export default connect(mapStateToProps)(BulkDownloads)
