import { PDFViewer } from '@react-pdf/renderer'
import React, { Component, useEffect, useState } from 'react'

import { Alert, Button, Checkbox, DatePicker, Modal, Radio, Tooltip, message } from 'antd'
import NewBadge from 'components/badges/new'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { downloadAPI, getAPI } from 'services/jwt'
const { RangePicker } = DatePicker

import Ledger from 'components/ledger/index'
import { connect } from 'react-redux'
import InfoComponent from 'components/badges/InfoComponent'
dayjs.extend(utc)
function LedgerDocument({ data }) {
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    setIsReady(true)
  }, [])

  return (
    <>
      {isReady ? (
        <PDFViewer key={Math.random()} className="col-12" style={{ height: '900px' }}>
          <Ledger data={{ ...data }} />
        </PDFViewer>
      ) : (
        ''
      )}
    </>
  )
}

// import GSTReport from 'components/gst/index'
// function GSTReportDocument({ data }) {
//   const [isReady, setIsReady] = useState(false)

//   useEffect(() => {
//     setIsReady(true)
//   }, [])

//   return (
//     <>
//       {isReady ? (
//         <PDFViewer key={Math.random()} className="col-12" style={{ height: '900px' }}>
//           <GSTReport data={{ ...data }} />
//         </PDFViewer>
//       ) : (
//         ''
//       )}
//     </>
//   )
// }

// import SalesReport from 'components/sales_report/index'
// function SalesReportDocument({ data }) {
//   const [isReady, setIsReady] = useState(false)

//   useEffect(() => {
//     setIsReady(true)
//   }, [])

//   return (
//     <>
//       {isReady ? (
//         <PDFViewer key={Math.random()} className="col-12" style={{ height: '900px' }}>
//           <SalesReport data={{ ...data }} />
//         </PDFViewer>
//       ) : (
//         ''
//       )}
//     </>
//   )
// }

// import HSNWiseReport from 'components/hsn_wise/index'
// function HSNWiseReportDocument({ data }) {
//   const [isReady, setIsReady] = useState(false)

//   useEffect(() => {
//     setIsReady(true)
//   }, [])

//   return (
//     <>
//       {isReady ? (
//         <PDFViewer key={Math.random()} className="col-12" style={{ height: '900px' }}>
//           <HSNWiseReport data={{ ...data }} />
//         </PDFViewer>
//       ) : (
//         ''
//       )}
//     </>
//   )
// }

// import ProfitLossReport from 'components/profit_loss_report/index'
// import NewBadge from 'components/badges/new'
// function ProfitLossReportDocument({ data }) {
//   const [isReady, setIsReady] = useState(false)

//   useEffect(() => {
//     setIsReady(true)
//   }, [])

//   return (
//     <>
//       {isReady ? (
//         <PDFViewer key={Math.random()} className="col-12" style={{ height: '900px' }}>
//           <ProfitLossReport data={{ ...data }} />
//         </PDFViewer>
//       ) : (
//         ''
//       )}
//     </>
//   )
// }

class EnhancedExport extends Component {
  dateFormat = 'DD-MM-YYYY'
  constructor(props) {
    super(props)
    this.state = {
      flag: false,
      value: 'Previous Month',
      date:
        dayjs()
          .subtract(1, 'months')
          .startOf('month')
          .format(this.dateFormat) +
        ' - ' +
        dayjs()
          .subtract(1, 'months')
          .endOf('month')
          .format(this.dateFormat),
      pdfLoading: false,
      loading: false,
      pdfFlag: false,
      pdfData: '',
      ledgerFlag: false,
      gstFlag: false,
      salesReportFlag: false,
      hsnWiseFlag: false,
      profitLossFlag: false,
      gstr1_hsn: false,
      gstr1_cdnr: false,
      gstr1_eco: false,
      gstr1_summary: false,
      error_data: {},
      error_modal: false,
      downloadOverride: false,
    }
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  handleDirectDownload(start_date, end_date) {
    this.setState(
      {
        date: start_date + ' - ' + end_date,
      },
      () => {
        this.handleDownload()
      },
    )
  }

  showModal = () => {
    this.setState({ flag: true })
  }

  getQuarter = currentQuarter => {
    var lastQuarter = currentQuarter === 1 ? 4 : currentQuarter - 1

    // Get the year of the last quarter (if current is Q1, move to last year's Q4)
    var year = currentQuarter === 1 ? dayjs().year() - 1 : dayjs().year()

    // Determine the starting month of the last quarter
    var startMonth = (lastQuarter - 1) * 3 // Q1 -> Jan(0), Q2 -> Apr(3), Q3 -> Jul(6), Q4 -> Oct(9)

    // Get start and end dates of the last quarter
    var startDate = dayjs()
      .year(year)
      .month(startMonth)
      .startOf('month')
      .format(this.dateFormat)
    var endDate = dayjs()
      .year(year)
      .month(startMonth + 2)
      .endOf('month')
      .format(this.dateFormat)

    return { startDate, endDate }
  }

  onModalChange = e => {
    var date = ''
    var is_quarter = false
    if (e.target.value == 'All Time') {
      var date =
        dayjs()
          .subtract(10, 'year')
          .month(0)
          .startOf('month')
          .format(this.dateFormat) +
        ' - ' +
        dayjs()
          .add(10, 'year')
          .month(0)
          .startOf('month')
          .format(this.dateFormat)
    }

    if (e.target.value == 'Today') {
      var date = dayjs().format(this.dateFormat) + ' - ' + dayjs().format(this.dateFormat)
    }

    if (e.target.value == 'Yesterday') {
      var date =
        dayjs()
          .subtract(1, 'days')
          .startOf('day')
          .format(this.dateFormat) +
        ' - ' +
        dayjs()
          .subtract(1, 'days')
          .startOf('day')
          .format(this.dateFormat)
    }

    if (e.target.value == 'Previous Month') {
      var date =
        dayjs()
          .subtract(1, 'months')
          .startOf('month')
          .format(this.dateFormat) +
        ' - ' +
        dayjs()
          .subtract(1, 'months')
          .endOf('month')
          .format(this.dateFormat)
    }

    if (e.target.value == 'Current Month') {
      var date =
        dayjs()
          .startOf('month')
          .format(this.dateFormat) +
        ' - ' +
        dayjs()
          .endOf('month')
          .format(this.dateFormat)
    }
    if (e.target.value == 'This Year') {
      var date =
        dayjs()
          .startOf('year')
          .format(this.dateFormat) +
        ' - ' +
        dayjs()
          .endOf('year')
          .format(this.dateFormat)
    }
    if (e.target.value == 'Current FY') {
      var date =
        dayjs()
          .year(2023)
          .month(3)
          .startOf('month')
          .format(this.dateFormat) +
        ' - ' +
        dayjs()
          .year(2024)
          .month(2)
          .endOf('month')
          .format(this.dateFormat)
    }
    if (e.target.value == 'This Week') {
      var date =
        dayjs()
          .startOf('week')
          .format(this.dateFormat) +
        ' - ' +
        dayjs()
          .endOf('week')
          .format(this.dateFormat)
    }
    if (e.target.value == 'Last Week') {
      var date =
        dayjs()
          .subtract(1, 'weeks')
          .startOf('week')
          .format(this.dateFormat) +
        ' - ' +
        dayjs()
          .subtract(1, 'weeks')
          .endOf('week')
          .format(this.dateFormat)
    }
    if (e.target.value == 'FY 22') {
      var date =
        dayjs()
          .year(2022)
          .month(3)
          .startOf('month')
          .format(this.dateFormat) +
        ' - ' +
        dayjs()
          .year(2023)
          .month(2)
          .endOf('month')
          .format(this.dateFormat)
    }
    if (e.target.value == 'FY 23') {
      var date =
        dayjs()
          .year(2023)
          .month(3)
          .startOf('month')
          .format(this.dateFormat) +
        ' - ' +
        dayjs()
          .year(2024)
          .month(2)
          .endOf('month')
          .format(this.dateFormat)
    }
    if (e.target.value == 'Last Quarter') {
      is_quarter = true
      // Get the current quarter
      var currentQuarter = dayjs().quarter()

      // Get the last quarter (handle Q1 edge case)
      const dates = this.getQuarter(currentQuarter)

      var date = `${dates.startDate} - ${dates.endDate}`
    }
    if (e.target.value == 'Quarter 1') {
      is_quarter = true
      // Get the quarter (handle Q1 edge case)
      const dates = this.getQuarter(3)

      var date = `${dates.startDate} - ${dates.endDate}`
    }
    if (e.target.value == 'Quarter 2') {
      is_quarter = true

      // Get the quarter (handle Q1 edge case)
      const dates = this.getQuarter(4)

      var date = `${dates.startDate} - ${dates.endDate}`
    }
    if (e.target.value == 'Quarter 3') {
      is_quarter = true
      // Get the current quarter
      var currentQuarter = 4

      // Get the quarter (handle Q1 edge case)
      const dates = this.getQuarter(1)

      var date = `${dates.startDate} - ${dates.endDate}`
    }
    if (e.target.value == 'Quarter 4') {
      is_quarter = true
      // Get the current quarter
      var currentQuarter = 1

      // Get the quarter (handle Q1 edge case)
      const dates = this.getQuarter(2)

      var date = `${dates.startDate} - ${dates.endDate}`
    }

    this.setState({
      value: e.target.value,
      date: date,
      is_quarter,
    })
  }

  onRangeChange = (date, datesString) => {
    var s = datesString[0].split('-')
    var e = datesString[1].split('-')
    var start_date = s[2] + '-' + s[1] + '-' + s[0]
    var end_date = e[2] + '-' + e[1] + '-' + e[0]

    if (datesString[0] != '') {
      this.setState({ date: start_date + ' - ' + end_date })
    } else {
      this.setState({ date: '' })
    }
  }

  handleDownload = async () => {
    if (this.props.menuName != '') {
      this.setState({ loading: true })
      var req = {}
      var module_name = this.props.menuName
      var api_name = 'download'

      if (this.props.menuName == 'sales_summary') {
        module_name = 'inventory'
        api_name = 'download_sales_summary'
      }

      if (this.props.menuName == 'gst') {
        module_name = 'v2/reports'
        api_name = 'gstr_1_v2'
        req = {
          page: 0,
          num_records: 0,
          download: 1,
          download_override: this.state.downloadOverride,
        }
      }

      if (this.props.menuName == 'gst_json') {
        module_name = 'v2/reports'
        api_name = 'gstr_1_v2'
        req = {
          page: 0,
          num_records: 0,
          download: 1,
          is_json: 1,
          add_hsn: this.state.gstr1_hsn,
          add_cdnr: this.state.gstr1_cdnr,
          add_summary: this.state.gstr1_summary,
          add_eco: this.state.gstr1_eco,
          download_override: this.state.downloadOverride,
          is_quarterly: this.state.is_quarter,
        }
      }

      if (this.props.menuName == 'tally_excel') {
        module_name = 'v2/reports'
        api_name = 'download_tally_excel'
        req = { page: 0, num_records: 0, download: 1, is_tally: 1 }
      }

      if (this.props.menuName == 'hsn_wise') {
        module_name = 'sales'
        api_name = 'download'
        req = { type: 'hsn_wise' }
      }

      if (this.props.menuName == 'sales') {
        module_name = 'v2/reports'
        api_name = 'transaction_wise_items'
        req = { type: 'sales_report', download: 1 }
      }

      if (this.props.menuName == 'expenses') {
        module_name = 'expenses'
        api_name = 'download'
      }

      if (this.props.menuName == 'customer/view_ledger') {
        req = { id: this.props.id, show_pending_docs: this.props.show_pending_docs || false }
      }

      if (this.props.menuName == 'customer/ledger') {
        req = {
          customer_id: this.props.id,
          show_pending_docs: this.props.show_pending_docs || false,
        }
      }

      if (this.props.menuName == 'vendor/view_ledger') {
        req = { id: this.props.id, show_pending_docs: this.props.show_pending_docs || false }
      }

      if (this.props.menuName == 'vendor/ledger') {
        req = { vendor_id: this.props.id, show_pending_docs: this.props.show_pending_docs || false }
      }

      if (this.state.value != 'custom' && this.state.value != 'All Time') {
        const data = await downloadAPI(module_name, api_name, {
          date: this.state.date,
          date_range: this.state.date,
          ...req,
        })
        if (data) {
          if (data.success != undefined && !this.state.downloadOverride) {
            if (this.props.menuName == 'gst_json' || this.props.menuName == 'gst') {
              this.setState({
                error_modal: true,
                error_data: data.errors,
                loading: false,
              })
            }
          }
        }
        this.setState({ flag: false, loading: false })
      } else {
        if (this.state.date == '') {
          message.error('Please select Date')
          this.setState({ loading: false })
        } else {
          const data = await downloadAPI(module_name, api_name, {
            date: this.state.date,
            date_range: this.state.date,
            ...req,
          })

          if (data.success != undefined && !this.state.downloadOverride) {
            if (this.props.menuName == 'gst_json') {
              if (data.success != undefined) {
                this.setState({
                  error_modal: true,
                  error_data: data.errors,
                  loading: false,
                })
              }
            }
            this.setState({ flag: false, loading: false })
          } else {
            this.setState({ loading: false, flag: false })
          }
        }
      }

      this.setState({ loading: false })
    }
  }

  handleDownloadPDF = async () => {
    if (this.props.menuName != '') {
      this.setState({ pdfLoading: true })
      var req = {}
      var module_name = this.props.menuName

      if (this.props.menuName == 'sales_summary') {
        module_name = 'inventory'
        menu_name = 'inventory'
        api_name = 'download_sales_summary'
        req = {
          is_pdf: true,
        }
      }

      if (this.props.menuName == 'gst') {
        module_name = 'sales'
        menu_name = 'sales'
        api_name = 'download'
        req = {
          type: 'gst',
          is_pdf: true,
        }
      }

      if (this.props.menuName == 'hsn_wise') {
        module_name = 'sales'
        menu_name = 'sales'
        api_name = 'download'
        req = {
          type: 'hsn_wise',
          is_pdf: true,
        }
      }

      if (this.props.menuName == 'sales') {
        module_name = 'sales'
        menu_name = 'sales'
        api_name = 'download'
        req = {
          type: 'sales_report',
          is_pdf: true,
        }
      }

      if (this.props.menuName == 'expenses') {
        module_name = 'expenses'
        menu_name = 'expenses'
        api_name = 'download'
      }

      if (this.props.menuName == 'customer/view_ledger') {
        var api_name = 'view_ledger'
        var menu_name = 'customer'
        req = {
          id: this.props.id,
          num_records: 200,
          page: 0,
          show_pending_docs: this.props.show_pending_docs || false,
        }
      }

      if (this.props.menuName == 'customer/ledger') {
        var api_name = 'ledger'
        var menu_name = 'customer'
        req = {
          id: this.props.id,
          num_records: 200,
          page: 0,
          all_records: 1,
          show_pending_docs: this.props.show_pending_docs || false,
        }
      }

      if (this.props.menuName == 'vendor/view_ledger') {
        var api_name = 'view_ledger'
        var menu_name = 'vendor'
        req = {
          id: this.props.id,
          num_records: 200,
          page: 0,
          show_pending_docs: this.props.show_pending_docs || false,
        }
      }

      if (this.props.menuName == 'vendor/ledger') {
        var api_name = 'ledger'
        var menu_name = 'vendor'
        req = {
          id: this.props.id,
          num_records: 200,
          page: 0,
          all_records: 1,
          show_pending_docs: this.props.show_pending_docs || false,
        }
      }

      if (this.state.date == '') {
        message.error('Please select Date')
      } else {
        const data = await getAPI(menu_name, api_name, { date: this.state.date, ...req })

        if (data?.success) {
          this.setState({ flag: false, pdfLoading: false, pdfData: data }, () => {
            this.setState({
              ledgerFlag: api_name.includes('ledger'),
              gstFlag: this.props.menuName == 'gst',
              salesReportFlag: this.props.menuName == 'sales',
              hsnWiseFlag: this.props.menuName == 'hsn_wise',
              profitLossFlag: this.props.menuName == 'sales_summary',
            })
          })
        }
      }
      this.setState({ pdfLoading: false })
    }
  }

  render() {
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    }
    return (
      <>
        {this.state.pdfData != '' && this.state.ledgerFlag && (
          <Modal
            title="Party Ledger"
            open={this.state.ledgerFlag}
            style={{ top: 20 }}
            width={900}
            onOk={() => this.setState({ ledgerFlag: false })}
            onCancel={() => this.setState({ ledgerFlag: false })}
            closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
            footer={[
              <Button
                key="back"
                type="default"
                onClick={() => this.setState({ ledgerFlag: false })}
              >
                Cancel
              </Button>,
            ]}
          >
            <LedgerDocument data={this.state.pdfData} />
          </Modal>
        )}

        {this.state.pdfData != '' && this.state.gstFlag && (
          <Modal
            title="GST Report"
            open={this.state.gstFlag}
            style={{ top: 20 }}
            width={900}
            onOk={() => this.setState({ gstFlag: false })}
            onCancel={() => this.setState({ gstFlag: false })}
            closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
            footer={[
              <Button key="back" type="default" onClick={() => this.setState({ gstFlag: false })}>
                Cancel
              </Button>,
            ]}
            zIndex={2001}
          >
            <GSTReportDocument data={this.state.pdfData} />
          </Modal>
        )}

        {this.state.pdfData != '' && this.state.salesReportFlag && (
          <Modal
            title="Sales Report"
            open={this.state.salesReportFlag}
            style={{ top: 20 }}
            width={900}
            onOk={() => this.setState({ salesReportFlag: false })}
            onCancel={() => this.setState({ salesReportFlag: false })}
            closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
            footer={[
              <Button
                key="back"
                type="default"
                onClick={() => this.setState({ salesReportFlag: false })}
              >
                Cancel
              </Button>,
            ]}
          >
            <SalesReportDocument data={this.state.pdfData} />
          </Modal>
        )}

        {this.state.pdfData != '' && this.state.hsnWiseFlag && (
          <Modal
            title="HSN WISE Report"
            open={this.state.hsnWiseFlag}
            style={{ top: 20 }}
            width={900}
            onOk={() => this.setState({ hsnWiseFlag: false })}
            onCancel={() => this.setState({ hsnWiseFlag: false })}
            closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
            footer={[
              <Button
                key="back"
                type="default"
                onClick={() => this.setState({ hsnWiseFlag: false })}
              >
                Cancel
              </Button>,
            ]}
          >
            <HSNWiseReportDocument data={this.state.pdfData} />
          </Modal>
        )}

        {this.state.pdfData != '' && this.state.profitLossFlag && (
          <Modal
            title="Profit & Loss Report"
            open={this.state.profitLossFlag}
            style={{ top: 20 }}
            width={900}
            onOk={() => this.setState({ profitLossFlag: false })}
            onCancel={() => this.setState({ profitLossFlag: false })}
            closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
            footer={[
              <Button
                key="back"
                type="default"
                onClick={() => this.setState({ profitLossFlag: false })}
              >
                Cancel
              </Button>,
            ]}
          >
            <ProfitLossReportDocument data={this.state.pdfData} />
          </Modal>
        )}

        <Modal
          title="Select data range"
          open={this.state.flag}
          onCancel={() => this.setState({ flag: false })}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          zIndex={2001}
          footer={
            <>
              <Button key="back" onClick={() => this.setState({ flag: false })}>
                Cancel
              </Button>
              <Button
                key="submit"
                type="action"
                className="font-weight-bold"
                onClick={this.handleDownload}
                loading={this.state.loading}
              >
                <p className="ml-1">Download</p>
              </Button>

              {(this.props.menuName.includes('customer/ledger') ||
                this.props.menuName.includes('vendor/ledger')) && (
                <Button
                  key="submit"
                  type="action-3"
                  onClick={this.handleDownloadPDF}
                  loading={this.state.pdfLoading}
                >
                  View PDF
                </Button>
              )}
            </>
          }
        >
          <div className="">
            {/*<h6 className="font-weight-bold">Select Data Range</h6>*/}

            <Radio.Group onChange={this.onModalChange} value={this.state.value}>
              {/* <Radio style={radioStyle} value={'All Time'}>
                All Time
              </Radio> */}
              {this.props.menuName != 'gst_json' && (
                <Radio style={radioStyle} value={'Today'}>
                  Today
                </Radio>
              )}
              {this.props.menuName != 'gst_json' && (
                <Radio style={radioStyle} value={'Yesterday'}>
                  Yesterday
                </Radio>
              )}
              <Radio style={radioStyle} value={'Current Month'}>
                Current Month
              </Radio>
              <Radio style={radioStyle} value={'Previous Month'}>
                Last Month
              </Radio>
              <Radio style={radioStyle} value={'Current FY'}>
                Current FY
              </Radio>
              {this.props.menuName != 'gst_json' && (
                <Radio style={radioStyle} value={'This Week'}>
                  This Week
                </Radio>
              )}
              {this.props.menuName != 'gst_json' && (
                <Radio style={radioStyle} value={'Last Week'}>
                  Last Week
                </Radio>
              )}
              <Radio style={radioStyle} value={'Last Quarter'}>
                Last Quarter
              </Radio>
              <Radio style={radioStyle} value={'Quarter 1'}>
                Quarter 1
              </Radio>
              <Radio style={radioStyle} value={'Quarter 2'}>
                Quarter 2
              </Radio>
              <Radio style={radioStyle} value={'Quarter 3'}>
                Quarter 3
              </Radio>
              <Radio style={radioStyle} value={'Quarter 4'}>
                Quarter 4
              </Radio>

              {this.props.menuName != 'gst_json' && (
                <Radio style={radioStyle} value={'This Year'}>
                  This Year
                </Radio>
              )}
              {this.props.menuName != 'gst_json' && (
                <Radio style={radioStyle} value={'FY 22'}>
                  FY 22
                </Radio>
              )}
              {this.props.menuName != 'gst_json' && (
                <Radio style={radioStyle} value={'FY 23'}>
                  FY 23
                </Radio>
              )}
              <Radio style={radioStyle} value={'custom'}>
                Custom
              </Radio>
              {this.state.value == 'custom' && (
                <RangePicker
                  ranges={
                    this.props?.country_info?.[this.props?.user?.selectedCompany?.country_code]
                      ?.date_ranges
                  }
                  style={{
                    left: '25px',
                    borderRadius: '5px',
                    marginTop: '0.8rem',
                    cursor: 'pointer',
                  }}
                  onChange={this.onRangeChange}
                />
              )}
            </Radio.Group>

            {/* GSTR 1 OPTIONS */}
            <div className="mt-3">
              {this.props.menuName == 'gst_json' && (
                <>
                  <label className="font-weight-bold mt-3">
                    Optional <NewBadge />
                  </label>
                  <div>
                    {' '}
                    <span className="text-gray-300 font-size-12">
                      Select the options below to include the respective data in the GSTR-1 JSON
                    </span>
                  </div>

                  {this.state.is_quarter && (
                    <InfoComponent
                      className="mr-2 mb-4 w-full font-size-12"
                      type="warning"
                      title=""
                      description={
                        <span>
                          Only B2B and CDNR invoices will be included in the JSON for quarterly
                          filing.
                        </span>
                      }
                    />
                  )}
                  <div>
                    <Checkbox
                      onChange={e => this.setState({ gstr1_hsn: e.target.checked })}
                      style={{ marginTop: '1rem' }}
                      disabled={this.state.is_quarter}
                    />
                    <span
                      className=" text-gray-500 font-weight-bold"
                      style={{ marginLeft: '0.5rem' }}
                    >
                      Add HSN Summary in GSTR-1 JSON
                    </span>
                  </div>
                  <div>
                    <Checkbox
                      onChange={e => this.setState({ gstr1_cdnr: e.target.checked })}
                      style={{ marginTop: '1rem' }}
                    />
                    <span
                      className=" text-gray-500 font-weight-bold"
                      style={{ marginLeft: '0.5rem' }}
                    >
                      Add Credit/Debit Notes in GSTR-1 JSON
                    </span>
                  </div>
                  <div>
                    <Checkbox
                      onChange={e => this.setState({ gstr1_eco: e.target.checked })}
                      style={{ marginTop: '1rem' }}
                      disabled={this.state.is_quarter}
                    />
                    <span
                      className=" text-gray-500 font-weight-bold"
                      style={{ marginLeft: '0.5rem' }}
                    >
                      Add E-Commerce in GSTR-1 JSON
                      <Tooltip title="Show up in the JSON if there are any e-commerce customers.">
                        <i className="fa fa-info-circle ml-1 text-gray-300"></i>
                      </Tooltip>
                    </span>
                  </div>

                  <div>
                    <Checkbox
                      onChange={e => this.setState({ gstr1_summary: e.target.checked })}
                      style={{ marginTop: '1rem' }}
                      disabled={this.state.is_quarter}
                    />
                    <span
                      className=" text-gray-500 font-weight-bold"
                      style={{ marginLeft: '0.5rem' }}
                    >
                      Add Documents Summary in GSTR-1 JSON
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal>

        <Modal
          title="Error in generating json"
          open={this.state.error_modal}
          width={800}
          onCancel={() => this.setState({ error_modal: false, flag: false })}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          zIndex={9999}
          footer={[
            <div className="mt-3">
              <Button key="back" onClick={() => this.setState({ error_modal: false, flag: false })}>
                Cancel
              </Button>
              <Button
                key="submit"
                type="danger-3"
                danger
                onClick={() =>
                  this.setState(
                    { error_modal: false, flag: true, loading: true, downloadOverride: true },
                    () => {
                      this.handleDownload()
                    },
                  )
                }
                loading={this.state.loading}
              >
                Continue Download
              </Button>
            </div>,
          ]}
          id="style-9"
        >
          <div
            className="text-danger pl-3"
            style={{
              overflowY: 'auto',
              overflowX: 'hidden',
              height: '550px',
              paddingTop: '0',
              paddingRight: '10px',
            }}
          >
            <div className="mt-3">
              {/* <span
                className="text-danger font-weight-bold p-2"
                style={{
                  border: '1px solid #ff4d4f',
                  borderRadius: '5px',
                }}
              > */}
              <Alert
                message={
                  //  add notes
                  <div>
                    <div className="font-weight-bold">Notes:-</div>
                    <div className="mt-2">
                      <ol>
                        <li>
                          You can download the JSON with the errors. But the JSON might not be
                          accepted by the GST portal.
                        </li>
                        <li>If unit is not valid those will be changed to OTH.</li>
                      </ol>
                    </div>
                  </div>
                }
                type="error"
              />

              {/* </span> */}
            </div>
            <div className="mt-3">
              {this.state.error_data &&
                Object.keys(this.state.error_data).map((key, index) => (
                  <>
                    <div className="mt-3 font-weight-bold ">{key}</div>
                    {this.state.error_data[key].map((item, index) => {
                      return (
                        <div
                          className="mt-2 text-black"
                          dangerouslySetInnerHTML={{ __html: item }}
                        ></div>
                      )
                    })}
                  </>
                ))}
            </div>
          </div>
        </Modal>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    country_info: state.countries.info,
  }
}

export default connect(mapStateToProps)(EnhancedExport)
