import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Descriptions,
  Divider,
  Input,
  Modal,
  Select,
  Switch,
  message,
} from 'antd'
import AddressForm from 'components/forms/addressForm'
import CompanyAddressForm from 'components/forms/companyAddressForm'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import EditCompany from 'components/forms/editCompany'
import { EnhancedNICCreds } from 'components/forms/nicCreds'
import { Loader } from 'components/loaders'
import { connect } from 'react-redux'
import UpgradeWithCountButton from 'components/other/UpgradeWithCountButton'
import ProductsList from 'components/productslist'
import store from 'store'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { isNil } from 'components/other/loadashUtils'
import React, { Component } from 'react'
import { getAPI, getAPIData } from 'services/jwt'
import { EWAYBILL_ERRORS } from 'utils/constants'
import { withRouter } from 'react-router-dom'
import ErrorItem from './ErrorItem'
import { emphasizeSection } from 'utils/utilFunctions'
import { FreeEwaybillLimit } from 'components/other/utility'
import { formatAddress, renderAddress } from 'pages/doc/create/utils'

dayjs.extend(utc)

const { Option } = Select

interface CreateEwayBillProps {
  visible: boolean
  closeModal: () => void
  save: () => void
  loading: boolean
  loadingSave: boolean
  data: any
  dataSource: any
  handleChange: (e: any, key: string) => void
  handleCheckboxChange: (e: any, key: string) => void
  handleEInvoice: (e: any) => void
  getEwayBillData: () => void
  onTransporterDateChange: (date: Dayjs, string: string | string[]) => void
  supplyType: any
  subSupplyType: any
  selectedSupplyType: string
  selectedSubSupplyType: string
  transaction_types: any
  selectedTransactionType: string
  recommendedTransactionType: string
  isPartAChecked: boolean
  isPartBChecked: boolean
  transporterGSTIN: string
  transporterName: string
  vehicleNumber: string
  distance: string
  approx_distance: string
  transportMode: any
  selectedTransportMode: string
  transDocNo: string
  transDocDate: string
  extraDiscount: any
  document_custom_additional_charges: any
  is_einvoice: number
  show_ewaybill_header: boolean
  edit: boolean
  document_company_shipping_address: any
  document_customer_shipping_address: any
  dispatch: any
  all_doc_counts: any
  vehicle_types: any
  vehicleType: string
  permissions: any
  is_shopify: boolean
}

class CreateEwayBill extends Component<CreateEwayBillProps> {
  dateFormat = 'DD/MM/YYYY'
  state: any = {}
  shippingAddressRef = React.createRef<any>()
  nic_creds: any = null
  EditCompanyForm: any = null
  addressFormRef = React.createRef<any>()
  subscriptionPlanRef = React.createRef()
  constructor(props: any) {
    super(props)
    this.state = {
      loading: false,
      visible: false,
      type: 'to',
      doc_count: -1,
      address_loading: false,
      sub_supply_type: '1',
      selectedAddress: {
        to_details: { line1: '', line2: '', city: '', state: '', country: '', pincode: '' },
        from_details: {
          line1: '',
          line2: '',
          city: '',
          state: '',
          country: '',
          pincode: '',
        },
      },
      document_type: 'invoice',
      transporter_ids: [],
      vehicle_nos: [],
      selectedVehicleNo: undefined,
      isEdit: false,
      searchValue: '',
      isAddressDropDownOpen: false,
      isDropDownPenVisible: false,
      isDropDownAddressOpen: false,
      isTransporterDropDownOpen: false,
    }
  }

  async componentDidMount() {
    try {
      const data = await getAPIData('ewaybills', 'get_transporter_ids')
      if (data?.success) {
        this.setState({
          transporter_ids: data.data,
          vehicle_nos: data.vehicle_nos,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  onAddressChange = async (addr_id: number, _type: any = -1) => {
    this.setState({
      address_loading: true,
    })
    const req = {
      document_type: this.props.data.invoice_details.document_type,
      doc_count: this.props.data.invoice_details.doc_count,
      type: _type != -1 ? _type : this.state.type,
      addr_id: addr_id,
    }

    const data = await getAPI('v2/doc', 'update_document_shipping_address', req)
    if (data?.success) {
      message.loading("Please wait while we're updating the address")
      await this.props.getEwayBillData()

      this.setState({
        address_loading: false,
        customer_shipping_addr_id: _type == 'to' ? addr_id : this.state.customer_shipping_addr_id,
        company_shipping_addr_id: _type == 'from' ? addr_id : this.state.company_shipping_addr_id,
      })
      message.success('Address Updated Successfully')
    } else {
      message.error(data?.message ?? 'something went wrong!')
      this.setState({
        address_loading: false,
      })
    }
  }

  verifyHSNCodes = async () => {
    //check hsn codes valid or not
    const req = {
      items: this.props.dataSource,
    }
    const valid = await getAPI('ewaybills', 'check_hsn_codes', req)
    if (valid && !valid.valid) {
      message.warning(valid.message + '!! It may give error in Eway Bill Generation')
    } else {
      message.success('HSN Codes are valid')
    }
  }

  onChangeTransporterId = (value: any) => {
    this.setState({ isTransporterDropDownOpen: false })
    // If values is Empty array -> empty the transporter details
    if (!value.length) {
      this.props.handleChange('', 'transporter_name')
      this.props.handleChange('', 'transporter_gstin')
      return
    }

    let transporter_id = value[value.length - 1]?.value

    transporter_id = transporter_id.toUpperCase()

    if (transporter_id.length > 15) {
      this.props.handleChange('', 'transporter_name')
      message.error('Transporter ID should be less than or equal to 15 characters')
      return
    }

    const setTransporterDetails = async (transporter_id: string) => {
      const transporter = this.state.transporter_ids.find(
        (item: { name: string }) => item.name === transporter_id,
      )
      if (transporter) {
        const transporter_name = transporter.transporter_name
        if (transporter_name) {
          this.props.handleChange(transporter_name, 'transporter_name')
        } else {
          this.props.handleChange('', 'transporter_name')
        }
      } else {
        this.props.handleChange('', 'transporter_name')
      }
    }
    setTransporterDetails(transporter_id)
    this.props.handleChange(transporter_id, 'transporter_gstin')
  }

  async fetchTransporterName(transporter_id: string) {
    try {
      var req = {
        transporter_no: transporter_id,
      }
      const exists = this.state.transporter_ids.some(
        (item: { name: string }) => item.name === transporter_id,
      )

      if (!exists) {
        var data = await getAPI('ewaybills', 'fetch_transporter_details', req)
        if (data && data.success && data.trade_name) {
          // Check if the transporter ID already exists in the transporter_ids array
          this.setState((prevState: any) => ({
            transporter_ids: [
              ...prevState.transporter_ids,
              { name: transporter_id, transporter_name: data.trade_name },
            ],
          }))
        } else {
          this.props.handleChange('', 'transporter_name')
        }
      }
    } catch (error) {
      message.error('Failed to get transporter details')
    }
  }

  handleSearch = (value: any) => {
    this.setState({ searchValue: value })
    if (value.length === 15) {
      this.fetchTransporterName(value)
    }
  }

  onCloseEditAddress = () => {
    this.setState({
      isEdit: false,
    })
  }

  render() {
    const FREE_USER_EWAYBILL_LIMIT = FreeEwaybillLimit(
      this.props?.permissions?.company_details?.company_id,
    )

    const noOfEwaybillsLeft = Math.max(
      FREE_USER_EWAYBILL_LIMIT - this.props.all_doc_counts?.eway_bill,
      0,
    )

    const issuesExist =
      this.props.dataSource?.some((item: any) => !item.hsn_code) ||
      this.props.document_company_shipping_address?.length === 0 ||
      (this.props.document_company_shipping_address?.length > 0 &&
        this.props.document_company_shipping_address?.[0]?.pincode == '') ||
      (!this.props.transporterGSTIN && !this.props.vehicleNumber)

    const fromAddress = () => (
      <Select
        id="dispatch_from_address"
        className="dispatch_from_address"
        suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
        placeholder={'Select address'}
        optionFilterProp="children"
        style={{ minWidth: '100%', maxWidth: 300 }}
        listHeight={200}
        dropdownStyle={{ minWidth: '450px', maxWidth: '450px' }}
        onChange={e => {
          if (e == undefined) {
            e = -1
          }
          if (!isNil(e)) {
            this.setState(this.onAddressChange(e, 'from'))
          }
        }}
        allowClear={this.props.is_einvoice == 1}
        value={
          this.props.document_company_shipping_address.length > 0
            ? this.props.document_company_shipping_address[0].addr_id
            : undefined
        }
        filterOption={(input: any, option: any) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        open={this.state.isDropDownPenVisible}
        onDropdownVisibleChange={() => {
          this.setState({ isDropDownPenVisible: !this.state.isDropDownPenVisible })
        }}
        labelRender={() => {
          const addresses = this.props.data.invoice_details.from_details.shipping_addresses
          const addr_id =
            this.props.document_company_shipping_address.length > 0
              ? this.props.document_company_shipping_address[0].addr_id
              : undefined

          const selectedAddress = addresses.find((address: any) => address.addr_id === addr_id)

          if (selectedAddress) {
            return formatAddress(selectedAddress).join(', ')
          } else {
            return null
          }
        }}
        dropdownRender={menu => (
          <>
            {menu}
            <div className="bg-gray-50">
              <Button
                block
                type="link-2"
                className="font-weight-bolder text-left my-2 arrow-transition"
                onClick={e => {
                  e.stopPropagation()
                  this.addressFormRef?.current?.showAddressDrawer('shipping', -1, { addr_id: -1 })
                }}
              >
                <i className="fa-solid fa-circle-plus mr-2"></i>
                Add Company Shipping address
                <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
              </Button>
            </div>
          </>
        )}
      >
        {this.props.data.invoice_details.from_details.shipping_addresses?.length > 0 && (
          <>
            {this.props.data.invoice_details.from_details.shipping_addresses.map(
              (item: any, i: number) =>
                item.is_delete == 0 && (
                  <Option key={i} value={item.addr_id} className="relative group">
                    {renderAddress(
                      item,
                      this.props.document_company_shipping_address.length > 0
                        ? this.props.document_company_shipping_address[0].addr_id
                        : undefined,
                    )}
                    {this.state.isDropDownPenVisible &&
                      item.addr_id ==
                        (this.props.document_company_shipping_address.length > 0
                          ? this.props.document_company_shipping_address[0].addr_id
                          : undefined) && (
                        <div className="absolute right-2 bottom-2">
                          <button
                            className="cursor-pointer bg-white border border-gray-200 px-3 py-1 rounded-lg font-semibold  hover:bg-blue-50 hover:border-blue-400 hover:text-blue-500"
                            onClick={e => {
                              e.stopPropagation()
                              this.addressFormRef?.current?.showAddressDrawer(
                                'shipping',
                                item.addr_id,
                                item,
                              )
                            }}
                          >
                            Edit
                          </button>
                        </div>
                      )}
                  </Option>
                ),
            )}
          </>
        )}
      </Select>
    )

    const toAddress = () => {
      // Edge Case: The user created an invoice with an address that was later deleted.
      // We will show the address but disbale the dropdown.

      return (
        <Select
          suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
          disabled={this.props.is_shopify}
          placeholder={'Select address'}
          optionFilterProp="children"
          style={{ minWidth: '100%', maxWidth: 300 }}
          listHeight={200}
          dropdownStyle={{ minWidth: '450px', maxWidth: '450px' }}
          allowClear={this.props.is_einvoice == 1}
          onChange={e => {
            this.setState({ isAddressDropDownOpen: false })
            if (e == undefined) {
              //@ts-ignore
              e = -1
            }
            if (!isNil(e)) {
              //@ts-ignore
              this.setState(this.onAddressChange(e, 'to'))
            }
          }}
          labelRender={props => {
            const item = this.props.document_customer_shipping_address.find(
              (item: any) => item.addr_id === props.value,
            )

            if (item) {
              return formatAddress({ ...item }).join(', ')
            } else {
              return null
            }
          }}
          value={
            this.props.document_customer_shipping_address.length > 0
              ? {
                  value: this.props.document_customer_shipping_address[0].addr_id,
                  label: (
                    <span>
                      {this.props.document_customer_shipping_address[0]?.line1},
                      {this.props.document_customer_shipping_address[0]?.line2},
                      {this.props.document_customer_shipping_address[0]?.city},
                      {this.props.document_customer_shipping_address[0]?.state},
                      {this.props.document_customer_shipping_address[0]?.pincode}
                    </span>
                  ),
                }
              : undefined
          }
          filterOption={(input: string, option: any) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          open={this.state.isDropDownAddressOpen}
          onDropdownVisibleChange={() => {
            this.setState({ isDropDownAddressOpen: !this.state.isDropDownAddressOpen })
          }}
          dropdownRender={menu => (
            <>
              {menu}
              <div className="bg-gray-50">
                <Button
                  block
                  type="link-2"
                  className="font-weight-bolder text-left my-2 arrow-transition"
                  onClick={e => {
                    e.stopPropagation()
                    this.shippingAddressRef?.current?.showAddressDrawer(
                      'shipping',
                      {
                        addr_id: -1,
                      },
                      {
                        party_id: this.props.data.invoice_details.party_id,
                        party_type: this.props.data.invoice_details.party_type,
                        is_party_edit: true,
                      },
                    )
                  }}
                >
                  <i className="fa-solid fa-circle-plus mr-2"></i>
                  Add Shipping address
                  <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                </Button>
              </div>
            </>
          )}
        >
          {this.props.data.invoice_details.to_details.shipping_address?.length > 0 && (
            <>
              {this.props.data.invoice_details.to_details.shipping_address.map(
                (item: any, i: number) =>
                  item.is_delete == 0 ? (
                    <Option key={i} value={item.addr_id} className="relative group">
                      {renderAddress(
                        item,
                        this.props.document_customer_shipping_address.length > 0
                          ? this.props.document_customer_shipping_address[0].addr_id
                          : undefined,
                      )}
                      {this.state.isDropDownAddressOpen &&
                        item.addr_id ==
                          (this.props.document_customer_shipping_address.length > 0
                            ? this.props.document_customer_shipping_address[0].addr_id
                            : undefined) && (
                          <button
                            className="absolute right-2 bottom-2 cursor-pointer bg-white border border-gray-200 px-3 py-1 rounded-lg font-semibold  hover:bg-blue-50 hover:border-blue-400 hover:text-blue-500"
                            onClick={e => {
                              e.stopPropagation()
                              this.shippingAddressRef?.current?.showAddressDrawer(
                                'shipping',
                                item,
                                {
                                  party_id: this.props.data.invoice_details.party_id,
                                  party_type: this.props.data.invoice_details.party_type,
                                  is_party_edit: true,
                                },
                              )
                            }}
                          >
                            Edit
                          </button>
                        )}
                    </Option>
                  ) : null,
              )}
            </>
          )}
        </Select>
      )
    }

    const Notes = (
      <div className="mt-4">
        <h6 className="font-weight-bold font-size-16 mb-2 text-danger">* Notes</h6>
        <ul className="text-gray-700 pl-3">
          <li>
            <strong>HSN Codes</strong> are mandatory for all products in EwayBills.
          </li>
          <li>
            If you are getting <strong>Invalid HSN Code</strong> error, see if the HSN code exists
            in the GST Database.{' '}
            <a
              href="https://services.gst.gov.in/services/searchhsnsac"
              target="_blank"
              className="font-weight-bold"
            >
              Click here to Check HSN Codes.
            </a>
          </li>
          <li>
            <strong>Invoice number</strong> should be less than <strong>16 characters</strong>.
          </li>
          <li>
            From and To <strong>Pincodes</strong> are mandatory.
          </li>
          <li>
            If you do not have <strong>PART-B details</strong> (like Vehicle Number & mode of
            transportation), only enter <strong>TRANSPORTER ID</strong> and nothing else.
          </li>
          <li>
            Vehicle Number should be in <strong>KA99MH9999</strong> format.
          </li>
          <li>
            If you are getting <strong>Invalid Distance</strong> error, please calculate the exact
            distance by going to this{' '}
            <a
              href="https://ewaybillgst.gov.in/Others/P2PDistance.aspx"
              target="_blank"
              className="font-weight-bold"
            >
              Link
            </a>
          </li>
          <li>
            <strong>For Exports</strong> Select/Enter the port shipping address
          </li>
          <li>
            <strong>For Exports</strong> Enter distance upto the port
          </li>
          <li>
            <strong>For Long Form</strong> You can check in the ewaybill portal and you will get{' '}
            <strong>Short Form</strong> after creation in ewaybills section. Both are accepted while
            transporting goods.
          </li>
        </ul>
      </div>
    )

    return (
      <div>
        {' '}
        <Modal
          open={this.props.visible}
          onCancel={this.props.closeModal}
          centered
          width={'95vw'}
          bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          wrapStyle={{ backdropFilter: 'blur(3px)' }}
          title={
            <div className="flex w-full items-center justify-between">
              <div className="flex flex-wrap font-size-16">
                <h4 className="">
                  {!this.props.edit && (
                    <span className="mr-3 font-weight-bold">Create Eway Bill</span>
                  )}
                  {this.props.edit && (
                    <span className="mr-3 font-weight-bold">Update Eway Bill</span>
                  )}
                </h4>
              </div>
              <div className="flex flex-wrap mr-8">
                {store.get('ewaybill') == 1 ? (
                  <Button
                    loading={this.props.loadingSave}
                    onClick={() => this.props.save()}
                    className="font-weight-bold arrow-transition"
                    disabled={!this.props.isPartAChecked && !this.props.isPartBChecked}
                  >
                    <span className="">Create EwayBill</span>
                    <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                  </Button>
                ) : (
                  this.props.all_doc_counts?.eway_bill >= FREE_USER_EWAYBILL_LIMIT / 2 && (
                    <UpgradeWithCountButton
                      noOfDocsLeft={noOfEwaybillsLeft}
                      onUpgradeClick={() =>
                        // @ts-ignore
                        this.subscriptionPlanRef.current?.openModal('eway_bill')
                      }
                      free_limit={FREE_USER_EWAYBILL_LIMIT}
                      type="ewaybill"
                      size="small"
                    />
                  )
                )}
              </div>
            </div>
          }
          footer={
            <>
              <div className="flex justify-between items-center">
                <div className="flex flex-col">
                  <span className="text-danger font-weight-bold font-weight-14 leading-none">
                    Read the notes carefully before creating Eway Bill.
                  </span>
                  <Button
                    onClick={e => this.nic_creds.showDrawerLogin(true)}
                    className="mt-auto mr-5 p-0 text-left font-size-14"
                    size="small"
                    type="link"
                  >
                    <i className="fa-solid fa-triangle-exclamation mr-2"></i>
                    <span className="font-size-14">Getting invalid credentials?</span>
                  </Button>
                </div>
                <div className="text-right">
                  <div className="">
                    <div className="">
                      {store.get('ewaybill') == 1 ||
                      this.props.all_doc_counts?.eway_bill < FREE_USER_EWAYBILL_LIMIT ? (
                        <Button
                          disabled={!this.props.isPartAChecked && !this.props.isPartBChecked}
                          loading={this.props.loadingSave}
                          onClick={() => this.props.save()}
                          type="primary"
                          className="font-weight-bold arrow-transition"
                        >
                          <span className="">Create EwayBill</span>
                          <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
                        </Button>
                      ) : (
                        this.props.all_doc_counts?.eway_bill >= FREE_USER_EWAYBILL_LIMIT && (
                          <UpgradeWithCountButton
                            noOfDocsLeft={noOfEwaybillsLeft}
                            onUpgradeClick={() =>
                              // @ts-ignore
                              this.subscriptionPlanRef.current?.openModal('eway_bill')
                            }
                            free_limit={FREE_USER_EWAYBILL_LIMIT}
                            type="ewaybill"
                            size="small"
                          />
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
          maskClosable={false}
        >
          {this.props.loading && (
            <>
              <Loader visibleText={true} />
              {Notes}
            </>
          )}
          {!this.props.loading && (
            <>
              <div>
                <div className="card">
                  <div className="card-body pb-1">
                    {this.props.show_ewaybill_header && (
                      <div className="mb-2">
                        <span className="font-weight-bold font-size-16 mr-2 align-middle">
                          Create E-Waybill using E-Invoice Details
                        </span>
                        <Switch
                          onChange={this.props.handleEInvoice}
                          checked={this.props.is_einvoice == 1}
                          title="Create E-Waybill With E-Invoice Details"
                        />
                      </div>
                    )}

                    <div className="flex flex-wrap">
                      <div className="lg:w-5/12 md:w-3/6 mb-2 pr-4">
                        <div className="mb-4">
                          {!this.props.is_einvoice && (
                            <Descriptions bordered className="" column={1} size="small">
                              <Descriptions.Item label="Document No." className="font-size-14">
                                {/* <span className="inline-flex"> */}
                                <Input
                                  placeholder="Document No."
                                  value={this.props.data.invoice_details.serial_number}
                                  disabled
                                  className="w-full"
                                />
                                {/* </span> */}
                              </Descriptions.Item>
                              <Descriptions.Item label="Document Date" className="font-size-14">
                                <DatePicker
                                  style={{
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    width: '100%',
                                  }}
                                  allowClear={false}
                                  format={this.dateFormat}
                                  value={dayjs(this.props.data.invoice_date, this.dateFormat)}
                                  disabled
                                />
                              </Descriptions.Item>
                              <Descriptions.Item label="Supply Type" className="font-size-14">
                                {this.props.supplyType.length && (
                                  <Select
                                    suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                                    defaultValue={this.props.supplyType[0]['supply_type']}
                                    value={this.props.selectedSupplyType}
                                    style={{ width: '100%' }}
                                    onChange={e => {
                                      this.props.handleChange(e, 'supply_type')
                                    }}
                                    disabled={
                                      this.props.data.invoice_details.document_type !=
                                      'delivery_challan'
                                    }
                                  >
                                    {this.props.supplyType.map((item: any, i: number) => (
                                      <Option key={i} value={item.value}>
                                        {item.supply_type}
                                      </Option>
                                    ))}
                                  </Select>
                                )}
                              </Descriptions.Item>
                              <Descriptions.Item label="Sub Supply" className="font-size-14">
                                {this.props.subSupplyType.length && (
                                  <Select
                                    suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                                    defaultValue={
                                      this.props.data.invoice_details.document_type == 'estimate'
                                        ? this.props.subSupplyType.filter(
                                            (item: any) => item.value == '8',
                                          )[0]['sub_supply_type']
                                        : this.props.data.invoice_details.document_type ==
                                          'delivery_challan'
                                        ? this.props.subSupplyType[11]['sub_supply_type']
                                        : this.props.data.invoice_details.is_export
                                        ? this.props.subSupplyType[1]['sub_supply_type']
                                        : this.props.subSupplyType[0]['sub_supply_type']
                                    }
                                    value={this.props.selectedSubSupplyType}
                                    style={{ width: '100%' }}
                                    onChange={e => {
                                      this.setState({ sub_supply_type: e })
                                      this.props.handleChange(e, 'sub_supply_type')
                                    }}
                                  >
                                    {this.props.subSupplyType.map(
                                      (item: any, i: number) =>
                                        item.sub_supply_type != 'Import' && (
                                          <Option key={i} value={item.value}>
                                            {item.sub_supply_type}
                                          </Option>
                                        ),
                                    )}
                                  </Select>
                                )}
                              </Descriptions.Item>
                              {(this.state.sub_supply_type == '8' ||
                                this.props.data.invoice_details.document_type == 'estimate') && (
                                <Descriptions.Item
                                  label="Sub Supply Type Description"
                                  className="font-size-14"
                                >
                                  <Input
                                    placeholder="Sub Supply Type Description"
                                    onChange={e => {
                                      this.props.handleChange(e, 'sub_supply_type_desc')
                                    }}
                                  />
                                </Descriptions.Item>
                              )}
                              {!this.props.is_einvoice && (
                                <>
                                  <Descriptions.Item
                                    label="Transaction Type"
                                    className="font-size-14"
                                  >
                                    {this.props.transaction_types?.length && (
                                      <Select
                                        className="w-full"
                                        suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                                        onChange={e => {
                                          this.setState({ transaction_type: e })
                                          this.props.handleChange(e, 'transaction_type')
                                        }}
                                        value={this.props.selectedTransactionType}
                                      >
                                        {this.props.transaction_types.map(
                                          (item: any, i: number) => (
                                            <Option key={i} value={item.value}>
                                              {item.transaction_type}{' '}
                                              {item.value ==
                                                this.props.recommendedTransactionType &&
                                              this.props.recommendedTransactionType !=
                                                this.props.selectedTransactionType ? (
                                                <span className="text-white bg-primary p-1 font-weight-bold rounded ml-3 font-size-12 align-middle border-gray-2">
                                                  Recommended
                                                </span>
                                              ) : (
                                                ''
                                              )}
                                            </Option>
                                          ),
                                        )}
                                      </Select>
                                    )}
                                  </Descriptions.Item>
                                  <Descriptions.Item>
                                    <p className="font-size-14 text-danger">
                                      *Important: Considering your data, we advise the specified
                                      transaction type. Any changes to this option can solely be
                                      implemented before generating the Ewaybill under your
                                      discretion.
                                    </p>
                                  </Descriptions.Item>
                                </>
                              )}
                            </Descriptions>
                          )}
                        </div>
                        <div className="mb-4">
                          <Descriptions bordered className="" column={1} size="small">
                            <Descriptions.Item
                              label={
                                <div>
                                  <span>Dispatch From</span>
                                </div>
                              }
                            >
                              {this.props.selectedSupplyType == 'O' ? fromAddress() : toAddress()}
                            </Descriptions.Item>

                            <Descriptions.Item
                              label={
                                <div>
                                  <span>Shipping To</span>
                                </div>
                              }
                            >
                              {this.props.selectedSupplyType == 'O' ? toAddress() : fromAddress()}
                            </Descriptions.Item>
                          </Descriptions>
                        </div>

                        {Notes}
                      </div>

                      <div className="lg:w-7/12 md:w-3/6 mb-0">
                        {issuesExist && (
                          <div className="mb-2 p-3 bg-yellow-50 border-l-4 border-yellow-400 text-yellow-700 rounded shadow-sm">
                            <h3 className="mb-2 flex items-center text-yellow-800 font-semibold text-base gap-2">
                              <i className="fa-solid fa-triangle-exclamation"></i>
                              Issues to Resolve
                            </h3>
                            <div className="flex flex-col gap-2">
                              {/* Missing HSN Code Error */}
                              {this.props.dataSource?.some((item: any) => !item.hsn_code) && (
                                <ErrorItem
                                  iconClass="fa-solid fa-box"
                                  message={EWAYBILL_ERRORS.HSN_MISSING}
                                  onClickHandler={() => {
                                    document.getElementById('products_list')?.scrollIntoView({
                                      behavior: 'smooth',
                                    })
                                    emphasizeSection('missing-hsn', 'blink')
                                  }}
                                />
                              )}

                              {/* Missing Dispatch From Address */}
                              {this.props.document_company_shipping_address?.length === 0 && (
                                <ErrorItem
                                  iconClass="fa-solid fa-map-marker-alt"
                                  message={EWAYBILL_ERRORS.DISPATCH_FROM_MISSING}
                                  onClickHandler={() => {
                                    this.setState({ isAddressDropDownOpen: true })
                                    emphasizeSection('dispatch_from_address', 'blink_border')
                                  }}
                                />
                              )}

                              {/* Missing Pincode in Dispatch From Address */}
                              {this.props.document_company_shipping_address?.length > 0 &&
                                this.props.document_company_shipping_address?.[0]?.pincode ==
                                  '' && (
                                  <ErrorItem
                                    iconClass="fa-solid fa-map-pin"
                                    message={EWAYBILL_ERRORS.PINCODE_MISSING}
                                    onClickHandler={() => {
                                      this.setState({ isAddressDropDownOpen: true })
                                      emphasizeSection('dispatch_from_address', 'blink_border')
                                    }}
                                  />
                                )}

                              {/* neither of transported ID or vehicleNumber selected */}
                              {!this.props.transporterGSTIN && !this.props.vehicleNumber && (
                                <ErrorItem
                                  iconClass="fa-solid fa-truck"
                                  message={EWAYBILL_ERRORS.TRANSPORTER_ID_MISSING}
                                  onClickHandler={() => {
                                    this.setState({ isTransporterDropDownOpen: true })
                                    emphasizeSection('transported_id', 'blink_border')
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        )}
                        <Card className="bg-card-forest border-radius-small">
                          {this.state.section != '3' && (
                            <div>
                              <Checkbox
                                checked={this.props.isPartAChecked}
                                onChange={e => this.props.handleCheckboxChange(e, 'isPartAChecked')}
                              >
                                <h6 className="font-size-14 text-gray-400">PART-A</h6>
                              </Checkbox>
                              <div className="flex flex-wrap mb-4">
                                <div className="w-1/2 pr-4">
                                  <h6>Transporter ID</h6>
                                  <span className="inline-flex w-full">
                                    <Select
                                      id="transported_id"
                                      suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                                      disabled={!this.props.isPartAChecked}
                                      mode="tags"
                                      className="transported_id w-full"
                                      onChange={this.onChangeTransporterId}
                                      value={
                                        this.props.transporterGSTIN
                                          ? [
                                              {
                                                key: this.props.transporterGSTIN,
                                                label: this.props.transporterGSTIN,
                                              },
                                            ]
                                          : []
                                      }
                                      open={this.state.isTransporterDropDownOpen}
                                      onDropdownVisibleChange={open => {
                                        this.setState({ isTransporterDropDownOpen: open })
                                      }}
                                      placeholder={
                                        <>
                                          <span className="text-gray-400">
                                            Enter Transporter ID or Select from Below
                                          </span>
                                        </>
                                      }
                                      maxTagCount={1}
                                      labelInValue
                                      onSearch={this.handleSearch}
                                      optionFilterProp="children"
                                      filterOption={(input: string, option: any) => {
                                        const childrenArray = option?.children
                                        const childrenText = Array.isArray(childrenArray)
                                          ? childrenArray[0]
                                          : childrenArray
                                        return childrenText
                                          ?.toLowerCase()
                                          ?.includes(input.toLowerCase())
                                      }}
                                    >
                                      {this.state.transporter_ids.map((item: any) => (
                                        <Option key={item.name} value={item.name}>
                                          {item.name}
                                          {item.transporter_name
                                            ? ` - ${item.transporter_name}`
                                            : null}
                                        </Option>
                                      ))}
                                    </Select>
                                  </span>
                                  {!this.props.is_einvoice && (
                                    <p className="text-gray-500 font-size-12 ml-1 mb-0">
                                      If you do not have PART-B (Vehicle Number / Mode of
                                      transportation), fill only Transporter ID and create the
                                      Eway-Bill.
                                    </p>
                                  )}
                                </div>

                                <div className="w-1/2">
                                  <h6 className="text-gray-500">Transporter Name (Optional)</h6>
                                  <span className="inline-flex" style={{ width: '100%' }}>
                                    <Input
                                      disabled={!this.props.isPartAChecked}
                                      placeholder="Name of Transporter"
                                      value={this.props.transporterName}
                                      onChange={e => {
                                        this.props.handleChange(e, 'transporter_name')
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                              <Divider />
                            </div>
                          )}
                          {this.state.section != '2' && (
                            <div>
                              <Checkbox
                                checked={this.props.isPartBChecked}
                                onChange={e => this.props.handleCheckboxChange(e, 'isPartBChecked')}
                              >
                                <h6 className="font-size-14 text-gray-400">PART-B</h6>
                              </Checkbox>
                              {!this.props.is_einvoice && (
                                <p className="text-gray-500 font-size-12 ml-1 mb-2">
                                  If you do not have PART-A (Transporter ID), Fill the below details
                                  and create the Eway-Bill.
                                </p>
                              )}

                              <div className="flex flex-wrap mb-4">
                                <div className="w-1/2 mb-4 pr-4">
                                  <h6>Vehicle Number</h6>
                                  <span className="inline-flex w-full">
                                    <Select
                                      suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                                      disabled={!this.props.isPartBChecked}
                                      className="w-full"
                                      mode="tags"
                                      onChange={e => {
                                        let vehicle_no = e[e.length - 1]
                                        this.setState({
                                          selectedVehicleNo: vehicle_no,
                                        })
                                        this.props.handleChange(vehicle_no, 'vehicle_number')
                                      }}
                                      value={this.props.vehicleNumber}
                                      placeholder={
                                        <>
                                          <span className="text-gray-400">
                                            Enter Vehicle Number like KA99MH9999 or Select from
                                            Below
                                          </span>
                                        </>
                                      }
                                      // value={this.state.selectedVehicleNo}
                                      maxTagCount={1}
                                      optionFilterProp="children"
                                      filterOption={(input: string, option: any) =>
                                        option.props.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {this.state.vehicle_nos.map((item: any, i: number) => (
                                        <Option key={i} value={item.name}>
                                          {item.name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </span>
                                </div>

                                <div className="w-1/2 mb-4">
                                  <h6>
                                    Approx. Distance in KMS
                                    <a
                                      href="https://ewaybillgst.gov.in/Others/P2PDistance.aspx"
                                      target="_blank"
                                      className="ml-2 font-size-12 font-weight-bold"
                                      style={{ color: '#276ef1' }}
                                    >
                                      Use Distance Calculator
                                    </a>
                                  </h6>
                                  <span className="w-full">
                                    <Input
                                      disabled={!this.props.isPartBChecked}
                                      placeholder={this.props.approx_distance + ' KMS in approx. '}
                                      type={'number'}
                                      value={this.props.distance}
                                      onChange={e => {
                                        this.props.handleChange(e, 'distance')
                                      }}
                                    />
                                  </span>
                                  <p className="text-gray-500 font-size-12 ml-1 mb-0">
                                    Leave blank for auto calculation by govt portal.
                                  </p>
                                </div>

                                <div className="w-1/2 mb-4 pr-4">
                                  <div className="flex align-center justify-between">
                                    <h6 className="text-gray-500">Transport Mode</h6>
                                    <div className="block select-borderless ml-2">
                                      <Select
                                        suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                                        size="small"
                                        dropdownStyle={{ minWidth: '300px' }}
                                        className="float-right"
                                        onChange={e => {
                                          this.props.handleChange(e, 'vehicle_type')
                                        }}
                                        disabled={
                                          this.props.selectedTransportMode != '1' ||
                                          !this.props.isPartBChecked
                                        }
                                        value={this.props.vehicleType}
                                        placeholder={
                                          <>
                                            <span className="text-gray-400">
                                              Select Vehicle Type
                                            </span>
                                          </>
                                        }
                                        optionFilterProp="children"
                                        filterOption={(input: string, option: any) =>
                                          option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                      >
                                        {this.props.vehicle_types?.map((item: any, i: number) => (
                                          <Option key={i} value={item.value}>
                                            {item.type}
                                          </Option>
                                        ))}
                                      </Select>
                                    </div>
                                  </div>
                                  {this.props.transportMode.length && (
                                    <Select
                                      suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                                      disabled={!this.props.isPartBChecked}
                                      defaultValue={this.props.transportMode[0]['mode']}
                                      style={{ width: '100%' }}
                                      onChange={e => {
                                        this.props.handleChange(e, 'transport_mode')
                                      }}
                                      value={
                                        this.props.selectedTransportMode != ''
                                          ? this.props.selectedTransportMode
                                          : undefined
                                      }
                                      placeholder="Select Transport Mode"
                                    >
                                      {this.props.transportMode.map((item: any, i: number) => (
                                        <Option key={i} value={item.value}>
                                          {item.mode}
                                        </Option>
                                      ))}
                                    </Select>
                                  )}
                                </div>

                                <div className="w-1/2 mb-4">
                                  <h6 className="text-gray-500">Transporter Doc No. (Optional)</h6>
                                  <span className="inline-flex" style={{ width: '100%' }}>
                                    <Input
                                      disabled={!this.props.isPartBChecked}
                                      placeholder="Ex. 12345678 "
                                      value={this.props.transDocNo}
                                      onChange={e => {
                                        this.props.handleChange(e, 'transporter_doc_no')
                                      }}
                                    />
                                  </span>
                                </div>

                                <div className="w-1/2 mb-4 pr-4">
                                  <h6 className="text-gray-500">Transporter Doc Date</h6>
                                  <DatePicker
                                    style={{
                                      borderRadius: '5px',
                                      cursor: 'pointer',
                                      width: '100%',
                                    }}
                                    allowClear={false}
                                    format={this.dateFormat}
                                    value={dayjs(this.props.transDocDate, this.dateFormat)}
                                    onChange={this.props.onTransporterDateChange}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="flex flex-wrap">
                            <div className="w-full" id="products_list">
                              <a
                                className="text-primary font-weight-bold"
                                onClick={() => {
                                  message.loading({
                                    content: 'Verifying HSN Codes...',
                                    duration: 0,
                                  })
                                  this.verifyHSNCodes()
                                }}
                              >
                                Verify HSN Codes
                              </a>
                              <ProductsList
                                data={this.props.dataSource}
                                extraDiscount={this.props.extraDiscount}
                                document_custom_additional_charges={
                                  this.props.document_custom_additional_charges
                                }
                                document={this.props.data.invoice_details}
                                refresh={() => this.props.getEwayBillData()}
                                checkForHSN
                              />
                            </div>
                          </div>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <CompanyAddressForm
                // @ts-ignore
                onFinish={async (values, addr_id, addressType) => {
                  await this.EditCompanyForm?._onAddressFinish(values, addr_id, addressType)
                  this.props.getEwayBillData()
                }}
                ref={this.addressFormRef}
              />
              <SubscriptionPlanModal ref={this.subscriptionPlanRef} />
            </>
          )}
        </Modal>
        <EnhancedNICCreds onRef={(ref: any) => (this.nic_creds = ref)} />
        <EditCompany
          onRef={(ref: any) => (this.EditCompanyForm = ref)}
          dispatch={this.props.dispatch}
          onAPICalled={() => this.props.getEwayBillData()}
        />
        <AddressForm ref={this.shippingAddressRef} onFinish={() => this.props.getEwayBillData()} />
      </div>
    )
  }
}

function mapStateToProps(state: any, ownProps: any) {
  return {
    all_doc_counts: state.document.all_doc_counts,
    permissions: state.permissions,
  }
}

export default connect(mapStateToProps)(withRouter(CreateEwayBill as any))
