import React from 'react'
import { Select } from 'antd'
import { FilterFunc } from 'rc-select/lib/Select'

export interface SearchableSelectOption<T> {
  label: React.ReactNode
  value: string
  data: T
  id: number
  key: number
}

export interface SearchableSelectProps<T> {
  dropdownRender?: (menu: React.ReactElement) => React.ReactElement
  className?: string
  selectedOption?: T
  children?: React.ReactNode
  options: SearchableSelectOption<T>[]
  placeholder?: React.ReactNode
  filterOption?: FilterFunc<SearchableSelectOption<T>>
  onChange: (value: string, option: SearchableSelectOption<T> | SearchableSelectOption<T>[]) => void
  formatOption: (option: T) => string
}

export const SearchableSelect = React.forwardRef<
  { resetValue: () => void },
  SearchableSelectProps<any>
>(
  (
    {
      dropdownRender,
      className,
      children,
      options,
      placeholder,
      filterOption,
      onChange,
      selectedOption,
      formatOption,
    },
    ref,
  ) => {
    if (typeof formatOption !== 'function') {
      throw new Error(
        'formatOption must be a stable function. Use useCallback to prevent unnecessary re-renders.',
      )
    }

    const [value, setValue] = React.useState<string | null>(null)

    React.useEffect(() => {
      if (selectedOption) {
        setValue(formatOption(selectedOption))
      } else {
        setValue(null)
      }
    }, [selectedOption, formatOption])

    const resetValue = () => {
      if (options.length > 0) {
        setValue(formatOption(options[0].data))
      }
    }

    React.useImperativeHandle(ref, () => ({
      resetValue,
    }))

    return (
      <Select
        showSearch
        className={className}
        variant="borderless"
        value={value}
        placeholder={placeholder}
        onChange={(value, option) => {
          setValue(value)
          onChange(value, option)
        }}
        labelRender={v => v.value}
        dropdownStyle={{ width: '450px' }}
        style={{ minWidth: '100px', maxWidth: '400px' }}
        allowClear
        filterOption={filterOption}
        dropdownRender={dropdownRender}
        options={options}
      >
        {children}
      </Select>
    )
  },
)
