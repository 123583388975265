import React, { useMemo, useRef, useState, forwardRef, useEffect, useImperativeHandle } from 'react'

import { getAPI } from 'services/jwt'
import { Loader } from 'components/loaders'
import { Button, Modal, Tooltip } from 'antd'
import { FunctionalInvoiceDrawer } from 'components/forms/invoiceDetails'

import AgGridTable from 'components/agGrid/agGridTable'
import InfoComponent from 'components/badges/InfoComponent'

interface ModalHandle {
  showModal: (show: boolean) => void
}

const BulkActionsHistory = forwardRef<ModalHandle, { agTheme: string }>((props, ref) => {
  // region variables

  const [visible, setVisible] = useState(false)
  const [history, setHistory] = useState([])
  const [page, setPage] = useState(0)
  const [num_records, setNumRecords] = useState(10)
  const [loading, setLoading] = useState(true)
  const gridRef = useRef<any | null>()
  const invoiceFormRef = useRef<any | null>()
  const [rowData, setRowData] = useState([])

  const getStatusClass = (data: string) => {
    switch (data) {
      case 'pending':
        return 'warning'
      case 'success':
        return 'success'
      case 'failed':
        return 'danger'
      default:
        return 'danger'
    }
  }
  const [columnDefs, setColumnDefs] = useState([
    {
      title: 'History ID',
      field: 'history_id',
      cellRenderer: 'agGroupCellRenderer',
      hide: true,
    },
    {
      field: 'description',
      cellRenderer: 'agGroupCellRenderer',
    },
    { field: 'category' },
    { field: 'action' },
    {
      field: 'status',
      cellRenderer: (params: any) => {
        return (
          <>
            <Button
              type={getStatusClass(params.value)}
              size="small"
              className="font-weight-bold ml-2"
              onClick={event => {
                event.stopPropagation()
              }}
            >
              {params.value}
            </Button>

            {params.data.link != '' && (
              <Button
                type={'secondary'}
                size="small"
                className="font-weight-bold ml-2"
                onClick={event => {
                  event.stopPropagation()
                  window.open(params.data.link, '_blank')
                }}
              >
                <i className="fa fa-download mr-1" aria-hidden="true"></i>
                Download
              </Button>
            )}
          </>
        )
      },
    },
    { title: 'Record Time', field: 'record_time' },
  ])

  const [docColDefs, setDocCol] = useState([
    {
      headerName: 'Serial No.',
      field: 'name',
      cellRenderer: (params: any) => {
        return (
          <>
            <span
              className="cursor-pointer"
              onClick={event => {
                if (invoiceFormRef.current) {
                  invoiceFormRef.current.showDocumentDrawer(params.data.type, params.data.hash_id)
                }
              }}
            >
              <Tooltip title={'Click here to check the document'}>
                <span>{params.value}</span>
              </Tooltip>
            </span>
          </>
        )
      },
    },
    {
      field: 'description',
    },
    {
      headerName: 'Document type',
      field: 'type',
    },
    {
      headerName: 'Action Requested',
      field: 'action',
    },
    {
      field: 'status',
      cellRenderer: (params: any) => {
        return (
          <Button
            type={getStatusClass(params.value)}
            size="small"
            className="font-weight-bold ml-2"
            onClick={event => {
              event.stopPropagation()
            }}
          >
            {params.value}
          </Button>
        )
      },
    },
    {
      field: 'Actions',
      cellRenderer: (params: any) => {
        return (
          <>
            <Button
              type={'warning'}
              size="small"
              className="font-weight-bold ml-2"
              onClick={event => {
                if (invoiceFormRef.current) {
                  invoiceFormRef.current.showDocumentDrawer(params.data.type, params.data.hash_id)
                }
              }}
            >
              <i className="fa fa-eye mr-1" aria-hidden="true"></i>
              view
            </Button>
            {params.data.link != '' && (
              <Button
                type={'secondary'}
                size="small"
                className="font-weight-bold ml-2"
                onClick={event => {
                  event.stopPropagation()
                  window.open(params.data.link, '_blank')
                }}
              >
                <i className="fa fa-download mr-1" aria-hidden="true"></i>
                Download
              </Button>
            )}
          </>
        )
      },
    },
  ])

  // end region

  const showModal = (show: boolean) => {
    setVisible(show)
  }

  useImperativeHandle(ref, () => ({
    showModal,
  }))

  const callData = async (req: { page?: number; num_records?: number }) => {
    setLoading(true)
    const data = await getAPI('user', 'history', req)
    if (data.success) {
      setHistory(data.records)
      setPage(req.page ?? 0)
      setNumRecords(req.num_records ?? 10)
      setRowData(data.records)
    }
    setLoading(false)

    return { data: data, total: data.length }
  }
  useEffect(() => {
    if (visible) {
      callData({ page, num_records })
    }
  }, [visible])

  const getColumns = (category: string) => {
    console.log('category', category)
    switch (category) {
      case 'documents':
        return docColDefs

      default:
        return [{ field: 'entity_type' }, { field: 'entity_id', minWidth: 150 }]
        break
    }
  }

  const detailCellRendererParams = (params: any) => {
    return {
      detailGridOptions: {
        columnDefs: getColumns(params.data.category),
      },
      getDetailRowData: (params: any) => {
        params.successCallback(params.data.records)
      },
    }
  }

  // const onGridReady = async (params: any) => {
  //   let dataSource = {
  //     getRows: async (params: any) => {
  //       params.api.setGridOption('loading', true)

  //       let filters = params.request.filterModel

  //       let reqObj = {
  //         page: params.request.startRow / (params.request.endRow - params.request.startRow),
  //         num_records: params.request.endRow - params.request.startRow,
  //         sorter: params.request.sortModel[0]?.sort,
  //         sort_type: params.request.sortModel[0]?.colId,
  //       }

  //       let response: any
  //       let data: any[]
  //       let total_rows: number | undefined = undefined

  //       response = await callData(reqObj)

  //       data = response.data
  //       total_rows = response.total

  //       console.log(data)

  //       params.api.setGridOption('loading', false)
  //       setTimeout(() => {
  //         params.success({
  //           rowData: data,
  //           rowCount: total_rows,
  //         })
  //         if (data.length === 0) {
  //           params.api.showNoRowsOverlay()
  //         } else {
  //           params.api.hideOverlay()
  //         }
  //       }, 500)
  //     },
  //   }

  //   params.api.setGridOption('serverSideDatasource', dataSource)
  // }

  const onCloseModel = () => {
    setVisible(false)
    setRowData([])
  }

  return (
    <>
      <Modal
        title="History"
        closable={true}
        onCancel={onCloseModel}
        footer={null}
        open={visible}
        width={'80%'}
        destroyOnClose={true}
      >
        <div className="mb-4">
          <InfoComponent
            description="This data contains actions done in the past 30 days from today"
            type="info"
          />
        </div>

        <AgGridTable
          loading={loading}
          gridKey={history}
          onReady={async (event: any) => {
            // onGridReady(event)
          }}
          dataSource={rowData}
          columns={columnDefs}
          sortable={false}
          ref={gridRef}
          masterDetail={true}
          detailRowAutoHeight={true}
          keepDetailRows={true}
          detailCellRendererParams={detailCellRendererParams}
        />
      </Modal>
      <FunctionalInvoiceDrawer ref={invoiceFormRef} />
    </>
  )
})

export default BulkActionsHistory
