import { Button, DatePicker, Divider, Menu, Select, Tabs } from 'antd'
import { NewBadge } from 'components/badges/new'
import ProBadge from 'components/badges/pro'
import EnhancedExport from 'components/modal/export'
import { EnhancedSubscriptionModal } from 'components/modal/subscription'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { Component, lazy, Suspense } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import store from 'store'
import DayBook from './DayBook'
import ExpenseReport from './ExpenseReport'
import { getReportList } from './data/reportList'
const { TabPane } = Tabs
import { HIDE_PAYOUTS_COMPANY_ID } from 'utils/constants'
import { getAPI } from 'services/jwt'
import ShareHistory from './ShareHistory'

const { Option } = Select
dayjs.extend(utc)
const { RangePicker } = DatePicker

const urlParams = new URLSearchParams(window.location.search)
var activeTab = urlParams.get('tab')
var activeTabKey = '1'
if (activeTab == 'gstr_1') {
  activeTabKey = '35'
} else if (activeTab == 'profit_loss') {
  activeTabKey = '1'
} else {
  activeTabKey = '1'
}

class ReportsMenu extends Component {
  dateFormat = 'DD-MM-YYYY'
  subscriptionPlanRef = React.createRef()
  constructor(props) {
    super(props)

    this.state = {
      defaultActiveKey: activeTabKey,
      transactions: [],
      num_records: 10,
      page: 0,
      total: 0,
      selectedCustomer: { name: '', id: -1 },
      selectedItem: { name: '', id: -1 },
      autocompleteData: [],
      itemAutoCompleteData: [],
      reportsNew: [],
      report: {
        name: 'Day Book',
        disabled: 0,
        type: 'day_book',
        menu_name: 'day_book',
        index: 0,
        data: {
          product_name: '',
          product_id: -1,
          variant_name: '',
          variant_id: 0,
        },
      },
      dates: store.get('reportsDateRange_' + this.props.company_details.company_id)
        ? store.get('reportsDateRange_' + this.props.company_details.company_id)
        : dayjs('01-01-2021', 'DD-MM-YYYY').format(this.dateFormat) +
          ' - ' +
          dayjs()
            .endOf('month')
            .format(this.dateFormat),
      searchType: 'Product Name',
      search: '',
      download_menu: 'sales_summary',
      openKeys: ['0'],
      selectedKeys: ['0'],
      public_report_data: {
        isPublic: this.props.isPublic,
        ...this.props.report_data,
      },
    }
  }

  changeReportType = async (key, report) => {
    this.setState({ defaultActiveKey: key, report: report })
  }

  componentDidMount() {
    this.setAllReports()

    store.set(
      'reportsDateRange_' + this.props.company_details.company_id,
      dayjs()
        .startOf('year')
        .format(this.dateFormat) +
        ' - ' +
        dayjs()
          .endOf('month')
          .format(this.dateFormat),
    )
    store.set(
      'reportsTooltips',
      store.get('reportsTooltips') != undefined ? store.get('reportsTooltips') : true,
    )
    store.set(
      'reportsSettings',
      store.get('reportsSettings') != undefined ? store.get('reportsSettings') : true,
    )
    store.set(
      'reportsPurchasePriceType',
      store.get('reportsPurchasePriceType') != undefined ||
        store.get('reportsPurchasePriceType') != 'purchase'
        ? store.get('reportsPurchasePriceType')
        : 'current',
    )
  }

  callback = key => {
    window.scrollTo(0, 0)
    this.setState({
      transactions: [],
      total: 0,
      page: 0,
      num_records: 10,
      selectedItem: { name: '', id: -1 },
      selectedCustomer: { name: '', id: -1 },
    })
  }

  setAllReports = () => {
    let reportsNew = getReportList(this.props)
    reportsNew = this.filterReports(reportsNew)

    let currentReport = {}
    if (reportsNew?.[0]?.children) {
      currentReport = reportsNew[0]?.children[0] ?? {}
    } else {
      currentReport = reportsNew[0] ?? {}
    }

    const { public_report_data } = this.state
    if (public_report_data?.isPublic) {
      currentReport.filters = public_report_data?.filters
      currentReport.isPublic = true
      currentReport.new_hash_id = public_report_data?.new_hash_id
    }

    this.setState({ reportsNew, report: currentReport })
  }

  filterReports = reports => {
    const { public_report_data } = this.state
    return reports.reduce((acc, report) => {
      if (!report.hide) {
        const newReport = { ...report }
        if (newReport.children) {
          newReport.children = this.filterReports(newReport.children)
        }

        const hasMatchingChildren = newReport.children && newReport.children.length > 0

        const matchesCurrent =
          !public_report_data.isPublic ||
          (public_report_data.report_type === newReport.type &&
            (!newReport.menu_name || public_report_data.report_name === newReport.menu_name))

        if (matchesCurrent || hasMatchingChildren) {
          acc.push(newReport)
        }
      }
      return acc
    }, [])
  }

  render() {
    const { reportsNew } = this.state

    const PLReport = lazy(() => import('./PLReport'))
    const TransactionReports = lazy(() => import('./TransactionReports'))
    const TransactionWiseItemReports = lazy(() => import('./TransactionWiseItemReports'))
    const StockReports = lazy(() => import('./StockReports'))
    const ItemWiseProfitandLoss = lazy(() => import('./ItemWiseProfitandLoss'))
    const PartyReports = lazy(() => import('./PartyReports'))
    const LedgerReports = lazy(() => import('./LedgerReports'))
    const GSTReport1 = lazy(() => import('./GSTReport1'))
    const GSTReport2 = lazy(() => import('./GSTR2'))
    const HSNSummary = lazy(() => import('./HSNSummary'))
    const TDSReports = lazy(() => import('./TDSReports'))
    const GSTReport3B = lazy(() => import('./GSTReport3'))
    const GSTCMP = lazy(() => import('./GSTCMP'))
    const GSTR4 = lazy(() => import('./GSTR4'))
    const ProfitLossReports = lazy(() => import('./ProfitLossReports'))
    const CommonReports = lazy(() => import('./commonReports'))
    const PaymentReports = lazy(() => import('./paymentReports'))

    return (
      <>
        {this.props.permissions.reports == 1 && (
          <div className={`px-2 py-2 ${this.props.isPublic ? '' : 'height-80vh'}`}>
            {!this.state.public_report_data?.isPublic ? (
              <div className="flex flex-wrap">
                <div className="mr-auto pr-3">
                  <div className="text-nowrap inline-block">
                    <div className="kit__utils__heading">
                      <h3>
                        <span className="mr-3 font-weight-bolder">
                          <FormattedMessage id="reports.heading" />
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap font-size-16">
                  <div className="inline-flex items-center float-right">
                    {/* {this.state.report.type == 'gstr_1' && ( */}
                    {/* )} */}

                    {/* {this.state.report.type == 'transaction_report' &&
                      this.state.report.menu_name == 'sales' && ( */}
                    {/* )} */}
                  </div>
                </div>
                <div className="flex flex-wrap font-size-16">
                  <div className="inline-flex items-center float-right"></div>
                </div>
              </div>
            ) : null}

            <div className="flex report-menu">
              {!this.state.public_report_data?.isPublic ? (
                <div>
                  <Menu
                    defaultOpenKeys={['0']}
                    defaultSelectedKeys={['0']}
                    selectedKeys={this.state.selectedKeys}
                    openKeys={this.state.openKeys}
                    onOpenChange={openKeys => {
                      //only allow one submenu open at a time
                      if (
                        openKeys.length > 0 &&
                        openKeys[openKeys.length - 1] !==
                          this.state.openKeys[this.state.openKeys.length - 1]
                      ) {
                        this.setState({
                          openKeys: [openKeys[openKeys.length - 1]],
                          report: reportsNew[openKeys[openKeys.length - 1]]?.children?.[0],
                          selectedKeys: [
                            String(reportsNew[openKeys[openKeys.length - 1]]?.children[0]?.index),
                          ],
                          showShareHistory: false,
                        })
                      }
                    }}
                    onClick={e => {
                      let index = reportsNew.findIndex(report => report.index == e.key)
                      if (reportsNew[index]?.main_menu) {
                        this.setState({
                          report: reportsNew[index],
                          selectedKeys: [String(reportsNew[index].index)],
                          openKeys: [String(index)],
                          showShareHistory: false,
                        })
                      }
                    }}
                    style={{ width: 200, height: '100%' }}
                    mode="inline"
                  >
                    {reportsNew?.map((report, index) => {
                      return report?.children ? (
                        <>
                          <Menu.SubMenu
                            key={index}
                            title={
                              <>
                                <span
                                  className={
                                    report.disabled
                                      ? `font-size-16 font-weight-bold text-gray-600 px-0 mt-3 ${
                                          report.name == 'Profit & Loss(PL) Reports'
                                            ? 'feature-3'
                                            : ''
                                        }`
                                      : `mt-3  ${
                                          report.name == 'Profit & Loss(PL) Reports'
                                            ? 'feature-3'
                                            : ''
                                        }`
                                  }
                                >
                                  {report.name} <span>{report.new && <NewBadge />} </span>
                                </span>
                              </>
                            }
                            style={{
                              margin: 0,
                            }}
                          >
                            {report.children?.map((submenu, index2) => {
                              return (
                                <Menu.Item
                                  key={submenu.index}
                                  onClick={e => {
                                    if (submenu.name != this.state.report.name) {
                                      this.setState({
                                        report: submenu,
                                        selectedKeys: [String(submenu.index)],
                                        showShareHistory: false,
                                      })
                                    }
                                  }}
                                  title={submenu.name}
                                >
                                  <span>
                                    {submenu.name} <span>{submenu.new && <NewBadge />}</span>
                                  </span>
                                </Menu.Item>
                              )
                            })}
                          </Menu.SubMenu>
                        </>
                      ) : (
                        <Menu.Item
                          key={report.index}
                          onClick={e => {
                            if (report.name != this.state.report.name) {
                              this.setState({
                                report: report,
                                selectedKeys: [String(report.index)],
                                showShareHistory: false,
                              })
                            }
                          }}
                          title={report.name}
                          className="mt-3 px-0 no-submenu"
                        >
                          {report.name} <span>{report.new && <NewBadge />}</span>
                        </Menu.Item>
                      )
                    })}
                    <div className="mt-16">
                      <Divider />
                      <div
                        className={`flex text-[#444950] text-md items-center mt-2 font-semibold hover:cursor-pointer px-1.5 py-1.5 mr-1 rounded-md ${
                          this.state.showShareHistory ? 'text-black bg-[#f5f5f7]' : ''
                        }`}
                        onClick={() => {
                          this.setState({
                            report: {},
                            selectedKeys: [],
                            showShareHistory: true,
                          })
                        }}
                      >
                        Share History <NewBadge />
                      </div>
                    </div>
                  </Menu>
                </div>
              ) : null}

              <div
                style={{
                  width: '100%',
                  padding: this.props.isPublic ? '0px' : '0px 20px',
                  overflow: 'auto',
                  height: this.props.isPublic ? '100%' : 'calc(90vh - 50px)',
                }}
                id="style-9"
              >
                {this.state.report?.disabled != 1 && this.state.report?.type == 'pl_report' && (
                  <Suspense fallback={<div>Loading...</div>}>
                    <PLReport report={this.state.report} {...this.props} />
                  </Suspense>
                )}

                {this.state.report?.disabled != 1 &&
                  this.state.report?.type == 'transaction_report' && (
                    <Suspense fallback={<div>Loading...</div>}>
                      <TransactionReports report={this.state.report} {...this.props} />
                    </Suspense>
                  )}
                {this.state.report?.disabled != 1 && this.state.report?.type == 'expense_report' && (
                  <Suspense fallback={<div>Loading...</div>}>
                    <ExpenseReport report={this.state.report} {...this.props} />
                  </Suspense>
                )}
                {this.state.report?.disabled != 1 && this.state.report?.type == 'income_report' && (
                  <Suspense fallback={<div>Loading...</div>}>
                    <ExpenseReport report={this.state.report} {...this.props} />
                  </Suspense>
                )}

                {this.state.report?.disabled != 1 && this.state.report?.type == 'day_book' && (
                  <Suspense fallback={<div>Loading...</div>}>
                    <DayBook report={this.state.report} {...this.props} />
                  </Suspense>
                )}

                {this.state.report?.disabled != 1 &&
                  this.state.report?.type == 'transaction_wise_item_report' && (
                    <Suspense fallback={<div>Loading...</div>}>
                      <TransactionWiseItemReports report={this.state.report} {...this.props} />
                    </Suspense>
                  )}

                {this.state.report?.disabled != 1 && this.state.report?.type == 'stock_report' && (
                  <Suspense fallback={<div>Loading...</div>}>
                    <StockReports report={this.state.report} {...this.props} />
                  </Suspense>
                )}
                {this.state.report?.disabled != 1 &&
                  this.state.report?.type == 'stock_transactions_report' && (
                    <Suspense fallback={<div>Loading...</div>}>
                      <StockReports report={this.state.report} {...this.props} />
                    </Suspense>
                  )}

                {this.state.report?.disabled != 1 &&
                  this.state.report?.type == 'item_wise_profit_loss' && (
                    <Suspense fallback={<div>Loading...</div>}>
                      <ItemWiseProfitandLoss report={this.state.report} {...this.props} />
                    </Suspense>
                  )}

                {this.state.report?.disabled != 1 &&
                  this.state.report?.type == 'item_wise_profit_loss_purchase_price' && (
                    <Suspense fallback={<div>Loading...</div>}>
                      <ItemWiseProfitandLoss report={this.state.report} {...this.props} />
                    </Suspense>
                  )}
                {this.state.report?.disabled != 1 &&
                  this.state.report?.type == 'category_wise_profit_loss_purchase_price' && (
                    <Suspense fallback={<div>Loading...</div>}>
                      <ItemWiseProfitandLoss report={this.state.report} {...this.props} />
                    </Suspense>
                  )}

                {this.state.report?.disabled != 1 &&
                  this.state.report?.type == 'item_wise_discount' && (
                    <Suspense fallback={<div>Loading...</div>}>
                      <ItemWiseProfitandLoss report={this.state.report} {...this.props} />
                    </Suspense>
                  )}

                {this.state.report?.disabled != 1 && this.state.report?.type == 'stock_value' && (
                  <Suspense fallback={<div>Loading...</div>}>
                    <ItemWiseProfitandLoss
                      report={this.state.report}
                      changeReport={this.changeReportType}
                      {...this.props}
                    />
                  </Suspense>
                )}

                {this.state.report?.disabled != 1 && this.state.report?.type == 'low_stock_report' && (
                  <Suspense fallback={<div>Loading...</div>}>
                    <ItemWiseProfitandLoss
                      report={this.state.report}
                      changeReport={this.changeReportType}
                      {...this.props}
                    />
                  </Suspense>
                )}

                {this.state.report?.disabled != 1 &&
                  this.state.report?.type == 'daily_stock_report' && (
                    <Suspense fallback={<div>Loading...</div>}>
                      <ItemWiseProfitandLoss report={this.state.report} {...this.props} />
                    </Suspense>
                  )}
                {this.state.report?.disabled != 1 && this.state.report?.type == 'party_report' && (
                  <Suspense fallback={<div>Loading...</div>}>
                    <PartyReports report={this.state.report} {...this.props} />
                  </Suspense>
                )}

                {this.state.report?.disabled != 1 && this.state.report?.type == 'ledger' && (
                  <Suspense fallback={<div>Loading...</div>}>
                    <LedgerReports report={this.state.report} {...this.props} />
                  </Suspense>
                )}
                {this.state.report?.disabled != 1 && this.state.report?.type == 'party_wise_items' && (
                  <Suspense fallback={<div>Loading...</div>}>
                    <LedgerReports report={this.state.report} {...this.props} />
                  </Suspense>
                )}

                {this.state.report?.disabled != 1 &&
                  this.state.report?.type == 'party_profit_loss_report' && (
                    <Suspense fallback={<div>Loading...</div>}>
                      <PartyReports report={this.state.report} {...this.props} />
                    </Suspense>
                  )}

                {this.state.report?.disabled != 1 && this.state.report?.type == 'gstr_1' && (
                  <Suspense fallback={<div>Loading...</div>}>
                    <GSTReport1 report={this.state.report} {...this.props} />
                  </Suspense>
                )}

                {this.state.report?.disabled != 1 &&
                  (this.state.report?.type == 'gstr_2' || this.state.report?.type == 'gstr_7') && (
                    <Suspense fallback={<div>Loading...</div>}>
                      <GSTReport2 report={this.state.report} {...this.props} />
                    </Suspense>
                  )}

                {this.state.report?.disabled != 1 && this.state.report?.type == 'hsn_summary' && (
                  <Suspense fallback={<div>Loading...</div>}>
                    <HSNSummary report={this.state.report} {...this.props} />
                  </Suspense>
                )}
                {this.state.report?.disabled != 1 &&
                  (this.state.report?.type == 'tds_report' ||
                    this.state.report?.type == 'tcs_report') && (
                    <Suspense fallback={<div>Loading...</div>}>
                      <TDSReports report={this.state.report} {...this.props} />
                    </Suspense>
                  )}
                {this.state.report?.disabled != 1 && this.state.report?.type == 'gstr_3' && (
                  <Suspense fallback={<div>Loading...</div>}>
                    <GSTReport3B report={this.state.report} {...this.props} />
                  </Suspense>
                )}
                {this.state.report?.disabled != 1 && this.state.report?.type == 'gst_cmp' && (
                  <Suspense fallback={<div>Loading...</div>}>
                    <GSTCMP report={this.state.report} {...this.props} />
                  </Suspense>
                )}
                {this.state.report?.disabled != 1 && this.state.report?.type == 'gstr_4' && (
                  <Suspense fallback={<div>Loading...</div>}>
                    <GSTR4 report={this.state.report} {...this.props} />
                  </Suspense>
                )}

                {/* Profit Loss Reports */}
                {this.state.report?.disabled != 1 && this.state.report?.type == 'profit_loss' && (
                  <Suspense fallback={<div>Loading...</div>}>
                    <ProfitLossReports report={this.state.report} {...this.props} />
                  </Suspense>
                )}
                {this.state.report?.disabled != 1 && this.state?.report?.isCommon == true && (
                  <Suspense fallback={<div>Loading...</div>}>
                    <CommonReports report={this.state.report} {...this.props} />
                  </Suspense>
                )}
                {this.state.report?.disabled != 1 &&
                  (this.state.report?.type == 'payment_timeline' ||
                    this.state.report?.type == 'payment_settlements' ||
                    this.state.report?.type == 'settlement_payouts') && (
                    <Suspense fallback={<div>Loading...</div>}>
                      <PaymentReports report={this.state.report} {...this.props} />
                    </Suspense>
                  )}

                {this.state.showShareHistory && (
                  <Suspense fallback={<div>Loading...</div>}>
                    <ShareHistory />
                  </Suspense>
                )}
              </div>
            </div>

            <EnhancedExport
              onRef={ref => (this.export = ref)}
              menuName={this.state.download_menu}
            />
            <EnhancedSubscriptionModal onRef={ref => (this.subscription = ref)} />
            <SubscriptionPlanModal ref={this.subscriptionPlanRef} />
          </div>
        )}
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  if (ownProps.isPublic) {
    return {
      permissions: ownProps.permissions,
      integrations: ownProps.permissions.integrations,
      user: ownProps.user,
      company_details: ownProps.permissions?.company_details,
      warehouses: ownProps.warehouses,
      product_options: ownProps.document?.product_options,
      prefixes: ownProps.document?.prefixes,
      country_info: ownProps.country?.info,
      business_companies: ownProps.business_companies,
    }
  } else {
    let business_id = state.user?.companies?.filter(
      company => company.company_id == state.user?.selectedCompany.company_id,
    )
    let business_companies = []

    if (business_id.length > 0) {
      business_id = business_id[0].business_id
      if (business_id != -1) {
        business_companies = state.user?.companies?.filter(
          company => company.business_id == business_id,
        )
      }
    }

    return {
      permissions: state.permissions.permission,
      integrations: state.permissions.integrations,
      user: state.user,
      company_details: state.permissions.company_details,
      warehouses: state.warehouse.warehouses,
      product_options: state.document.product_options,
      prefixes: state.document.prefixes,
      country_info: state.countries.info,
      business_companies,
    }
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(ReportsMenu)
