import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Bank } from 'redux/types'
import { Select, Button, Card } from 'antd'

import { getAPIData } from 'services/jwt'
import SectionHeader from './sectionHeader'
import NewBankForm from 'components/forms/newBankForm'

const { Option } = Select

interface Props {
  onChange: (selectedBank: any) => void
  selectedBankData: Bank | undefined
}

const BankSelection: React.FC<Props> = ({ onChange, selectedBankData }) => {
  const bankFormRef = useRef<any>(null)
  const permissions = useSelector((state: any) => state.permissions.permission)

  const [bankDetails, setBankDetails] = useState<Bank[]>([])
  const [selectedBank, setSelectedBank] = useState<Bank | undefined>(undefined)

  const refreshBanks = async (newBank: Bank | undefined = undefined) => {
    const data = await getAPIData('user', 'bank_details')

    if (data?.bank_details) {
      setBankDetails(data.bank_details)
    }
    if (newBank) {
      setSelectedBank(newBank)
      onChange(newBank)
    }
  }

  const handleBankChange = (bankId: number) => {
    const selectedBank = bankDetails.find(bank => bank.id === bankId) || null

    if (!selectedBank) return
    setSelectedBank(selectedBank)

    onChange(selectedBank)
  }

  useEffect(() => {
    refreshBanks()
  }, [])

  useEffect(() => {
    if (selectedBankData) {
      setSelectedBank(selectedBankData)
    }
  }, [selectedBankData])

  return (
    <>
      <SectionHeader
        title={'Select Bank'}
        infoContent={
          <span>
            <span>Select Bank Details to show in document PDFs.</span>
            <br />
            <span>
              Please click on <strong>Add New Bank</strong> to add new bank details.
            </span>
          </span>
        }
        link={bankDetails?.length > 1}
        linkTo={() => bankFormRef?.current?.onOpen()}
        linkText="Add new Bank"
      />
      <Card size="default" bordered={false} className="bg-card-indigo border-radius-small mb-8">
        <div className="text-left">
          {bankDetails?.length > 1 && (
            <Select
              suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
              placeholder="Select Bank"
              style={{ width: '100%' }}
              onChange={handleBankChange}
              value={selectedBank?.id}
              filterOption={(input, option) =>
                (option?.children as any)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              dropdownRender={menu => (
                <>
                  {menu}
                  <div className="bg-gray-75">
                    <Button
                      block
                      type="link-2"
                      className="font-weight-bolder text-left my-2 arrow-transition"
                      onMouseDown={e => e.preventDefault()}
                      onClick={() => bankFormRef?.current?.onOpen()}
                    >
                      <i className="fa-solid fa-circle-plus mr-2"></i>
                      Add New Bank
                      <i className="fa-solid fa-arrow-right ml-2"></i>
                    </Button>
                  </div>
                </>
              )}
              id="selectBank"
            >
              {bankDetails.map((item, i) => (
                <Option key={i} value={item.id}>
                  {item.bank_name + ' '} {item.bank_no != '' && '(' + item.bank_no + ')'}
                </Option>
              ))}
            </Select>
          )}

          {bankDetails?.length <= 1 && permissions.bank_details === 1 && (
            <Button
              type="dashed"
              size="large"
              onClick={() => bankFormRef?.current?.onOpen()}
              block
              style={{
                color: '#FC3654',
                whiteSpace: 'normal',
                height: 'auto',
              }}
            >
              <i className="fa fa-university mr-2 font-size-18" style={{ color: '#FC3654' }} />
              <span className="font-size-18 font-weight-bold" style={{ color: '#FC3654' }}>
                Add Bank to Invoice (Optional)
              </span>
            </Button>
          )}
        </div>
      </Card>

      <NewBankForm ref={bankFormRef} refreshBanks={refreshBanks} />
    </>
  )
}

export default BankSelection
