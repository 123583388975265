import { Tag } from 'antd'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import React from 'react'

dayjs.extend(utc)
dayjs.extend(customParseFormat)

interface ExpiringInProps {
  expiryDate: string
}

const ExpiringIn = ({ expiryDate }: ExpiringInProps) => {
  const formats = ['DD-MM-YYYY', 'DD MMM YY, hh:mm A'] // Add more if needed

  let expiry = dayjs(expiryDate, formats, true) // Try multiple formats

  if (!expiry.isValid()) {
    console.warn('Invalid expiry date format:', expiryDate)
    return null
  }

  const now = dayjs()
  const diffDays = expiry.diff(now, 'day') + 1

  const getExpiryLabel = () => {
    if (diffDays >= 7 && diffDays <= 9) return 'in 1 week'
    else if (diffDays > 9 && diffDays % 7 <= 2) return `in ${Math.floor(diffDays / 7)} weeks`
    else if (diffDays <= 1) return 'in 1 day'
    else return `in ${diffDays} days`
  }

  return (
    <Tag className="scale-75 rounded-2xl px-1.5p py-0.5 text-sm" color="orange">
      <span className="text-orange-600 font-medium">
        <i className="fa-regular fa-clock mr-1" />
        {getExpiryLabel()}
      </span>
    </Tag>
  )
}

export default ExpiringIn
