import React, { useState, useRef, useEffect } from 'react'
import {
  Input,
  Button,
  Modal,
  List,
  Tooltip,
  message,
  Tabs,
  Table,
  Card,
  Statistic,
  Row,
  Col,
  Typography,
  Tag,
  Popover,
} from 'antd'
import {
  SendOutlined,
  LikeOutlined,
  DislikeOutlined,
  InfoCircleOutlined,
  DownloadOutlined,
} from '@ant-design/icons'
import { EnhancedMailFrom } from 'components/forms/mailForm'
import EnhancedPartyAnalysis from 'components/other/PartyAnalysis'
import ReactMarkdown from 'react-markdown'
import AutoFinish from 'components/autoFinish'
import { config } from 'services/axios'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import store, { set } from 'store'
import { getAPI, downloadAPI } from 'services/jwt'
import Ledger from 'pages/payment/ledger'
import BulkDownloads from 'pages/myDrive/bulkDownloads'
import { useSelector } from 'react-redux'

const CLIENT_REQUEST_ID = 'X-Request-ID'
const REQUEST_TIMESTAMP = 'Request-Timestamp'
const WEB = 'web'
const CUSTOM_HEADERS = {
  source: WEB,
}
dayjs.extend(utc)

const AIFileAssistant = ({ visible, onClose, initialSearchText }) => {
  const [searchText, setSearchText] = useState(initialSearchText || '')
  const [focusInput, setFocusInput] = useState(false)
  const [currsearchText, setCurrSearchText] = useState('')
  const [messageHistory, setMessageHistory] = useState([])
  const [feedbackGiven, setFeedbackGiven] = useState({})
  const [showFeedback, setShowFeedback] = useState(false)
  const [threadId, setThreadId] = useState(null)
  const [buttonLoadingState, setButtonLoadingState] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isTyping, setIsTyping] = useState(false)
  const [triggerSearch, setTriggerSearch] = useState(false)
  const [documentType, setDocumentType] = useState('')
  const [menuName, setMenuName] = useState('')
  const [currPartyType, setCurrPartyType] = useState('')
  const [partyDetails, setPartyDetails] = useState(null)
  const [customerSearchText, setCustomerSearchText] = useState('')
  const [showAutoFinish, setShowAutoFinish] = useState({})
  const [customerLedger, setCustomerLedger] = useState(null)
  const user = useSelector(state => state.user)
  const permissions = useSelector(state => state.permissions)
  const chatContainerRef = useRef(null)
  const inputRef = useRef(null)
  const mailRef = useRef(null)
  const autoFinishRef = useRef(null)
  const ledgerRef = useRef(null)
  const partyanalysisRef = useRef(null)
  const bulkDownloadsRef = useRef(null)

  const sampleQueries = [
    'How to add image to a Product?',
    'How to add Google review link on web?',
    'How to make an E-way bill?',
  ]

  useEffect(() => {
    const menuMap = {
      invoice: 'sales',
      purchase: 'purchases',
      purchase_order: 'purchase_orders',
      estimate: 'estimates',
      sales_return: 'sales_returns',
      delivery_challan: 'delivery_challans',
      purchase_return: 'purchase_returns',
      pro_forma_invoice: 'pro_forma_invoices',
      sales_order: 'sales_orders',
    }

    setMenuName(menuMap[documentType] || 'sales')
  }, [documentType])

  useEffect(() => {
    if (visible && threadId === null) {
      const fetchThreadId = async () => {
        try {
          const response = await getAPI('ai', 'create_thread')
          const newThreadId = response.thread_id
          setThreadId(newThreadId)
        } catch (error) {
          console.error('Error creating new thread:', error)
        }
      }

      fetchThreadId()
    }
  }, [visible, threadId])

  useEffect(() => {
    if (focusInput) {
      setTimeout(() => {
        inputRef.current?.focus()
      }, 0)
    }
  }, [focusInput])

  useEffect(() => {
    if (visible && initialSearchText) {
      setSearchText(initialSearchText)
      handleSearch()
      setFocusInput(true)
    }
    if (visible) {
      setFocusInput(true)
    }
  }, [visible, initialSearchText])

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [messageHistory])

  useEffect(() => {
    scrollToBottom()
  }, [customerLedger])

  useEffect(() => {
    if (partyDetails !== null) {
      const ledger = showCustomerLedger()
      setCustomerLedger(ledger)
    } else {
      setCustomerLedger(null)
    }
  }, [partyDetails])

  const handleCustomerCallData = (value, option, date) => {
    option['ai'] = true
    option['date'] = messageHistory[messageHistory.length - 1].customerFlowData?.date
    setPartyDetails(option)
  }

  const handleCustomerClear = () => {
    setPartyDetails(null)
    setCustomerSearchText('')
  }

  const openModalWithPrefilledData = data => {
    if (bulkDownloadsRef.current) {
      bulkDownloadsRef.current.updateState({
        start_date: data.start_date,
        end_date: data.end_date,
        pdf_types: [1],
        document_type: data.document_type,
        search: data.search,
        showDrawer: true,
        dates: [dayjs(data.start_date, 'DD-MM-YYYY'), dayjs(data.end_date, 'DD-MM-YYYY')],
        is_ai: true,
      })
    }
  }

  const handleSearch = async () => {
    if (!searchText.trim() || isLoading) return

    setFocusInput(false)
    setIsLoading(true)
    setMessageHistory(prevHistory => [
      ...prevHistory,
      { type: 'user', content: searchText, timestamp: new Date() },
    ])

    const userMessage = searchText
    setCurrSearchText(searchText)
    setSearchText('')

    try {
      const response = await fetch(config.url.API_URL + '/conversation/ask', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...CUSTOM_HEADERS,
          [CLIENT_REQUEST_ID]: WEB + '-' + uuidv4(),
          [REQUEST_TIMESTAMP]: dayjs(),
          DeviceHash: store.get('device_hash'),
          Authorization: `Bearer ${store.get('accessToken')}`,
          AgentAuthorization: `Bearer ${store.get('agentAccessKey')}`,
        },
        body: JSON.stringify({
          query: searchText,
          thread_id: threadId,
        }),
      })

      if (response.status === 500) {
        // Handle 500 error
        setMessageHistory(prevHistory => [
          ...prevHistory,
          {
            type: 'ai',
            contentType: 'text',
            content: 'Please try again',
            timestamp: new Date(),
          },
        ])
        setIsLoading(false)
        setShowFeedback(true)
        setFocusInput(true)
        return
      }

      const contentType = response.headers.get('AI-Chat-Content-Type')

      // Handle streaming text response
      if (contentType === 'stream-text') {
        if (!response.body) throw new Error('No response body from streaming API')

        const reader = response.body.getReader()

        setMessageHistory(prevHistory => [
          ...prevHistory,
          {
            type: 'ai',
            contentType: 'text',
            content: '',
            timestamp: new Date(),
          },
        ])

        let streamedContent = ''

        while (true) {
          setIsTyping(true)
          const { done, value } = await reader.read()
          if (done) {
            // Update final message with complete content
            setMessageHistory(prevHistory => {
              const newHistory = [...prevHistory]
              const lastIndex = newHistory.length - 1
              if (lastIndex >= 0 && newHistory[lastIndex].type === 'ai') {
                newHistory[lastIndex] = {
                  ...newHistory[lastIndex],
                  content: streamedContent,
                }
              }
              return newHistory
            })

            setIsTyping(false)
            setIsLoading(false)
            setShowFeedback(true)
            setFocusInput(true)

            // for support messages where ai could not answer, update in DB.
            const isSupportNeeded = async text => {
              const pattern = 'https://app.getswipe.in/help?msg='

              // Check if the pattern exists in the text
              if (text.includes(pattern)) {
                try {
                  const response = await getAPI('ai', 'notify_support', {
                    thread_id: threadId,
                  })
                } catch (error) {
                  console.error('Error submitting feedback:', error)
                }
              }
            }
            isSupportNeeded(streamedContent)

            break
          }

          const chunk = new TextDecoder().decode(value)
          streamedContent += chunk

          // Update message history with new chunk
          setMessageHistory(prevHistory => {
            const newHistory = [...prevHistory]
            const lastIndex = newHistory.length - 1
            if (lastIndex >= 0 && newHistory[lastIndex].type === 'ai') {
              newHistory[lastIndex] = {
                ...newHistory[lastIndex],
                content: streamedContent,
              }
            }
            return newHistory
          })
        }
      }
      // Handle non-streaming table response
      else if (contentType === 'transaction-json') {
        const data = await response.json()

        if (data.is_table) {
          const newMessage = {
            type: 'ai',
            contentType: 'table',
            content: data.response_table,
            timestamp: new Date(),
            summaryStats: {
              totalTransactions: data.response_table.total_records,
              amountPaid: data.response_table.amount_paid,
              amountPending: data.response_table.amount_pending,
            },
          }
          setMessageHistory(prevHistory => [...prevHistory, newMessage])
        } else {
          const newMessage = {
            type: 'ai',
            contentType: 'text',
            content: data.response_text,
            timestamp: new Date(),
          }
          setMessageHistory(prevHistory => [...prevHistory, newMessage])
        }

        setThreadId(data.thread_id)
        setIsLoading(false)
        setShowFeedback(true)
        setFocusInput(true)
      }

      // handle download response
      else if (contentType === 'transaction-download') {
        const data = await response.json()
        if (data.is_text) {
          const newMessage = {
            type: 'ai',
            contentType: 'text',
            content: data.response_text,
            downloadData: data.download_data,
            reportType: data?.transaction_type ?? 'documents',
            timestamp: new Date(),
          }
          setMessageHistory(prevHistory => [...prevHistory, newMessage])
        }
        setThreadId(data.thread_id)
        setIsLoading(false)
        setShowFeedback(true)
        setFocusInput(true)
      } else if (contentType === 'transaction-share') {
        const data = await response.json()

        setDocumentType(data.response_table.transactions[0].document_type)

        if (data.is_table) {
          const newMessage = {
            type: 'ai',
            contentType: 'share-table',
            content: data.response_table,
            timestamp: new Date(),
          }
          setMessageHistory(prevHistory => [...prevHistory, newMessage])
        } else {
          const newMessage = {
            type: 'ai',
            contentType: 'text',
            content: data.response_text,
            timestamp: new Date(),
          }
          setMessageHistory(prevHistory => [...prevHistory, newMessage])
        }

        setThreadId(data.thread_id)
        setIsLoading(false)
        setShowFeedback(true)
        setFocusInput(true)
      } else if (contentType === 'gstr-download') {
        const data = await response.json()
        if (Object.keys(data.error_data).length == 0) {
          const newMessage = {
            type: 'ai',
            contentType: 'text',
            content:
              'Here is your GST report for the time period: ' + data.download_gst_data.date + '.',
            gstrDownloadParams: data.download_gst_data,
            timestamp: new Date(),
          }
          setMessageHistory(prevHistory => [...prevHistory, newMessage])
        } else {
          const newMessage = {
            type: 'ai',
            contentType: 'text',
            content:
              'Here is your GST report for the time period: ' +
              data.download_gst_data.date +
              '.\n' +
              'We found some errors which are given below. If you still wish to download click on the download button.',
            gstrDownloadParams: data.download_gst_data,
            gstrErrors: data.error_data,
            timestamp: new Date(),
          }
          setMessageHistory(prevHistory => [...prevHistory, newMessage])
        }
        setThreadId(data.thread_id)
        setIsLoading(false)
        setShowFeedback(true)
        inputRef?.current?.focus()
      } else if (contentType === 'party-flow') {
        const data = await response.json()
        const newMessage = {
          type: 'ai',
          contentType: 'party-flow',
          customerFlowData: data.cust_data,
          timestamp: new Date(),
        }
        setCustomerSearchText(data.cust_data.search)
        setCurrPartyType(data.cust_data.party_type)

        setIsLoading(false)
        setMessageHistory(prevHistory => {
          const newIndex = prevHistory.length
          setShowAutoFinish(prevState => ({
            ...prevState,
            [`${newIndex}`]: true,
          }))
          return [...prevHistory, newMessage]
        })

        setThreadId(data.thread_id)
      } else if (contentType === 'party-analysis') {
        const data = await response.json()
        const newMessage = {
          type: 'ai',
          contentType: 'party-analysis',
          textContent: data.text_content,
          params: data.function_params,
          data: data?.parties,
          partyType: data.party_type,
          timestamp: new Date(),
        }
        setMessageHistory(prevHistory => [...prevHistory, newMessage])
        setThreadId(data.thread_id)
        setIsLoading(false)
      } else if (contentType === 'bulk-download') {
        const data = await response.json()
        const newMessage = {
          type: 'ai',
          contentType: 'bulk-download',
          data: data?.download_data,
          title: data?.title,
          timestamp: new Date(),
        }
        setMessageHistory(prevHistory => [...prevHistory, newMessage])
        setThreadId(data.thread_id)
        setIsLoading(false)
      }
    } catch (error) {
      console.error('Error calling AI service:', error)
      setIsLoading(false)
    }
  }

  const handleFeedback = async (messageIndex, isLike) => {
    if (feedbackGiven[messageIndex] !== undefined) return

    const user_message = messageHistory[messageIndex]

    let feedbackContent = ''

    if (user_message.contentType === 'table') {
      feedbackContent = 'Table was provided'
    } else if (user_message.contentType === 'share-table') {
      feedbackContent = 'Table was shared'
    } else if (user_message.contentType === 'party-flow') {
      feedbackContent = 'Party flow was provided'
    } else if (user_message.contentType === 'party-analysis') {
      feedbackContent = 'Party analysis was provided'
    } else if (user_message.contentType === 'bulk-download') {
      feedbackContent = 'Bulk download was provided'
    } else {
      feedbackContent = user_message.content
    }

    const feedback = isLike ? 1 : -1

    try {
      const response = await getAPI('ai', 'chat_feedback', {
        query: currsearchText,
        answer: feedbackContent ?? '',
        feedback: feedback,
        thread_id: threadId,
      })

      if (response.success) {
        // Update feedbackGiven state to reflect the feedback type
        setFeedbackGiven(prev => ({
          ...prev,
          [messageIndex]: isLike,
        }))
        message.success(response.message)
      }
    } catch (error) {
      console.error('Error submitting feedback:', error)
    }
  }

  const renderTextMessage = content => (
    <ReactMarkdown
      components={{
        // Convert links to open in a new tab and add underline
        a: ({ node, ...props }) => (
          <a
            {...props}
            style={{ textDecoration: 'underline', lineHeight: '1.5' }}
            className="text-primary"
            target="_blank"
            rel="noopener noreferrer"
          />
        ),
        h1: ({ node, ...props }) => (
          <p
            className="font-size-20 font-weight-bold my-4"
            style={{ lineHeight: '2' }}
            {...props}
          />
        ),
        h2: ({ node, ...props }) => (
          <p
            className="font-size-20 font-weight-bold my-4"
            style={{ lineHeight: '1.9' }}
            {...props}
          />
        ),
        h3: ({ node, ...props }) => (
          <p
            className="font-size-20 font-weight-bold my-2"
            style={{ lineHeight: '1.8' }}
            {...props}
          />
        ),
        h4: ({ node, ...props }) => (
          <p
            className="font-size-20 font-weight-bold my-2"
            style={{ lineHeight: '1.7' }}
            {...props}
          />
        ),
        h5: ({ node, ...props }) => (
          <p
            className="font-size-20 font-weight-bold my-2"
            style={{ lineHeight: '1.6' }}
            {...props}
          />
        ),
        h6: ({ node, ...props }) => (
          <p
            className="font-size-20 font-weight-bold text-gray-900 my-2"
            style={{ lineHeight: '1.5' }}
            {...props}
          />
        ),
        // Handle ** text as bold
        strong: ({ node, ...props }) => (
          <strong
            className="font-weight-bold text-gray-900"
            style={{ lineHeight: '1.5' }}
            {...props}
          />
        ),
        // Handle normal text
        p: ({ node, ...props }) => (
          <p className="mb-1 text-gray-900" style={{ lineHeight: '1.5' }} {...props} />
        ),
        // Handle normal text
        span: ({ node, ...props }) => (
          <>
            <span className="my-1 text-gray-900" style={{ lineHeight: '1.5' }} {...props} />
          </>
        ),
        li: ({ node, ...props }) => (
          <li
            className="my-1 text-gray-900"
            style={{ lineHeight: '1.5', marginLeft: '1.5em' }}
            {...props}
          />
        ),
        ul: ({ node, ...props }) => (
          <ul
            className="my-2 text-gray-900 list-disc"
            style={{ paddingLeft: '1em', lineHeight: '1.5' }}
            {...props}
          />
        ),
        ol: ({ node, ...props }) => (
          <ol
            className="my-2 text-gray-900 list-decimal"
            style={{ paddingLeft: '1em', lineHeight: '1.5' }}
            {...props}
          />
        ),
      }}
    >
      {content.replace(/【\d+:\d+†[^】]+】/g, '')}
    </ReactMarkdown>
  )

  const renderTableMessage = (content, message) => {
    if (!content) {
      return <Typography.Text className="font-size-14">No table data available</Typography.Text>
    }

    const title = content.doc_type ?? ''

    const displayColumns = content.display_columns
      ? content.display_columns
      : ['Amount', 'Status', 'Bill#', 'Date']
    const columns = content.columns
      ? content.columns
          .filter(col => displayColumns.includes(col.title || col.label))
          .map(col => ({
            title: col.title || col.label,
            dataIndex: col.dataIndex,
            key: col.dataIndex,
            render: (text, record) => {
              if (col.dataIndex === 'payment_status') {
                return (
                  <Tag
                    className="font-size-12"
                    color={text === 'pending' ? 'warning' : text === 'paid' ? 'success' : 'default'}
                  >
                    {text.toUpperCase()}
                  </Tag>
                )
              } else if (col.dataIndex === 'customer') {
                return <span className="font-size-14">{record.customer?.name || ''}</span>
              }
              return <span className="font-size-14">{text}</span>
            },
          }))
      : []

    return (
      <div className="bg-white">
        <span className="mb-4 font-size-14 font-bold">{title} Transaction Summary</span>
        {message.summaryStats && (
          <div>
            <Card
              className="mb-4 mt-3 border border-gray-200 rounded-lg shadow-sm fade-in"
              style={{ width: 650 }}
            >
              <Row gutter={16}>
                <Col span={8}>
                  <Statistic
                    title={<span className="font-size-14">Total Transactions</span>}
                    value={message.summaryStats.totalTransactions}
                    valueStyle={{ color: '#0175E4' }}
                  />
                </Col>
                <Col span={8}>
                  <Statistic
                    title={<span className="font-size-14">Amount Paid</span>}
                    value={message.summaryStats.amountPaid}
                    prefix="₹"
                    precision={2}
                    valueStyle={{ color: '#088A08' }}
                  />
                </Col>
                <Col span={8}>
                  <Statistic
                    title={<span className="font-size-14">Amount Pending</span>}
                    value={message.summaryStats.amountPending}
                    prefix="₹"
                    precision={2}
                    valueStyle={{ color: '#C08400' }}
                  />
                </Col>
              </Row>
            </Card>
          </div>
        )}
        {content.transactions && columns.length > 0 ? (
          <>
            <span className="mb-3 font-size-14 font-bold">{title} Transaction Details</span>
            <Table
              columns={columns}
              dataSource={content.transactions}
              pagination={
                content.transactions.length > 6
                  ? {
                      total: content.total_records,
                      pageSize: 6,
                    }
                  : false
              }
              size="middle"
              scroll={{ x: 'max-content' }}
              className="mt-3 mb-3 fade-in"
              bordered
              style={{
                border: '1px solid #e8e8e8',
                borderRadius: '8px',
                overflow: 'hidden',
              }}
            />
          </>
        ) : (
          <span className="font-size-14 font-bold">No transaction data available</span>
        )}
      </div>
    )
  }

  const renderShareTableMessage = (content, message) => {
    if (!content) {
      return <Typography.Text className="font-size-14">No table data available</Typography.Text>
    }

    const title = content.doc_type ?? ''

    const displayColumns = ['Amount', 'Bill#', 'Date', 'Customer']

    let columns = content.columns
      ? content.columns
          .filter(col => displayColumns.includes(col.title || col.label))
          .map(col => ({
            title: col.title || col.label,
            dataIndex: col.dataIndex,
            key: col.dataIndex,
            render: (text, record) => {
              if (col.dataIndex === 'payment_status') {
                return (
                  <Tag
                    className="font-size-12"
                    color={text === 'pending' ? 'warning' : text === 'paid' ? 'success' : 'default'}
                  >
                    {text.toUpperCase()}
                  </Tag>
                )
              } else if (col.dataIndex === 'customer') {
                return <span className="font-size-14">{record.customer?.name || ''}</span>
              }
              return <span className="font-size-14">{text}</span>
            },
          }))
      : []

    const shareColumn = {
      title: 'Share Link',
      key: 'share_link',
      render: (_, record) => {
        const handleEmailClick = () => mailRef.current?.onShow?.(record)

        const phoneNumber =
          record.customer.phone != null && record.customer.phone.length === 10
            ? record.customer.dial_code + record.customer.phone
            : ''

        // Build the message components safely with proper encoding
        const greeting =
          record.customer.name !== '' ? `%20*${encodeURIComponent(record.customer.name)}*` : ''

        const documentInfo = `*${encodeURIComponent(
          record.document_title + ': ' + record.serial_number,
        )}*`

        const totalAmount = `*${encodeURIComponent(
          'Total: ' +
            // (userProps?.selectedCompany?.currency_symbol || '') +
            ' ' +
            record.total_amount,
        )}*`

        const documentLink = `*${encodeURIComponent(
          'Link:* https://swipe.pe/n/view/' + record.menu_name + '/' + record.new_hash_id,
        )}`

        const companyInfo = `*${encodeURIComponent(record.company.organization_name)}*%0A*${
          record.company.mobile
        }*`

        const whatsappLine2 =
          record.company.whatsapp_line2 !== ''
            ? `%0A${encodeURIComponent(record.company.whatsapp_line2)}`
            : ''

        const googleReview = store.get('place_id')
          ? `%20%0AReview us on Google here:%20https://search.google.com/local/writereview?placeid=${store.get(
              'place_id',
            )}%20%0A`
          : ''

        const swipeFooter =
          store.get('paid') === 0
            ? '%0A%0ASent%20using%20*Swipe%3A%20Simple%20Invoicing%2C%20Billing%2C%20Payments*%20(getswipe.in)'
            : ''

        // Construct the complete URL
        const whatsappUrl =
          'https://api.whatsapp.com/send?phone=' +
          phoneNumber +
          '&text=Hello' +
          greeting +
          '%2C%20%0A%0A' +
          encodeURIComponent(record.company.whatsapp_line1) +
          '%2E%20%0A%0A' +
          documentInfo +
          '%0A' +
          totalAmount +
          '/' +
          '%0A' +
          documentLink +
          '%0A' +
          whatsappLine2 +
          '%0A%0AThanks%0A' +
          companyInfo +
          '%0A' +
          googleReview +
          '%0A' +
          swipeFooter

        return (
          <div style={{ display: 'flex' }}>
            <Button
              size="small"
              onClick={() => {
                window.open(whatsappUrl)
              }}
              className="hover:bg-gray-100 transition-colors duration-200 focus:outline-none mr-2"
            >
              <i className="fa fa-whatsapp text-forest fa-lg font-bold" />
            </Button>
            <Button
              size="small"
              onClick={handleEmailClick}
              className="hover:bg-gray-100  transition-colors duration-200 focus:outline-none"
            >
              <i className="fa fa-envelope-o text-primary fa-lg font-bold" />
            </Button>
          </div>
        )
      },
    }

    columns.push(shareColumn)

    return (
      <div className="bg-white">
        {content.transactions && columns.length > 0 ? (
          <>
            <Typography.Title level={5} className="mb-3 font-size-14">
              Share {title} Transaction Details
            </Typography.Title>
            <Table
              columns={columns}
              dataSource={content.transactions}
              pagination={
                content.transactions.length > 6
                  ? {
                      total: content.total_records,
                      pageSize: 6,
                    }
                  : false
              }
              size="middle"
              scroll={{ x: 'max-content' }}
              bordered
              style={{
                border: '1px solid #e8e8e8',
                borderRadius: '8px',
                overflow: 'hidden',
              }}
              className="fade-in"
            />
          </>
        ) : (
          <Typography.Text className="font-size-14">No transaction data available</Typography.Text>
        )}
      </div>
    )
  }

  const renderErrorTabs = errorData => {
    const errorTabs = Object.keys(errorData).map(errorCategory => {
      const dataSource = errorData[errorCategory].map((error, index) => ({
        key: index,
        errorMessage: error,
      }))

      const columns = [
        {
          title: 'Error Message',
          dataIndex: 'errorMessage',
          key: 'errorMessage',
          render: text => <span dangerouslySetInnerHTML={{ __html: text }} />,
        },
      ]

      return {
        label: errorCategory,
        key: errorCategory,
        children: (
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={
              dataSource.length > 6
                ? {
                    total: content.total_records,
                    pageSize: 6,
                  }
                : false
            }
            size="middle"
            className="mt-3 mb-3 fade-in"
            bordered
            style={{
              border: '1px solid #e8e8e8',
              borderRadius: '8px',
              overflow: 'hidden',
            }}
          />
        ),
      }
    })

    return <Tabs items={errorTabs} tabPosition="top" />
  }

  const renderCustomerFlow = customerFlowData => {
    setShowFeedback(false)
    return (
      <div className="party-flow-container fade-in">
        <AutoFinish
          style={{ width: 400 }}
          placeholder={'Search your party'}
          autoFocus={true}
          searchType={currPartyType ?? 'customer'}
          onRef={ref => (autoFinishRef.current = ref)}
          callData={handleCustomerCallData}
          dropdownMatchSelectWidth={true}
          searchText={customerSearchText}
          onChange={value => setCustomerSearchText(value)}
          onClear={handleCustomerClear}
        />
      </div>
    )
  }

  const showCustomerLedger = () => {
    if (partyDetails === null) {
      message.error('Please select a customer first')
      return null
    }
    setShowAutoFinish(prevState => ({
      ...prevState,
      [`${messageHistory.length - 1}`]: false,
    }))
    setIsTyping(false)
    setShowFeedback(true)
    setFocusInput(true)
    return (
      <div className="customer-ledger-container border border-gray-300 rounded-lg fade-in">
        <Ledger
          selectedUser={partyDetails}
          onAPICalled={e => {}}
          type={currPartyType ?? 'customer'}
          onRef={ref => (ledgerRef.current = ref)}
          callData={e => {}}
        />
      </div>
    )
  }

  const formatNumber = (number, format = true) => {
    if (format && number < 10000) return number
    const formatter = new Intl.NumberFormat('en-IN', {
      notation: 'compact',
      compactDisplay: 'short', // Other option is 'long'
      maximumFractionDigits: 2,
    })
    const formattedNumber = formatter.format(number)
    return formattedNumber
  }

  const renderCustomerAnalysisTable = (title, data, params, partyType) => {
    // Function to handle the customer click and invoke showModal
    const handleCustomerClick = (partyData, partyType) => {
      let partyid = partyType === 'customer' ? partyData.customer_id : partyData.vendor_id
      partyanalysisRef.current?.showModal({ ...partyData, id: partyid }, partyType)
    }

    const handleTableDataDownload = async params => {
      await downloadAPI('conversation', 'download', params)
    }

    const partyColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        align: 'left',
        ellipsis: true,
        render: (text, record) => (
          <div
            className="flex flex-col overflow-hidden"
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            <Popover content={text}>
              <span
                className="text-gray-600 font-weight-bold overflow-hidden cursor-pointer hover-underline"
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
                onClick={e => {
                  e.stopPropagation()
                  handleCustomerClick(record, partyType)
                }}
              >
                {text}
              </span>
            </Popover>

            <span
              className={`font-weight-medium font-size-14 ${
                record.amount < 0 ? 'text-danger' : 'text-gray-400'
              }`}
            >
              {user?.selectedCompany?.currency_symbol} {formatNumber(record.amount)}
            </span>
          </div>
        ),
      },
      {
        title: 'Ph. No.',
        dataIndex: 'phone',
        key: 'phone',
        align: 'right',
        render: (text, record) => (
          <div>
            {record?.phone?.length === 10 && (
              <a
                href={encodeURI(
                  `https://api.whatsapp.com/send/?phone=${text}&text=Hello ${record?.name}!`,
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-whatsapp fa-xl text-whatsapp" />
              </a>
            )}
          </div>
        ),
      },
    ]

    setIsTyping(false)
    setShowFeedback(true)
    setFocusInput(true)

    return (
      <div
        className="flex flex-col overflow-hidden"
        style={{
          width: '400px',
        }}
      >
        <Typography.Title level={5} className="mb-3 font-size-14">
          {title}:
        </Typography.Title>
        <Table
          size="small"
          columns={partyColumns}
          className="custom-table-scrollbar fade-in"
          dataSource={data.map((customer, index) => ({
            index: index + 1,
            name: customer.name,
            amount: customer.value ?? 0,
            phone: customer.phone,
            id: customer.customer_id,
            ...customer,
          }))}
          pagination={
            data.length > 5
              ? {
                  total: data.total_records,
                  pageSize: 5,
                  showSizeChanger: false,
                }
              : false
          }
          scroll={undefined}
        />
        <div className="flex flex-start mt-2">
          <Button
            type="primary"
            size="small"
            onClick={() => handleTableDataDownload(params)}
            style={{ width: '80px' }}
          >
            Download
          </Button>
        </div>
      </div>
    )
  }

  const renderBulkDownload = data => {
    return (
      <div className="flex flex-start mt-2">
        <Button
          onClick={e => {
            if (permissions.integrations.bulk_downloads == 0) {
              e.stopPropagation()
              e.preventDefault()
              subscriptionPlanRef?.current?.openModal('bulk_downloads')
            } else {
              openModalWithPrefilledData(data)
            }
          }}
          type="action-o"
          className="mr-3 font-weight-medium"
        >
          <span className="text-primary">Click to proceed for download</span>
          <i className="text-primary font-weight-medium fa-regular fa-arrow-down ml-2" />
        </Button>
      </div>
    )
  }

  const renderMessage = (message, index) => (
    <div
      key={index}
      className={`flex flex-row message ${message.type} ${
        message.type === 'user' ? 'justify-end' : 'justify-start'
      } mb-4`}
    >
      <div
        style={{
          maxWidth: '95%',
          padding: '12px 16px',
        }}
        className={`font-size-14 ${
          message.type === 'user'
            ? 'bg-[var(--chat-message-user)] text-[var(--chat-text-user)] rounded-t-2xl rounded-l-2xl rounded-br-md'
            : 'bg-white rounded-t-2xl rounded-r-2xl rounded-bl-md'
        }`}
      >
        {/* className={`font-size-16 ${
          message.type === 'user'
            ? 'bg-gray-75 rounded-t-2xl rounded-l-2xl rounded-br-md'
            : 
        }`} */}
        {message.type === 'ai' ? (
          <>
            <div className="flex flex-start">
              <div className="mr-4">
                <div
                  className="rounded-full flex items-center justify-center border"
                  style={{
                    width: '40px',
                    height: '40px',
                    padding: '4px',
                  }}
                >
                  <img src="/resources/images/ai_icon.svg" alt="SwipeAi" />
                </div>
              </div>
              <div className="pt-2 w-full">
                {message.contentType === 'text' && message.content && (
                  <div className="mt-3 mb-4 bg-[var(--chat-message-ai)] text-gray-900 rounded-tr-2xl rounded-br-2xl rounded-bl-2xl px-4 py-3">
                    {renderTextMessage(message.content)}
                    {message.downloadData &&
                      renderDownloadButtons(message.downloadData, message.reportType)}
                    {message.gstrErrors && renderErrorTabs(message.gstrErrors)}
                    {message.gstrDownloadParams &&
                      renderGstrDownloadButtons(message.gstrDownloadParams)}
                  </div>
                )}
                {message.contentType === 'table' &&
                  message.content &&
                  renderTableMessage(message.content, message)}
                {message.contentType === 'share-table' &&
                  message.content &&
                  renderShareTableMessage(message.content, message)}
                {message.contentType === 'party-flow' && message.customerFlowData && (
                  <div className="mb-4 br-2">
                    {showAutoFinish[`${index}`] &&
                      renderTextMessage(
                        `Please select the ${currPartyType.charAt(0).toUpperCase() +
                          currPartyType.slice(1)} first to proceed: `,
                      )}
                    {showAutoFinish[`${index}`] && <div style={{ marginBottom: '10px' }}></div>}
                    {message.customerFlowData &&
                      showAutoFinish[`${index}`] &&
                      renderCustomerFlow(message.customerFlowData)}
                    {!showAutoFinish[`${index}`] && (
                      <div>
                        <span className="mb-4 font-size-14">
                          {' '}
                          {currPartyType.charAt(0).toUpperCase() +
                            currPartyType.slice(1)} Ledger{' '}
                        </span>
                      </div>
                    )}
                    {!showAutoFinish[`${index}`] && <div style={{ marginBottom: '10px' }}></div>}
                    {!showAutoFinish[`${index}`] && customerLedger}
                  </div>
                )}
                {message.contentType === 'party-analysis' &&
                  message.data &&
                  renderCustomerAnalysisTable(
                    message.textContent,
                    message.data,
                    message.params,
                    message.partyType,
                  )}
                {message.contentType === 'bulk-download' &&
                  message.data &&
                  (permissions.integrations.bulk_downloads === 1 ? (
                    <>
                      {renderTextMessage(message.title)}
                      {renderBulkDownload(message.data)}
                    </>
                  ) : (
                    <div className="mb-2">
                      {renderTextMessage(
                        'Bulk download feature is not available in your current plan. Please upgrade to access this feature. Check out our plans here: [Plans](https://app.getswipe.in/pricing)',
                      )}
                    </div>
                  ))}
                {showFeedback && !isTyping && (
                  <div className="flex justify-end -mt-[4px]">
                    {feedbackGiven[index] === undefined || feedbackGiven[index] === true ? (
                      <Tooltip title="Helpful">
                        <Button
                          icon={
                            <span
                              style={{
                                fontSize: '14px',
                                transition: 'transform 0.2s ease',
                              }}
                              onMouseEnter={e => {
                                e.target.style.transform = 'scale(1.5)'
                              }}
                              onMouseLeave={e => {
                                e.target.style.transform = 'scale(1)'
                              }}
                            >
                              👍
                            </span>
                          }
                          size="small"
                          type="text"
                          onClick={() => handleFeedback(index, true)}
                          disabled={feedbackGiven[index] !== undefined}
                        />
                      </Tooltip>
                    ) : null}

                    {feedbackGiven[index] === undefined || feedbackGiven[index] === false ? (
                      <Tooltip title="Not helpful">
                        <Button
                          icon={
                            <span
                              style={{
                                fontSize: '14px',
                                transition: 'transform 0.2s ease',
                              }}
                              onMouseEnter={e => {
                                e.target.style.transform = 'scale(1.5)'
                              }}
                              onMouseLeave={e => {
                                e.target.style.transform = 'scale(1)'
                              }}
                            >
                              👎
                            </span>
                          }
                          size="small"
                          type="text"
                          onClick={() => handleFeedback(index, false)}
                          disabled={feedbackGiven[index] !== undefined}
                        />
                      </Tooltip>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <p style={{ margin: 0 }}>{message.content}</p>
        )}
      </div>
    </div>
  )

  const renderThinkingAnimation = () => (
    <div
      className="flex flex-start"
      style={{
        maxWidth: '70%',
        padding: '12px 14px',
        borderRadius: '18px',
      }}
    >
      <div className="mr-4">
        <div
          className="rounded-full flex items-center justify-center border"
          style={{
            width: '40px',
            height: '40px',
            padding: '4px',
          }}
        >
          <img src="/resources/images/ai_icon.svg" alt="SwipeAi" />
        </div>
      </div>
      <div className="thinking-animation flex flex-start mb-4 mt-4">
        <div className="circle bg-gray-500"></div>
        <div className="circle bg-gray-500"></div>
        <div className="circle bg-gray-500"></div>
      </div>
    </div>
  )

  const hashCode = obj => {
    let str = JSON.stringify(obj)
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i)
      hash = (hash << 5) - hash + char
      hash |= 0 // Convert to 32-bit integer
    }
    return hash
  }

  const renderDownloadButtons = (downloadData, reportType) => {
    // Function to determine file type icon
    const getFileIcon = type => {
      switch (type) {
        case 'excel':
          return 'fa-duotone fa-file-excel'
        case 'pdf':
          return 'fa-duotone fa-file-pdf'
        default:
          return 'fa-duotone fa-file'
      }
    }

    // Function to determine button text
    const getButtonText = type => {
      switch (type) {
        case 'excel':
          return 'Download Excel'
        case 'pdf':
          return 'Download PDF'
        default:
          return 'Download File'
      }
    }

    const downloadTypes = downloadData.download === 1 ? ['excel'] : ['pdf']

    const downloadKey = hashCode(downloadData)

    return (
      <div className="flex flex-wrap gap-2 mt-3">
        {downloadTypes.map(type => (
          <Button
            key={type}
            type="action"
            className="mx-2 my-2"
            loading={buttonLoadingState[downloadKey] || false}
            onClick={() => handleDownload(downloadData, downloadKey, reportType)}
          >
            <i className={`${getFileIcon(type)} mr-2`} />
            {getButtonText(type)}
          </Button>
        ))}
      </div>
    )
  }

  const renderGstrDownloadButtons = downloadData => {
    const getFileIcon = type => {
      switch (type) {
        case 'excel':
          return 'fa-duotone fa-file-excel'
        case 'json':
          return 'fa-duotone fa-file-code'
        default:
          return 'fa-duotone fa-file'
      }
    }

    // Function to determine button text
    const getButtonText = type => {
      switch (type) {
        case 'excel':
          return 'Download Excel'
        case 'json':
          return 'Download JSON'
        default:
          return 'Download File'
      }
    }

    const downloadTypes = downloadData.is_json === 1 ? ['json'] : ['excel']

    const downloadKey = hashCode(downloadData)

    return (
      <div className="flex flex-wrap gap-2 mt-3">
        {downloadTypes.map(type => (
          <Button
            key={type}
            type="action"
            className="mx-2 my-2"
            loading={buttonLoadingState[downloadKey] || false}
            onClick={() => handleGstrDownload(downloadData, downloadKey)}
          >
            <i className={`${getFileIcon(type)} mr-2`} />
            {getButtonText(type)}
          </Button>
        ))}
      </div>
    )
  }

  // Handle download
  const handleDownload = async (downloadData, downloadKey, reportType) => {
    // Set loading state for this specific button
    setButtonLoadingState(prevState => ({
      ...prevState,
      [downloadKey]: true,
    }))

    if (reportType === 'items_report') {
      await downloadAPI('v2/reports', 'transaction_wise_items', downloadData)
    } else {
      await downloadAPI('v2/reports', 'transactions', downloadData)
    }

    // Reset loading state after download completes
    setButtonLoadingState(prevState => ({
      ...prevState,
      [downloadKey]: false,
    }))
  }

  const handleGstrDownload = async (downloadData, downloadKey) => {
    // Set loading state for this specific button
    setButtonLoadingState(prevState => ({
      ...prevState,
      [downloadKey]: true,
    }))

    const response = await downloadAPI('v2/reports', 'gstr_1_v2', downloadData)

    // Reset loading state after download completes
    setButtonLoadingState(prevState => ({
      ...prevState,
      [downloadKey]: false,
    }))
  }

  useEffect(() => {
    if (triggerSearch) {
      handleSearch()
      setTriggerSearch(false)
    }
  }, [searchText, triggerSearch])

  const handleSampleQueryClick = query => {
    setSearchText(query)
    setTriggerSearch(true)
  }

  const renderSampleQueries = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: '20px',
      }}
    >
      <h4 style={{ marginBottom: '20px', fontWeight: 'bold' }}>Try asking about</h4>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: '10px',
          width: '100%',
        }}
      >
        {sampleQueries.map((query, index) => (
          <Tooltip key={index} title="Click to use this query">
            <Button
              onClick={() => handleSampleQueryClick(query)}
              className="bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600"
              style={{
                borderRadius: '12px',
                border: 'none',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                transition: 'all 0.3s ease',
                padding: '24px',
                fontSize: '14px',
                fontWeight: '500',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                cursor: 'pointer',
              }}
              onMouseOver={e => {
                e.currentTarget.style.transform = 'translateY(-2px)'
                e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.15)'
              }}
              onMouseOut={e => {
                e.currentTarget.style.transform = 'none'
                e.currentTarget.style.boxShadow = '0 2px 4px rgba(0,0,0,0.1)'
              }}
            >
              {query}
            </Button>
          </Tooltip>
        ))}
      </div>
    </div>
  )

  return (
    <Modal
      title={'Ask anything or search docs...'}
      visible={visible}
      onCancel={() => {
        onClose()
        setFocusInput(false)
        setSearchText('')
      }}
      footer={null}
      className="custom-scrollbar"
      width={'60vw'}
      centered
    >
      <div
        ref={chatContainerRef}
        className="mr-4 pr-4 bg-white"
        style={{
          height: '70vh',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {messageHistory.length === 0 ? (
          renderSampleQueries()
        ) : (
          <List dataSource={messageHistory} renderItem={renderMessage} />
        )}
        {isLoading && !isTyping && renderThinkingAnimation()}
      </div>
      <div className="mb-2 mt-4" style={{ position: 'relative' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Input.TextArea
            ref={inputRef}
            placeholder="Ask anything or search docs..."
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            onPressEnter={e => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault()
                handleSearch()
              }
            }}
            size="large"
            autoSize={{ minRows: 1, maxRows: 4 }}
            style={{ borderRadius: '20px', resize: 'none' }}
            disabled={isLoading}
            className="bg-gray-75"
            autoFocus
          />
          <Button
            type="link"
            size="large"
            className="ml-1"
            icon={<SendOutlined style={{ fontSize: '24px' }} />}
            onClick={handleSearch}
            disabled={isLoading || !searchText.trim()}
          />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <span className="font-size-12 text-gray-400">
          This is AI generated response. Please double check before using it.
        </span>
      </div>
      <style jsx>{`
        .thinking-animation {
          display: flex;
          align-items: center;
        }
        .circle {
          width: 10px;
          height: 10px;
          margin: 0 5px;
          border-radius: 50%;
          animation: pulse 1.5s infinite ease-in-out;
        }
        .circle:nth-child(2) {
          animation-delay: 0.2s;
        }
        .circle:nth-child(3) {
          animation-delay: 0.4s;
        }
        @keyframes pulse {
          0%,
          100% {
            transform: scale(0.5);
            opacity: 0.5;
          }
          50% {
            transform: scale(1);
            opacity: 1;
          }
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(10px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .fade-in {
          animation: fadeIn 1.2s ease-out;
        }

        .custom-scrollbar .ant-modal-body {
          --sb-track-color: transparent;
          --sb-thumb-color: #c6cdd4;
          --sb-size: 7px;
        }

        .custom-scrollbar .ant-modal-body ::-webkit-scrollbar {
          width: var(--sb-size);
        }

        .custom-scrollbar .ant-modal-body ::-webkit-scrollbar-track {
          background: var(--sb-track-color);
          border-radius: 14px;
        }

        .custom-scrollbar .ant-modal-body ::-webkit-scrollbar-thumb {
          background: var(--sb-thumb-color);
          border-radius: 14px;
        }

        @supports not selector(: : -webkit-scrollbar) {
          .custom-scrollbar .ant-modal-body {
            scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
          }
        }
      `}</style>
      <EnhancedMailFrom
        onRef={ref => (mailRef.current = ref)}
        document_type={documentType}
        menuName={menuName}
      />

      <EnhancedPartyAnalysis onRef={ref => (partyanalysisRef.current = ref)} />

      <BulkDownloads
        onRef={ref => {
          bulkDownloadsRef.current = ref
        }}
      />
    </Modal>
  )
}

export default AIFileAssistant
