import { Button, Card, message } from 'antd'
import { FormattedMessage } from 'react-intl'
import GetPaymentDocument from 'components/other/GetPaymentDocument'
import { Payment } from 'pages/doc/create/amountSettledPopUp'
import { useRef } from 'react'

type ModalRef = {
  showModal: (serialNumber: string) => void
}

const PaymentCards = ({
  payments,
  currencySymbol,
  variant,
}: {
  payments: Payment[]
  currencySymbol?: string
  variant: 'small' | 'large'
}) => {
  const ref = useRef<ModalRef>(null)

  if (!payments?.length) return null

  return (
    <div className={variant === 'large' ? 'pt-2' : ''}>
      {payments.map((payment, index) => (
        <Card
          key={payment.serial_number}
          className={`bg-${getPaidClass(payment.payment_mode)} ${
            index !== payments.length - 1 ? 'mb-3' : ''
          }`}
        >
          <div
            className={`flex justify-between items-center min-w-64 ${
              variant === 'large' ? 'p-1' : ''
            }`}
          >
            <div>
              <p className="font-bold">
                {currencySymbol}
                {payment.amount.toFixed(2)}, {payment.payment_mode}
              </p>
              <p className={`${variant === 'large' ? 'text-sm' : 'font-size-12'} text-gray-500`}>
                {payment.payment_date} {payment.serial_number}
              </p>
            </div>
            <Button
              // @ts-ignore
              type="view-button"
              size="small"
              onClick={() => {
                message.info('Opening Payment Receipt...')
                ref.current?.showModal(payment.serial_number)
              }}
              className="flex items-center gap-2"
            >
              <i className="fa-solid fa-eye mr-1" />
              <FormattedMessage id="button.view" />
            </Button>
          </div>
        </Card>
      ))}
      <GetPaymentDocument ref={ref} />
    </div>
  )
}

function getPaidClass(data: string) {
  switch (data) {
    case 'Cash':
      return 'cash'
    case 'Card':
      return 'card'
    case 'UPI':
      return 'upi'
    case 'EMI':
      return 'emi'
    case 'Net Banking':
      return 'netbanking'
    case 'Cheque':
      return 'cheque'
    case 'paylater':
      return 'paylater'
    default:
      return 'card'
  }
}

export default PaymentCards
