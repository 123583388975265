import { useState, forwardRef, useImperativeHandle } from 'react'
import { Button, Modal } from 'antd'
import PaymentCards from 'components/PaymentCards'

export interface Payment {
  amount: number
  bank_id: number
  notes: string
  payment_date: string
  payment_mode: string
  serial_number: string
  settled_amount: number
}

export type AmountSettledShowModalProps = {
  serialNumber: string
  documentType: string
  newHashId: string
  currencySymbol?: string
}

const AmountSettledModal = forwardRef<{}, { paymentsDetail: Payment[] }>((props, ref) => {
  const [visible, setVisible] = useState(false)
  const [invoiceDetails, setInvoiceDetails] = useState<AmountSettledShowModalProps>({
    serialNumber: '',
    documentType: '',
    newHashId: '',
    currencySymbol: '',
  })

  useImperativeHandle(ref, () => ({
    showModal,
  }))

  const showModal = (data: AmountSettledShowModalProps) => {
    setVisible(true)
    setInvoiceDetails(data)
  }

  const onCancel = () => {
    setVisible(false)
  }

  const messageJsx = (
    <p>
      <span className="text-gray-700 font-size-16">
        Amount Settled for the invoice{' '}
        <span className="font-weight-bold">{invoiceDetails.serialNumber}</span> is more than the
        total amount. Please delete Payments and try again.{' '}
      </span>{' '}
    </p>
  )

  return (
    <>
      <Modal
        maskClosable={false}
        open={visible}
        closable
        title={
          <>
            <i className="fad fa-info-circle fa-beat mr-3 text-danger"></i>
            Warning
          </>
        }
        footer={
          <>
            <Button type="danger" onClick={onCancel}>
              Close
            </Button>
          </>
        }
        destroyOnClose
        onCancel={onCancel}
        closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
      >
        {messageJsx}
        {props.paymentsDetail.length && (
          <div className="mt-3">
            <p className="font-bold">Payments</p>
            <PaymentCards
              payments={props.paymentsDetail}
              currencySymbol={invoiceDetails.currencySymbol}
              variant="large"
            />
          </div>
        )}
      </Modal>
    </>
  )
})

export default AmountSettledModal
