import { StyleSheet, Text, View } from '@react-pdf/renderer'

const getStyles = (FONT_FACTOR, PAPER_SIZE, NUM_CUSTOM_COLUMNS) => {
  var font_size = (FONT_FACTOR * (9 * 15)) / (16 + NUM_CUSTOM_COLUMNS)

  return StyleSheet.create({
    batch_details: {
      fontSize: font_size,
      color: '#212020',
      display: 'block',
    },
  })
}
const ItemBatchDetails = ({ invoice, item }) => {
  var styles = getStyles(
    invoice.invoice_settings.font_size,
    invoice.invoice_settings.paper_size == 'A4' ? 1 : 0.8,
    invoice.custom_col_names.length,
  )

  return (
    invoice.batch_settings.show_as_separate_cols == 0 && (
      <View style={styles.batch_details}>
        {item.batch_no != '' && (
          <Text>
            {item.has_batches == 2
              ? invoice.batch_settings.serial_number_label
                ? invoice.batch_settings.serial_number_label + ': \n'
                : ''
              : 'Batch No: '}
            {item.has_batches == 2 ? (
              <>
                <View style={styles.table}>
                  {item.batch_no.split(',').map((sno, colIndex) => {
                    return (
                      <Text>
                        {' '}
                        {sno}
                        {'\n'}
                      </Text>
                    )
                  })}
                </View>
              </>
            ) : (
              item.batch_no
            )}
          </Text>
        )}
        {item.has_batches == 1 && item.mfg_date != '' && <Text>{'Mfg Dt: ' + item.mfg_date}</Text>}
        {item.has_batches == 1 && item.expiry_date != '' && (
          <Text>{'Exp Dt: ' + item.expiry_date}</Text>
        )}
      </View>
    )
  )
}

export default ItemBatchDetails
