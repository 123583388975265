import React, { useState, useEffect, useImperativeHandle, forwardRef, createRef } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Drawer,
  Form,
  Input,
  List,
  message,
  Switch,
  Tabs,
  Typography,
  Select,
} from 'antd'

import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { getAPI, getAPIData } from 'services/jwt'
import CustomDeleteModal from 'components/modal/delete/customFieldDelete'
import SectionHeader from 'components/other/sectionHeader'
import EmailEditor from 'components/other/editorTemplate'
import { templateMentionData } from 'utils/constants'
import { removeMentionSpans } from 'utils/utilFunctions'

const { TabPane } = Tabs
const { Option } = Select

const sms_templates = [
  {
    label: 'Thank you for choosing our services at ',
    value: 'Thank you for choosing our services at',
  },
  {
    label: 'Thank you for your purchase at ',
    value: 'Thank you for your purchase at',
  },
]

const ShareTemplates = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false)
  const [key, setKey] = useState('1')
  const [showdocumentDrawer, setShowDocumentDrawer] = useState(false)
  const [email_list, setEmailList] = useState([])
  const [selected_email, setSelectedEmail] = useState({})
  const [default_sms, setDefaultSMS] = useState('')
  const [showEmailDrawer, setShowEmailDrawer] = useState(false)
  const [editorState, setEditorState] = useState('')
  const [headerEditorState, setHeaderEditorState] = useState('')
  const [footerEditorState, setFooterEditorState] = useState('')
  const [label, setLabel] = useState('')
  const [isEmailTemplateAdd, setIsEmailTemplateAdd] = useState(false)
  const [whatsappMessage, setWhatsappMessage] = useState({
    line1: '',
    line2: '',
  })
  const user = useSelector(state => state.user)
  const integrations = useSelector(state => state.permissions.integrations)
  const emailRef = createRef()
  const deleteModalRef = createRef()

  const showDrawer = (type = 'email') => {
    console.log('type', type)
    let key = type == 'sms' ? '3' : type == 'whatsapp' ? '2' : '1'
    setShowDocumentDrawer(true)
    onTabChange(key)
  }
  useImperativeHandle(ref, () => ({
    showDrawer,
  }))
  const closeDrawer = () => {
    props.refreshNotesTerms()
    setShowDocumentDrawer(false)
  }
  const editEmail = (data, is_Add) => {
    setSelectedEmail(data)
    setShowEmailDrawer(true)
    setIsEmailTemplateAdd(is_Add)
  }
  const onChangeEmailText = async req => {
    if (req.email_combined) {
      const processedContent = removeMentionSpans(req.email_combined)
      req.email_combined = processedContent
    }
    if (req.label == '') {
      message.error('Label cannot be empty')
      return
    }
    if (req.email_combined) {
      if (req.email_combined == '' || req.email_combined == '<p><br></p>') {
        message.error('Email text cannot be empty')
        return
      }
    } else {
      if (req.email_text == '' || req.email_text == '<p><br></p>') {
        message.error('Email text cannot be empty')
        return
      }
    }
    const data = await getAPI('utils', 'email_templates', req)
    onTabChange(key)
    setShowEmailDrawer(false)
  }
  const onDescriptionChange = data => {
    setSelectedEmail({
      ...selected_email,
      // email_text: stateToHTML(data.getCurrentContent()),
    })
  }
  const onTabChange = async key => {
    if (key == '3') {
      await getDefaultSMS()
    } else if (key == '2') {
      await getDefaultWhatsappMessage()
    } else if (key == '1') {
      await getEmailTemplates()
    }
    setKey(key)
  }
  const getEmailTemplates = async () => {
    const data = await getAPIData('utils', 'email_templates')
    if (data?.success) {
      setEmailList(data.email_templates)
    }
  }
  const getDefaultSMS = async () => {
    const data = await getAPIData('utils', 'general_settings')
    if (data?.success) {
      setDefaultSMS(data.general_settings.default_sms_template || '')
    }
  }
  const onChangeDefaultSms = async item => {
    console.log('item', item)
    var req = {
      default_sms_template: item.value,
    }
    const data = await getAPI('utils', 'general_settings', req)
    if (data?.success) {
      message.success('SMS Template Updated')
      setDefaultSMS(item.value)
    }
  }
  const getDefaultWhatsappMessage = async () => {
    const data = await getAPIData('user', 'settings')
    if (data?.success) {
      setWhatsappMessage({
        line1: data.settings.whatsapp_line1,
        line2: data.settings.whatsapp_line2,
      })
    }
  }
  const changeWhatsappMessage = async () => {
    var req = {
      whatsapp_line1: whatsappMessage.line1,
      whatsapp_line2: whatsappMessage.line2,
    }
    const data = await getAPI('user', 'settings', req)
    if (data?.success) {
      message.success('Whatsapp Message Updated')
    }
  }
  return (
    <div>
      <Drawer
        title={
          <>
            <div className="flex items-center">
              <div className="mr-auto">
                <span className="font-weight-bolder text-gray-700 font-size-21 mr-2">
                  Templates
                </span>
              </div>
            </div>
          </>
        }
        placement="right"
        width={isMobile ? '90%' : '50%'}
        closable={() => closeDrawer()}
        onClose={() => closeDrawer()}
        closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
        open={showdocumentDrawer}
        destroyOnClose={true}
        footer={
          <>
            <Button
              onClick={() => (key == '2' ? changeWhatsappMessage() : closeDrawer())}
              type="primary"
              className="mr-2 arrow-transition"
              loading={loading}
            >
              Save
              <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
            </Button>

            <Button onClick={() => closeDrawer()} type="danger">
              Close
            </Button>
          </>
        }
      >
        <Card className="w-full">
          <Tabs
            defaultActiveKey="1"
            activeKey={key}
            onChange={onTabChange}
            destroyInactiveTabPane={true}
          >
            <TabPane tab="Email Templates" key="1">
              <>
                <List
                  header={
                    <div className="flex w-full justify-between text-gray-500 font-weight-bolder font-size-14">
                      <div className="w-1/2">
                        <Typography.Text
                          style={{ color: '#000', backgroundColor: '#ffe3ac' }}
                          className="p-1 font-weight-medium font-size-14"
                        >
                          Note: Active fields will be shown in the Email Templates.
                        </Typography.Text>
                      </div>
                      <div className="flex w-1/2 justify-between gap-2">
                        <div className="w-1/3 ">Set as Default?</div>
                        <div className="w-1/3 ">Active</div>
                        <div className="w-1/3 text-right mr-3">Actions</div>
                      </div>
                    </div>
                  }
                  footer={
                    <div className="center mt-4">
                      <Button
                        type="dashed"
                        style={{ borderColor: 'blue' }}
                        onClick={() => {
                          setEditorState('')
                          setLabel('')
                          setHeaderEditorState('')
                          setFooterEditorState('')
                          editEmail(
                            {
                              template_id: -1,
                              is_delete: 0,
                              is_active: 1,
                              email_text: '',
                              email_combined: '',
                              label: '',
                              is_default: 0,
                            },
                            false,
                          )
                        }}
                        block
                        icon={
                          <PlusOutlined className="font-weight-bold" style={{ color: 'blue' }} />
                        }
                      >
                        <span className="font-weight-bold" style={{ color: 'blue' }}>
                          New Email Template
                        </span>
                      </Button>
                    </div>
                  }
                  dataSource={email_list}
                  renderItem={item => (
                    <List.Item actions={[]}>
                      <div className="w-1/2">
                        <span className="font-weight-bold font-size-1">{item.label}</span>
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              item.email_combined !== ''
                                ? item.email_combined
                                : (item.email_header || '') +
                                  '<br />' +
                                  (item.email_text || '') +
                                  '<br />' +
                                  (item.email_footer || ''),
                          }}
                          className="p-2 mr-2 my-2 font-size-14 bg-gray-50 rounded-lg ql-editor"
                        ></p>
                      </div>
                      <div className="flex w-1/2 justify-between gap-2">
                        <div className="w-1/3">
                          <Switch
                            checkedChildren="Default"
                            unCheckedChildren="Not Default"
                            checked={item.is_default}
                            style={{ color: 'blue' }}
                            onChange={(checked, e) =>
                              onChangeEmailText({ ...item, is_default: checked })
                            }
                          />
                        </div>
                        <div className="w-1/3">
                          <Switch
                            checked={item.is_active}
                            style={{ color: 'blue' }}
                            onChange={(checked, e) =>
                              onChangeEmailText({ ...item, is_active: checked })
                            }
                          />
                        </div>
                        <div className="w-1/3 text-right mr-3">
                          <a
                            onClick={e => {
                              setEditorState(
                                item.email_combined == ''
                                  ? [item.email_header, item.email_text, item.email_footer]
                                      .filter(Boolean)
                                      .join('<br /><br />')
                                  : item.email_combined,
                              )
                              // setHeaderEditorState(item.email_header ?? '')
                              // setFooterEditorState(item.email_footer ?? '')
                              setLabel(item.label)
                              editEmail(
                                {
                                  ...item,
                                },
                                true,
                              )
                            }}
                            className="card-link font-weight-bold text-action font-size-14 ml-3"
                          >
                            <span>Edit</span>
                            <i className="fa fa-pencil-o" />
                          </a>
                          <span
                            className="text-danger font-weight-bold ml-3"
                            style={{ cursor: 'pointer' }}
                            onClick={e => {
                              e.stopPropagation()
                              deleteModalRef?.current?.showDeleteModal(item, true)
                            }}
                          >
                            Delete
                          </span>
                        </div>
                      </div>
                    </List.Item>
                  )}
                />
                <CustomDeleteModal ref={deleteModalRef} onFinish={onChangeEmailText} />
              </>
            </TabPane>
            <TabPane tab="WhatsApp" key="2">
              <div className="p-4 ml-2 bg-gray-50 rounded-lg font-weight-bold font-size-14">
                <p className="pl-1">Hello! *Customer Name*</p>
                <Form.Item extra="Document Whatsapp Message Line 1" colon={false}>
                  <Input
                    placeholder="Enter document whatsapp message line1"
                    value={whatsappMessage.line1}
                    onChange={e => {
                      setWhatsappMessage({ ...whatsappMessage, line1: e.target.value })
                    }}
                  />
                </Form.Item>
                <p className="pl-1">Document No: ******</p>
                <p className="pl-1">Total: Rs ******</p>
                <p className="pl-1 pb-2">Link: https://swipe.pe/n/view/xxxxxx/xxxxxx</p>
                <Form.Item extra="Document Whatsapp Message Line 2" colon={false}>
                  <Input
                    placeholder="Enter document whatsapp message line2"
                    value={whatsappMessage.line2}
                    onChange={e => {
                      setWhatsappMessage({ ...whatsappMessage, line2: e.target.value })
                    }}
                  />
                </Form.Item>
                <p className="pl-1">Thanks</p>
                <p className="pl-1">*Company Name*</p>
                <p className="pl-1">*123456xxxx*</p>
              </div>
            </TabPane>
            <TabPane tab="SMS" key="3">
              <>
                <List
                  header={
                    <div className="flex w-full justify-between font-weight-bolder font-size-14 text-gray-500">
                      <div>
                        <Typography.Text
                          style={{ color: '#000', backgroundColor: '#ffe3ac' }}
                          className="p-1 font-weight-medium font-size-14"
                        >
                          SMS Templates
                        </Typography.Text>
                      </div>
                      <div className="mr-4">Default</div>
                    </div>
                  }
                  footer={null}
                  dataSource={sms_templates}
                  renderItem={item => (
                    <List.Item>
                      <div className="w-1/2">
                        <p className="p-2 bg-gray-50 font-size-14 rounded-lg">
                          Hello!
                          <br />
                          {item.label + ' '} <strong>{user.selectedCompany.company_name}</strong>.
                          <br />
                          Invoice No: ******
                          <br />
                          Total: Rs ******
                          <br />
                          Link: <strong>https://swipe.pe/n/view/sales/xxxxxx</strong>
                        </p>
                      </div>
                      <div className="w-1/2 flex justify-end">
                        <Switch
                          checkedChildren="Default"
                          unCheckedChildren="Not Default"
                          checked={item.value == default_sms}
                          style={{ color: 'blue' }}
                          onChange={(checked, e) => onChangeDefaultSms({ ...item })}
                        />
                      </div>
                    </List.Item>
                  )}
                />
              </>
            </TabPane>
          </Tabs>
        </Card>
        <Drawer
          title={
            <>
              <div className="flex items-center">
                <div className="mr-auto">
                  <span className="font-weight-bolder text-gray-700 font-size-21 mr-2">
                    {`${isEmailTemplateAdd ? 'Edit' : 'Add'} Email Template`}
                  </span>
                </div>
              </div>
            </>
          }
          placement="right"
          width={isMobile ? '90%' : '40%'}
          closable={() => setShowEmailDrawer(false)}
          onClose={() => setShowEmailDrawer(false)}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          open={showEmailDrawer}
          destroyOnClose={true}
          extra={
            <Button
              onClick={() =>
                onChangeEmailText({
                  ...selected_email,
                  label: label,
                  email_combined: editorState,
                })
              }
              type="primary"
              className="arrow-transition"
            >
              Save
              <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
            </Button>
          }
          footer={
            <>
              <Button
                onClick={() =>
                  onChangeEmailText({
                    ...selected_email,
                    label: label,
                    email_combined: editorState,
                  })
                }
                type="primary"
                className="mr-2 arrow-transition"
                loading={loading}
              >
                Save
                <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
              </Button>

              <Button onClick={() => setShowEmailDrawer(false)} type="danger">
                Close
              </Button>
            </>
          }
        >
          <Card className="p-5 shadow-md rounded-lg h-[400px]">
            {' '}
            {/* Set a fixed height */}
            <h6 className="font-semibold mb-2">{'Template Name'}:</h6>
            <Input
              placeholder="Template Name"
              className="w-full mb-4"
              value={label}
              onChange={e => setLabel(e.target.value)}
            />
            <h6 className="font-semibold mb-2">Email Text:</h6>
            <EmailEditor
              value={editorState}
              onChange={setEditorState}
              placeholder="Email Text"
              mentionData={templateMentionData}
              template={selected_email}
            />
          </Card>
        </Drawer>
      </Drawer>
    </div>
  )
})

export default ShareTemplates
