import { Button, Checkbox, Dropdown, Input, Menu } from 'antd'
import { useEffect, useRef, useState } from 'react'

const MultiSelectDropdown = ({
  list,
  title,
  label,
  label_id,
  onChange,
  default_value = undefined,
  showLeftBorder = true,
  showCount = false,
  styles = {},
  classStyles = '',
  disabled = false,
}) => {
  const [selectedList, setSelectedList] = useState([])
  const [open, setOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const dropdownRef = useRef()
  const handleClick = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.body.addEventListener('click', handleClick)
    if (default_value) {
      if (Array.isArray(default_value)) {
        setSelectedList(default_value)
      } else {
        setSelectedList([default_value])
      }
    }
    return () => {
      document.body.removeEventListener('click', handleClick)
    }
  }, [])

  const filteredList = list?.filter(item =>
    item[label]?.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  const menu = (
    <Menu style={{ maxHeight: 400 }} className="custom-style-scrollbar">
      <Menu.Item key="search" onClick={e => e.domEvent.stopPropagation()}>
        <Input
          placeholder="Search"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
      </Menu.Item>
      {selectedList?.map(item_id => {
        const selectedItem = list?.find(item => item[label_id] === item_id)
        return (
          <Menu.Item key={selectedItem[label_id]} onClick={e => e.domEvent.stopPropagation()}>
            <Checkbox
              onChange={e => {
                const updatedSelectedList = selectedList?.filter(id => id !== item_id)
                setSelectedList([...updatedSelectedList])
                onChange(updatedSelectedList)
              }}
              className="w-full"
              checked={true}
              disabled={disabled}
            >
              {selectedItem[label]}
            </Checkbox>
          </Menu.Item>
        )
      })}
      {filteredList
        ?.filter(item => !selectedList?.includes(item[label_id]))
        .map(item => (
          <Menu.Item key={item[label_id]} onClick={e => e.domEvent.stopPropagation()}>
            <Checkbox
              onChange={e => {
                const updatedSelectedList = e.target.checked
                  ? [...selectedList, item[label_id]]
                  : selectedList?.filter(each_item => each_item !== item[label_id])
                setSelectedList([...updatedSelectedList])
                onChange(updatedSelectedList)
              }}
              className="w-full"
              checked={selectedList?.includes(item[label_id])}
              disabled={disabled}
            >
              {item[label]}
            </Checkbox>
          </Menu.Item>
        ))}
      {filteredList?.length == 0 && <Menu.Item>No Data</Menu.Item>}
    </Menu>
  )

  return (
    <div ref={dropdownRef}>
      <Dropdown overlay={menu} open={open}>
        <Button
          className={classStyles}
          style={{
            ...(showLeftBorder
              ? {}
              : {
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }),
            ...styles,
          }}
          type="input-button"
          onClick={() => setOpen(!open)}
        >
          <span style={{ color: selectedList?.length > 0 ? 'var(--primary)' : '' }}>
            {title}
            {showCount
              ? ' (' + String(selectedList?.length) + '/' + String(list?.length) + ')'
              : ''}
          </span>
          <i
            className="fa-solid fa-chevron-down ml-2"
            style={{ color: selectedList?.length > 0 ? 'var(--primary)' : '' }}
          />
        </Button>
      </Dropdown>
    </div>
  )
}

export default MultiSelectDropdown
