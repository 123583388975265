import { Image, Text, View } from '@react-pdf/renderer'
import React from 'react'
import { getStateName } from '../export_condition'
import { styles as getStyles } from './styles'

const CompanyDetails = ({ invoice, type }) => {
  var styles = getStyles(invoice)
  var data = invoice.gst_details.id == undefined ? invoice.company : invoice.gst_details
  var tax_id_label = invoice.company.tax_id_label
  return (
    <View fixed={invoice.invoice_settings.repeat_header == 1}>
      {invoice.invoice_settings.show_company_details == 1 && (
        <View style={styles.company_headerContainer}>
          <View style={styles.company_blockC}>
            <Text
              style={[
                data.company_name.length > 40
                  ? { fontSize: (15.3 * 40) / data.company_name.length }
                  : styles.company_large,
                styles.company_bold,
              ]}
            >
              {data.company_name}
            </Text>

            <Text>
              {data.gstin.length == 15 && (
                <>
                  <Text style={styles.company_label}>{tax_id_label} </Text>
                  <Text style={[styles.company_invoiceDate, styles.company_bold]}>
                    {data.gstin} {'   '}
                  </Text>
                </>
              )}
              {data.pan_number != '' && data.pan_number != undefined && (
                <>
                  <Text style={styles.company_label}>PAN </Text>
                  <Text style={[styles.company_invoiceDate, styles.company_bold]}>
                    {data.pan_number}
                  </Text>
                </>
              )}
            </Text>
            {'custom_fields' in data && data.custom_fields.length > 0 && (
              <>
                {data.custom_fields.map((field, index) => {
                  if (field.value != '' && field.value != null && field.value != undefined) {
                    return (
                      <Text key={index}>
                        <Text style={styles.company_label}>{field.field_name} </Text>
                        <Text style={[styles.company_invoiceDate, styles.company_bold]}>
                          {field.value}
                        </Text>
                      </Text>
                    )
                  }
                })}
              </>
            )}
            <View style={styles.company_address_block}>
              <Text
                style={styles.billTo_billTo}
              >{`${invoice.invoice_settings.labels.customer_billing_address}:`}</Text>
              <Text style={styles.company_address}>{data.address_1}</Text>
              <Text style={styles.company_address}>{data.address_2}</Text>
              <Text style={styles.company_address}>
                {data.city == ''
                  ? ''
                  : data.city +
                    (data.state == '' ||
                    data.state == undefined ||
                    data.state == null ||
                    data.state == 'OTHERTERRITORY' ||
                    data.state == '97-OTHER TERRITORY' ||
                    data.state == '97-OTHERTERRITORY'
                      ? ''
                      : ',')}{' '}
                {data.state == '' ||
                data.state == '97-OTHER TERRITORY' ||
                data.state == '97-OTHERTERRITORY' ||
                data.state == 'OTHERTERRITORY'
                  ? ''
                  : getStateName(data.state) +
                    (data.pincode == '' || data.pincode == undefined || data.pincode == null
                      ? ''
                      : ', ')}
                {invoice.is_export == 1 && `${data.country}${data.pincode != '' ? ', ' : ''}`}
                {data.pincode}
              </Text>
              <Text>
                {data.mobile != '' && data.mobile != 'None' && (
                  <>
                    <Text style={styles.company_label}>Mobile </Text>
                    <Text style={styles.company_invoiceDate}>
                      {`+${data.dial_code} ${data.mobile}`}
                      {data.alt_contact == '' ? '   ' : ', '}
                      {data.alt_contact}
                      {data.alt_contact != '' && '   '}
                    </Text>
                  </>
                )}
                {data.email != '' && (
                  <>
                    <Text style={styles.company_label}>Email </Text>
                    <Text style={styles.company_invoiceDate}>{data.email}</Text>
                  </>
                )}
              </Text>
              {data.website != '' && (
                <>
                  <Text>
                    <Text style={styles.company_label}>Website </Text>
                    <Text style={styles.company_invoiceDate}>{data.website}</Text>
                  </Text>
                </>
              )}

              {/*{data.pan_number != '' && (
                  <>
                    <Text>
                      <Text style={styles.company_label}>PAN </Text>
                      <Text style={[styles.company_invoiceDate, styles.company_bold]}>{data.pan_number}</Text>
                    </Text>
                  </>
                )}*/}
            </View>
          </View>

          {invoice.company.logo != null && invoice.company.logo != '' && (
            <View style={styles.company_blockI}>
              <Image
                style={styles.company_logo}
                src={{
                  uri: invoice.company.logo,
                  method: 'GET',
                  headers: { 'Cache-Control': 'no-cache' },
                }}
                crossorigin="anonymous"
              />
            </View>
          )}
        </View>
      )}

      <View style={styles.company_headerContainer}>
        <View style={styles.company_block2}>
          <Text>
            <Text style={styles.company_label}>{invoice.document_title} #: </Text>
            <Text style={[styles.company_invoiceDate, styles.company_bold]}>
              {invoice.serial_number}
            </Text>
          </Text>
        </View>

        <View style={styles.company_block2}>
          <Text>
            <Text style={styles.company_label}>{invoice.document_title} Date: </Text>
            <Text style={[styles.company_invoiceDate, styles.company_bold]}>
              {invoice.document_date}
            </Text>
          </Text>
        </View>
        {invoice.invoice_settings.show_due_date == 1 && (
          <View style={styles.company_block2}>
            <Text>
              <Text style={styles.company_label}>Due Date: </Text>
              <Text style={styles.company_invoiceDate}>{invoice['document_due_date']}</Text>
            </Text>
          </View>
        )}
      </View>

      {invoice.document_type == 'purchases' && invoice.supplier_invoice_serial_number != '' && (
        <View style={styles.company_headerContainer}>
          <View style={styles.company_block2}>
            <Text>
              <Text style={styles.company_label}>Supplier Invoice #: </Text>
              <Text style={[styles.company_invoiceDate, styles.company_bold]}>
                {invoice.supplier_invoice_serial_number}
              </Text>
            </Text>
          </View>

          <View style={styles.company_block2}>
            <Text>
              <Text style={styles.company_label}>Supplier Invoice Date: </Text>
              <Text style={[styles.company_invoiceDate, styles.company_bold]}>
                {invoice.supplier_invoice_date_string}
              </Text>
            </Text>
          </View>
        </View>
      )}
    </View>
  )
}

export default CompanyDetails
