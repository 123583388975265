import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { breakName } from 'components/other/utility'
import { cloneDeep } from 'components/other/loadashUtils'
import { Fragment } from 'react'

const borderColor = '#1d1df'

const getStyles = invoice => {
  let FONT_FACTOR = invoice.invoice_settings.font_size
  let PAPER_SIZE = invoice.invoice_settings.paper_size == 'A4' ? 1 : 0.8
  let locale = invoice.company.locale
  let font_style = invoice.invoice_settings.font_style

  if (locale == 'English') {
    locale = font_style
  }
  return StyleSheet.create({
    container: {
      flexDirection: 'row',
      borderTopWidth: 0.5,
      borderBottomWidth: 0.5,
      borderColor: '#1d1d1f',
      color: '#141414',
      fontSize: 8.1 * FONT_FACTOR,
      textAlign: 'center',
      fontFamily: `helmed_${locale}`,
    },
    item: {
      flexDirection: 'row',
      fontSize: 8.1 * FONT_FACTOR,
      borderBottomWidth: 0.5,
      borderBottomColor: '#333333',
      textAlign: 'center',
      fontFamily: `helmed_${locale}`,
      flexWrap: 'wrap',
    },
    footer: {
      flexDirection: 'row',
      color: '#141414',
      fontSize: 8.1,
      textAlign: 'center',
      fontFamily: `helbold_${locale}`,
      flexWrap: 'wrap',
    },
    cell: {
      paddingVertical: 0.9,
      paddingHorizontal: 4.5,
      fontSize: 8.1 * FONT_FACTOR,
      borderRightWidth: 0.5,
      borderRightColor: '#333333',
      // borderBottomWidth: 0.36,
      // borderBottomColor: '#1d1d1f',
    },
  })
}

const InvoiceHsnTable = props => {
  const { invoice } = props
  const invoice2 = cloneDeep(invoice)
  const options = { maximumFractionDigits: 2, minimumFractionDigits: 2 }
  const tax_options = { maximumFractionDigits: 2, minimumFractionDigits: 2 }
  var styles = getStyles(invoice)
  var FONT_FACTOR = invoice.invoice_settings.font_size
  const isIGST = invoice2.tax.length > 0 && invoice2.tax[0].tax_type == 'IGST'
  const hasCess = invoice.cess_amount > 0

  const hsn_label_width = hasCess ? 12 : 24
  const general_combined_col_width = hasCess ? 20 : 24
  const integrated_combined_col_width = hasCess ? 30 : 48

  const general_tax_header = (
    <View fixed>
      <View style={styles.container}>
        <Text
          style={{
            ...styles.cell,
            textAlign: 'left',
            width: `${hsn_label_width}%`,
          }}
        >
          {invoice.invoice_settings.labels.hsn_code}
        </Text>
        <Text
          style={{
            ...styles.cell,
            textAlign: 'right',
            width: `14%`,
          }}
        >
          Taxable Value
        </Text>
        <View style={{ flexDirection: 'column', width: `${general_combined_col_width}%` }}>
          <View>
            <Text
              style={{
                ...styles.cell,
                textAlign: 'center',
                width: `100%`,
                borderBottomWidth: 0.36,
              }}
            >
              Central Tax
            </Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text
              style={{
                ...styles.cell,
                width: `50%`,
              }}
            >
              Rate
            </Text>
            <Text
              style={{
                ...styles.cell,
                width: `50%`,
              }}
            >
              Amount
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: 'column', width: `${general_combined_col_width}%` }}>
          <View>
            <Text
              style={{
                ...styles.cell,
                textAlign: 'center',
                width: `100%`,
                borderBottomWidth: 0.36,
              }}
            >
              State/UT Tax
            </Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text
              style={{
                ...styles.cell,
                width: `50%`,
              }}
            >
              Rate
            </Text>
            <Text
              style={{
                ...styles.cell,
                width: `50%`,
              }}
            >
              Amount
            </Text>
          </View>
        </View>

        {hasCess && (
          <View style={{ flexDirection: 'column', width: `${general_combined_col_width}%` }}>
            <View>
              <Text
                style={{
                  ...styles.cell,
                  textAlign: 'center',
                  width: `100%`,
                  borderBottomWidth: 0.36,
                }}
              >
                Cess
              </Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Text
                style={{
                  ...styles.cell,
                  width: `50%`,
                }}
              >
                Rate
              </Text>
              <Text
                style={{
                  ...styles.cell,
                  width: `50%`,
                }}
              >
                Amount
              </Text>
            </View>
          </View>
        )}

        <Text
          style={{
            ...styles.cell,
            textAlign: 'center',
            width: `14%`,
            borderRight: 'none',
          }}
          hyphenationCallback={e => breakName(e)}
        >
          Total Tax Amount
        </Text>
      </View>
      <View></View>
    </View>
  )

  const integrated_tax_header = (
    <View fixed>
      <View style={styles.container}>
        <Text
          style={{
            ...styles.cell,
            textAlign: 'left',
            width: `${hsn_label_width}%`,
          }}
        >
          {invoice.invoice_settings.labels.hsn_code}
        </Text>
        <Text
          style={{
            ...styles.cell,
            textAlign: 'right',
            width: `14%`,
          }}
        >
          Taxable Value
        </Text>
        <View style={{ flexDirection: 'column', width: `${integrated_combined_col_width}%` }}>
          <View>
            <Text
              style={{
                ...styles.cell,
                textAlign: 'center',
                width: `100%`,
                borderBottomWidth: 0.36,
              }}
            >
              Integrated Tax
            </Text>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text
              style={{
                ...styles.cell,
                width: `50%`,
              }}
            >
              Rate
            </Text>
            <Text
              style={{
                ...styles.cell,
                width: `50%`,
              }}
            >
              Amount
            </Text>
          </View>
        </View>
        {hasCess && (
          <View style={{ flexDirection: 'column', width: `${integrated_combined_col_width}%` }}>
            <View>
              <Text
                style={{
                  ...styles.cell,
                  textAlign: 'center',
                  width: `100%`,
                  borderBottomWidth: 0.36,
                }}
              >
                Cess
              </Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Text
                style={{
                  ...styles.cell,
                  width: `50%`,
                }}
              >
                Rate
              </Text>
              <Text
                style={{
                  ...styles.cell,
                  width: `50%`,
                }}
              >
                Amount
              </Text>
            </View>
          </View>
        )}
        <Text
          style={{
            ...styles.cell,
            textAlign: 'center',
            borderRight: 'none',
            width: `14%`,
          }}
          hyphenationCallback={e => breakName(e)}
        >
          Total Tax Amount
        </Text>
      </View>
      <View></View>
    </View>
  )

  let items = invoice2.items
  // insert additional charges as items into items, with shema same as reduce method below
  if (invoice2.document_custom_additional_charges) {
    invoice2.document_custom_additional_charges.forEach(charge => {
      items.push({
        hsn_code: charge.sac_code,
        tax_amount: charge.tax_amount * charge.type,
        tax: charge.tax,
        net_amount: charge.net_amount * charge.type,
        cess_amount: null,
        cess: null,
        cess_on_qty: null,
      })
    })
  }

  // merge all the duplicate hsn codes into one
  items = items.reduce((acc, item) => {
    const item_hsn = acc.find(
      item_hsn => item_hsn.hsn_code == item.hsn_code && item.tax == item_hsn.tax,
    )
    if (item_hsn) {
      item_hsn.tax_amount += item.tax_amount
      item_hsn.net_amount += item.net_amount
      item_hsn.cess_amount += item.cess_amount
      // if (item_hsn.cess !== item.cess) {
      //   item_hsn.cess = null
      // }
      if (item.cess !== 0 && !item_hsn.cessValues.includes(item.cess)) {
        item_hsn.cessValues.push(item.cess)
        item_hsn.cessOnQtyValues.push(item.cess_on_qty)
      }
    } else {
      acc.push({
        hsn_code: item.hsn_code,
        tax_amount: item.tax_amount,
        tax: item.tax,
        net_amount: item.net_amount,
        cess_amount: item.cess_amount,
        cess: item.cess,
        cess_on_qty: item.cess_on_qty,
        unit: item.unit,
        cessValues: item.cess !== 0 ? [item.cess] : [],
        cessOnQtyValues: item.cess_on_qty !== 0 ? [item.cess_on_qty] : [],
      })
    }
    return acc
  }, [])

  // if there are multiple cess values for a single hsn code, then set cess to null
  items = items.map(item => {
    if (item.cessValues?.length > 1) {
      item.cess = null
    } else if (item.cessValues?.length == 1) {
      item.cess = item.cessValues[0]
    } else {
      item.cess = 0
    }
    if (item.cessOnQtyValues?.length > 1) {
      item.cess_on_qty = null
    } else if (item.cessOnQtyValues?.length == 1) {
      item.cess_on_qty = item.cessOnQtyValues[0]
    } else {
      item.cess_on_qty = 0
    }
    delete item.cessValues
    delete item.cessOnQtyValues
    return item
  })

  let net_amount = 0,
    tax_amount = 0
  items.forEach(item => {
    net_amount += item.net_amount
    tax_amount += item.tax_amount
  })
  if (hasCess) {
    tax_amount += invoice2.cess_amount
  }
  items = items.filter(item => item.tax_amount > 0)

  const rows = items.map((item, index) => {
    const itemHasCess = item.cess != null && item.cess != 0
    const itemHasCessOnQty = item.cess_on_qty != null && item.cess_on_qty != 0
    const hasCessAndCessOnQty = itemHasCess && itemHasCessOnQty

    const items = (
      <Fragment key={index}>
        <View style={{ ...styles.item }}>
          <Text style={{ ...styles.cell, textAlign: 'left', width: `${hsn_label_width}%` }}>
            {item.hsn_code == '' ? '-' : item.hsn_code}
          </Text>
          <Text style={{ ...styles.cell, textAlign: 'right', width: '14%' }}>
            {Number(item.net_amount)
              .toFixed(2)
              .toLocaleString('en-IN', options)}
          </Text>
          {!isIGST && (
            <>
              <Text style={{ ...styles.cell, width: `${general_combined_col_width / 2}%` }}>
                {Number(item.tax / 2)}%
              </Text>
              <Text style={{ ...styles.cell, width: `${general_combined_col_width / 2}%` }}>
                {Number(item.tax_amount / 2).toLocaleString('en-IN', tax_options)}
              </Text>
              <Text style={{ ...styles.cell, width: `${general_combined_col_width / 2}%` }}>
                {Number(item.tax / 2)}%
              </Text>
              <Text style={{ ...styles.cell, width: `${general_combined_col_width / 2}%` }}>
                {Number(item.tax_amount / 2).toLocaleString('en-IN', tax_options)}
              </Text>
            </>
          )}
          {isIGST && (
            <>
              <Text style={{ ...styles.cell, width: `${integrated_combined_col_width / 2}%` }}>
                {Number(item.tax)}%
              </Text>
              <Text style={{ ...styles.cell, width: `${integrated_combined_col_width / 2}%` }}>
                {Number(item.tax_amount)
                  .toFixed(2)
                  .toLocaleString('en-IN', options)}
              </Text>
            </>
          )}
          {hasCess && (
            <>
              <Text
                style={{
                  ...styles.cell,
                  textAlign: 'center',
                  width: `${
                    isIGST ? integrated_combined_col_width / 2 : general_combined_col_width / 2
                  }%`,
                  ...(hasCessAndCessOnQty ? { fontSize: 5.1 * FONT_FACTOR } : {}),
                }}
              >
                {itemHasCess ? `${Number(item.cess)}%` : !itemHasCessOnQty ? '-' : ''}
                {itemHasCessOnQty &&
                  ` ${itemHasCess ? '+' : ''} ${Number(item.cess_on_qty)}/${item.unit}`}
              </Text>
              <Text
                style={{
                  ...styles.cell,
                  textAlign: 'center',
                  width: `${
                    isIGST ? integrated_combined_col_width / 2 : general_combined_col_width / 2
                  }%`,
                }}
              >
                {item.cess_amount
                  ? Number(item.cess_amount)
                      .toFixed(2)
                      .toLocaleString('en-IN', options)
                  : '-'}
              </Text>
            </>
          )}
          <Text style={{ ...styles.cell, textAlign: 'right', borderRight: 'none', width: '14%' }}>
            {Number(item.tax_amount + (item.cess_amount ? item.cess_amount : 0))
              .toFixed(2)
              .toLocaleString('en-IN', options)}
          </Text>
        </View>
      </Fragment>
    )
    return items
  })

  const footer = (
    <>
      <Fragment>
        <View style={styles.footer}>
          <Text style={{ ...styles.cell, width: `${hsn_label_width}%`, textAlign: 'right' }}>
            {'TOTAL'}
          </Text>
          <Text style={{ ...styles.cell, textAlign: 'right', width: '14%' }}>
            {Number(net_amount.toFixed(2)).toLocaleString('en-IN', options)}
          </Text>
          {!isIGST && (
            <>
              <Text
                style={{
                  ...styles.cell,
                  textAlign: 'center',
                  width: `${general_combined_col_width / 2}%`,
                }}
              >
                {/* {Number(invoice2.tax / 2).toFixed(2)}% */}
              </Text>
              <Text
                style={{
                  ...styles.cell,
                  textAlign: 'center',
                  width: `${general_combined_col_width / 2}%`,
                }}
              >
                {Number(tax_amount / 2).toLocaleString('en-IN', tax_options)}
              </Text>
              <Text
                style={{
                  ...styles.cell,
                  textAlign: 'center',
                  width: `${general_combined_col_width / 2}%`,
                }}
              >
                {/* {Number(item.tax / 2).toFixed(2)}% */}
              </Text>
              <Text
                style={{
                  ...styles.cell,
                  textAlign: 'center',
                  width: `${general_combined_col_width / 2}%`,
                }}
              >
                {Number(tax_amount / 2).toLocaleString('en-IN', tax_options)}
              </Text>
            </>
          )}
          {isIGST && (
            <>
              <Text style={{ ...styles.cell, width: `${integrated_combined_col_width / 2}%` }}>
                {/* {Number(invoice2.tax / 2).toFixed(2)}% */}
              </Text>
              <Text style={{ ...styles.cell, width: `${integrated_combined_col_width / 2}%` }}>
                {Number(tax_amount.toFixed(2)).toLocaleString('en-IN', options)}
              </Text>
            </>
          )}
          {hasCess && (
            <>
              <Text
                style={{
                  ...styles.cell,
                  textAlign: 'center',
                  width: `${
                    isIGST ? integrated_combined_col_width / 2 : general_combined_col_width / 2
                  }%`,
                }}
              >
                {''}
              </Text>
              <Text
                style={{
                  ...styles.cell,
                  textAlign: 'center',
                  width: `${
                    isIGST ? integrated_combined_col_width / 2 : general_combined_col_width / 2
                  }%`,
                }}
              >
                {Number(invoice2.cess_amount)
                  .toFixed(2)
                  .toLocaleString('en-IN', options)}
              </Text>
            </>
          )}
          <Text style={{ ...styles.cell, textAlign: 'right', borderRight: 'none', width: '14%' }}>
            {Number(tax_amount)
              .toFixed(2)
              .toLocaleString('en-IN', options)}
          </Text>
        </View>
      </Fragment>
    </>
  )

  const header = isIGST ? integrated_tax_header : general_tax_header
  return (
    <Fragment>
      <View>
        {header}
        {rows}
        {footer}
      </View>
    </Fragment>
  )
}

export default InvoiceHsnTable
