import store from 'store'
import { isNil, isEmpty } from 'components/other/loadashUtils'
import {
  CompanyDetails,
  MarketingDetails,
  LoggerParams,
  TrackEventParams,
  UserAttributes,
  UserAttributesResult,
  UserDetails,
  ValidationResult,
  AllDocCounts,
} from './types'
import { LIST_ID, NETCORE_PREFIX, REQUIRED_ATTRIBUTES, TRACKING } from './constants'

declare var smartech: any

/**
 * Retrieves user attributes from the store.
 * @returns {Object} A collection of user attributes and primaryKey.
 */
export const getUserAttributes = (user: any = {}): UserAttributesResult => {
  let userDetails: UserDetails
  let companyDetails: CompanyDetails
  let marketingDetails: MarketingDetails = store.get('marketing_details')
  let allDocCounts: AllDocCounts = {}
  let hasShopify: Boolean = false

  if (!isEmpty(user) && !isEmpty(user.userDetails) && !isEmpty(user.selectedCompany)) {
    // Getting user data from redux
    userDetails = user.userDetails
    companyDetails = user.selectedCompany
    allDocCounts = user.all_doc_counts
    hasShopify = user.has_shopify
  } else {
    // Getting user data from local storage
    userDetails = store.get('user_details')
    companyDetails = store.get('company_details')
  }

  if (userDetails?.user_id && companyDetails?.company_id) {
    const primaryKey = `U${userDetails.user_id}C${companyDetails.company_id}`

    const attributes: UserAttributes = {
      email: userDetails.email,
      mobile: userDetails.mobile,
      NAME: (userDetails.name ? `${userDetails.name}-` : '') + companyDetails.company_name,
      USER_NAME: userDetails.name,
      OWNER: companyDetails.company_name,
      ROLE: userDetails.role,
      PAID: userDetails.paid ? 1 : 0,
      BILLING: userDetails.billing,
      COMPANY_ID: companyDetails.company_id,
      USER_ID: userDetails.user_id,
      GSTIN: companyDetails.gstin,
      COMPANY_STATE: companyDetails.billing_address?.state,
      FIRST_PAYMENT_DATE: netcoreDateFormat(userDetails.start_date),
      END_DATE: netcoreDateFormat(userDetails.end_date),
      SIGNUP_DATE: netcoreDateFormat(marketingDetails?.signup_date),
      INTERESTED_IN: marketingDetails?.interested_in,
      FIRE_TAG: marketingDetails?.fire_tag,
      LEAD_STATUS: marketingDetails?.lead_status,
      REMARKS: marketingDetails?.remarks,
      FOLLOWUP_DATE: netcoreDateFormat(marketingDetails?.followup_date),
      RESOURCE: marketingDetails?.resource,
      DEMO_DONE: marketingDetails?.demo_done ? 1 : 0,
      LAST_USED: netcoreDateFormat(marketingDetails?.last_used),
      BUSINESS_TYPE: marketingDetails?.business_type,
      REACHABILITY: marketingDetails?.reachability ? 1 : 0,
      EINVOICES: allDocCounts?.einvoice ?? 0,
      EWAYBILLS: allDocCounts?.eway_bill ?? 0,
      EXPORT_INVOICES: allDocCounts?.export_invoice ?? 0,
      SHOPIFY_INVOICES: allDocCounts?.shopify_invoices ?? 0,
      SHOPIFY_USER: hasShopify ? 1 : 0,
      SOURCE: 'web',
    }

    return {
      primaryKey,
      attributes,
    }
  }

  return {
    primaryKey: '',
    attributes: {},
  }
}

/**
 * Tracks the user by sending their attributes to netcore.
 * If the required fields or primary key are missing, the function stops execution.
 */
export const trackUser = (user: any): void => {
  const { primaryKey, attributes } = getUserAttributes(user)
  const { isValid, message } = validateAttributes({ primaryKey, attributes })

  if (!isValid) {
    netcoreLogger({ message })
    return
  }

  try {
    smartech('contact', LIST_ID, {
      'pk^uc_id': primaryKey, // Primary key (format pk^[primary_key_name])
      ...attributes,
    })
    smartech('identify', primaryKey)
    netcoreLogger({
      obj: {
        type: TRACKING.userTracking,
        primaryKey,
        attributes,
      },
    })
  } catch (error) {
    netcoreLogger({ message: `Error tracking user: ${error}` })
  }
}

/**
 * Tracks event like page views or button clicks.
 * Identify is used to link all the events to a particular User (UC_ID).
 */
export const trackEvent = ({
  eventName,
  eventAttributes = {},
  includeUserAttributes = false,
  identity,
}: TrackEventParams): void => {
  let { primaryKey, attributes } = getUserAttributes()

  const { isValid, message } = validateAttributes({ primaryKey, attributes })

  if (!isValid && includeUserAttributes) {
    netcoreLogger({ message })
    return
  }

  if (!eventName) {
    netcoreLogger({ message: 'Event name is missing.' })
    return
  }

  // When you want to link the event with a user other than the one that is currently logged in
  if (identity) {
    primaryKey = identity
  }

  if (!includeUserAttributes) {
    attributes = {}
  }

  try {
    smartech('identify', primaryKey)
    smartech('dispatch', eventName, {
      ...attributes, // Fixed user attributes
      ...eventAttributes, // Event specific attributes
    })

    netcoreLogger({
      obj: {
        type: TRACKING.eventTracking,
        eventName,
        primaryKey,
        attributes: {
          ...attributes,
          ...eventAttributes,
        },
      },
    })
  } catch (error) {
    netcoreLogger({ message: `Error tracking event: ${error}` })
  }
}

/**
 * Validates the presence and validity of required attributes.
 * @param {Object} attributes - The attributes to validate.
 * @returns {ValidationResult} Result of validation.
 */
const validateAttributes = ({
  primaryKey,
  attributes,
}: {
  primaryKey: string
  attributes: UserAttributes
}): ValidationResult => {
  for (const field of REQUIRED_ATTRIBUTES) {
    const value = attributes[field as keyof UserAttributes]
    if (isNil(value) || value === '') {
      const message = `Missing or invalid value for required field: ${field}`
      return {
        isValid: false,
        message,
      }
    }
  }

  if (!primaryKey) {
    const message = 'Missing primary key.'
    return {
      isValid: false,
      message,
    }
  }

  return {
    isValid: true,
    message: 'All fields are valid.',
  }
}

/**
 * Logs messages to the console in development mode.
 * @param {LoggerParams} params - The logging parameters.
 */
const netcoreLogger = ({ message = '', obj = '' }: LoggerParams): void => {
  if (process.env.REACT_APP_STAGE === 'development') {
    console.log(`${NETCORE_PREFIX}${message}`, obj)
  }
}

/**
 * Formats the date string to the format expected by netcore.
 * @param {string | undefined} dateStr - The date string to format.
 * @returns {string} The formatted date string (YYYY-MM-DD HH:mm:ss).
 */
export const netcoreDateFormat = (dateStr?: string): string => {
  if (!dateStr) return ''

  const dateObj = new Date(dateStr)

  const year = dateObj.getFullYear()
  const month = String(dateObj.getMonth() + 1).padStart(2, '0')
  const day = String(dateObj.getDate()).padStart(2, '0')
  const hours = String(dateObj.getHours()).padStart(2, '0')
  const minutes = String(dateObj.getMinutes()).padStart(2, '0')
  const seconds = String(dateObj.getSeconds()).padStart(2, '0')

  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`

  return formattedDate
}
