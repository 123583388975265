import {
  Button,
  Card,
  Checkbox,
  Collapse,
  Drawer,
  Dropdown,
  Modal,
  Space,
  Table,
  Tabs,
  Tooltip,
} from 'antd'
import ExitSaveModal from 'components/modal/exitSaveModal'
import SectionHeader from 'components/other/sectionHeader'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { PaymentDocument } from 'pages/payment/paymentView'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import { getTdsSections, get_payment_modes } from 'redux/document/actions'
import { getAPI } from 'services/jwt'
import CreatePayment from './createPayment'
import AdvanceSettlement from './advanceSettlement'
import { Document_Redux, DocumentSettings, DocumentTypes } from 'redux/types'
import BetaBadge from 'components/badges/beta'
import { LoadingOutlined } from '@ant-design/icons'
import AuditHistory from 'components/other/Activity'
import EnhancedInvoiceForm from 'components/forms/invoiceDetails'
import { Menu } from 'antd'
import CopyToClipboard from 'react-copy-to-clipboard'
import { message } from 'antd'
import GetPaymentDocument from 'components/other/GetPaymentDocument'
import { EnhancedMailFrom } from 'components/forms/mailForm'

const { Panel } = Collapse
const { TabPane } = Tabs

dayjs.extend(utc)

interface Props {
  user: any
  payment_modes: Document_Redux['paymentModes']
  tds_sections: Document_Redux['tdsSections']
  banks: Document_Redux['bankDetails']
  permissions: any
  invoice_settings: DocumentSettings

  onRef: (ref: any) => void
  dispatch: any
  onApicalled: () => void
}
interface AdvancePayments {
  amount: number
  amount_left: number
  amount_remaining: number
  doc_count: number
  document_title: string
  document_type: DocumentTypes
  party_id: number
  payment_mode: string
  record_time: string
  serial_number: string
  setteled_documents: any[]
  total_amount_paid: number
  amount_settle: number
  disable: boolean
  key: number
  settled_serial_number: string
  settled_document_type: string
  settled_doc_count: number
}

interface SelectedData {
  total_amount: number
  net_amount: number
  amount_pending: number
  amount_paid: number
  customer: {
    customer_id: number
    vendor_id: number
    name: string
    phone: string
    email: string
    company_name: string
    balance: number
    opening_balance: number
  }
  type: string
  serial_number: string
  doc_count: number
  payment_date: Dayjs
  notes: string
  pay: number
  invoice_date: string
  due_date: string
  payment_details: any
}
interface State {
  visible: boolean
  formFlag: boolean
  loading: boolean
  typeFlag: boolean
  bal: number
  payment_type: 'in' | 'out'
  name: string
  paymentData: any
  paymentFlag: boolean
  advance_payments: AdvancePayments[]
  selectedData: SelectedData
  collapseActiveKey2: '2' | ''
  collapseActiveKey1: '1' | ''
  is_advance_payment: boolean
  attachments: any[]
  paymentModeChanged: boolean
  party_type: 'customer' | 'vendor'
  document_type: DocumentTypes

  // FOR EDIT
  activeKey: 'payment_details' | 'comments_history'
  payment_history: any[]
  data: any // data that can be used in any place like edit, record and crreate payment
  is_edit: boolean
}

class RecordPaymentForm extends React.PureComponent<Props, State> {
  dateFormat = 'DD-MM-YYYY'
  formRef = React.createRef()
  private invoiceForm = React.createRef<{
    showDocumentDrawer: (document_type: DocumentTypes, hash_id: string) => void
  }>()
  private createPaymentRef = React.createRef<{
    onFill: (data: any) => void
    onSubmit: () => void
  }>()
  private advanceSettlementRef = React.createRef<{ onSubmit: () => void }>()
  private paymentRef = React.createRef<{ showModal: (serial_number: string) => void }>()
  private mailRef = React.createRef<any>()
  // private mailRef = React.createRef<{ onShow: (data: any) => void }>()
  constructor(props: Props) {
    super(props)
    this.state = {
      visible: false,
      formFlag: false,
      loading: false,
      typeFlag: false,
      bal: 0,
      payment_type: 'in',
      name: '',
      paymentData: '',
      paymentFlag: false,
      advance_payments: [],
      selectedData: {
        total_amount: 0,
        net_amount: 0,
        amount_pending: 0,
        amount_paid: 0,
        customer: {
          customer_id: -1,
          vendor_id: -1,
          name: '',
          phone: '',
          email: '',
          company_name: '',
          balance: 0,
          opening_balance: 0,
        },
        type: '',
        serial_number: '',
        doc_count: -1,
        payment_date: dayjs(),
        notes: '',
        pay: 0,
        invoice_date: dayjs().format('YYYY-MM-DD'),
        due_date: dayjs().format('YYYY-MM-DD'),
        payment_details: {},
      },
      collapseActiveKey2: '2',
      collapseActiveKey1: '',
      is_advance_payment: false,
      attachments: [],
      paymentModeChanged: false,
      party_type: 'customer',
      document_type: 'invoice',

      // FOR EDIT
      activeKey: 'payment_details',
      payment_history: [],
      data: {},
      is_edit: false,
    }
  }

  async componentDidMount() {
    this.props.onRef(this)

    if (this.props?.payment_modes?.length == 0 || this.props?.banks?.length == 0) {
      await this.props.dispatch(get_payment_modes())
    }
    if (this.props?.tds_sections?.length == 0) {
      await this.props.dispatch(getTdsSections())
    }
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  get_advance_payments = async (
    data: SelectedData,
    flag: boolean,
    document_type: DocumentTypes,
  ) => {
    var req = {
      party_type: flag ? 'customer' : 'vendor',
      party_id: data.customer[flag ? 'customer_id' : 'vendor_id'],
      payment_type: this.state.payment_type,
      document_type: document_type,
    }

    var doc_serial_number = 'invoice_serial_number'
    if (document_type == 'purchase' || document_type == 'sales_return') {
      doc_serial_number = 'po_serial_number'
    }

    const result = await getAPI('payments', 'advance_payments', req)
    if (result && result.success) {
      var amount_settle = 0
      var total_pending_payment = this.state.selectedData.amount_pending
      var advance_payments = result.payments.map((row: AdvancePayments, index: number) => {
        var amount_remaining = row.amount_remaining
        if (amount_remaining <= total_pending_payment) {
          amount_settle = amount_remaining
        } else {
          amount_settle = total_pending_payment
        }

        total_pending_payment -= amount_settle

        return {
          // @ts-ignore
          key: index,
          ...row,
          amount_settle: amount_settle,
          disable: amount_settle > 0 ? false : true,
          settled_serial_number: this.state.selectedData.serial_number,
          settled_document_type: document_type,
          settled_doc_count: this.state.selectedData.doc_count,
        }
      })
      advance_payments = advance_payments.filter(
        (item: AdvancePayments) => item.amount_remaining > 0,
      )
      this.setState({
        advance_payments: advance_payments,
        selectedData: {
          ...this.state.selectedData,
          type: result?.recent_mode ? result?.recent_mode : 'UPI',
        },
      })
    }
  }

  onFill = (data: SelectedData, flag: boolean, document_type: DocumentTypes) => {
    let payment_type: 'in' | 'out' =
      document_type == 'invoice' || document_type == 'purchase_return' ? 'in' : 'out'
    this.setState(
      {
        collapseActiveKey2: '2',
        collapseActiveKey1: '',
        visible: true,
        formFlag: true,
        selectedData: data,
        typeFlag: flag,
        party_type: flag ? 'customer' : 'vendor',
        document_type: document_type,
        is_advance_payment: false,
        payment_type: payment_type,
        is_edit: false,
      },
      () => {
        let banks = this.props.banks.filter(item => item.bank_name != 'Cash')
        let selectedBankId =
          banks.length > 0
            ? banks.filter(item => item.is_default == 1).length > 0
              ? banks.filter(item => item.is_default == 1)[0].id
              : banks[0].id
            : 0
        let formData = {
          ...data,
          [`${this.state.typeFlag ? 'customer' : 'vendor'}_name`]: data.customer.name,
          amount: data.total_amount,
          paid: data.amount_paid,
          payment_date: dayjs(),
          payment_type:
            this.props.banks.length == 1
              ? 'Cash'
              : this.props.payment_modes.length > 0
              ? this.props.payment_modes[0].value
              : '',
          bank_id: selectedBankId,
          notes: '',
          pay: data.amount_pending,
          exclusive_notes: '',
          send_sms: this.props.invoice_settings.record_payment_sms == 1,
          send_email: this.props.invoice_settings.record_payment_email == 1,
          pending_amount: data.amount_pending,
        }
        this.createPaymentRef.current?.onFill(formData)
        this.setState({
          selectedData: formData,
        })
        this.get_advance_payments(data, flag, document_type)
      },
    )
  }

  onEdit = async (serial_number: string, document_type: DocumentTypes) => {
    const req = {
      serial_number: serial_number,
    }

    const data1 = await getAPI('payments', 'get_payment', req)
    this.setState({ activeKey: 'payment_details' }, () => {
      if (data1 && data1.success) {
        const is_linked =
          data1.payment_in_settled_invoices.length > 1 || data1.payment_out_settled_pos.length > 1
        let selectedData = {
          ...this.state.selectedData,
          total_amount: data1.payment_details.amount,
          // Amount pending is amount remaining in case of payment edit
          amount_pending: data1.payment_details.amount_remaining,
          // Amount settled in paymenrt edit
          amount_paid: data1.payment_details.amount - data1.payment_details.amount_remaining,
          customer: data1.customer_details,
          type: data1.payment_details.payment_mode,
          serial_number: data1.payment_details.serial_number,
          doc_count: data1.payment_details.pay_count,
          payment_date: dayjs(data1.payment_details.payment_date),
          notes: data1.payment_details.notes,
          exclusive_notes: data1.payment_details.exclusive_notes,
          pay: data1.payment_details.amount,
          invoice_date: dayjs().format('YYYY-MM-DD'),
          due_date: dayjs().format('YYYY-MM-DD'),
          bank_id: data1.payment_details.bank_id,
          payment_details: data1.payment_details,
        }

        this.setState(
          {
            visible: true,
            name: data1.customer_details.name,
            selectedData: selectedData,
            paymentData: data1,
            document_type: document_type,
            attachments: data1.payment_details.attachments.map(
              (item: {
                file_size: number
                file_type: string
                id: number
                name: string
                url: string
              }) => {
                return { ...item, is_old: true }
              },
            ),
            data: data1,
            is_edit: true,
          },
          () => {
            this.createPaymentRef.current?.onFill(selectedData)
          },
        )
      }
    })
  }

  getStatusClass = (data: number) => {
    if (data < 0) {
      return 'text-danger'
    } else if (data == 0) {
      return 'text-gray-400'
    } else {
      return 'text-forest'
    }
  }

  cancelPaymentReceiptModal = () => {
    this.setState({ paymentFlag: false, paymentData: '' })
  }

  handelAdvancePayment = (keys: any[]) => {
    if (keys.length > 0) {
      this.setState({ is_advance_payment: true, collapseActiveKey1: '1', collapseActiveKey2: '' })
    } else {
      this.setState({ is_advance_payment: false, collapseActiveKey1: '', collapseActiveKey2: '2' })
    }
  }

  onClose = () => {
    let isValuesChanged = false

    if (isValuesChanged) {
      ExitSaveModal({
        onConfirm: () => {
          ;(this.state.is_advance_payment
            ? this.advanceSettlementRef
            : this.createPaymentRef
          ).current?.onSubmit()
        },
        onCancel: () => {
          this.setState({
            visible: false,
            advance_payments: [],

            attachments: [],
            paymentModeChanged: false,
          })
        },
      }).showModal()
    } else {
      this.setState({
        visible: false,
        advance_payments: [],

        attachments: [],
        paymentModeChanged: false,
      })
    }
  }

  // FOR EDIT
  get_payment_history = async (serial_number: string) => {
    const req = {
      serial_number,
    }
    this.setState({ loading: true, activeKey: 'comments_history' })
    const payment_history = await getAPI('payments', 'get_payment_history', req)

    this.setState({ payment_history: payment_history?.audit_history, loading: false })
  }

  showDocumentDrawer = async (document_type: DocumentTypes, serial_number: string) => {
    if (document_type != 'expense' && document_type != 'indirect_income') {
      const data = await getAPI('utils', 'get_new_hash_id', {
        document_type,
        serial_number,
      })
      if (data?.success) {
        this.invoiceForm.current?.showDocumentDrawer(document_type, data.new_hash_id)
      }
    }
  }

  handleDelete = async (id: string) => {
    const req = {
      serial_numbers: [id],
    }

    Modal.confirm({
      title: 'Are you sure you want to delete ?',
      content: (
        <>
          <p className="font-size-18">
            {this.state.paymentData.payment_in_settled_invoices?.length > 0 && (
              <span>
                This Payment is linked to multiple Documents
                <br />
              </span>
            )}
            {this.state.paymentData.payment_details?.payment_mode == 'Credits' && (
              <span className="font-size-16">
                This payment is created while settling with Invoice/Purchase/Credit/Debit Note.
                <br />
                <span className="text-danger font-size-14">
                  This action will delete the payment entry for respective
                  Invoice/Purchase/Credit/Debit Notes as well.
                </span>
              </span>
            )}
          </p>
        </>
      ),
      width: '30%',
      icon: <i className="fad fa-info-circle fa-beat mr-3 mt-2 text-danger"></i>,
      okText: 'Confirm',
      cancelButtonProps: { type: 'danger' },
      onOk: async () => {
        this.setState({ loading: true })
        const data = await getAPI('v3/payments', 'cancel', req)

        if (data) {
          message.success(data.message)
          this.setState({ visible: false, loading: false }, () => this.props.onApicalled())
        } else this.setState({ loading: false })
      },
      onCancel: () => {
        // message.error('Please change/remove the records and try again')
      },
    })
  }

  render() {
    const activeFormRef = this.state.is_advance_payment
      ? this.advanceSettlementRef
      : this.createPaymentRef

    const dropdownShareMenu = () => (
      <Menu>
        <Menu.Item>
          <a
            type="whatsapp-outline"
            // @ts-ignore
            size="small"
            className="font-weight-bold"
            style={{ height: '24px', borderRadius: '8px' }}
            onClick={event => {
              event.stopPropagation()
              window.open(
                'https://api.whatsapp.com/send/?phone=' +
                  this.state.paymentData.customer_details.dial_code +
                  this.state.paymentData.customer_details.phone +
                  '&text=Hello%20*' +
                  encodeURIComponent(this.state.paymentData.customer_details.name) +
                  '*%2C%20%0A%0A' +
                  encodeURIComponent(this.state.paymentData.company_details.whatsapp_line1) +
                  '%20%0A%0APlease%20find%20your%20payment%20receipt%20' +
                  this.state.paymentData.payment_details.serial_number +
                  '%20here%2C%20%0Ahttps://app.getswipe.in/view/' +
                  'payments/' +
                  this.state.paymentData.payment_details.hash_id +
                  '%0A%0A' +
                  encodeURIComponent(this.state.paymentData.company_details.whatsapp_line2) +
                  '%0A%0AThanks%0A*' +
                  encodeURIComponent(this.state.paymentData.company_details.organization_name) +
                  '*%0A*' +
                  this.state.paymentData.company_details.mobile +
                  '*%0A%0ASent%20using%20*Swipe%3A%20Smart%20Invoicing%2C%20Inventory%2C%20Accounting%20for%20SMEs*%20(getswipe.in)',
              )
            }}
          >
            <i className="fa-brands fa-whatsapp fa-fw fa-lg text-whatsapp mr-2" />
            <span className="">Whatsapp</span>
          </a>
        </Menu.Item>
        <Menu.Item>
          <a
            // @ts-ignore
            size="small"
            className="font-weight-bold"
            onClick={event => {
              // @ts-ignore
              this.mailRef?.onShow(this.state.paymentData)
            }}
          >
            <i className="fa-duotone fa-envelope fa-lg fa-fw mr-2 text-primary" />{' '}
            <span className="">Email</span>
          </a>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <CopyToClipboard
            text={`https://swipe.pe/view/payments/${this.state.paymentData.payment_details.hash_id}`}
            onCopy={() => message.success('Copied')}
          >
            <a
              onClick={event => {
                event.stopPropagation()
              }}
            >
              <i className="fa-regular fa-clone fa-lg mr-2 fa-fw" />
              <span className="float">Copy link</span>
            </a>
          </CopyToClipboard>
        </Menu.Item>
      </Menu>
    )
    const settled_invoice_columns = [
      {
        title: 'Date',
        render: (text: any, record: any) => {
          return <span>{dayjs(record.record_time).format('DD/MM/YYYY')}</span>
        },
      },
      {
        title: 'Serial Number',
        render: (text: any, record: any) => {
          return (
            <span
              className="font-weight-bold cursor-pointer"
              onClick={() => {
                record.document_type != '' &&
                  this.showDocumentDrawer(record.document_type, record.invoice_serial_number)
              }}
            >
              {record.invoice_serial_number}
            </span>
          )
        },
      },
      {
        title: 'Amount',
        render: (text: any, record: any) => {
          return <span>{record.amount_paid.toFixed(2)}</span>
        },
      },
    ]
    return (
      <>
        {this.state.paymentData != '' && (
          <Modal
            title="Payment Receipt"
            open={this.state.paymentFlag}
            style={{ top: 20 }}
            width={900}
            onOk={() => this.setState({ paymentFlag: false })}
            onCancel={() => this.cancelPaymentReceiptModal()}
            closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
            footer={[
              <Button
                key="back"
                type="default"
                onClick={() => this.setState({ paymentFlag: false })}
              >
                Cancel
              </Button>,
            ]}
          >
            <PaymentDocument data={this.state.paymentData} />
          </Modal>
        )}

        <Drawer
          title={
            <span>
              <span className="lh-normal">
                <span className="font-weight-bolder mb-0">
                  {!this.state.is_edit ? 'Record Payment for ' : 'Payment #'}{' '}
                </span>
                <span className="font-weight-bolder text-muted">
                  {this.state.selectedData?.serial_number}
                </span>
                {/*{capitalizeFirstLetters(this.state.document_type)} #*/}
              </span>
              <p className="font-size-13 text-muted font-weight-medium mt-0 mb-0 lh-normal">
                <span>{this.state.selectedData.invoice_date}</span>
                {this.state.selectedData.invoice_date == this.state.selectedData.due_date &&
                  !this.state.is_edit && (
                    <span className="font-weight-bold">
                      {', Due on ' + this.state.selectedData.due_date}
                    </span>
                  )}
              </p>
            </span>
          }
          width={isMobile ? '100%' : '60%'}
          height={isMobile ? '90%' : '100%'}
          placement={isMobile ? 'top' : 'right'}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          onClose={this.onClose}
          open={this.state.visible}
          extra={
            <Space>
              <Button
                onClick={() => activeFormRef.current?.onSubmit()}
                type="primary"
                className="font-weight-bold arrow-transition mr-2"
                loading={this.state.loading}
                disabled={
                  this.state.paymentData.payment_details?.payment_mode == 'Credits' ||
                  (this.state.is_edit && this.props.permissions.payment_edit == 0)
                }
              >
                {this.state.formFlag || this.state.is_edit ? 'Update Payment' : 'Add Payment'}
                <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
              </Button>
              {this.state.is_edit && this.props.permissions.payment_delete == 1 && (
                <Button
                  type="danger"
                  onClick={() => this.handleDelete(this.state.selectedData.serial_number)}
                >
                  <i className="fa fa-trash" />
                </Button>
              )}
            </Space>
          }
          footer={
            <>
              <Button
                onClick={() => activeFormRef.current?.onSubmit()}
                type="primary"
                className="font-weight-bold mr-2"
                loading={this.state.loading}
                disabled={
                  this.state.paymentData.payment_details?.payment_mode == 'Credits' ||
                  (this.state.is_edit && this.props.permissions.payment_edit == 0)
                }
              >
                {this.state.formFlag || this.state.is_edit ? 'Update Payment' : 'Add Payment'}
              </Button>

              {this.state.is_edit && this.props.permissions.payment_delete == 1 && (
                <Button
                  type="danger"
                  onClick={() => this.handleDelete(this.state.selectedData.serial_number)}
                >
                  <i className="fa fa-trash" />
                </Button>
              )}
            </>
          }
          destroyOnClose={true}
        >
          <Tabs
            destroyInactiveTabPane={true}
            defaultActiveKey="payment_details"
            activeKey={this.state.activeKey}
            onChange={activeKey => {
              // this.setState({ activeKey: activeKey }, () => {
              if (activeKey == 'payment_details') {
                // this.onAdd(this.state.data, this.state.selectedData, this.state.document_type)
                this.setState({ activeKey: 'payment_details' })
              } else {
                this.get_payment_history(this.state.selectedData.serial_number)
              }
              // })
            }}
          >
            <TabPane tab={<span>Details </span>} key="payment_details">
              {this.state.paymentData != '' && (
                <div className="mb-4">
                  <Button
                    className="mr-2"
                    size="small"
                    // @ts-ignore
                    type="view-button"
                    onClick={() =>
                      this.paymentRef.current?.showModal(this.state.selectedData.serial_number)
                    }
                  >
                    <i className="fa-solid fa-eye mr-2" />
                    View Receipt
                  </Button>

                  <Dropdown
                    overlay={dropdownShareMenu()}
                    placement="bottomRight"
                    trigger={['click']}
                  >
                    <Button
                      // @ts-ignore
                      type="send-button"
                      size="small"
                      className="mr-2"
                    >
                      <i className="fa-solid fa-paper-plane" />
                      <span className="ml-1">Send</span>
                    </Button>
                  </Dropdown>
                </div>
              )}

              <SectionHeader
                title={
                  <>
                    <span>
                      {this.state.selectedData.customer.company_name != ''
                        ? this.state.selectedData.customer.company_name
                        : this.state.selectedData.customer.name}
                    </span>
                  </>
                }
                right={true}
                rightText={
                  <>
                    {this.state.selectedData?.customer?.customer_id &&
                      this.state.selectedData?.customer?.customer_id != 0 && (
                        <Tooltip title={'Current Balance'}>
                          <p
                            className={`${this.getStatusClass(
                              this.state.selectedData.customer.balance +
                                this.state.selectedData.customer.opening_balance,
                            )} mt-0 pt-0 mb-0 font-size-14`}
                          >
                            <span className="font-size-14 mr-1 font-weight-bold">Balance </span>
                            <span className=" font-weight-bold">
                              <span className="font-size-12 ml-1 mr-1">
                                {this.props?.user?.selectedCompany?.currency_symbol}
                              </span>
                              {Math.abs(
                                this.state.selectedData.customer.balance +
                                  this.state.selectedData.customer.opening_balance,
                              )}
                            </span>
                          </p>
                        </Tooltip>
                      )}
                  </>
                }
                className="mb-2"
              />

              {!this.state.is_edit && this.state.advance_payments.length > 0 && (
                <Collapse
                  className="site-collapse-custom-collapse bg-card-orange-dark collapse-align-center"
                  bordered={false}
                  activeKey={this.state.collapseActiveKey1}
                  onChange={key => {
                    this.handelAdvancePayment(key)
                  }}
                  expandIcon={({ isActive }) => {
                    return (
                      <Checkbox
                        checked={this.state.is_advance_payment}
                        onChange={e => {
                          this.setState({ is_advance_payment: !this.state.is_advance_payment })
                          // this.getListPendings(this.state.selectedCustomer)
                        }}
                        style={{ fontSize: '0.9rem' }}
                        // className="mt-2"
                        onClick={e =>
                          this.handelAdvancePayment(
                            this.state.collapseActiveKey1 == '1' ? [] : ['1'],
                          )
                        }
                      ></Checkbox>
                    )
                  }}
                >
                  <Panel
                    header={
                      <>
                        <span className="font-size-18 font-weight-bold">
                          Settle From Advance Payments
                        </span>
                        <p className="text-muted font-size-13 mb-0">
                          You have advance payments from this customer. Click here to Settle from
                          Advance Payments.
                        </p>
                      </>
                    }
                    key="1"
                    className="mb-4"
                  >
                    <AdvanceSettlement
                      selectedData={this.state.selectedData}
                      ref={this.advanceSettlementRef}
                      user={this.props.user}
                      payment_type={this.state.payment_type}
                      typeFlag={this.state.typeFlag}
                      document_type={this.state.document_type}
                      onApicalled={() => {
                        this.setState({
                          visible: false,
                          attachments: [],
                          collapseActiveKey2: '2',
                          collapseActiveKey1: '',
                        })
                        this.props.onApicalled()
                      }}
                      advance_payments={this.state.advance_payments}
                      updateAdvancePayments={values => {
                        this.setState({ advance_payments: values })
                      }}
                      setLoading={(loading: boolean) => this.setState({ loading })}
                    />
                  </Panel>
                </Collapse>
              )}

              <Collapse
                className="bg-white"
                bordered={false}
                expandIcon={({ isActive }) => (
                  <span className="">
                    <i
                      className={`fa-solid fa-chevron-down ml-1 ${!isActive && 'fa-rotate-270'}`}
                    />
                  </span>
                )}
                activeKey={this.state.collapseActiveKey2}
                onChange={key => {
                  if (key.length > 0) {
                    this.setState({ collapseActiveKey2: '2' })
                  } else {
                    this.setState({ collapseActiveKey2: '' })
                  }
                }}
                collapsible={this.state.is_advance_payment ? 'disabled' : undefined}
              >
                <Panel
                  header={
                    <>
                      <span className="font-weight-bold text-gray-500">Payment Details</span>
                    </>
                  }
                  key="2"
                  className="mb-4"
                >
                  <CreatePayment
                    selectedData={this.state.selectedData}
                    payment_modes={this.props.payment_modes}
                    tds_sections={this.props.tds_sections}
                    banks={this.props.banks.filter(item => item.bank_name != 'Cash')}
                    attachments={this.state.attachments}
                    payment_type={this.state.payment_type}
                    ref={this.createPaymentRef}
                    user={this.props.user}
                    typeFlag={this.state.typeFlag}
                    document_type={this.state.document_type}
                    onApicalled={() => {
                      this.setState({ visible: false, attachments: [] })
                      this.props.onApicalled()
                    }}
                    setLoading={(loading: boolean) => this.setState({ loading })}
                    is_edit={this.state.is_edit}
                  />
                </Panel>
              </Collapse>

              {this.state.paymentData.payment_in_settled_invoices?.length > 0 && (
                <>
                  <div className="mt-4">
                    <SectionHeader
                      title={
                        this.state.document_type == 'expense'
                          ? 'Settled Expenses'
                          : 'Settled Documents'
                      }
                      right={false}
                    />
                  </div>
                  <Table
                    size="small"
                    dataSource={this.state.paymentData.payment_in_settled_invoices}
                    columns={settled_invoice_columns}
                    pagination={
                      this.state.paymentData.payment_in_settled_invoices > 5
                        ? { size: 'small' }
                        : false
                    }
                  />
                </>
              )}
            </TabPane>

            {this.props.permissions.history == 1 && this.state.is_edit && (
              <TabPane
                tab={
                  <span>
                    Activity
                    <BetaBadge />
                  </span>
                }
                key="comments_history"
              >
                <Card>
                  {this.state.loading ? (
                    <p className="w-full flex justify-center my-5">
                      <LoadingOutlined style={{ fontSize: 35 }} className="text-primary" spin />
                    </p>
                  ) : (
                    <AuditHistory auditHistory={this.state.payment_history} />
                  )}
                </Card>
              </TabPane>
            )}
          </Tabs>
          <EnhancedInvoiceForm onRef={(ref: any) => this.invoiceForm} />
          <GetPaymentDocument ref={this.paymentRef} />
          <EnhancedMailFrom
            onRef={(ref: any) => (this.mailRef = ref)}
            document_type={'payments'}
            menuName={'payments'}
          />
        </Drawer>
      </>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    payment_modes: state.document.paymentModes,
    tds_sections: state.document.tdsSections,
    banks: state.document.bankDetails == null ? [] : state.document.bankDetails,
    invoice_settings: state.document.invoiceSettings,
    user: state.user,
    permissions: state.permissions.permission,
  }
}

export default connect(mapStateToProps)(RecordPaymentForm)
