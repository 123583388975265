'use client'
import { cn } from 'components/other/utility'
import { motion, useInView } from 'framer-motion'
import * as React from 'react'

export function LettersPullUp({
  text,
  className,
  y = 9,
}: {
  text: string
  className?: string
  y?: number
}) {
  const splittedText = Array.from(text)

  const pullupVariant = {
    initial: { y: y, opacity: 0 },
    animate: (i: number) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: i * 0.05,
      },
    }),
  }
  const ref = React.useRef(null)
  const isInView = useInView(ref, { once: true })
  return (
    <span className="flex justify-center">
      {splittedText.map((current, i) => (
        <motion.div
          key={i}
          ref={ref}
          variants={pullupVariant}
          initial="initial"
          animate={isInView ? 'animate' : ''}
          custom={i}
          className={
            className || 'text-xl text-center sm:text-2xl font-bold tracking-tighter md:text-5xl'
          }
        >
          {current == ' ' ? <span>&nbsp;</span> : current}
        </motion.div>
      ))}
    </span>
  )
}
