import { InboxOutlined } from '@ant-design/icons'
import { pdf } from '@react-pdf/renderer'
import {
  Button,
  Card,
  Checkbox,
  Drawer,
  Form,
  Image,
  Input,
  Modal,
  Select,
  Space,
  Tooltip,
  message,
} from 'antd'
import Dragger from 'antd/lib/upload/Dragger'
import EwayBill from 'components/ewaybill/index'
import { registerFont, registerEnglishFont } from 'components/fonts'
import { AllInvoice } from 'components/kit/widgets/Tables/8'
import MyEditor from 'components/other/editor'
import SectionHeader from 'components/other/sectionHeader'
import { EditorState } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import 'draft-js/dist/Draft.css'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { getAPI, getAPIData, getMultipartAPI } from 'services/jwt'

import CustomNotes from 'components/forms/customNotesForm'
import ShareTemplates from 'components/forms/shareTemplates'
import EmailEditor from 'components/other/editorTemplate'
import Payment from 'components/payment'
import 'react-quill/dist/quill.snow.css'

import { templateMentionData } from 'utils/constants'
import { removeMentionSpans } from 'utils/utilFunctions'
import { connect } from 'react-redux'
import store from 'store'

const { TextArea } = Input

export default class MailForm extends Component {
  formRef = React.createRef()
  email_templates_ref = React.createRef()
  customNotesRef = React.createRef()
  constructor(props) {
    super(props)
    this.timeout = 0
    this.state = {
      visible: false,
      editorState: '',
      headerEditorState: '',
      footerEditorState: '',
      emailCombined: '',
      loading: false,
      fileList: [],
      uploadDisabled: false,
      fileSize: 0,
      company_email: '',
      details: '',
      emails: [],
      bccEmails: [],
      ccEmails: [],
      document: {},
      document_details: {},
      attachments: [],
      sendAttachments: false,
      mail_templates: [],
      selectedTemplate: 0,
      cc_hidden: true,
      bcc_hidden: true,
      previewModal: false,
      emails_list: [],
      page: 0,
      num_records: 25,
      query: '',
      custom_sender_email: '',
    }
  }

  componentDidMount = async () => {
    this.props.onRef(this)
    this.get_mail_templates()
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }
  get_mail_templates = async () => {
    try {
      const mail_templates_list = await getAPIData('utils', 'email_templates')
      if (mail_templates_list.success) {
        let default_key = 0
        mail_templates_list.email_templates?.map((item, index) => {
          if (item.is_default == 1) {
            default_key = index
          }
        })
        this.setState({
          mail_templates: mail_templates_list.email_templates,
          selectedTemplate: default_key,
        })
        if (mail_templates_list.email_templates.length > 0) {
          const selectedTemplate = mail_templates_list.email_templates[default_key]
          const editorContent =
            selectedTemplate.email_combined !== ''
              ? selectedTemplate.email_combined
              : [
                  selectedTemplate.email_header,
                  selectedTemplate.email_text,
                  selectedTemplate.email_footer,
                ]
                  .filter(Boolean)
                  .join('<br /><br />')

          this.setState({ editorState: editorContent })

          this.formRef.current?.setFieldsValue({ emailText: default_key })
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  getValue = data => {
    return data == undefined ? '' : data
  }

  uploadFile = async (file, serial_number) => {
    const form = new FormData()
    form.append('file', file)
    form.append('new_hash_id', this.state.new_hash_id)
    form.append(
      'document_type',
      this.props.document_type == 'ewaybill' ? 'invoice' : this.props.document_type,
    )
    form.append('serial_number', serial_number)

    const data = await getMultipartAPI('doc', 'upload', form)
    if (data && !data.success) {
      message.error(data?.message ?? 'something went wrong!')
    }
  }
  onSendAttachments = checked => {
    if (checked) {
      this.setState({ sendAttachments: true })
      const attachments = this.state.document_details.invoice_details.attachments
      let attachementFiles = []
      for (let k in attachments) {
        let url = attachments[k].url
        let fileName = url.substring(url.lastIndexOf('/') + 1)
        fetch(url)
          .then(response => {
            return response.blob()
          })
          .then(blob => {
            return new File([blob], `${fileName}`, {
              type: blob.type,
            })
          })
          .then(file => {
            attachementFiles.push(file)
          })
      }
      this.setState({ attachments: attachementFiles, fileList: [...this.state.fileList] })
    } else {
      this.setState({ sendAttachments: false, attachments: [] })
    }
  }

  onFinish = async values => {
    this.setState({ loading: true })

    if (this.state.emails.length == 0) {
      message.error('Please enter email')
      this.setState({ loading: false })
      return
    } else if (this.state.emails.length == 1) {
      if (!this.is_valid_email(this.state.emails[0])) {
        message.error('Please enter valid email')
        this.setState({ loading: false })
        return
      }
    }
    const emptyContentPattern = /^(\s*<p><br><\/p>\s*)+$/
    let checkEmptyBody = emptyContentPattern.test(this.state.editorState)

    const processedContent = removeMentionSpans(this.state.editorState)
    this.setState({ editorState: processedContent })

    if (this.props.document_type == 'ewaybill') {
      const data = this.state.document
      await registerFont(data.invoice_details)
      const blob = await pdf(<EwayBill invoice={{ ...data.invoice_details }} />).toBlob()
      await this.uploadFile(
        new File([blob], this.state.new_hash_id + '.pdf', { type: 'application/pdf' }),
        data.invoice_details.serial_number,
      )

      var reqData = {
        to: this.state.emails.join(','),
        cc: this.state.ccEmails.join(','),
        bcc: this.state.bccEmails.join(','),
        subject: this.getValue(values.subject),
        body: checkEmptyBody ? '' : this.state.editorState,
        new_hash_id: this.state.new_hash_id,
        document_type: data.invoice_details.document_type,
        header: '',
        footer: '',
      }

      var emaildata = await getAPI('v2/doc', 'email', reqData)

      if (emaildata) {
        this.setState({ visible: false, loading: false, fileList: [] })
        message.success(emaildata.message)
      } else {
        this.setState({ loading: false })
      }
    } else if (this.props.document_type != 'payments') {
      var req = {
        new_hash_id: this.state.new_hash_id,
        document_type: this.props.document_type,
        is_pdf: false,
        identifier: 'mail_form',
      }
      var data = await getAPI('v2/doc', 'get_invoice', req)

      if (data?.success) {
        await registerFont(data.invoice_details)
        const blob = await pdf(
          <AllInvoice order={data.invoice_details} menuName={this.props.menuName} />,
        ).toBlob()
        await this.uploadFile(
          new File([blob], this.state.new_hash_id + '.pdf', { type: 'application/pdf' }),
          data.invoice_details.serial_number,
        )
        let fileList = this.state.fileList
        if (data.invoice_details.eway_bill.length > 0) {
          await registerFont(data.invoice_details)
          var req_eway = {
            invoice_serial_number: data.invoice_details.eway_bill[0].invoice_serial_number,
            new_hash_id: data.invoice_details.eway_bill[0].invoice_new_hash_id,
            document_type: data.invoice_details.eway_bill[0].document_type,
            serial_number: data.invoice_details.eway_bill[0].serial_number,
          }
          const ewaybill_data = await getAPI('ewaybills', 'get_ewaybill', req_eway)

          const blob = await pdf(
            <EwayBill invoice={{ ...ewaybill_data.invoice_details }} />,
          ).toBlob()

          fileList.push(
            new File([blob], 'EwayBill-' + req_eway.serial_number + '.pdf', {
              type: 'application/pdf',
            }),
          )
        }

        //multiple attachments
        if (fileList.length > 0) {
          const fd = new FormData()
          for (let k in fileList) {
            fd.append(k, fileList[k])
          }
          fd.append('serial_number', data.invoice_details.serial_number)
          fd.append('document_type', this.props.document_type)
          let data2 = await getMultipartAPI('doc', 'upload_attachments', fd)
        }

        var reqData = {
          to: this.state.emails.join(','),
          cc: this.state.ccEmails.join(','),
          bcc: this.state.bccEmails.join(','),
          subject: this.getValue(values.subject),
          body: checkEmptyBody ? '' : this.state.editorState,
          new_hash_id: this.state.new_hash_id,
          document_type: this.props.document_type,
          header: '',
          footer: '',
        }

        var email_data = await getAPI('v2/doc', 'email', reqData)

        if (email_data) {
          this.setState({ visible: false, loading: false, fileList: [] })
          message.success(email_data.message)
        } else {
          this.setState({ loading: false })
        }
      }
      // }
    } else {
      var reqData = {
        to: this.state.emails.join(','),
        cc: this.state.ccEmails.join(','),
        bcc: this.state.bccEmails.join(','),
        subject: this.getValue(values.subject),
        body: checkEmptyBody ? '' : this.state.editorState,
        details: this.state.details,
      }
      await registerEnglishFont(this.state.details.company_details.locale)
      const blob = await pdf(<Payment data={{ ...this.state.details }} />).toBlob()
      const fd = new FormData()
      fd.append(
        'file',
        new File(
          [blob],
          'PaymentDocument' + this.state.details.payment_details.serial_number + '.pdf',
          {
            type: 'application/pdf',
          },
        ),
      )
      fd.append('reqData', JSON.stringify(reqData))

      var data = await getMultipartAPI('payments', 'send_receipt', fd)

      if (data) {
        this.setState({ visible: false, loading: false, fileList: [] })
        message.success(data.message)
      } else {
        this.setState({ loading: false })
      }
    }
    this.setState({ page: 0 })
  }

  handleFileRemove = async file => {
    this.setState(
      {
        fileList: this.state.fileList.filter(item => item.uid !== file.uid),
        fileSize: this.state.fileSize - file.size * 0.000001,
        attachments: this.state.attachments.filter(item => item.uid !== file.uid),
      },
      () => {
        if (this.state.fileSize <= 30) {
          this.setState({ uploadDisabled: false })
        }
      },
    )
  }
  changeEmailText = index => {
    const selectedTemplate = this.state.mail_templates[index]
    const editorContent =
      selectedTemplate.email_combined && selectedTemplate.email_combined !== ''
        ? selectedTemplate.email_combined
        : [
            selectedTemplate.email_header,
            selectedTemplate.email_text,
            selectedTemplate.email_footer,
          ]
            .filter(Boolean)
            .join('<br /><br />')
    this.setState({
      selectedTemplate: selectedTemplate,
      editorState: editorContent,
    })
  }
  searchEmails = async (searchText = '') => {
    if (searchText != '') {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(async () => {
        var reqData = {
          page: this.state.page,
          num_records: this.state.num_records,
          query: searchText,
        }
        const data = await getAPI('doc', 'email_list', reqData)
        if (data) {
          this.setState({
            emails_list: [...data.emails_list],
          })
        }
      }, 500)
    } else {
      this.setState({ query: searchText, page: 0 })
    }
  }
  onShow = async mail => {
    if (this.props.document_type != 'payments') {
      var reqData = { new_hash_id: mail.new_hash_id, document_type: this.props.document_type }
      const data = await getAPI('doc', 'email_details', reqData)
      var req = {
        new_hash_id: mail.new_hash_id,
        document_type: this.props.document_type,
        is_pdf: false,
        identifier: 'mail_form_show',
      }
      var invoiceData = await getAPI('v2/doc', 'get_invoice', req)
      // const email_data = await getAPI('doc', 'email_list', {
      //   page: this.state.page,
      //   num_records: this.state.num_records,
      // })
      let attachments = []

      if (data) {
        if (data.details.cc.length > 0) {
          //remove empty string
          data.details.cc = data.details.cc.filter(function(el) {
            return el != ''
          })
        }
        if (data.details.bcc.length > 0) {
          //remove empty string
          data.details.bcc = data.details.bcc.filter(function(el) {
            return el != ''
          })
        }
        if (data.details.to.length > 0) {
          //remove empty string
          data.details.to = data.details.to.filter(function(el) {
            return el != ''
          })
        }
        this.setState(
          {
            new_hash_id: mail.new_hash_id,
            visible: true,
            // company_email: data.details.cc.length > 0 ? data.details.cc[0] : '',
            editorState:
              this.state.mail_templates.length == 0 ? data.details.body : this.state.editorState,
            emails: data.details.to,
            ccEmails: data.details.cc.length > 0 ? data.details.cc : [],
            bccEmails: data.details.bcc,
            fileList: attachments,
            document_details: invoiceData,
            custom_sender_email: data.details.from,
          },
          () => {
            this.formRef.current.setFieldsValue({
              // to: data.details.to,
              // cc: '',
              // bcc: data.details.bcc,
              body: data.details.body,
              subject: data.details.subject,
              from: data.details.from,
            })
          },
        )
      }
    } else {
      let ccEmails = mail.customer_details.cc_emails.split(',').filter(item => item != '')
      if (mail.company_details.email != '') {
        ccEmails.push(mail.company_details.email)
      }
      this.setState(
        {
          visible: true,
          details: mail,
          company_email: mail.company_details.email,
          emails:
            mail.customer_details.email != '' && mail.customer_details.email != null
              ? [mail.customer_details.email]
              : [],
          ccEmails: ccEmails,
          bccEmails: [],
          editorState:
            this.state.mail_templates.length == 0
              ? 'Payment Receipt for Payment #' + mail.payment_details.serial_number
              : this.state.editorState,
          custom_sender_email: mail.company_details.from_email,
        },
        () => {
          let party_type = mail.payment_details.party_type
          this.formRef.current.setFieldsValue({
            to: this.state.emails,
            cc: ccEmails,
            bcc: '',
            body: 'Payment Receipt for Payment #' + mail.payment_details.serial_number,
            subject: 'Payment #' + mail.payment_details.serial_number,
          })
        },
      )
    }
  }
  onEwayBillMail = async mail => {
    var reqData = {
      invoice_serial_number: mail.invoice_serial_number,
      new_hash_id: mail.invoice_new_hash_id,
      document_type: mail.type,
      serial_number: mail.serial_number,
    }
    const data = await getAPI('ewaybills', 'get_ewaybill', reqData)
    this.setState({ document: data })
    if (data) {
      const invoice_details = data.invoice_details
      this.setState(
        {
          new_hash_id: mail.invoice_new_hash_id,
          visible: true,
          company_email: invoice_details.company.email,
          editorState: '',
          emails: invoice_details.customer.email.split(','),
          ccEmails: [],
          bccEmails: [],
        },
        () => {
          this.formRef.current.setFieldsValue({
            to: invoice_details.customer.email.split(','),
            cc: '',
            bcc: '',
            body: '',
            subject: 'E-Way Bill #' + mail.serial_number,
          })
        },
      )
    }
  }
  onPaymentReminder = async mail => {
    var reqData = { new_hash_id: mail.new_hash_id, document_type: this.props.document_type }
    const data = await getAPI('doc', 'email_details', reqData)

    let party_type = 'customer'
    if (this.props.document_type.includes('purchase')) {
      party_type = 'vendor'
    }
    let ccEmails =
      mail[party_type].cc_emails != '' && mail[party_type].cc_emails != null
        ? mail[party_type].cc_emails.split(',')
        : []
    if (mail.company.email != '') {
      ccEmails.push(mail.company.email)
    }

    this.setState(
      {
        visible: true,
        details: mail,
        company_email: mail.company.email,
        editorState: mail.mail_body,
        new_hash_id: mail.new_hash_id,
        emails:
          mail[party_type].email != '' && mail[party_type].email != null
            ? mail[party_type].email.split(',')
            : [],
        ccEmails: ccEmails,
        bccEmails: [],
        custom_sender_email: data.details.from,
      },
      () => {
        this.formRef.current.setFieldsValue({
          to: this.state.emails,
          cc: ccEmails,
          bcc: '',
          body: mail.mail_body,
          subject: 'Payment Reminder #' + mail.serial_number,
          from: data.details.from,
        })
      },
    )
  }
  is_valid_email = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }
  onchangeValidateEmail = (email_name, values) => {
    let emails = this.state[email_name]
    if (values.length > emails.length) {
      if (this.is_valid_email(values[values.length - 1])) {
        emails.push(values[values.length - 1])
      } else {
        message.error('Please enter valid email')
      }
    } else {
      emails = values
    }
    // console.log(emails)
    this.setState({ [email_name]: emails }, () => {
      // console.log(this.state[email_name], email_name)
    })
  }
  handleScroll = e => {
    const bottom =
      parseInt(e.target.scrollHeight - e.target.scrollTop) - parseInt(e.target.clientHeight) <= 1

    if (bottom) {
      this.setState({ page: this.state.page + 1 }, () => {
        this.searchEmails(this.state.query)
      })
    }
  }
  render() {
    const formItemLayout = {
      layout: 'vertical',
      labelCol: {
        xs: { span: 4 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 36 },
        sm: { span: 36 },
      },
    }
    return (
      <>
        <Drawer
          title={'Send Email'}
          width={isMobile ? '100%' : '50%'}
          height={isMobile ? '90%' : '100%'}
          placement={isMobile ? 'top' : 'right'}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          destroyOnClose={true}
          onClose={() => this.setState({ visible: false, fileList: [], page: 0 })}
          open={this.state.visible}
          extra={
            <Space>
              <Button
                onClick={() => this.formRef.current.submit()}
                type="primary"
                loading={this.state.loading}
                disabled={this.state.uploadDisabled}
              >
                Send Mail
              </Button>
            </Space>
          }
          footer={
            <Button
              onClick={() => this.formRef.current.submit()}
              type="primary"
              loading={this.state.loading}
              disabled={this.state.uploadDisabled}
            >
              Send Mail
            </Button>
          }
        >
          <Form
            {...formItemLayout}
            labelAlign="left"
            ref={this.formRef}
            name="mailForm"
            onFinish={this.onFinish}
          >
            <Card className="mb-4">
              {/* <Form.Item name="to" autoFocus label="To"> */}
              {/* <Input autoFocus placeholder="Add multiple emails using comma separator" /> */}
              <Form.Item
                label="From"
                name="from"
                extra={
                  this.state.custom_sender_email == 'noreply@swipebilling.com' &&
                  store.get('paid') == 1 && (
                    <div>
                      <a
                        onClick={() =>
                          window.open(
                            'https://app.getswipe.in/help?msg=Hello%20Swipe,%20%20I%20want%20to%20know%20more%20about%20Custom%20Email',
                          )
                        }
                        className="text-blue-500 hover-underline ml-1 mr-1"
                      >
                        <strong>Click here</strong>
                      </a>
                      to integrate your email
                    </div>
                  )
                }
              >
                {this.state.custom_sender_email == 'noreply@swipebilling.com' &&
                store.get('paid') == 1 ? (
                  <Tooltip
                    trigger={['hover']}
                    title={
                      <p className="text-gray-500">
                        E-Mail will be sent from this email id.
                        <a
                          onClick={() =>
                            window.open(
                              'https://app.getswipe.in/help?msg=Hello%20Swipe,%20%20I%20want%20to%20know%20more%20about%20Custom%20Email',
                            )
                          }
                          className="font-weight-bolder hover-underline ml-1 mr-1"
                        >
                          <strong>Click here</strong>
                        </a>
                        to know how to add yours.
                      </p>
                    }
                    color={store.get('app.settings.theme') == 'default' ? 'white' : ''}
                  >
                    <Input
                      readOnly={true}
                      placeholder="Enter From Email"
                      suffix={
                        <>
                          {this.state.custom_sender_email == 'noreply@swipebilling.com' && (
                            <i className="fa-solid fa-lock mr-1 ml-2" />
                          )}
                        </>
                      }
                      className={`bg-gray-50 cursor-pointer text-gray-400`}
                      value={this.state.custom_sender_email}
                      onClick={() =>
                        window.open(
                          'https://app.getswipe.in/help?msg=Hello%20Swipe,%20%20I%20want%20to%20know%20more%20about%20Custom%20Email',
                        )
                      }
                    />
                  </Tooltip>
                ) : (
                  <Input
                    readOnly={true}
                    placeholder="Enter From Email"
                    className={`bg-gray-50 cursor-pointer text-gray-400`}
                    value={this.state.custom_sender_email}
                  />
                )}
              </Form.Item>
              <div className="flex justify-between items-center w-full">
                <span className="custom-form-label">To</span>
                {(this.state.cc_hidden || this.state.bcc_hidden) && (
                  <div className="flex justify-end w-full">
                    {/* {this.state.cc_hidden && (
                      <span
                        className="cursor-pointer mr-2 text-gray-500 font-size-12 hover-underline"
                        onClick={() => this.setState({ cc_hidden: false })}
                      >
                        CC
                      </span>
                    )} */}
                    <span
                      className="cursor-pointer text-gray-500 font-size-12 hover-underline"
                      onClick={() => this.setState({ bcc_hidden: false })}
                    >
                      BCC
                    </span>
                  </div>
                )}
              </div>
              {/* <ReactMultiEmail
                placeholder="To"
                emails={this.state.emails}
                onChange={_emails => {
                  this.setState({ emails: _emails })
                }}
                validateEmail={email => {
                  const ismail = isEmail(email)
                  if (!ismail) {
                    message.error('Please enter valid email')
                  }
                  return ismail // return boolean
                }}
                getLabel={(email, index, removeEmail = index) => {
                  return (
                    <div data-tag key={index}>
                      {email}
                      <span data-tag-handle onClick={() => removeEmail(index)}>
                        <i className="fa-regular fa-xmark fa-lg"></i>
                      </span>
                    </div>
                  )
                }}
              /> */}
              <Select
                mode="tags"
                value={this.state.emails}
                placeholder="To"
                style={{ width: '100%' }}
                onChange={value => this.onchangeValidateEmail('emails', value)}
                onSearch={this.searchEmails}
                onPopupScroll={this.handleScroll}
              >
                {this.state.emails_list.map((email, index) => (
                  <Option key={index} value={email}>
                    {email}
                  </Option>
                ))}
              </Select>

              <Form.Item
                autoFocus
                //  hidden={this.state.cc_hidden}
              >
                {/* <Form.Item name="cc" label={'CC'} className="mb-0"> */}
                {/* <Input placeholder="CC, Add multiple emails using comma separator" /> */}
                {/* <ReactMultiEmail
                    placeholder="Cc"
                    emails={this.state.ccEmails}
                    onChange={_emails => {
                      this.setState({ ccEmails: _emails })
                    }}
                    validateEmail={email => {
                      const ismail = isEmail(email)
                      if (!ismail) {
                        message.error('Please enter valid email')
                      }
                      return ismail // return boolean
                    }}
                    getLabel={(email, index, removeEmail = index) => {
                      return (
                        <div data-tag key={index}>
                          {email}
                          <span data-tag-handle onClick={() => removeEmail(index)}>
                            <i className="fa-regular fa-xmark fa-lg"></i>
                          </span>
                        </div>
                      )
                    }}
                  /> */}
                <span className="custom-form-label">CC</span>

                <Select
                  mode="tags"
                  maxTagCount="responsive"
                  value={this.state.ccEmails}
                  placeholder="CC"
                  style={{ width: '100%' }}
                  onChange={value => this.onchangeValidateEmail('ccEmails', value)}
                  onPopupScroll={this.handleScroll}
                  onSearch={this.searchEmails}
                  maxTagPlaceholder={omittedValues => (
                    <Tooltip title={omittedValues.map(({ label }) => label).join(', ')}>
                      <span>+{omittedValues.length}...</span>
                    </Tooltip>
                  )}
                >
                  {' '}
                  {this.state.emails_list.map((email, index) => (
                    <Option key={index} value={email}>
                      {email}
                    </Option>
                  ))}
                </Select>
                {/* </Form.Item> */}
                {/* {this.state.company_email != '' && (
                  <p className="text-gray-500 mt-0 mb-1 font-size-12 pl-1">
                    Your email,{' '}
                    <span className="text-primary font-weight-bold">
                      {' '}
                      {this.state.company_email},{' '}
                    </span>
                    <span>is marked as CC by default.</span>
                  </p>
                )}
                {this.state.company_email == '' && (
                  <p className="text-gray-4 mt-0 mb-1 font-size-12 pl-1">
                    Add Your company email in CC.
                  </p>
                )} */}
              </Form.Item>
              <Form.Item autoFocus hidden={this.state.bcc_hidden}>
                {/* <Input placeholder="Bcc" /> */}
                {/* <ReactMultiEmail
                  placeholder="Bcc"
                  emails={this.state.bccEmails}
                  onChange={_emails => {
                    this.setState({ bccEmails: _emails })
                  }}
                  validateEmail={email => {
                    const ismail = isEmail(email)
                    if (!ismail) {
                      message.error('Please enter valid email')
                    }
                    return ismail // return boolean
                  }}
                  getLabel={(email, index, removeEmail = index) => {
                    return (
                      <div data-tag key={index}>
                        {email}
                        <span data-tag-handle onClick={() => removeEmail(index)}>
                          <i className="fa-thin fa-xmark fa-lg"></i>
                        </span>
                      </div>
                    )
                  }}
                /> */}
                <span className="custom-form-label">BCC</span>
                <Select
                  mode="tags"
                  value={this.state.bccEmails}
                  placeholder="Bcc"
                  style={{ width: '100%' }}
                  onChange={value => this.onchangeValidateEmail('bccEmails', value)}
                  onPopupScroll={this.handleScroll}
                  onSearch={this.searchEmails}
                >
                  {this.state.emails_list.map((email, index) => (
                    <Option key={index} value={email}>
                      {email}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="subject" label="Subject">
                <Input placeholder="Subject" />
              </Form.Item>
            </Card>
            <SectionHeader
              title="Email Body"
              link={true}
              linkTo={() => this.email_templates_ref?.current?.showDrawer('email')}
              linkText="Add/Select Template"
            />

            <Card className="mb-4">
              <Form.Item
                name="emailText"
                label={<span style={{ whiteSpace: 'nowrap' }}>Email Template</span>}
                extra={
                  <>
                    <div className="flex justify-between">
                      <span className="font-size-12"> (Only available on web) </span>

                      <a
                        className="font-size-12 text-primary cursor-pointer"
                        onClick={() =>
                          this.setState({
                            previewModal: true,
                          })
                        }
                      >
                        {' '}
                        Preview Template
                      </a>
                    </div>
                  </>
                }
              >
                {this.state.mail_templates.length > 0 ? (
                  <Select
                    defaultValue={this.state.selectedTemplate}
                    value={this.state.selectedTemplate}
                    onChange={this.changeEmailText}
                    dropdownRender={menu => (
                      <>
                        {menu}
                        <div className="px-1 py-1">
                          <Button
                            block
                            type="primary"
                            className="font-weight-bolder"
                            onClick={() => this.email_templates_ref?.current?.showDrawer('email')}
                          >
                            <i className="fa fa-plus mr-1" />
                            Add New Template
                          </Button>
                        </div>
                      </>
                    )}
                  >
                    {this.state.mail_templates.map((template, index) => {
                      return <Option value={index}>{template.label}</Option>
                    })}
                  </Select>
                ) : (
                  <Button
                    type="primary"
                    onClick={() => this.email_templates_ref?.current?.showDrawer('email')}
                  >
                    Add Template
                  </Button>
                )}
              </Form.Item>

              {/* <Button onClick={() => this.email_templates_ref?.current?.showDrawer('email')}>
              Add Template
            </Button> */}

              {/* Only Editors without preview */}
              <div className="mb-3">
                {/* <div className="mt-3">
                  <SectionHeader title="Email Header" className="mt-3 mb-3" />
                </div>
                <MyEditor
                  placeholder="Email header"
                  editorState={this.state.headerEditorState}
                  onChange={(text, editorState) =>
                    this.setState({
                      headerEditorState: editorState,
                    })
                  }
                /> */}
                <div className="mt-3">
                  <SectionHeader title="Email Text" className="mt-3 mb-3" />
                </div>
                <EmailEditor
                  placeholder="Email content"
                  value={this.state.editorState}
                  onChange={editorState => {
                    this.setState({ editorState: editorState })
                  }}
                  mentionData={templateMentionData}
                  template={this.state.selectedTemplate}
                />
                {/* <div className="mt-3">
                  <SectionHeader title="Email Footer" className="mt-3 mb-3" />
                </div>
                <MyEditor
                  placeholder="Email footer"
                  editorState={this.state.footerEditorState}
                  onChange={(text, editorState) =>
                    this.setState({ footerEditorState: editorState })
                  }
                /> */}
              </div>

              {/* multiple attachments in development  */}
              {this.props.document_type != 'payments' && this.props.document_type != 'ewaybill' ? (
                <div className="mt-16">
                  {this.state.document_details?.invoice_details?.attachments?.length > 0 && (
                    <>
                      {' '}
                      <Checkbox
                        checked={this.state.sendAttachments}
                        onChange={e => this.onSendAttachments(e.target.checked)}
                      />
                      <span className="ml-3">Send Attached Documents</span>
                    </>
                  )}
                  <h6>
                    <span className="pl-2 mr-3 mt-4 text-gray-500 font-weight-bold">
                      Upload Attachments
                    </span>
                  </h6>
                  {this.state.uploadDisabled && (
                    <p className="text-danger mt-2 mb-1 font-size-14">
                      Files Size Exceeded 30 MB
                      {message.error('File Size should not exceeded 30 MB')}
                    </p>
                  )}
                  <Dragger
                    multiple={true}
                    listType="picture"
                    onRemove={info => {
                      this.handleFileRemove(info)
                    }}
                    fileList={[...this.state.fileList, ...this.state.attachments]}
                    beforeUpload={file => {
                      this.setState(
                        {
                          fileList: [...this.state.fileList, file],
                          fileSize: this.state.fileSize + file.size * 0.000001,
                        },
                        () => {
                          if (this.state.fileSize > 30) {
                            this.setState({ uploadDisabled: true })
                          }
                        },
                      )

                      return false
                    }}
                    defaultFileList={this.state.fileList}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text text-gray-500">
                      Click or drag file to this area to upload (Max Size: 30 MB)
                    </p>
                    <p className="ant-upload-hint text-gray-500">
                      Support for a single or multiple files upload.
                    </p>
                  </Dragger>
                </div>
              ) : (
                ''
              )}

              <p className="text-gray-500 font-weight-medium mt-2 mb-1 font-size-12">
                *PDF Attachment will be sent along with the email by default.
              </p>
            </Card>
          </Form>

          {/* <CustomNotes
            ref={this.email_templates_ref}
            refreshNotesTerms={() => {
              this.get_mail_templates()
            }}
          /> */}
          <ShareTemplates
            ref={this.email_templates_ref}
            refreshNotesTerms={() => {
              this.get_mail_templates()
            }}
          />
          {/* Template Preview Modal*/}
          <Modal
            title="Mail Preview"
            open={this.state.previewModal}
            onOk={() => this.setState({ previewModal: false })}
            onCancel={() => this.setState({ previewModal: false })}
            footer={null}
            width={700}
            style={{ top: 20, left: 20, margin: 0, position: 'relative' }}
            mask={false}
            getContainer={false}
            maskClosable={false}
            zIndex={-1}
          >
            <Card
              className="mb-4"
              style={{
                border: '1px solid #e8e8e8',
                borderRadius: '5px',
                padding: '10px',
                marginBottom: '20px',
                width: '100%',
              }}
            >
              <div className="flex flex-row justify-start" style={{ width: '100%' }}>
                <div style={{ width: '100%' }}>
                  <p className="font-weight-medium mt-3 mb-1 font-size-12">
                    From: {this.state.custom_sender_email}
                  </p>
                  <div className="p-4 mb-4 sm:p-6 md:p-8">
                    <Image width={'72px'} src={store.get('logo')} />
                    <p className="font-weight-medium mt-3 mb-1 font-size-12">
                      {this.state.document_details?.invoice_details?.company.company_name}
                    </p>
                    <hr style={{ width: '100%' }} />
                    <p className="font-weight-medium mt-3 mb-1 font-size-12">
                      <div
                        className="ql-editor"
                        style={{
                          overflowWrap: 'break-word',
                          wordWrap: 'break-word',
                          wordBreak: 'break-word',
                        }}
                        dangerouslySetInnerHTML={{
                          __html: this.state.editorState,
                        }}
                      />
                    </p>
                  </div>
                  <div style={{ width: '100%' }}>
                    <div
                      className="bg-white border border-gray-200 rounded-lg shadow-md p-4 mb-4 sm:p-6"
                      style={{ width: '100%' }}
                    >
                      <p className="text-gray-500 font-weight-medium mt-3 mb-1 font-size-12">
                        Please find the PDF attached to this email.
                      </p>
                      <p className="text-gray-500 font-weight-bold mt-3 mb-1 font-size-14">
                        TOTAL AMOUNT
                      </p>
                      <div className="flex flex-row justify-between" style={{ width: '100%' }}>
                        <div>
                          <p className="text-gray-500 font-weight-medium mt-3 mb-1 font-size-12">
                            {this.state.document_details?.invoice_details?.document_title}#
                          </p>
                          <p className="text-gray-500 font-weight-medium mt-3 mb-1 font-size-12">
                            {this.state.document_details?.invoice_details?.document_title} Date
                          </p>
                        </div>
                        <div>
                          <p className="font-weight-bold mt-3 mb-1 font-size-12">
                            {this.state.document_details?.invoice_details?.serial_number}
                          </p>
                          <p className="font-weight-bold mt-3 mb-1 font-size-12">
                            {this.state.document_details?.invoice_details?.invoice_date}
                          </p>
                        </div>
                      </div>
                      <a href="#" className="text-blue-800">
                        Check {this.state.document_details?.invoice_details?.document_title} details
                      </a>
                    </div>
                    <p className="text-gray-500 font-weight-medium mt-3 mb-1 font-size-12">
                      If you have any questions, kindly reply all to this email{' '}
                      {this.state.company_email}
                    </p>

                    <p className="font-weight-medium mt-3 mb-1 font-size-12">
                      {this.state.footerEditorState !== '' ? (
                        <div
                          className="ql-editor"
                          style={{
                            overflowWrap: 'break-word',
                            wordWrap: 'break-word',
                            wordBreak: 'break-word',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: this.state.footerEditorState,
                          }}
                        />
                      ) : (
                        <div>
                          <p> Regards,</p>
                          <strong>
                            {this.state.document_details?.invoice_details?.company.company_name}
                          </strong>
                        </div>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          </Modal>
        </Drawer>
      </>
    )
  }
}

export class EnhancedMailFrom extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <MailForm {...this.props} />
  }
}

const FunctionalMailForm = connect(null, null, null, { forwardRef: true })(MailForm)
export const FunctionalInvoiceDrawer = React.forwardRef((props, ref) => (
  <FunctionalMailForm {...props} onRef={() => {}} ref={ref} />
))
