import { Button, Input, Typography } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAPI } from 'services/jwt'
import store from 'store'
import { debounce } from 'lodash'

const { Text } = Typography

export class OTPVerification extends Component {
  formRefAddress = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      otp_sent: false,
      otp_verified: false,
      otp: '',
      input_value: store.get('mobile'),
      otpHasBeenResend: false,
      canResend: false,
      timer: 30,
    }
    this.debouncedHandleOTP = debounce(this.handleOTP, 300)
    this.debouncedHandleResendOTP = debounce(this.handleResendOTP, 300)
  }
  componentDidMount() {
    // this.props.onRef(this)
  }
  handleOTP = async () => {
    let req = {}
    if (!this.state.otp_sent && !this.state.otp_verified) {
      req = {
        mobile: store.get('mobile'),
        send_otp: true,
        verify_otp: false,
        otp: '',
        source: this.props.source,
      }
      const data = await getAPI('user', 'otp_verification', req)
      if (data) {
        if (data?.success) {
          this.setState({
            otp_sent: true,
            input_value: '',
            canResend: false,
          })
          this.startResendTimer()
        }
      }
    } else if (this.state.otp_sent && !this.state.otp_verified) {
      req = {
        mobile: store.get('mobile'),
        send_otp: false,
        verify_otp: true,
        otp: this.state.otp,
        source: this.props.source,
      }
      const data = await getAPI('user', 'otp_verification', req)
      if (data) {
        if (data?.success) {
          this.setState(
            {
              otp_verified: true,
            },
            () => this.props.onVerify(),
          )
        }
      }
    }
  }

  startResendTimer = () => {
    this.setState({ timer: 30 }, () => {
      const interval = setInterval(() => {
        this.setState(prevState => {
          if (prevState.timer <= 1) {
            clearInterval(interval)
            return { timer: 0, canResend: true }
          }
          return { timer: prevState.timer - 1 }
        })
      }, 1000)
    })
  }

  handleResendOTP = async () => {
    if (this.state.canResend) {
      const req = {
        mobile: store.get('mobile'),
        send_otp: true,
        verify_otp: false,
        otp: '',
        source: this.props.source,
      }
      const data = await getAPI('user', 'otp_verification', req)
      if (data?.success) {
        this.setState({ canResend: false })
        this.startResendTimer()
      }
    }
  }

  render() {
    const { columns } = this.props
    return (
      <>
        <div className="mt-3">
          {!this.state.otp_verified ? (
            <>
              <p className="text-gray-500 mt-0 mb-1 font-size-14">
                {this.props.text ? this.props.text : 'OTP Verification is required to continue.'}
              </p>
              <div className="flex flex-col">
                <Input.Group className="flex items-center">
                  <Input
                    placeholder={!this.state.otp_sent ? 'Mobile Number' : 'Enter OTP'}
                    onChange={e =>
                      this.setState({ otp: e.target.value, input_value: e.target.value })
                    }
                    style={{ width: '50%' }}
                    value={this.state.input_value}
                    disabled={!this.state.otp_sent && !this.state.otp_verified}
                  />
                  <Button
                    onClick={this.debouncedHandleOTP}
                    size="default"
                    className="mr-2 font-weight-bold rounded-l-none"
                  >
                    {!this.state.otp_sent && !this.state.otp_verified
                      ? 'Request OTP'
                      : 'Verify OTP'}
                  </Button>
                </Input.Group>
                {this.state.otp_sent && (
                  <span
                    onClick={() => {
                      if (this.state.canResend) {
                        this.debouncedHandleResendOTP()
                        this.setState({
                          otpHasBeenResend: true,
                        })
                      }
                    }}
                    size="default"
                    type="link"
                    className={`mt-3 text-sm text-[#3253F5] hover:underline  select-none ${
                      this.state.timer > 0 ? 'cursor-not-allowed' : 'cursor-pointer'
                    }`}
                    disabled={!this.state.canResend}
                  >
                    Resend OTP {this.state.timer > 0 && `(${this.state.timer}s)`}
                  </span>
                )}
                <div>
                  {this.state.otpHasBeenResend ? (
                    <Text type="secondary" className="text-sm mt-1 !text-yellow-500">
                      Please check your spam folder if you haven't received the OTP
                    </Text>
                  ) : null}
                </div>
              </div>
            </>
          ) : (
            <div className="mt-3 text-success">
              <i className="fa fa-check-circle mr-2" />
              OTP Verified Successfully
            </div>
          )}
        </div>
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    permissions: state.permissions.permission,
    states: state.document.states,
  }
}

export default connect(mapStateToProps)(OTPVerification)
