import { Tooltip } from 'antd'
import { cn } from 'components/other/utility'
import React, { Component } from 'react'

export class BetaBadge extends Component {
  render() {
    return (
      <Tooltip
        title="This feature is in BETA. You can help us by reporting any issues by clicking the help button."
        color="#122A47"
        className="align-middle"
      >
        <span
          className={cn(
            'badge badge-beta align-text-middle badge-collapsed-hidden italic font-weight-bolder ml-1',
            this.props.className,
          )}
        >
          BETA
        </span>
      </Tooltip>
    )
  }
}

export default BetaBadge
