import { Image, Text, View } from '@react-pdf/renderer'
import { breakName } from 'components/other/utility'
import { Fragment } from 'react'
import { HIDE_PRICES } from '../utils'
import ItemBatchDetails from '../itemBatchDetails'
import RichText from '../RichText'
import { styles as getStyles } from './styles'
import ItemsHeader, { mergeItemsWithHeaders } from '../itemsHeader'

const InvoiceTableRow = ({
  items,
  data,
  invoice,
  type,
  show_free_qty,
  no_of_default_cols,
  no_of_dc_cols,
  no_of_with_out_tax_cols,
}) => {
  var styles = getStyles(invoice)
  let locale = invoice.company.locale
  let font_style = invoice.invoice_settings.font_style
  if (locale == 'English') {
    locale = font_style
  }
  const hide_hsn = invoice.invoice_settings.hide_hsn
  const show_3_decimals = invoice.invoice_settings.qty_decimals_3

  var FONT_FACTOR = invoice.invoice_settings.font_size
  const options = { maximumFractionDigits: 2, minimumFractionDigits: 2 }

  var dc_col = (1 * 100) / no_of_dc_cols
  var single_col = (1 * 100) / (no_of_default_cols + invoice.custom_col_names.length)
  var single_col2 = (1 * 100) / (no_of_with_out_tax_cols + invoice.custom_col_names.length)
  var font_size = (FONT_FACTOR * (9 * 10)) / (9 + invoice.custom_col_names.length)
  var font_size2 = (FONT_FACTOR * (10 * 15)) / (15 + invoice.custom_col_names.length)
  let conversion_factor = 1
  let currency_symbol = invoice.company.currency_symbol

  if (
    (invoice.is_export == 1 || invoice.is_multi_currency == 1) &&
    invoice.invoice_settings.show_both_currencies != true
  ) {
    currency_symbol = invoice.export_details[0].currency_symbol
    conversion_factor = invoice.export_details[0].conversion_factor
  }
  invoice.itemsWithHeaders = mergeItemsWithHeaders(invoice)
  const rows = invoice.itemsWithHeaders.map((item, i) => (
    <Fragment key={i}>
      {item.header_value ? (
        <ItemsHeader styles={styles} invoice={invoice} i={i} />
      ) : (
        <>
          {(type == 'Delivery Challan' || type == HIDE_PRICES) && (
            <>
              {(item.product_type == 'Product' || type == HIDE_PRICES) && (
                <View style={styles.invoiceTableRow_border}>
                  <View style={styles.invoiceTableRow_row} key={(i + 1).toString()} wrap={false}>
                    <Text
                      hyphenationCallback={e => breakName(e)}
                      style={{ ...styles.invoiceTableRow_slno_dc, width: `${0.2 * dc_col}%` }}
                    >
                      {item.position + 1}
                    </Text>
                    <Text
                      style={{
                        ...styles.invoiceTableRow_description_dc,
                        width: `${1.8 * dc_col}%`,
                      }}
                    >
                      <Text
                        hyphenationCallback={e => breakName(e)}
                        style={styles.invoiceTableRow_bold}
                      >
                        {item.name} {item.variant_name}
                      </Text>
                      {'\n'}
                      <ItemBatchDetails item={item} invoice={invoice} />
                      {!hide_hsn && item.hsn_code != '' && (
                        <>
                          <RichText
                            locale={locale}
                            note={
                              item.product_type == 'Product'
                                ? 'HSN: ' + item.hsn_code
                                : 'SAC: ' + item.hsn_code
                            }
                            FONT_FACTOR={0.9 * font_size}
                          />
                        </>
                      )}
                    </Text>
                    {invoice.invoice_settings.hide_qty == 0 && (
                      <Text style={{ ...styles.invoiceTableRow_qty_dc, width: `${dc_col}%` }}>
                        <Text>
                          {((item.qty + item.free_qty) * item.conversion_rate).toLocaleString(
                            'en-IN',
                            {
                              maximumFractionDigits: 3,
                              minimumFractionDigits: show_3_decimals ? 3 : 0,
                            },
                          )}
                        </Text>
                        {item.item_unit != '' &&
                          item.item_unit != 'OTH' &&
                          item.item_unit != 'NONE' &&
                          item.item_unit != 'UNDEFINED' && (
                            <Text> {item.item_unit == '' ? item.unit : item.item_unit}</Text>
                          )}
                        {invoice.invoice_settings.show_qty_conversion_rate == 1 &&
                          item.conversion_rate != 1 && (
                            <Text>
                              {'\n'}
                              <RichText
                                locale={locale}
                                note={
                                  '(' +
                                  parseFloat(item.qty + item.free_qty).toFixed(3) +
                                  ' ' +
                                  item.unit +
                                  ')'
                                }
                                FONT_FACTOR={font_size}
                              />
                            </Text>
                          )}
                      </Text>
                    )}
                  </View>

                  {data.show_description == 1 &&
                    item.description != '' &&
                    item.description != '<p><br></p>' && (
                      <View style={styles.invoiceTableRow_description} key={(i + 1).toString()}>
                        <View
                          style={{
                            width: `${2.8 * dc_col}%`,
                            textAlign: 'left',
                            flexBasis: '100%',
                            paddingLeft: `${0.2 * dc_col}%`,
                          }}
                        >
                          <RichText
                            locale={locale}
                            note={item.description}
                            FONT_FACTOR={font_size}
                          />
                        </View>
                      </View>
                    )}

                  {invoice.invoice_settings.show_images == 1 &&
                    item.product_images[0].is_empty == 0 && (
                      <View style={styles.invoiceTableRow_description} key={(i + 1).toString()}>
                        <View
                          style={{
                            width: `${2.3 * single_col2}%`,
                            textAlign: 'left',
                            paddingLeft: `${0.2 * single_col2}%`,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: 'row',
                            }}
                          >
                            {item.product_images.map((image, index) => (
                              <Image
                                style={styles.invoiceTableRow_image}
                                src={{
                                  uri: image.url,
                                  method: 'GET',
                                  headers: { 'Cache-Control': 'no-cache' },
                                }}
                                crossorigin="anonymous"
                              />
                            ))}
                          </View>
                        </View>
                      </View>
                    )}
                </View>
              )}
            </>
          )}

          {type != 'Delivery Challan' && type != HIDE_PRICES && (
            <>
              <Fragment key={i}>
                {(invoice.with_tax == 0 || invoice.company.gstin.length < 15) && (
                  <View style={styles.invoiceTableRow_border}>
                    <View style={styles.invoiceTableRow_row} key={(i + 1).toString()} wrap={false}>
                      <Text
                        hyphenationCallback={e => breakName(e)}
                        style={{
                          paddingLeft: 4.5,
                          fontSize: font_size2,
                          textAlign: 'left',
                          width: `${0.2 * single_col2}%`,
                        }}
                      >
                        {item.position + 1}
                      </Text>

                      <View
                        style={{
                          textAlign: 'left',
                          fontSize: font_size2,
                          width: `${1.8 * single_col2}%`,
                        }}
                      >
                        <Text
                          hyphenationCallback={e => breakName(e)}
                          style={styles.invoiceTableRow_bold}
                        >
                          {item.name} {item.variant_name}
                        </Text>
                        <ItemBatchDetails item={item} invoice={invoice} />
                        {/*{data.show_description == 1 && (
                      <View
                        style={{
                          flexDirection: 'row',
                        }}
                      >
                        {item.product_images[0].url != '' &&
                          item.product_images[0].url !=
                            'https://app.getswipe.in/no_product3.png' && (
                            <Image
                              style={styles.invoiceTableRow_logo}
                              src={{
                                uri: item.product_images[0].url,
                                method: 'GET',
                                headers: { 'Cache-Control': 'no-cache' },
                              }}
                              crossorigin="anonymous"
                            />
                          )}
                        <View style={styles.invoiceTableRow_block}>
                          {item.description != '' &&
                            item.description &&
                            item.description != '<p><br></p>' && (
                              <RichText locale={locale} note={item.description} FONT_FACTOR={font_size}/>
                            )}
                        </View>
                      </View>
                    )}*/}
                      </View>

                      {!hide_hsn && (
                        <Text
                          style={{
                            fontSize: font_size,
                            width: `${single_col2}%`,
                          }}
                          hyphenationCallback={e => breakName(e)}
                        >
                          <Text hyphenationCallback={e => breakName(e)}>
                            {item.hsn_code != '' && item.hsn_code != null ? item.hsn_code : '-'}
                          </Text>
                        </Text>
                      )}

                      {item.custom_col_values.map((each_item, index) => (
                        <Text
                          key={index}
                          style={{ fontSize: font_size2, width: `${single_col2}%` }}
                        >
                          {each_item == '' ? '-' : each_item}
                        </Text>
                      ))}

                      <Text
                        style={{
                          paddingRight: 4.5,
                          fontSize: font_size2,
                          width: `${single_col2}%`,
                        }}
                      >
                        {(item.net_amount / conversion_factor).toLocaleString('en-IN', options)}
                      </Text>
                    </View>

                    {data.show_description == 1 &&
                      item.description != '' &&
                      item.description != '<p><br></p>' && (
                        <View style={styles.invoiceTableRow_description} key={(i + 1).toString()}>
                          <View
                            style={{
                              width: `${2.3 * single_col2}%`,
                              textAlign: 'left',
                              paddingLeft: `${0.2 * single_col2}%`,
                            }}
                          >
                            <RichText
                              locale={locale}
                              note={item.description}
                              FONT_FACTOR={font_size}
                            />
                          </View>
                        </View>
                      )}

                    {invoice.invoice_settings.show_images == 1 &&
                      item.product_images[0].is_empty == 0 && (
                        <View style={styles.invoiceTableRow_description} key={(i + 1).toString()}>
                          <View
                            style={{
                              width: `${2.3 * single_col2}%`,
                              textAlign: 'left',
                              paddingLeft: `${0.2 * single_col2}%`,
                            }}
                          >
                            <View
                              style={{
                                flexDirection: 'row',
                              }}
                            >
                              {item.product_images.map((image, index) => (
                                <Image
                                  style={styles.invoiceTableRow_image}
                                  src={{
                                    uri: image.url,
                                    method: 'GET',
                                    headers: { 'Cache-Control': 'no-cache' },
                                  }}
                                  crossorigin="anonymous"
                                />
                              ))}
                            </View>
                          </View>
                        </View>
                      )}
                  </View>
                )}

                {invoice.with_tax == 1 && invoice.company.gstin.length == 15 && (
                  <View style={styles.invoiceTableRow_border}>
                    <View style={styles.invoiceTableRow_row} key={(i + 1).toString()} wrap={false}>
                      <Text
                        hyphenationCallback={e => breakName(e)}
                        style={{
                          paddingLeft: 4.5,
                          fontSize: font_size,
                          textAlign: 'left',
                          width: `${0.5 * single_col}%`,
                        }}
                      >
                        {item.position + 1}
                      </Text>

                      <View
                        style={{
                          textAlign: 'left',
                          fontSize: font_size,
                          width: `${2.5 * single_col}%`,
                        }}
                      >
                        <Text
                          hyphenationCallback={e => breakName(e)}
                          style={styles.invoiceTableRow_bold}
                        >
                          {item.name} {item.variant_name}
                        </Text>
                        <ItemBatchDetails item={item} invoice={invoice} />
                      </View>

                      {!hide_hsn && (
                        <Text
                          style={{
                            textAlign: 'right',
                            fontSize: font_size,
                            width: `${0.8 * single_col}%`,
                          }}
                          hyphenationCallback={e => breakName(e)}
                        >
                          <Text hyphenationCallback={e => breakName(e)}>
                            {item.hsn_code != '' && item.hsn_code != null ? item.hsn_code : '-'}
                          </Text>
                        </Text>
                      )}

                      {item.custom_col_values.map((each_item, index) => (
                        <Text
                          key={index}
                          style={{ flexWrap: 'wrap', fontSize: font_size, width: `${single_col}%` }}
                        >
                          {each_item == '' ? '-' : each_item}
                        </Text>
                      ))}

                      <Text
                        style={{
                          paddingRight: 4.5,
                          fontSize: font_size,
                          textAlign: 'right',
                          width: `${1.2 * single_col}%`,
                        }}
                      >
                        {(item.net_amount / conversion_factor).toLocaleString('en-IN', options)}
                      </Text>
                    </View>

                    {data.show_description == 1 &&
                      item.description != '' &&
                      item.description != '<p><br></p>' && (
                        <View style={styles.invoiceTableRow_description} key={(i + 1).toString()}>
                          <View
                            style={{
                              width: `${5 * single_col}%`,
                              textAlign: 'left',
                              paddingLeft: `${0.5 * single_col}%`,
                            }}
                          >
                            <RichText
                              locale={locale}
                              note={item.description}
                              FONT_FACTOR={font_size}
                            />
                          </View>
                        </View>
                      )}

                    {invoice.invoice_settings.show_images == 1 &&
                      item.product_images[0].is_empty == 0 && (
                        <View style={styles.invoiceTableRow_description} key={(i + 1).toString()}>
                          <View
                            style={{
                              width: `${5 * single_col}%`,
                              textAlign: 'left',
                              paddingLeft: `${0.5 * single_col}%`,
                            }}
                          >
                            <View
                              style={{
                                flexDirection: 'row',
                              }}
                            >
                              {item.product_images.map((image, index) => (
                                <Image
                                  style={styles.invoiceTableRow_image}
                                  src={{
                                    uri: image.url,
                                    method: 'GET',
                                    headers: { 'Cache-Control': 'no-cache' },
                                  }}
                                  crossorigin="anonymous"
                                />
                              ))}
                            </View>
                          </View>
                        </View>
                      )}
                  </View>
                )}
              </Fragment>
            </>
          )}
        </>
      )}
    </Fragment>
  ))
  return <Fragment>{rows}</Fragment>
}

export default InvoiceTableRow
