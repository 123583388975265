import { message } from 'antd'
import { history } from 'index'
import queryString from 'query-string'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { set_prefixes } from 'redux/document/actions'
import {
  SET_CUSTOM_COLUMNS,
  SET_INVOICE_SETTINGS,
  SET_DOCUMENTS_CREATED_COUNT,
} from 'redux/document/types'
import {
  setAllUsers,
  setCompanyDetails,
  setDefaultUrl,
  setIntegration,
  setPermissions,
} from 'redux/permissions/actions'
import * as jwt from 'services/jwt'
import actions from './actions'
import store from 'store'
import { set_warehouse_permissions } from 'redux/warehouses/actions'
import { getCountryInfo } from 'redux/countries/saga'
import { get_pos_settings } from 'redux/document/saga'

const mapAuthProviders = {
  login: jwt.login,
  mobileLogin: jwt.mobileLogin,
  verifyOtp: jwt.verifyOtp,
  register: jwt.register,
  currentAccount: jwt.currentAccount,
  logout: jwt.logout,
  company: jwt.company,
  getCompanies: jwt.getCompanies,
}

export function* LOGIN({ payload }) {
  const { email, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(mapAuthProviders.login, email, password)
  if (success == 'Bank') {
    yield history.push('/list/sales')
  } else if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
      ...payload,
      role: success.user_details.role,
    })
    message.success('Welcome Back!')
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* MOBILE_LOGIN({ payload }) {
  // const { mobile, params } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      mobileLoading: true,
    },
  })
  const success = yield call(mapAuthProviders.mobileLogin, payload)

  yield put({
    type: 'user/SET_STATE',
    payload: {
      mobileLoading: false,
    },
  })
}

export function* VERIFY_OTP({ payload }) {
  const { mobile, otp, params, email, accessToken } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      otpLoading: true,
    },
  })
  const response = yield call(mapAuthProviders.verifyOtp, mobile, otp, params, email, accessToken)
  if (response.success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
      role: response.user_details.role,
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        new_user: response.new_user,
      },
    })
    message.success('Welcome')
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      otpLoading: false,
    },
  })
}

export function* COMPANY({ payload }) {
  const { company } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(mapAuthProviders.company, company)

  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    message.success('Welcome')
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* REGISTER({ payload }) {
  const { email, password, name, mobile } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(mapAuthProviders.register, email, password, mobile, name)
  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    yield history.push('/list/sales')
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  // const startTime = performance.now()
  if (store.get('isPublic') == 1) return

  yield put({
    type: 'user/SET_STATE',
    payload: { loading: true, pageLoading: true },
  })

  // Fetch multiple API calls in parallel
  const response = yield call(mapAuthProviders.currentAccount)
  if (response && typeof response === 'object') {
    const [
      document_settings,
      custom_fields,
      companies,
      _, // country_info
      pos_settings,
    ] = yield all([
      call(() => jwt.getAPIData('utils', 'invoice_settings')),
      call(() => jwt.getAPIData('v2/custom_fields', 'item')),
      call(() => mapAuthProviders.getCompanies()),
      call(() => getCountryInfo({ payload: { country_code: response.company.country_code } })),
      call(() => get_pos_settings()),
    ])

    // Migrate old local storage data (temporary)
    const companyId = response.company.company_id
    const migrateStorage = (oldKey, newKey) => {
      if (store.get(oldKey)) {
        store.set(newKey + companyId, store.get(oldKey))
        store.remove(oldKey)
      }
    }
    migrateStorage('transactions_default_dates', 'transactions_default_dates_')
    migrateStorage('reportsDateRange', 'reportsDateRange_')

    yield all([
      put(setCompanyDetails(response.company)),

      // Update document settings
      document_settings?.success &&
        put({
          type: 'user/SET_STATE',
          payload: {
            document_settings: {
              ...document_settings.invoice_settings,
              invoice_templates: document_settings.invoice_templates,
              num_invoices: document_settings.num_invoices,
              paid: document_settings.paid,
            },
          },
        }),
      document_settings?.success && put(set_prefixes(document_settings.prefixes)),
      document_settings?.success &&
        put({
          type: SET_INVOICE_SETTINGS,
          payload: {
            ...document_settings.invoice_settings,
            invoice_templates: document_settings.invoice_templates,
            num_invoices: document_settings.num_invoices,
            paid: document_settings.paid,
          },
        }),

      // User state updates
      put({
        type: 'user/SET_STATE',
        payload: {
          authorized: true,
          role: response.role,
          paid: response.user_details?.paid,
          company_id: companyId,
          status_response: response,
        },
      }),

      // Update custom columns & barcode settings
      custom_fields?.success &&
        put({ type: SET_CUSTOM_COLUMNS, payload: custom_fields.custom_columns }),

      put(
        setPermissions({
          ...response.integrations,
          ...response.roles[0],
          id: response.roles[0].id,
          campaigns: response.roles[0].campaigns,
          trial_days: response.trial_days,
          is_pos: response.is_pos,
          additional_cess: response.company.additional_cess,
          bom: response.company.bom,
          reminders: response.company.reminders,
          payment_gateway_authorized:
            response.payment_gateway_settings?.payment_gateway_authorize == 1,
          role: response.role,
        }),
      ),
      put(set_warehouse_permissions(response.warehouse_permissions)),
      put(setIntegration(response.integrations)),
      put(setDefaultUrl(response.url)),
      put(setAllUsers(response.all_users)),
      put({
        type: 'welcome/UPDATE_WELCOME_DETAILS',
        payload: {
          company_details_added: response.company_details_added,
          products_added: response.products_added,
          bank_details_added: response.bank_details_added,
          invoices_created: response.invoices_created,
        },
      }),
      companies?.success &&
        put({
          type: 'user/SET_STATE',
          payload: { companies: companies.companies, selectedCompany: response.company },
        }),
      put({ type: 'menu/GET_DATA' }),
      put({
        type: 'user/SET_USER_DETAILS',
        payload: { ...response.user_details },
      }),
      put({
        type: SET_DOCUMENTS_CREATED_COUNT,
        payload: {
          invoices_created: response.invoices_created ?? 0,
          delivery_challans_created: response.delivery_challans_created ?? 0,
          sales_orders_created: response.sales_orders_created ?? 0,
          pro_forma_invoices_created: response.pro_forma_invoices_created ?? 0,
        },
      }),
    ])

    // Redirect user if necessary
    const queryParams = queryString.parseUrl(window.location.href)?.query
    const paramsString = queryParams ? '?' + queryString.stringify(queryParams) : ''
    if (window.location.pathname.includes('/auth')) {
      yield history.push(queryParams?.redirect ?? '/' + paramsString)
    } else {
      yield history.push(window.location.pathname + paramsString)
    }
  }
  yield put({
    type: 'user/SET_STATE',
    payload: { loading: false, pageLoading: false },
  })
  // const endTime = performance.now()
  // console.log('LOAD_CURRENT_ACCOUNT in ms=', endTime - startTime)
}

export function* get_companies() {
  const prev_company_details = yield select(state => state.user.selectedCompany)
  const response2 = yield call(mapAuthProviders.getCompanies)
  if (response2 != true) {
    if (response2.success) {
      const new_selected_company_details = response2.companies.find(
        company => company.company_id == prev_company_details.company_id,
      )
      yield put({
        type: 'user/SET_STATE',
        payload: {
          companies: response2.companies,
          selectedCompany: { ...prev_company_details, ...new_selected_company_details },
        },
      })
    }
  }
}

export function* LOGOUT() {
  yield put({ type: actions.PAGE_LOADING, payload: true })

  yield call(mapAuthProviders.logout)
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOGIN, LOGIN),
    takeLatest(actions.MOBILE_LOGIN, MOBILE_LOGIN),
    takeLatest(actions.VERIFY_OTP, VERIFY_OTP),
    takeLatest(actions.REGISTER, REGISTER),
    takeLatest(actions.COMPANY, COMPANY),
    takeLatest(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeLatest(actions.LOGOUT, LOGOUT),
    takeLatest(actions.GET_COMPANIES, get_companies),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
