import React, { useEffect, useState, Component, useReducer } from 'react'
import { Button, Card, Collapse, Tabs, Input, Typography, Tooltip, message } from 'antd'
import { CopyOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { PDFDownloadLink } from '@react-pdf/renderer'
import './style.module.scss'
import { EnhancedQRCode } from 'components/other/qrCodeFilePosBill'
import { getAPI, getAPIData, getAPIPDF } from 'services/jwt'
import SectionHeader from 'components/other/sectionHeader'
import CopyText from 'components/other/CopyText'
import Invoice1 from 'components/new_invoice/1/index'
import Invoice2 from 'components/new_invoice/2/index'
import Invoice3 from 'components/new_invoice/3/index'
import Invoice4 from 'components/new_invoice/4/index'
import Invoice5 from 'components/new_invoice/5/index'
import Invoice6 from 'components/new_invoice/6/index'
import Invoice7 from 'components/new_invoice/7/index'
import Invoice8 from 'components/new_invoice/8/index'
import Invoice9 from 'components/new_invoice/9/index'
import Invoice10 from 'components/new_invoice/10/index'
import Invoice11 from 'components/new_invoice/11/index'
import Invoice12 from 'components/new_invoice/12/index'
import Invoice13 from 'components/new_invoice/13/index'
import Invoice14 from 'components/new_invoice/14/index'
import EnhancedInvoiceForm from 'components/forms/invoiceDetails'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import DOMPurify from 'dompurify'
import { STOCK_IN_STOCK_OUT_DOC_TYPES } from 'utils/constants'

dayjs.extend(utc)
const { Panel } = Collapse
const { TabPane } = Tabs

const Map = {
  Invoice1: Invoice1,
  Invoice2: Invoice2,
  Invoice3: Invoice3,
  Invoice4: Invoice4,
  Invoice5: Invoice5,
  Invoice6: Invoice6,
  Invoice7: Invoice7,
  Invoice8: Invoice8,
  Invoice9: Invoice9,
  Invoice10: Invoice10,
  Invoice11: Invoice11,
  Invoice12: Invoice12,
  Invoice13: Invoice13,
  Invoice14: Invoice14,
}

const columns = [
  {
    title: 'Product',
    dataIndex: 'name',
    render: (text, record) => (
      <span>
        {text}
        <br />
        {record.description}
      </span>
    ),
  },
  {
    title: 'Qty',
    dataIndex: 'qty',
  },
  {
    title: 'Disc',
    dataIndex: 'discount',
  },
  {
    title: 'Net Amount',
    dataIndex: 'net_amount',
  },
  {
    title: 'Tax Amount',
    dataIndex: 'tax_amount',
    render: (text, record) => (
      <span>
        {text} ({record.tax}%)
      </span>
    ),
  },
  {
    title: 'Amount',
    dataIndex: 'total_amount',
  },
]

const getStatusClass = data => {
  switch (data) {
    case 'pending':
      return 'warning'
    case 'paid':
      return 'success'
    case 'cancelled':
      return 'danger'
    case 'partially paid':
      return 'partial'
    default:
      return 'warning'
  }
}

export class Table8 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewDetails: false,
      copied: false,
      loading: false,
      eway_bill_details: {},
    }
  }

  showDrawer = () => {
    this.setState({
      viewDetails: true,
    })
  }

  onClose = () => {
    this.setState({
      viewDetails: false,
    })
  }
  async downloadFile(url, name) {
    const res = await fetch(url)
    const blob = await res.blob()
    const url2 = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url2
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  //don't remove this code

  // downloadDocument = async (document, menuName, document_name) => {
  //   await registerFont(document.company.locale)
  //   const blob = await pdf(<AllInvoice order={document} menuName={menuName} />).toBlob()
  //   this.downloadFile(
  //     URL.createObjectURL(blob),
  //     `${document_name}-${document.company.organization_name}-${document.serial_number}`,
  //   )
  //   if (document.eway_bill.length > 0) {
  //     const req = {
  //       document_type: document.document_type,
  //       invoice_serial_number: document.eway_bill[0].invoice_serial_number,
  //       new_hash_id: document.new_hash_id,
  //       serial_number: document.eway_bill[0].serial_number,
  //     }
  //     const data = await getAPI(`ewaybills`, 'get_ewaybill', req)
  //     console.log(data)
  //     const eway_bill_blob = await pdf(<EwayBill invoice={{ ...data.invoice_details }} />).toBlob()
  //     this.downloadFile(
  //       URL.createObjectURL(eway_bill_blob),
  //       `${'EwayBill'}-${document.company.organization_name}-${
  //         document.eway_bill[0].serial_number
  //       }`,
  //     )
  //   }
  // }
  download = async (pdfDataArrayBuffer, pdfName) => {
    try {
      const uint8Array = new Uint8Array(pdfDataArrayBuffer)
      const blob = new Blob([uint8Array], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${pdfName}`)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      console.error('Error generating or downloading PDF:', error)
    }
  }
  downloadEwayBill = async () => {
    const doc = this.props.order
    const req = {
      document_type: doc.document_type,
      invoice_serial_number: doc.eway_bill[0].invoice_serial_number,
      new_hash_id: doc.new_hash_id,
      serial_number: doc.eway_bill[0].serial_number,
    }
    const hide = message.loading({
      content: 'Downloading...',
      duration: 0,
    })
    const filename = `${'EwayBill'}-${doc.company.organization_name}-${
      doc.eway_bill[0].serial_number
    }`
    const data = await getAPIPDF(`ewaybills`, 'get_ewaybill_document_public', req)
    this.download(data.data, filename)
    hide()
  }

  sanitizeHtmlInput = ({ htmlInput }) => {
    return DOMPurify.sanitize(htmlInput)
  }

  render() {
    var document = this.props.order
    var menuName = this.props.menuName
    var paymentGateway = this.props.paymentGateway
    var displayRazorpay = this.props.displayRazorpay

    const onSelectChange = keys => {
      setSelectedRowKeys(keys)
    }

    var document_name = 'Invoice'
    if (menuName == 'sales') {
      document_name = 'Invoice'
    } else if (menuName == 'purchases') {
      document_name = 'Purchase'
    } else if (menuName == 'purchase_orders') {
      document_name = 'Purchase Order'
    } else if (menuName == 'estimates') {
      document_name = this.props.order.document_title
    } else if (menuName == 'pos') {
      document_name = 'Invoice'
    } else if (menuName == 'purchase_returns') {
      document_name = 'Purchase Return'
    } else if (menuName == 'sales_returns') {
      document_name = 'Sales Return'
    } else if (menuName == 'delivery_challans') {
      document_name = 'Delivery Challan'
    } else if (menuName == 'sales_orders') {
      document_name = 'Sales Order'
    }

    const DescriptionItem = ({ title, content }) => (
      <div className="site-description-item-profile-wrapper" style={{ lineHeight: '2.25rem' }}>
        <div className="flex p-0 m-0 justify-end">
          <div className="w-7/12 text-right">
            <span
              className="site-description-item-profile-p-label font-weight-bold text-gray-500"
              style={{ display: 'inline-block', fontSize: '1.08rem' }}
            >
              {title}
            </span>
          </div>
          <div className="w-4/12 m-0 p-0 align-self-end">
            <span
              className="font-weight-bolder"
              style={{ display: 'inline-block', fontSize: '1.44rem' }}
            >
              {' '}
              {content}{' '}
            </span>
          </div>
        </div>
      </div>
    )

    const dotOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    // console.log(dayjs(document.eway_bill[0].eway_bill_date).format("MMM D YYYY"))

    return (
      <div className="flex flex-col items-center px-4">
        <div className="px-4 md:px-8">
          <Card size="drawer" className="border-radius-18 card-shadow-3 mb-8">
            {/* <SectionHeader title={document_name + ' details'} center={true} /> */}
            <div className="flex flex-wrap">
              <div className="w-full text-center">
                {document.customer.name != '' && document.customer.name != null && (
                  <h5 className="font-weight-bolder text-gray-900">
                    Hi, {document.customer.name}!
                  </h5>
                )}
                <h6 className="font-weight-medium tetx-lg text-gray-500 mt-1 mb-6">
                  Here's your {document_name} from {document.company.organization_name}.
                </h6>
              </div>

              <div className="w-full text-base mb-2">
                <div className="grid grid-cols-2 gap-x-8 gap-y-2 leading-tight">
                  <div className="text-right font-weight-bold">
                    <div className="mb-2 text-gray-500">{document_name} #</div>
                    <div className="mb-2 text-gray-500">Date</div>
                    <div className="mb-2 text-gray-500">Status</div>
                    {document.eway_bill.length > 0 && (
                      <div className="mb-2 text-gray-500">Eway Bill #</div>
                    )}
                    {document.eway_bill.length > 0 && (
                      <div className="mb-2 text-gray-500">Eway Bill Date:</div>
                    )}
                    {/*{document.customer.balance < 0 && <h6 className="mb-2 text-gray-500">Balance :</h6>}*/}
                  </div>
                  <div className="text-left font-weight-bolder">
                    <div className="mb-2">
                      <CopyText text={document.serial_number} />
                    </div>
                    <div className="mb-2">{document.document_date}</div>
                    <div className="mb-2">
                      {document.document_type != 'estimate' &&
                      document.document_type != 'pro_forma_invoice' &&
                      document.document_type != 'purchase_order' ? (
                        <a_link
                          size=""
                          onClick={() =>
                            this.invoiceForm.onOpen(menuName, {
                              new_hash_id: this.props.new_hash_id,
                            })
                          }
                          type="link"
                          className="font-weight-bolder"
                        >
                          {document.payment_status == 'paid' && (
                            <span className="flex items-center">
                              <i className="fa-solid text-forest fa-circle-check mr-2"></i>
                              <span className="text-forest">{document.payment_status}</span>
                            </span>
                          )}
                          {document.payment_status != 'paid' && (
                            <span className="flex items-center">
                              <i className="fa-regular fa-circle-exclamation text-pending mr-2"></i>
                              <span className="text-pending">{document.payment_status}</span>
                            </span>
                          )}
                        </a_link>
                      ) : (
                        <a_link
                          size=""
                          onClick={() =>
                            this.invoiceForm.onOpen(menuName, {
                              new_hash_id: this.props.new_hash_id,
                            })
                          }
                          type="link"
                          className="font-weight-bolder"
                        >
                          <span className="flex items-center">
                            <i
                              className={`fa-regular fa-circle-exclamation mr-2 ${
                                document.status == 'closed' || document.status == 'converted'
                                  ? 'text-success'
                                  : 'text-pending'
                              }`}
                            ></i>
                            <span
                              className={
                                document.status == 'closed' || document.status == 'converted'
                                  ? 'text-success'
                                  : 'text-pending'
                              }
                            >
                              {document.status}
                            </span>
                          </span>
                        </a_link>
                      )}
                    </div>
                    {document.eway_bill.length > 0 && (
                      <div className="mb-2">
                        <CopyText text={document.eway_bill[0].serial_number} />
                      </div>
                    )}
                    {document.eway_bill.length > 0 && (
                      <div className="mb-2">
                        {dayjs(document.eway_bill[0].eway_bill_date).format('MMM D YYYY')}
                      </div>
                    )}

                    {/*{document.customer.balance < 0 && (
                  <h6 className="mb-2 font-weight-bold">{Math.abs(document.customer.balance)}</h6>
                )}*/}
                  </div>
                </div>
                <div className="w-full mt-1 mb-2 text-center"></div>
              </div>

              <div className="w-full text-center">
                {document.hide_totals == 0 && (
                  <div className="flex flex-col items-center">
                    <p className="font-weight-bold text-gray-500 text-base mb-0">Amount</p>
                    <span className="font-weight-bolder text-gray-900 text-3xl leading-none">
                      {document.export_details.length > 0 &&
                      document.invoice_settings.show_both_currencies != true ? (
                        <span className="text-xl ">
                          {document.export_details[0].currency_symbol}
                        </span>
                      ) : (
                        <span className="text-xl mr-1">{document?.company?.currency_symbol}</span>
                      )}
                      {document.export_details.length > 0 &&
                      document.invoice_settings.show_both_currencies != true
                        ? (
                            document.total_amount / document.export_details[0].conversion_factor
                          ).toFixed(2)
                        : document.total_amount.toLocaleString('en-IN', dotOptions)}
                    </span>
                  </div>
                )}

                {document.amount_paid > 0 &&
                  document.amount_paid < document.total_amount &&
                  document.is_tds == 0 && (
                    <div className="flex mt-1">
                      <div className="w-1/2 mb-2 text-center">
                        <p className="text-gray-500 font-weight-bold text-forest text-base mb-0">
                          Paid
                        </p>
                        <h6 className="font-weight-bolder text-green text-gray-900 text-xl mb-1 leading-none">
                          <span className="font-size-18 mr-1">
                            {document?.company?.currency_symbol}
                          </span>
                          {document.amount_paid.toLocaleString('en-IN', dotOptions)}
                        </h6>
                      </div>

                      <div className="w-1/2 mb-2 text-center">
                        <p className="text-gray-500 font-weight-bold text-base mb-0">Pending</p>
                        <h6 className="font-weight-bolder text-card text-xl mb-1 leading-none">
                          <span className="text-xl mr-1">{document?.company?.currency_symbol}</span>
                          {document.amount_pending.toLocaleString('en-IN', dotOptions)}
                        </h6>
                      </div>
                    </div>
                  )}
                {document.amount_paid > 0 &&
                  document.amount_paid < document.total_amount &&
                  document.is_tds == 1 && (
                    <div className="flex mt-2">
                      <div className="w-1/2 mb-2 text-center">
                        <p className="text-gray-500 font-weight-bold font-size-12 mb-0">Pending</p>
                        <h6 className="font-weight-bolder text-card text-xl mb-1">
                          <span className="text-xl mr-1">{document?.company?.currency_symbol}</span>
                          {document.amount_pending.toLocaleString('en-IN', dotOptions)}
                        </h6>
                      </div>
                    </div>
                  )}

                {menuName != 'estimates' &&
                  document.document_due_date != document.document_date && (
                    <p className="text-pink font-weight-bold text-base">
                      Due on {document.document_due_date}
                    </p>
                  )}
                {menuName == 'estimates' && (
                  <p className="text-gray-500 text-base">Thank you for your business!</p>
                )}
                {/*<Button type="link" className="font-weight-bolder" onClick={this.showDrawer}>
              View Details <i className="ml-2 fa fa-arrow-right"></i>
            </Button>*/}

                {/* <div className="element">Hover Me</div> */}
                {/* <div className="element">Hover Me <i className="fas fa-arrow-right"></i></div> */}
              </div>

              {document.payment_status != 'cancelled' && (
                <div className="w-full mt-6 mb-2 text-center">
                  <Button
                    type="link"
                    className="font-weight-bolder arrow-transition mr-4"
                    onClick={() =>
                      this.invoiceForm.onOpen(menuName, { new_hash_id: this.props.new_hash_id })
                    }
                  >
                    View details <i className="ml-2 fa fa-arrow-right arrow-transition"></i>
                  </Button>

                  <PDFDownloadLink
                    style={{ color: '#ffffff' }}
                    document={<AllInvoice order={document} menuName={menuName} />}
                    fileName={`${document_name}-${document.company.organization_name}-${document.serial_number}.pdf`}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <Button type="primary" className="">
                          Loading document ...{' '}
                          <i className="ml-1 fa-solid fa-circle-notch fa-spin"></i>{' '}
                        </Button>
                      ) : (
                        <Button type="primary" className="">
                          <i className="mr-2 fa-solid fa-sharp font-weight-bold fa-arrow-down-to-line"></i>
                          Download {document_name}
                        </Button>
                      )
                    }
                  </PDFDownloadLink>

                  {/* <Button
                type="primary"
                className="mt-3 mr-2 ml-2"
                onClick={() => this.downloadDocument(document, menuName, document_name)}
              >
                Download {document_name} <i className="ml-2 fa-solid fa-arrow-down-to-square"></i>
              </Button> */}

                  {/* {paymentGateway == true &&
                    (document.payment_status == 'pending' ||
                      document.payment_status == 'partially paid') && (
                      <Button
                        key={0}
                        type="pay"
                        size="default"
                        className="px-5 mt-3 mr-2 ml-2"
                        loading={this.state.loading}
                        onClick={() => displayRazorpay()}
                      >
                        <span className="flex items-center">
                          <span className="font-weight-bolder">Pay Now</span>
                          <i className="fa fa-angle-double-right font-weight-bold ml-1 fa-beat" />
                        </span>
                      </Button>
                    )} */}

                  {document.eway_bill.length > 0 && (
                    <Button
                      type="primary"
                      className="mr-2 ml-2"
                      onClick={() => this.downloadEwayBill()}
                    >
                      <i className="mr-2 fa-solid fa-sharp font-weight-bold fa-arrow-down"></i>
                      Download EwayBill
                    </Button>
                  )}
                </div>
              )}
            </div>
          </Card>

          {document_name == 'Invoice' &&
            (document.payment_status == 'pending' || document.payment_status == 'partially paid') &&
            (document.bank_details.bank_no != '' ||
              document.company.upi != '' ||
              paymentGateway == true) && (
              <Card size="drawer" className="border-radius-18 card-shadow-3 mb-8">
                <SectionHeader title="Payment options" center={true} />
                <div className="">
                  {/* Tabs Section */}
                  <Tabs defaultActiveKey="1" centered className="" size="small">
                    {paymentGateway == true &&
                      (document.payment_status == 'pending' ||
                        document.payment_status == 'partially paid') && (
                        <TabPane tab="Pay with Swipe" key="1">
                          <div className="flex flex-col items-center">
                            <span className="text-gray-700 block text-center">
                              Pay directly online to {document.company.organization_name}.
                            </span>
                            <Button
                              key={0}
                              type="pay"
                              size="large"
                              className="px-5 mt-3 mr-2 ml-2 text-center"
                              loading={this.state.loading}
                              onClick={() => displayRazorpay()}
                            >
                              <span className="flex items-center">
                                <span className="font-weight-bolder">Pay Now</span>
                                <i className="fa fa-angle-double-right font-weight-bold ml-1 fa-beat" />
                              </span>
                            </Button>
                          </div>
                        </TabPane>
                      )}
                    {document.company.upi != '' && (
                      <TabPane tab="UPI" key="2">
                        <div className="w-full">
                          {/*<h6 className="font-weight-bold text-gray-900 text-primary mb-2">Pay now using UPI</h6>*/}
                          <span className="text-gray-900 block text-center">
                            Scan the QR code to pay the invoice to{' '}
                            {document.company.organization_name}.
                          </span>
                          <span className="text-gray-500 block text-center mb-6">
                            You can also pay using the UPI ID below.
                          </span>
                          <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-2 items-center">
                            {/* QR Code - Left side, Right-Aligned */}
                            <div className="flex justify-end">
                              <div className="text-center">
                                <EnhancedQRCode
                                  className="mb-2"
                                  onRef={ref => (document = ref)}
                                  value={document.upi}
                                />
                                <p className="font-weight-bold text-gray-500 pt-1 text-primary font-size-12 mb-2">
                                  Pay now using UPI
                                </p>
                              </div>
                            </div>

                            {/* UPI ID Text - Right side, Left-Aligned */}
                            <div className="text-left">
                              <span className="text-gray-500 text-base font-weight-medium">
                                UPI ID
                              </span>
                              <p className="text-base font-weight-bold mb-0">
                                <CopyText text={document.company.upi} />
                              </p>

                              {/* {document.company.gpay_number && (
                                <p className="font-size-16 mb-0 mt-4">
                                  <CopyText text={document.company.gpay_number} />
                                </p>
                              )} */}
                            </div>
                          </div>
                        </div>
                      </TabPane>
                    )}
                    {document.bank_details.bank_no != '' && (
                      <TabPane tab="Manual Transfer" key="3">
                        <div>
                          {/* Beneficiary Details */}
                          <div className="">
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-2">
                              <span className="text-gray-500 text-center md:text-right">
                                Account Number
                              </span>
                              <span className="text-gray-900 font-weight-bold text-center md:text-left">
                                <CopyText text={document.bank_details.bank_no} />
                              </span>

                              <span className="text-gray-500 text-center md:text-right">
                                IFSC Code
                              </span>
                              <span className="text-gray-900 font-weight-bold text-center md:text-left">
                                <CopyText text={document.bank_details.ifsc} />
                              </span>

                              <span className="text-gray-500 text-center md:text-right">
                                Beneficiary name
                              </span>
                              <span className="text-gray-900 font-weight-bold text-center md:text-left">
                                <CopyText text={document.company.company_name} />
                              </span>

                              <span className="text-gray-500 text-center md:text-right">Bank</span>
                              <span className="text-gray-900 font-weight-bold text-center md:text-left">
                                <CopyText
                                  text={
                                    document.bank_details.bank_name +
                                    (document.bank_details.branch_name != '' &&
                                      `, ${document.bank_details.branch_name}`)
                                  }
                                />
                              </span>
                              {document.bank_details.notes != '' && (
                                <>
                                  <span className="text-gray-500 text-center md:text-right">
                                    Notes
                                  </span>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: this.sanitizeHtmlInput({
                                        htmlInput: document.bank_details.notes,
                                      }),
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </TabPane>
                    )}
                  </Tabs>
                </div>
              </Card>
            )}

          {document.attachments.length > 0 && (
            <Card size="drawer" className="border-radius-18 card-shadow-3 mb-8">
              <SectionHeader title="Attachments" center={true} />
              <div className="justify-center mt-4 ">
                <div className="">
                  <dd className="mt-2 text-sm text-gray-900">
                    <ul
                      role="list"
                      className="divide-y divide-gray-100 rounded-md border border-gray-100 overflow-hidden"
                    >
                      {document.attachments.map((attachment, index) => (
                        <li
                          key={index}
                          className="flex items-center justify-between py-4 pl-4 pr-5 text-base hover:bg-gray-200 transition duration-200 cursor-pointer"
                          onClick={e => {
                            e.stopPropagation()
                            this.downloadFile(attachment.url, attachment.name)
                          }}
                        >
                          {/* Left Side - Attachment Icon & Name */}
                          <div className="flex w-0 flex-1 items-center">
                            <i
                              className="fa fa-paperclip text-gray-400 mr-2"
                              aria-hidden="true"
                            ></i>
                            <div className="ml-2 flex min-w-0 flex-1 gap-2">
                              <span className="truncate font-medium">{attachment.name}</span>
                            </div>
                          </div>

                          {/* Right Side - Download Button */}
                          <div className="ml-4 shrink-0">
                            <a
                              onClick={e => {
                                e.stopPropagation()
                                this.downloadFile(attachment.url, attachment.name)
                              }}
                              className="font-medium text-indigo-600 hover:text-indigo-500 flex items-center gap-1"
                            >
                              <i className="fa-regular fa-arrow-down"></i> Download
                            </a>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </dd>
                </div>
              </div>
            </Card>
          )}
        </div>

        <EnhancedInvoiceForm onRef={ref => (this.invoiceForm = ref)} />
      </div>
    )
  }
}

export default Table8

export function AllInvoice({ order, menuName, flag = false }) {
  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    invoiceSettings: { ...order.invoice_settings },
  })
  let type = ['']

  var SelectedTemplate = Map['Invoice1']

  if (flag) {
    var SelectedTemplate = ChallanInvoice
  }
  if ((menuName == 'sales' || menuName == 'pos' || menuName == 'sales_returns') && flag == false) {
    var SelectedTemplate = Map['Invoice' + String(state.invoiceSettings.invoice_template)]
  }
  if (menuName == 'purchases' || menuName == 'purchase_orders' || menuName == 'purchase_returns') {
    var SelectedTemplate = Map['Invoice' + String(state.invoiceSettings.purchase_template)]
  }
  if (
    menuName == 'estimates' ||
    menuName == 'pro_forma_invoices' ||
    menuName == 'delivery_challans' ||
    menuName == 'sales_orders'
  ) {
    var SelectedTemplate = Map['Invoice' + String(state.invoiceSettings.estimate_template)]
  }
  if (menuName == 'delivery_challans') {
    type = ['Delivery Challan']
  }

  if (SelectedTemplate == undefined) {
    SelectedTemplate = Map['Invoice1']
  }

  return (
    <React.Fragment>
      {(menuName == 'sales' || menuName == 'pos') && flag == false && (
        <SelectedTemplate
          invoice={{
            ...order,
            document_type: menuName,
            type: [order.invoice_settings.labels.original_for_recipient],
            myKey: 0,
          }}
        />
      )}

      {menuName == 'sales_returns' && (
        <SelectedTemplate invoice={{ ...order, document_type: menuName, type: [''] }} />
      )}

      {(menuName == 'purchases' ||
        menuName == 'purchase_orders' ||
        menuName == 'purchase_returns') && (
        <SelectedTemplate invoice={{ ...order, document_type: menuName, type: [''] }} />
      )}

      {(menuName == 'estimates' ||
        menuName == 'delivery_challans' ||
        menuName == 'pro_forma_invoices' ||
        menuName == 'sales_orders') && (
        <SelectedTemplate invoice={{ ...order, document_type: menuName, type: type }} />
      )}
    </React.Fragment>
  )
}
