import { Button, Modal } from 'antd'
import React, { forwardRef, useEffect, useImperativeHandle, useState, useRef } from 'react'
import { downloadPDF, getAPIPDF } from 'services/jwt'
import { arrayBufferToBase64 } from './utility'

const GetPaymentDocument = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false)
  const [obj, setObj] = useState(null)
  const [serial_number, setSerialNumber] = useState(null)
  const [downloadedPDF, setDownloadedPDF] = useState(null)
  const paymentPdfRef = useRef(null)

  useImperativeHandle(ref, () => ({
    showModal,
  }))

  useEffect(() => {
    if (obj && paymentPdfRef.current) {
      paymentPdfRef.current.innerHTML = ''
      paymentPdfRef.current.appendChild(obj)
    }
  }, [obj])

  const showModal = async (serial_number, hash_id) => {
    if (!loading) {
      setLoading(true)
      setSerialNumber(serial_number)
      let url = ''
      if (serial_number) {
        url = 'get_payment_receipt'
      } else if (hash_id) {
        url = 'get_view_payment_receipt'
      }
      const req = {
        serial_number,
        hash_id,
      }

      const data = await getAPIPDF('payments', url, req)
      if (data) {
        setDownloadedPDF(data.data)
        let base64Str = arrayBufferToBase64(data.data)
        var iframe = document.createElement('iframe')
        iframe.style.width = '100%'
        iframe.style.height = '842pt'
        iframe.src = 'data:application/pdf;base64,' + base64Str
        setObj(iframe)
      }
      setLoading(false)
    }
  }

  return (
    <div>
      <Modal
        title={
          <>
            <div className="mr-4 d-flex justify-content-between">
              <h5>Payment Receipt {serial_number ? serial_number : ''}</h5>
              <Button onClick={() => downloadPDF(downloadedPDF)} size="medium">
                <i className="fa-regular fa-download" />
              </Button>
            </div>
          </>
        }
        open={!!obj}
        style={{ top: 20 }}
        width={800}
        height={1024}
        onOk={() => setObj(null)}
        onCancel={() => setObj(null)}
        closeIcon={<i className="fa-solid fa-xmark"></i>}
        footer={[
          <Button key="back" type="default" onClick={() => setObj(null)}>
            Cancel
          </Button>,
        ]}
      >
        <div ref={paymentPdfRef} className="h-full"></div>
      </Modal>
    </div>
  )
})

export default GetPaymentDocument
