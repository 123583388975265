import * as dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'
import { getAPIData } from 'services/jwt'

dayjs.extend(utc)
dayjs.extend(updateLocale)
dayjs.extend(calendar)

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'few seconds',
    m: 'a minute',
    mm: '%dm',
    h: 'an hour',
    hh: '%dh',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
})

export const getUpdatedPeriodAgo = date => {
  const dateObj = dayjs(date, { format: 'DD MMM YY, hh:mm A' }).format('YYYY-MM-DD')
  const currentDate = dayjs()

  const diffDays = currentDate.diff(dateObj, 'days')
  const diffHours = currentDate.diff(dateObj, 'hours')

  const isMoreThanAWeek = diffDays > 7
  const isLessThanADay = diffHours < 24

  let displayDate = dayjs(date).calendar(null, {
    sameDay: '[Today], h:mm A',
    lastDay: '[Yesterday], h:mm A',
    lastWeek: 'ddd h:mm A',
    sameElse: 'ddd h:mm A',
  })

  // var displayDate = diffDays + ' days ago'

  // if (diffHours > 24 && diffHours < 48) {
  //   displayDate = 'Yesterday'
  // }

  if (isMoreThanAWeek) {
    displayDate = date
  }

  if (isLessThanADay) {
    displayDate = dayjs(date, { format: 'DD MMM YY, hh:mm A' }).fromNow()
  }

  return {
    displayDate,
    isMoreThanAWeek,
  }
}

export const formatDashedDate = dateStr => {
  try {
    const formattedDate = dayjs(dateStr, 'DD-MM-YYYY').format('DD MMM YYYY')
    if (formattedDate === 'Invalid Date') {
      return dateStr
    }
    return formattedDate
  } catch (error) {
    console.error('Error', error)
    return dateStr
  }
}

export const mergeItemsAndItemHeaders = (items, itemHeaders) => {
  // item headers might have the same key as items (when fetching from backend), so put headers first

  let finalData = []
  let itemIndex = 0
  let headerIndex = 0

  if (!items) {
    items = []
  }
  if (!itemHeaders) {
    itemHeaders = []
  }
  if (itemHeaders?.length === 0) {
    return items
  }

  while (itemIndex < items?.length && headerIndex < itemHeaders?.length) {
    if (items[itemIndex].key < itemHeaders[headerIndex].key) {
      finalData.push(items[itemIndex])
      itemIndex++
    } else {
      finalData.push(itemHeaders[headerIndex])
      headerIndex++
    }
  }

  while (itemIndex < items.length) {
    finalData.push(items[itemIndex])
    itemIndex++
  }

  while (headerIndex < itemHeaders.length) {
    finalData.push(itemHeaders[headerIndex])
    headerIndex++
  }

  // make them in incremental order
  finalData = finalData.map((item, index) => ({
    ...item,
    key: index + 1,
  }))

  return finalData
}

export const isRecent = recordTime => {
  if (recordTime == null || recordTime == '' || recordTime == undefined) {
    return false
  }
  if (dayjs(recordTime, { format: 'DD MMM YY, hh:mm A' })?.isAfter(dayjs().subtract(1, 'minute'))) {
    return true
  }
  return false
}

export const emphasizeSection = (selector, style) => {
  setTimeout(() => {
    const rows = document.querySelectorAll(`.${selector}`)
    rows.forEach(row => {
      row.classList?.add(style)
    })
    setTimeout(() => {
      rows.forEach(row => {
        row.classList?.remove(style)
      })
    }, 2000)
  }, 500)
}

export const removeMentionSpans = html => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const mentionSpans = doc.querySelectorAll('span.mention')
  mentionSpans.forEach(span => {
    const mentionText = span.innerText || span.textContent
    const textNode = doc.createTextNode(mentionText || '')
    if (span.parentNode) {
      span.parentNode.replaceChild(textNode, span)
    }
  })

  return doc.body.innerHTML
}
export const convertDays = num_days => {
  const years = Math.floor(num_days / 365)
  const remainingDaysAfterYears = num_days % 365
  const months = Math.floor(remainingDaysAfterYears / 30)
  const days = remainingDaysAfterYears % 30
  return { years, months, days }
}

export const getExchangeRate = async toCurrency => {
  try {
    const data = await getAPIData('utils', 'exhange_rate?currency=' + toCurrency)
    if (data?.success) {
      return { exchange_rate: data.exchange_rate, message: data.message ?? '' }
    }
    return { exchange_rate: 1, message: '' }
  } catch (error) {
    console.error('Error', error)
    return { exchange_rate: 1, message: 'error' }
  }
}
