import React, { useRef } from 'react'
import { Button } from 'antd'

import NewBadge from 'components/badges/new'
import ShareSettings, { ShareSettingsRef } from 'pages/reports/ShareSettings'
import { ShareSettingsProps } from 'pages/reports/data/types'
import store from 'store'

const ReportShareButton = ({ report, publicFilters }: ShareSettingsProps) => {
  const shareSettingsRef = useRef<ShareSettingsRef>(null)

  return (
    <>
      <Button
        onClick={() => shareSettingsRef?.current?.openDrawer()}
        className="hidden sm:flex items-center"
      >
        <i className="fa-regular fa-share-nodes mr-2" /> Share
        <NewBadge />
      </Button>

      <ShareSettings ref={shareSettingsRef} report={report} publicFilters={publicFilters} />
    </>
  )
}

export default ReportShareButton
