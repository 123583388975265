import React from 'react'
import {
  Modal,
  Button,
  Row,
  Col,
  Typography,
  Space,
  Skeleton,
  Input,
  Collapse,
  Divider,
} from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import { getAPI } from 'services/jwt'
import { InputRef } from 'antd'

const { Title, Text, Paragraph } = Typography
const { Panel } = Collapse

interface AffixItem {
  id: number
  documentType: DocumentType
  name: string
  oldPrefix: string
  newPrefix: string
  oldSuffix: string
  newSuffix: string
  hasDocs: boolean
}

type DocumentAffix = {
  company_id: number
  document_type: DocumentType
  id: number
  is_default: number
  is_delete: number

  prefix: string
  new_prefix: string
  suffix: string
  new_suffix: string
  dont_show: boolean

  shopify_default: number
  has_docs: boolean
}

type DocumentType = keyof typeof DOCUMENT_TYPE_MAP

const DOCUMENT_TYPE_MAP = {
  invoice: 'Sales Invoice',
  purchase: 'Purchase',
  purchase_return: 'Purchase Return',
  sales_return: 'Sales Return',
  delivery_challan: 'Delivery Challan',
  estimate: 'Estimates',
  pro_forma_invoice: 'Pro Forma',
  purchase_order: 'Purchase Order',
  subscription: 'Subscription',
  packing_list: 'Packing List',
  sales_order: 'Sales Order',
} as const

const AffixChangeModal = React.forwardRef<
  { showContent: (prefixesFetched: DocumentAffix[]) => void },
  {
    type: 'modal' | 'tab'
    updateAffixFlag: (value: number, changeTab?: boolean) => void
    updateShownAffixModalFlag: (value: number) => void
    updateHasNoDocs: (value: number) => void
    setShowSuccessModal: (open: boolean) => void
    toggleShowFYChanges: (show: boolean, changeTab?: boolean) => void
    isAfterMarch31?: boolean
  }
>((props, ref) => {
  const [isVisible, setIsVisible] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)

  const [showSuffix, setShowSuffix] = React.useState(false)
  const [alreadyUpdated, setAlreadyUpdated] = React.useState(false)

  const firstInputRef = React.useRef<InputRef>(null)

  const [defaultItems, setDefaultItems] = React.useState<{
    usedAffixes: AffixItem[]
    nonUsedAffixes: AffixItem[]
  }>({ usedAffixes: [], nonUsedAffixes: [] })

  const [isSubmitLoading, setIsSubmitLoading] = React.useState(false)

  const showContent = React.useCallback((prefixesFetched: DocumentAffix[]) => {
    if (prefixesFetched) {
      const hasSuffix = prefixesFetched.some(pf => pf.suffix !== '')

      if (hasSuffix) {
        setShowSuffix(true)
      }

      const prefixes: AffixItem[] = prefixesFetched.map(
        (item: DocumentAffix): AffixItem => {
          return {
            documentType: item.document_type,
            name: DOCUMENT_TYPE_MAP[item.document_type],
            oldPrefix: item.prefix,
            newPrefix: item.new_prefix,
            oldSuffix: item.suffix,
            newSuffix: item.new_suffix,
            id: item.id,
            hasDocs: item.has_docs,
          }
        },
      )

      const nonUsedAffixes = prefixes.filter(prefix => !prefix.hasDocs)
      const usedAffixes = prefixes.filter(prefix => prefix.hasDocs)

      setIsVisible(true)
      setDefaultItems({
        nonUsedAffixes,
        usedAffixes,
      })
    }
    setIsLoading(false)
  }, [])

  React.useEffect(() => {
    if (props.type === 'tab') {
      const fetchFYAffixes = async () => {
        const response = await getAPI('utils', 'get_all_active_document_affix')

        if (response?.dont_show) {
          props.toggleShowFYChanges(false, true)
          return
        }

        if (response?.already_updated) {
          props.updateShownAffixModalFlag(1)
          props.updateAffixFlag(1)
          setAlreadyUpdated(true)
        }

        if (response?.success && response?.affixes) {
          const affixes = response?.affixes
          if (!affixes.some((affix: DocumentAffix) => affix.has_docs)) {
            props.updateHasNoDocs(1)
            return
          }

          showContent(affixes)
        }
      }

      fetchFYAffixes()
    }
  }, [showContent])

  const handleConfirm = async (affixes: {
    usedAffixes: AffixItem[]
    nonUsedAffixes: AffixItem[]
  }) => {
    const mapper = (prefix: AffixItem) => ({
      document_type: prefix.documentType,
      name: prefix.name,
      new_prefix: prefix.newPrefix,
      old_prefix: prefix.oldPrefix,
      new_suffix: prefix.newSuffix,
      old_suffix: prefix.oldSuffix,
    })

    try {
      setIsSubmitLoading(true)

      const { nonUsedAffixes, usedAffixes } = affixes
      const reqData = usedAffixes.map(mapper).concat(nonUsedAffixes.map(mapper))

      const data = await getAPI('utils', 'update_all_affix', { changed_affixes: reqData })

      if (data && data.success) {
        setIsVisible(false)
        props.setShowSuccessModal(true)

        if (props.type === 'modal') {
          if (props.isAfterMarch31) {
            props.updateShownAffixModalFlag(2)
          } else {
            props.updateShownAffixModalFlag(1)
          }
        }

        props.updateAffixFlag(1, true)
      }
    } catch (error) {
      console.error('Error in handleConfirm:', error)
    } finally {
      setIsSubmitLoading(false)
    }
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
    type: 'prefix' | 'suffix',
  ) => {
    const value = e.target.value

    setDefaultItems(preValue => {
      const mapper = (affix: AffixItem) => {
        if (type === 'suffix') {
          return affix.id === id
            ? {
                ...affix,
                newSuffix: value,
              }
            : affix
        }

        return affix.id === id
          ? {
              ...affix,
              newPrefix: value,
            }
          : affix
      }

      const nonUsedAffixes = preValue.nonUsedAffixes.map(mapper)
      const usedAffixes = preValue.usedAffixes.map(mapper)

      return {
        nonUsedAffixes,
        usedAffixes,
      }
    })
  }

  React.useImperativeHandle(ref, () => ({
    showContent,
  }))

  const content = isLoading ? (
    <Skeleton active />
  ) : (
    <>
      <Row
        style={{
          marginBottom: 8,
          fontWeight: 'bold',
          borderBottom: '1px solid #f0f0f0',
          paddingBottom: 8,
        }}
      >
        <Col span={showSuffix ? 5 : 9}>
          <Text className="text-xl font-semibold">Document Type</Text>
        </Col>
        <Col span={showSuffix ? 4 : 5}>
          <Space size={0} direction="vertical">
            <Text className="text-xl font-semibold">Current Prefix</Text>
            <Text className="font-light text-sm text-gray-500">Until March 31</Text>
          </Space>
        </Col>
        {showSuffix && (
          <Col span={3}>
            <Space size={0} direction="vertical">
              <Text className="text-xl font-semibold">Current Suffix</Text>
              <Text className="font-light text-sm text-gray-500">Until March 31</Text>
            </Space>
          </Col>
        )}
        <Col span={4}></Col>
        <Col span={showSuffix ? 4 : 6}>
          <Space size={0} direction="vertical">
            <Text className="text-xl font-semibold pl-1">New Prefix</Text>
            <Text className="font-light text-sm text-gray-500 pl-1">From 1st April</Text>
          </Space>
        </Col>
        {showSuffix && (
          <Col span={4}>
            <Space size={0} direction="vertical">
              <Text className="text-xl font-semibold pl-1">New Suffix</Text>
              <Text className="font-light text-sm text-gray-500 pl-1">From 1st April</Text>
            </Space>
          </Col>
        )}
      </Row>

      {defaultItems.usedAffixes.map((item, index) => (
        <Row key={item.documentType} style={{ marginBottom: 8 }} align="middle" gutter={8}>
          <Col span={showSuffix ? 5 : 9}>
            <Text className="text-gray-500">{item.name}</Text>
          </Col>
          <Col span={showSuffix ? 4 : 5}>
            <Text className="text-gray-500">{item.oldPrefix}</Text>
          </Col>
          {showSuffix && (
            <Col span={3}>
              <Text className="text-gray-500">{item.oldSuffix}</Text>
            </Col>
          )}
          <Col span={4}>
            <Arrow />
          </Col>
          <Col span={showSuffix ? 4 : 6}>
            <Input
              value={item.newPrefix}
              onChange={e => handleInputChange(e, item.id, 'prefix')}
              className={`py-3 ${showSuffix ? 'w-10/12' : null}`}
              ref={props.type === 'modal' && index === 0 ? firstInputRef : null}
              autoFocus={props.type === 'tab' && index === 0 ? true : undefined}
              style={{
                border: '1px solid #d9d9d9',
                borderRadius: '4px',
                fontSize: '14px',
                textAlign: 'center',
              }}
            />
          </Col>
          {showSuffix && (
            <Col span={4}>
              <Input
                value={item.newSuffix}
                onChange={e => handleInputChange(e, item.id, 'suffix')}
                className="py-3"
                style={{
                  border: '1px solid #d9d9d9',
                  borderRadius: '4px',
                  fontSize: '14px',
                  textAlign: 'center',
                }}
              />
            </Col>
          )}
        </Row>
      ))}

      {defaultItems.nonUsedAffixes.length > 0 && (
        <Collapse bordered={true} style={{ marginTop: 10 }}>
          <Panel
            header={
              <span className="text-gray-500">
                {defaultItems.nonUsedAffixes.length} not frequently used prefixes
                {showSuffix ? '/suffixes' : null} (Click to expand)
              </span>
            }
            key="1"
          >
            <Space size={10} direction="vertical" className="w-full">
              {defaultItems.nonUsedAffixes.map(item => (
                <Row key={item.documentType} align="middle" gutter={8}>
                  <Col span={showSuffix ? 5 : 9}>
                    <Text className="text-gray-500">{item.name}</Text>
                  </Col>
                  <Col span={showSuffix ? 4 : 5}>
                    <Text className="text-gray-500">{item.oldPrefix}</Text>
                  </Col>
                  {showSuffix && (
                    <Col span={3}>
                      <Text className="text-gray-500">{item.oldSuffix}</Text>
                    </Col>
                  )}
                  <Col span={4}>
                    <Arrow />
                  </Col>
                  <Col span={showSuffix ? 4 : 6}>
                    <Input
                      value={item.newPrefix}
                      onChange={e => handleInputChange(e, item.id, 'prefix')}
                      className={`py-3 ${showSuffix ? 'w-10/12' : null}`}
                      style={{
                        border: '1px solid #d9d9d9',
                        borderRadius: '4px',
                        fontSize: '14px',
                        textAlign: 'center',
                      }}
                    />
                  </Col>
                  {showSuffix && (
                    <Col span={4}>
                      <Input
                        value={item.newSuffix}
                        onChange={e => handleInputChange(e, item.id, 'suffix')}
                        className="py-3"
                        style={{
                          border: '1px solid #d9d9d9',
                          borderRadius: '4px',
                          fontSize: '14px',
                          textAlign: 'center',
                        }}
                      />
                    </Col>
                  )}
                </Row>
              ))}
            </Space>
          </Panel>
        </Collapse>
      )}
    </>
  )

  const handleOnClose = () => {
    setIsVisible(false)
    if (props.isAfterMarch31) {
      props.updateShownAffixModalFlag(2)
    } else {
      props.updateShownAffixModalFlag(1)
    }
  }

  const invoiceAffix =
    defaultItems.usedAffixes.find(affix => affix.documentType === 'invoice') ||
    defaultItems.nonUsedAffixes.find(affix => affix.documentType === 'invoice') ||
    null

  if (props.type === 'modal' && invoiceAffix) {
    return (
      <div onClick={e => e.stopPropagation()}>
        <FinancialYearModal
          visible={isVisible}
          onCancel={handleOnClose}
          onSave={affix => handleConfirm(affix)}
          invoiceAffix={invoiceAffix}
          hasSuffix={showSuffix && !!invoiceAffix?.oldSuffix}
        />
      </div>
    )
  } else if (props.type === 'tab') {
    return (
      <div style={{ width: '100%' }}>
        <Space direction="vertical" style={{ width: '100%' }} className="mt-2">
          {content}
          <Button
            key="confirm"
            type="primary"
            onClick={() => handleConfirm(defaultItems)}
            loading={isSubmitLoading}
            className="!py-6 mt-4"
          >
            {alreadyUpdated ? 'Update' : 'Confirm'}
          </Button>
        </Space>
      </div>
    )
  }

  return null
})

const Arrow = () => (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <ArrowRightOutlined style={{ color: '#8c8c8c' }} />
  </div>
)

export const yearInfo = (input: string) => {
  const years = ['21', '22', '23', '24', '25']

  let firstYear = ''
  let secondYear = ''

  years.forEach(year => {
    let flag = input.includes(year)

    if (flag && firstYear === '') {
      firstYear = year
    } else if (flag) {
      secondYear = year
    }
  })

  return {
    firstYear: firstYear,
    secondYear: secondYear,
  }
}

const FinancialYearModal = ({
  visible,
  onCancel,
  onSave,
  invoiceAffix,
  hasSuffix,
}: {
  visible: boolean
  onCancel: () => void
  onSave: (affix: { usedAffixes: AffixItem[]; nonUsedAffixes: AffixItem[] }) => Promise<void>
  invoiceAffix: AffixItem
  hasSuffix?: boolean
}) => {
  const [prefix, setPrefix] = React.useState({
    oldPrefix: invoiceAffix?.oldPrefix,
    newPrefix: invoiceAffix?.newPrefix,
  })
  const [suffix, setSuffix] = React.useState({
    oldSuffix: invoiceAffix?.oldSuffix,
    newSuffix: invoiceAffix?.newSuffix,
  })

  React.useEffect(() => {
    setPrefix({
      oldPrefix: invoiceAffix?.oldPrefix,
      newPrefix: invoiceAffix?.newPrefix,
    })

    setSuffix({
      oldSuffix: invoiceAffix?.oldSuffix,
      newSuffix: invoiceAffix?.newSuffix,
    })
  }, [invoiceAffix])

  const onChange = (type: 'prefix' | 'suffix', value: string) => {
    if (type === 'prefix') {
      setPrefix(prevValue => ({
        ...prevValue,
        newPrefix: value,
      }))
      return
    }

    setSuffix(prevValue => ({
      ...prevValue,
      newSuffix: value,
    }))
  }

  const iconStyle = { fontSize: 18, color: 'var(--primary)' }

  return (
    <Modal
      open={visible}
      width={500}
      centered
      onCancel={onCancel}
      title={null}
      footer={
        <span className="flex gap-4 mt-8 justify-end">
          <Button key="later" onClick={onCancel}>
            Later
          </Button>

          <Button
            key="save"
            type="primary"
            onClick={() =>
              onSave({
                usedAffixes: [
                  {
                    ...invoiceAffix,
                    newPrefix: prefix.newPrefix,
                    newSuffix: suffix.newSuffix,
                  },
                ],
                nonUsedAffixes: [],
              })
            }
          >
            Save Prefixes
          </Button>
        </span>
      }
      closeIcon={<span className="text-xl">&times;</span>}
    >
      <div>
        {/* Header */}
        <div className="flex items-center mb-4">
          <i
            className="fa-regular fa-calendar"
            style={{ ...iconStyle, fontSize: 22, marginRight: 12 }}
          />
          <Title level={4} style={{ margin: 0 }} className="text-primary">
            New Financial Year Approaching
          </Title>
        </div>

        {/* Description */}
        <Paragraph style={{ marginBottom: 24 }}>
          Set up your document prefixes for the new financial year. Remember, serial numbers must be
          unique across financial years.
        </Paragraph>

        {/* Prefix Section */}
        <Row gutter={16} className="mb-2">
          <Col span={10}>
            <div>
              <div className="flex items-center mb-1">
                <i className="fa-regular fa-file-lines mr-2" style={iconStyle} />
                <Title level={5} style={{ margin: 0 }}>
                  Old Invoice Prefix
                </Title>
              </div>
              <div className="mb-2 ml-6">
                <Text type="secondary">Until March 31st</Text>
              </div>
            </div>
          </Col>
          <Col span={4} className="flex justify-center items-center">
            <i
              className="fa-solid fa-arrow-right"
              style={{ color: 'var(--ant-text-color-secondary)' }}
            />
          </Col>
          <Col span={10}>
            <div>
              <div className="flex items-center mb-1">
                <i className="fa-regular fa-file-lines mr-2" style={iconStyle} />
                <Title level={5} style={{ margin: 0 }}>
                  New Invoice Prefix
                </Title>
              </div>
              <div className="mb-2 ml-6">
                <Text type="secondary">From April 1st</Text>
              </div>
            </div>
          </Col>
        </Row>

        {/* Prefix Values */}
        <Row gutter={16} className="mb-4">
          <Col span={10}>
            <div className="ml-6">
              <Text style={{ fontSize: 16 }}>{prefix.oldPrefix}</Text>
            </div>
          </Col>
          <Col span={4} />
          <Col span={10}>
            <Input
              placeholder="e.g., INV-24"
              size="middle"
              value={prefix.newPrefix}
              onChange={e => onChange('prefix', e.target.value)}
              style={{ fontSize: 16 }}
            />
          </Col>
        </Row>

        {/* Suffix Section (Conditional) */}
        {hasSuffix && (
          <>
            <div className="mt-4 mb-4">
              <Divider />
            </div>

            <Row gutter={16} className="mb-2">
              <Col span={10}>
                <div>
                  <div className="flex items-center mb-1">
                    <i className="fa-regular fa-file-lines mr-2" style={iconStyle} />
                    <Title level={5} style={{ margin: 0 }}>
                      Old Invoice Suffix
                    </Title>
                  </div>
                  <div className="mb-2 ml-6">
                    <Text type="secondary">Until March 31st</Text>
                  </div>
                </div>
              </Col>
              <Col span={4} className="flex justify-center items-center">
                <i
                  className="fa-solid fa-arrow-right"
                  style={{ color: 'var(--ant-text-color-secondary)' }}
                />
              </Col>
              <Col span={10}>
                <div>
                  <div className="flex items-center mb-1">
                    <i className="fa-regular fa-file-lines mr-2" style={iconStyle} />
                    <Title level={5} style={{ margin: 0 }}>
                      New Invoice Suffix
                    </Title>
                  </div>
                  <div className="mb-2 ml-6">
                    <Text type="secondary">From April 1st</Text>
                  </div>
                </div>
              </Col>
            </Row>

            <Row gutter={16} className="mb-4">
              <Col span={10}>
                <div className="ml-6">
                  <Text style={{ fontSize: 16 }}>{suffix.oldSuffix}</Text>
                </div>
              </Col>
              <Col span={4} />
              <Col span={10}>
                <Input
                  placeholder="e.g., FY-25"
                  size="middle"
                  value={suffix.newSuffix}
                  onChange={e => onChange('suffix', e.target.value)}
                  style={{ fontSize: 16 }}
                />
              </Col>
            </Row>
          </>
        )}
      </div>
    </Modal>
  )
}

export default AffixChangeModal
