import { Text, View } from '@react-pdf/renderer'
import { HIDE_PRICES } from '../utils'
import { styles as getStyles } from './styles'

const borderColor = '#142328'

const InvoiceTableHeader = ({
  invoice,
  type,
  show_free_qty,
  show_discount_col,
  no_of_default_cols,
  no_of_dc_cols,
  no_of_with_out_tax_cols,
}) => {
  var styles = getStyles(invoice)
  var FONT_FACTOR = invoice.invoice_settings.font_size

  var single_col = (1 * 100) / (no_of_default_cols + invoice.custom_col_names.length)
  var single_col2 = (1 * 100) / (no_of_with_out_tax_cols + invoice.custom_col_names.length)
  var dc_col = (1 * 100) / (no_of_dc_cols + invoice.custom_col_names.length)
  var font_size = (FONT_FACTOR * (8 * 8.1)) / (8 + invoice.custom_col_names.length)
  const column_labels = invoice.column_labels

  const rows = (
    <>
      {(type == 'Delivery Challan' || type == HIDE_PRICES) && (
        <>
          <View
            style={{
              ...styles.invoiceTableHeader_container,
              borderBottomColor: invoice.company.color,
            }}
          >
            <Text
              style={{
                ...styles.invoiceTableHeader_cell,
                textAlign: 'left',
                paddingLeft: 4.5,
                width: `${0.2 * dc_col}%`,
              }}
            >
              {column_labels.id}
            </Text>
            <Text
              style={{
                ...styles.invoiceTableHeader_cell,
                textAlign: 'left',
                width: `${1.8 * dc_col}%`,
              }}
            >
              {column_labels.product_name}
            </Text>
            {invoice.custom_col_names.map((item, index) => (
              <Text key={index} style={{ width: `${dc_col}%` }}>
                {item}
              </Text>
            ))}
            {invoice.invoice_settings.hide_qty == 0 && (
              <Text
                style={{
                  ...styles.invoiceTableHeader_cell,
                  textAlign: 'right',
                  paddingRight: 4.5,
                  width: `${dc_col}%`,
                }}
              >
                {column_labels.qty}
              </Text>
            )}
          </View>
        </>
      )}

      {type != 'Delivery Challan' && type != HIDE_PRICES && (
        <>
          {(invoice.with_tax == 0 || invoice.company.gstin.length < 15) && (
            <View
              style={{
                ...styles.invoiceTableHeader_container,
                fontSize: font_size,
                borderBottomColor: invoice.company.color,
              }}
            >
              <Text
                style={{
                  ...styles.invoiceTableHeader_cell,
                  textAlign: 'left',
                  paddingLeft: 4.5,
                  width: `${0.2 * single_col2}%`,
                }}
              >
                {column_labels.id}
              </Text>
              <Text style={{ textAlign: 'left', width: `${1.8 * single_col2}%` }}>
                {column_labels.product_name}
              </Text>
              {invoice.custom_col_names.map((item, index) => (
                <Text key={index} style={{ width: `${single_col2}%` }}>
                  {item}
                </Text>
              ))}
              <Text style={{ ...styles.invoiceTableHeader_cell, width: `${single_col2}%` }}>
                {column_labels.unit_price}
              </Text>
              {show_discount_col && (
                <Text style={{ ...styles.invoiceTableHeader_cell, width: `${single_col2}%` }}>
                  {'Disc (%)'}
                </Text>
              )}
              {invoice.invoice_settings.hide_qty == 0 && (
                <Text style={{ ...styles.invoiceTableHeader_cell, width: `${single_col2}%` }}>
                  {column_labels.qty}
                </Text>
              )}
              {show_free_qty && (
                <Text style={{ ...styles.invoiceTableHeader_cell, width: `${single_col2}%` }}>
                  {column_labels.free_qty}
                </Text>
              )}
              <Text
                style={{
                  ...styles.invoiceTableHeader_cell,
                  paddingRight: 4.5,
                  width: `${single_col2}%`,
                }}
              >
                {column_labels.total_amount}
              </Text>
            </View>
          )}

          {invoice.with_tax == 1 && invoice.company.gstin.length == 15 && (
            <View
              style={{
                ...styles.invoiceTableHeader_container,
                fontSize: font_size,
                borderBottomColor: invoice.company.color,
              }}
            >
              <Text
                style={{
                  ...styles.invoiceTableHeader_cell,
                  textAlign: 'left',
                  paddingLeft: 4.5,
                  width: `${0.4 * single_col}%`,
                }}
              >
                {column_labels.id}
              </Text>
              <Text style={{ textAlign: 'left', width: `${2.4 * single_col}%` }}>
                {column_labels.product_name}
              </Text>
              {invoice.custom_col_names.map((item, index) => (
                <Text key={index} style={{ width: `${single_col}%` }}>
                  {item}
                </Text>
              ))}
              <Text style={{ ...styles.invoiceTableHeader_cell, width: `${1.1 * single_col}%` }}>
                {column_labels.unit_price}
              </Text>
              {show_discount_col && (
                <Text style={{ ...styles.invoiceTableHeader_cell, width: `${single_col}%` }}>
                  {'Disc (%)'}
                </Text>
              )}
              {invoice.invoice_settings.hide_qty == 0 && (
                <Text style={{ ...styles.invoiceTableHeader_cell, width: `${single_col}%` }}>
                  {column_labels.qty}
                </Text>
              )}
              {show_free_qty && (
                <Text
                  style={{
                    ...styles.invoiceTableHeader_cell,
                    width: `${single_col}%`,
                    paddingRight: 8,
                  }}
                >
                  {column_labels.free_qty}
                </Text>
              )}
              <Text style={{ ...styles.invoiceTableHeader_cell, width: `${single_col}%` }}>
                {column_labels.net_amount}
              </Text>
              <Text style={{ ...styles.invoiceTableHeader_cell, width: `${1.1 * single_col}%` }}>
                {column_labels.tax_amount}
              </Text>
              <Text
                style={{
                  paddingRight: 4.5,
                  width: `${single_col}%`,
                }}
              >
                {column_labels.total_amount}
              </Text>
            </View>
          )}
        </>
      )}
    </>
  )
  return <View fixed>{rows}</View>
}

export default InvoiceTableHeader
