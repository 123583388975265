import { PlusOutlined } from '@ant-design/icons'
import { Button, Drawer, Empty, Input, Modal, Popconfirm, Select, Table, Tabs, message } from 'antd'
import BetaBadge from 'components/badges/beta'
import BatchingForm from 'components/forms/batchingForm'
import { EnhancedSubscriptionModal } from 'components/modal/subscription'
import SubscriptionPlanModal from 'components/modal/subscription/subscriptionPlan'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import TimelineTransactions from 'pages/inventory/Timeline/transactions'
import React, { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux'
import { get_batch_settings } from 'redux/document/actions'
import { getAPI } from 'services/jwt'
import store from 'store'
import { getExpiryDays } from './utility'
dayjs.extend(utc)

const { TabPane } = Tabs

class Batches extends Component {
  formRef = React.createRef()
  dateFormat = 'DD-MM-YYYY'
  dateTimeFormat = 'YYYY-MM-DD hh:mm a'
  subscriptionPlanRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      loading: false,
      activeKey: '1',
      defaultActiveKey: '1',
      batches: [],
      is_serial: false,
      product_id: -1,
      product_details: {},
      variant_id: 0,
      batchSettings: this.props?.batch_settings,
      serial_number_label: this.props?.batch_settings?.serial_number_label,
      show_timeline: false,
      batchSearch: '',
      defaultWarehouse: this.props.warehouses ? this.props.warehouses[0]?.warehouse_id : undefined,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.batch_settings !== this.props.batch_settings) {
      this.setState({
        batchSettings: this.props.batch_settings,
        serial_number_label: this.props.batch_settings.serial_number_label,
      })
    }
  }

  async componentDidMount() {
    this.props.onRef(this)
    if (this.props.batch_settings == undefined || this.props.batch_settings == {}) {
      this.props?.dispatch(get_batch_settings())
    }
  }

  onShow = item => {
    this.setState(
      {
        visible: true,
        is_serial: item.has_batches == 2,
        product_id: item.product_id,
        product_details: item,
        variant_id: item.variant_id,
        selectedWarehouse: item.warehouse_id || undefined,
        has_batches: item.has_batches,
      },
      () => this.callData(),
    )
  }

  callData = async (tableProps = {}) => {
    this.setState({ loading: true })
    var data = await getAPI('product', 'batches', {
      product_id: this.state.product_id,
      variant_id: this.state.variant_id,
      warehouse_id: this.state.selectedWarehouse || -1,
      has_batches: this.state.has_batches,
      sortField: tableProps.sortField,
      sortOrder: tableProps.sortOrder,
    })
    if (data && data.success) {
      this.setState({
        batches: data.batches,
        loading: false,
      })
    } else {
      this.setState({ loading: false })
    }
  }
  componentWillUnmount() {
    this.props.onRef(null)
  }

  handleDeleteBatch = async (batch, is_delete) => {
    this.setState({ loading: true })
    const req = {
      product_id: this.state.product_details.product_id,
      variant_id:
        this.state.product_details.variant_id || this.state.product_details.new_variant_id || 0,
      batch_id: batch.batch_id,
      is_delete: is_delete,
    }
    const data = await getAPI('product', 'remove_batch', req)
    if (data && data.success) {
      message.success(data.message)
      this.callData()
    }
    this.setState({
      loading: false,
    })
  }

  editBatch(batch) {
    this.batching_form.onEdit(batch, this.state.product_details)
  }

  async onViewTimeline(batch) {
    this.setState(
      {
        show_timeline: true,
      },
      () => {
        this.timeline.onViewTimeline(this.state.product_details, batch)
      },
    )
  }

  handleWarehouseChange = value => {
    this.setState({ selectedWarehouse: value }, this.callData)
  }

  handleTableChange = (pagination, filters, sorter) => {
    const sortField = sorter?.field || undefined // Column to sort by
    const sortOrder =
      sorter?.order === 'ascend' ? 'ASC' : sorter?.order === 'descend' ? 'DESC' : undefined

    const tableProps = {
      sortField,
      sortOrder,
    }
    this.callData(tableProps)
  }

  render() {
    const Batchcolumns = [
      {
        title: 'Batch No',
        dataIndex: 'batch_no',
        key: 'batch_no',
        render: (text, data) => {
          return (
            <span>
              {data.batch_no}
              <p className="text-gray-500 font-weight-bold">{getExpiryDays(data.expiry_date)}</p>
            </span>
          )
        },
      },

      {
        title: 'Manufacturing Date',
        dataIndex: 'mfg_date',
        key: 'mfg_date',
        sorter: true,
      },
      {
        title: 'Expiry Date',
        dataIndex: 'expiry_date',
        key: 'expiry_date',
        sorter: true,
      },
      {
        title: 'Quantity',
        dataIndex: 'qty',
        key: 'qty',
        sorter: true,
      },
      {
        title: 'Selling Price',
        dataIndex: 'price_with_tax',
        key: 'price_with_tax',
        sorter: true,
      },
      {
        title: 'Purchase Price',
        dataIndex: 'purchase_price',
        key: 'purchase_price',
        sorter: true,
      },
      {
        title: 'Actions',
        dataIndex: 'action',
        key: 'action',
        render: (_, data) => (
          <>
            <Button
              type="view-button"
              size="small"
              className="mr-2"
              onClick={e => {
                e.stopPropagation()
                this.onViewTimeline(data)
              }}
            >
              <i className="fa-regular fa-eye fa-lg mr-2 " />
              Timeline
            </Button>
            <Button
              type="yellow-2"
              size="small"
              className="mr-2"
              onClick={e => {
                e.stopPropagation()
                this.editBatch(data)
              }}
            >
              <i className="fa fa-edit mr-2" /> Edit
            </Button>

            <Popconfirm
              title="Sure to delete? Cannot undo once deleted."
              onConfirm={e => {
                e.stopPropagation()
                this.handleDeleteBatch(data, 1)
              }}
              onCancel={e => {
                e.stopPropagation()
              }}
              placement="left"
            >
              <Button
                type="danger"
                size="small"
                onClick={e => {
                  e.stopPropagation()
                }}
              >
                <i className="fa fa-trash" />
              </Button>
            </Popconfirm>
          </>
        ),
      },
    ]
    const { batches } = this.state

    const SerialNumberColumns = [
      {
        title: this.state.serial_number_label,
        dataIndex: 'batch_no',
        key: 'batch_no',
      },

      {
        title: 'Actions',
        dataIndex: 'action',
        key: 'action',
        render: (_, data) => (
          <>
            {data.qty != 0 ? (
              <>
                <Button
                  type="yellow-2"
                  size="small"
                  className="mr-2"
                  onClick={e => {
                    e.stopPropagation()
                    this.editBatch(data)
                  }}
                >
                  <i className="fa fa-edit mr-2" /> Edit
                </Button>
                <Popconfirm
                  title="Are you sure you want to do stock out? This action is irreversible"
                  cancelText="No"
                  okText="Yes"
                  onConfirm={() => this.handleDeleteBatch(data, 0)}
                  onCancel={e => e.stopPropagation()}
                >
                  <Button type="" size="small" className="mr-2 text-danger">
                    <i className="fa-regular fa-circle-arrow-up fa-lg text-danger mr-2 " /> Stock
                    Out
                  </Button>
                </Popconfirm>
              </>
            ) : (
              <>
                <span className="mr-2 text-gray-500 font-size-15">Sold Out</span>
                <Button
                  type=""
                  size="small"
                  className="mr-2 text-primary"
                  onClick={e => {
                    e.stopPropagation()
                    this.onViewTimeline(data)
                  }}
                >
                  <i className="fa-regular fa-eye fa-lg text-primary mr-2 " /> View Timeline
                </Button>
              </>
            )}

            <Popconfirm
              title="Sure to do delete? This action cannot be reversed."
              onConfirm={e => {
                e.stopPropagation()
                this.handleDeleteBatch(data, 1)
              }}
              onCancel={e => {
                e.stopPropagation()
              }}
            >
              <Button type="danger" size="small" onClick={e => e.stopPropagation()}>
                <i className="fa fa-trash mr-2" /> Delete
              </Button>
            </Popconfirm>
          </>
        ),
      },
    ]
    const warehouseOptions = this.props.warehouses?.map(warehouse => (
      <Option key={warehouse.warehouse_id} value={warehouse.warehouse_id}>
        {warehouse.name}
      </Option>
    ))

    return (
      <div>
        <Drawer
          title={
            <>
              <div className="flex items-center">
                <div className="mr-auto">
                  {!this.state.is_serial ? (
                    <span className="font-weight-bolder text-gray-700 font-size-21 mr-2">
                      {this.state.product_details?.product_name + ' Batches'} <BetaBadge />
                    </span>
                  ) : (
                    <span className="font-weight-bolder text-gray-700 font-size-21 mr-2">
                      {this.state.product_details?.product_name}
                      <BetaBadge />
                    </span>
                  )}
                </div>
                <div>
                  <Button
                    type="primary"
                    onClick={() =>
                      store.get('paid') == 0
                        ? this.subscriptionPlanRef.current.openModal('batches')
                        : this.batching_form.onAdd(this.state.product_details)
                    }
                    icon={<PlusOutlined className="font-weight-bold" />}
                  >
                    <span className="font-weight-bold">Add New</span>
                  </Button>
                </div>
              </div>
            </>
          }
          placement="right"
          width={isMobile ? '90%' : '50%'}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          closable={() => this.setState({ visible: false, batches: [] })}
          onClose={() =>
            this.setState({ visible: false, batches: [] }, () => {
              if (this.props?.onAPICalled) {
                this.props.onAPICalled()
              }
            })
          }
          open={this.state.visible}
          maskClosable={false}
          destroyOnClose={false}
          footer={
            <>
              <Button
                onClick={() => this.setState({ visible: false }, () => this.props.onAPICalled())}
                type="danger"
              >
                Close
              </Button>
            </>
          }
        >
          <Tabs defaultActiveKey={this.state.defaultActiveKey} key={this.state.defaultActiveKey}>
            <TabPane
              tab={
                <span className="mt-4">
                  <i className="fa fa-boxes-stacked mr-2" />
                  {!this.state.is_serial ? 'Batches' : this.state.serial_number_label}
                </span>
              }
              key="1"
            >
              <div className="flex justify-center items-center gap-2 mb-3 w-full">
                <Input
                  placeholder={'Search '}
                  value={this.state.batchSearch}
                  onChange={e => this.setState({ batchSearch: e.target.value })}
                  className={`${this.props?.warehouses?.length > 0 ? 'w-3/5' : 'w-full'}`}
                />
                {this.props.warehouses?.length > 0 ? (
                  <Select
                    placeholder="Select Warehouse"
                    onChange={this.handleWarehouseChange}
                    value={this.state.selectedWarehouse}
                    className="w-2/5"
                  >
                    {warehouseOptions}
                  </Select>
                ) : null}
              </div>
              <Table
                columns={this.state.is_serial ? SerialNumberColumns : Batchcolumns}
                dataSource={_.cloneDeep(batches).filter(item =>
                  item.batch_no.toLowerCase().includes(this.state.batchSearch.toLowerCase()),
                )}
                pagination={{
                  pageSize: 10,
                }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: event => {
                      if (this.state.is_serial) {
                        event.stopPropagation()
                        return
                      }
                      event.stopPropagation()
                      this.editBatch(record)
                    },
                  }
                }}
                style={{ cursor: 'pointer' }}
                size="small"
                onChange={this.handleTableChange}
                locale={{
                  emptyText: (
                    <div className="flex justify-center items-center w-full h-full">
                      <Empty
                        className="m-4"
                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        // image="../../../welcome3.png"
                        imageStyle={{
                          height: 256,
                        }}
                        description={
                          <Button
                            type="primary"
                            onClick={() =>
                              store.get('paid') == 0
                                ? this.subscriptionPlanRef.current.openModal('batches')
                                : this.batching_form.onAdd(this.state.product_details)
                            }
                            icon={<PlusOutlined className="font-weight-bold" />}
                          >
                            <span className="font-weight-bold">
                              Add {!this.state.is_serial ? 'Batch' : this.state.serial_number_label}
                            </span>
                          </Button>
                        }
                      ></Empty>
                    </div>
                  ),
                }}
                loading={this.state.loading}
              />
            </TabPane>
          </Tabs>

          <Modal
            title={'Inventory Timeline'}
            open={this.state.show_timeline}
            width={'90%'}
            onCancel={() => {
              this.timeline.onCloseTimeline()
              this.setState({
                show_timeline: false,
              })
            }}
            footer={null}
            bodyStyle={{
              overflow: 'auto',
              maxHeight: '800px',
            }}
          >
            <div className="p-3 bg-gray-50">
              <TimelineTransactions onRef={ref => (this.timeline = ref)} fetchOnLoad={true} />
            </div>
          </Modal>
          <EnhancedSubscriptionModal onRef={ref => (this.subscription = ref)} />
          <SubscriptionPlanModal ref={this.subscriptionPlanRef} />
          <BatchingForm
            onRef={ref => (this.batching_form = ref)}
            onAPICalled={this.callData}
            selectedWarehouse={this.state.selectedWarehouse}
          />
          {/* <Drawer
            title={
              <>
                <div className="flex items-center">
                  <div className="mr-auto">Batch Settings</div>
                </div>
              </>
            }
            placement="right"
            width={isMobile ? '90%' : '50%'}
            closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
            closable={() => this.setState({ show_batch_settings: false })}
            onClose={() => this.setState({ show_batch_settings: false })}
            open={this.state.show_batch_settings}
            maskClosable={false}
            destroyOnClose={false}
            footer={
              <>
                <Button onClick={() => this.setState({ show_batch_settings: false })} type="danger">
                  Close
                </Button>
              </>
            }
          >
            <BatchSettingForm />
          </Drawer> */}
        </Drawer>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    companyDetailsAdded: state.welcome.company_details_added,
    bankDetailsAdded: state.welcome.bank_details_added,
    productsAdded: state.welcome.products_added,
    batch_settings: state.document.batch_settings,
    warehouses: state.warehouse.warehouses,
  }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(Batches)
