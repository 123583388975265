import { useRef } from 'react'
import { Select, Button } from 'antd'
import AddressForm from 'components/forms/addressForm'
import { Address } from 'redux/types'
import { formatAddress, renderAddress } from 'pages/doc/create/utils'

type SelectAddressProps = {
  address: Address[]
  selectedAddress: Address
  addressType: string
  invoiceDetails: any
  onChangeAddress: (value: number) => void
  onFinish: () => void
  disabled: boolean
}

const SelectAddress = (props: SelectAddressProps) => {
  const addressFormRef = useRef<{
    showAddressDrawer: (type: string, address: any, partyDetails: any) => void
  } | null>(null)

  const value = props.selectedAddress ? props.selectedAddress?.addr_id : undefined

  return (
    <>
      <Select
        suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
        placeholder={'Select address'}
        optionFilterProp="children"
        style={{ minWidth: '100%', maxWidth: 400 }}
        listHeight={200}
        dropdownStyle={{ minWidth: '450px', maxWidth: '450px' }}
        value={value}
        filterOption={(input: any, option: any) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        labelRender={() => {
          const addresses = props.address
          const addr_id = value

          const selectedAddress = addresses.find((address: any) => address.addr_id === addr_id)

          if (selectedAddress) {
            return formatAddress(selectedAddress).join(', ')
          } else {
            return null
          }
        }}
        onChange={e => props.onChangeAddress(e)}
        disabled={props.disabled}
        dropdownRender={menu => (
          <>
            {menu}
            <div className="bg-gray-50">
              <Button
                block
                type="link-2"
                className="font-weight-bolder text-left my-2 arrow-transition"
                onClick={e => {
                  e.stopPropagation()
                  addressFormRef?.current?.showAddressDrawer(
                    props.addressType,
                    {
                      addr_id: -1,
                    },
                    {
                      party_id: props.invoiceDetails.party_id,
                      party_type: props.invoiceDetails.party_type,
                      is_party_edit: true,
                    },
                  )
                }}
              >
                <i className="fa-solid fa-circle-plus mr-2"></i>
                Add Customer Shipping address
                <i className="fa-solid fa-arrow-right ml-2 arrow-transition"></i>
              </Button>
            </div>
          </>
        )}
      >
        {props.address?.length > 0 && (
          <>
            {props.address &&
              props.address.map(
                (item: any, i: number) =>
                  item &&
                  item.is_delete == 0 && (
                    <Select.Option key={i} value={item.addr_id} className="relative">
                      {renderAddress(item, value)}
                      {item.addr_id === value ? (
                        <button
                          className="absolute right-2 bottom-2 cursor-pointer bg-white border border-gray-200 px-3 py-1 rounded-lg font-semibold  hover:bg-blue-50 hover:border-blue-400 hover:text-blue-500"
                          onClick={e => {
                            e.stopPropagation()
                            addressFormRef?.current?.showAddressDrawer(props.addressType, item, {
                              party_id: props.invoiceDetails.party_id,
                              party_type: props.invoiceDetails.party_type,
                              is_party_edit: true,
                            })
                          }}
                        >
                          Edit
                        </button>
                      ) : null}
                    </Select.Option>
                  ),
              )}
          </>
        )}
      </Select>
      <AddressForm ref={addressFormRef} onFinish={props.onFinish} />
    </>
  )
}

export default SelectAddress
