// eslint-disable-next-line no-unused-vars
import { Affix, Button, Card, Divider, Layout } from 'antd'
import queryString from 'query-string'
import React, { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Link, useParams, withRouter } from 'react-router-dom'
import { get_product_options } from 'redux/document/actions'
import { setIntegration, setPermissions } from 'redux/permissions/actions'
import { get_warehouses } from 'redux/warehouses/actions'
import { currentAccount, getAPI } from 'services/jwt'
import { DOCUMENT_SHORTCUTS } from 'utils/constants'

// import { motion } from 'framer-motion'

const ReportLayout = ({ children }) => {
  const [is_paid, setIsPaid] = React.useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    load_data()
  }, [])

  const load_data = async () => {
    const statusData = await currentAccount(false)
    if (!statusData) {
      return
    }
    const permissionData = {
      ...statusData.roles[0],
      ...statusData.integrations,
      ...statusData.user_details,
      id: statusData.roles[0].id,
      campaigns: statusData.roles[0].campaigns,
      trial_days: statusData.trial_days,
      is_pos: statusData.is_pos,
      additional_cess: statusData.company.additional_cess,
      bom: statusData.company.bom,
      reminders: statusData.company.reminders,
      payment_gateway_authorized:
        statusData.payment_gateway_settings?.payment_gateway_authorize == 1,
      role: statusData.role,
      dataLoaded: true,
    }
    dispatch(setPermissions(permissionData))
    dispatch(get_warehouses())
    dispatch(setIntegration(statusData.integrations))
    dispatch({
      type: 'user/SET_STATE',
      payload: {
        ...statusData.user_details,
      },
    })
    dispatch(get_product_options())
  }

  return (
    <Layout className="bg-gray-50 bg-cover bg-center">
      <Layout.Content className="mt-0 mb-4">
        <div className="cui__utils__content">{children}</div>
      </Layout.Content>
    </Layout>
  )
}

export default withRouter(ReportLayout)
