import React, { useEffect, useState } from 'react'
import {
  Divider,
  message,
  Table,
  Tooltip,
  Button,
  Space,
  Tag,
  Popconfirm,
  Menu,
  Dropdown,
} from 'antd'
import {
  EyeOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { getAPI, getAPIData } from 'services/jwt'
import CopyText from 'components/other/CopyText'
import UpdatedDaysAgoLabel from 'components/other/updatedDaysAgoLabel'
import dayjs from 'dayjs'
import ExpiringIn from 'components/tags/ExpiringIn'

const ShareHistory = () => {
  const [shareHistory, setShareHistory] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const [showPins, setShowPins] = useState<boolean[]>([])

  const fetchShareHistory = async () => {
    setIsLoading(true)
    const data = await getAPIData('v2/reports', 'public_reports_history')
    if (data?.success) {
      setShareHistory(data.data)
      setShowPins(data.data.map(() => false)) // Initialize pin visibility to false for all rows
    } else {
      message.error('Failed to fetch share history')
    }
    setIsLoading(false)
  }

  const handleDelete = async (id: number) => {
    if (id > 0) {
      setIsLoading(true)
      const payload = {
        row_id: id,
        is_delete: 1,
      }

      const data = await getAPI(`v2/reports`, 'edit_public_report', payload)
      if (data?.success) {
        message.success('Report deleted successfully')
        fetchShareHistory()
      } else {
        message.error('Something went wrong')
      }

      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchShareHistory()
  }, [])

  const menu = (record: any) => {
    return (
      <Menu>
        <Menu.Item
          key="copy"
          onClick={() => {
            navigator.clipboard.writeText(record.link)
            message.success('Link copied')
          }}
        >
          <i className="fa-regular fa-copy" /> <span className="ml-2">Copy Link</span>
        </Menu.Item>
        <Menu.Item key="open" onClick={() => window.open(record.link)}>
          <i className="fa-solid fa-arrow-up-right-from-square" />{' '}
          <span className="ml-2">Open Link</span>
        </Menu.Item>
        <Menu.Item key="delete" onClick={() => handleDelete(record.id)}>
          <i className="text-red-700 fa-solid fa-trash fa-fw" />{' '}
          <span className="ml-2">Delete</span>
        </Menu.Item>
      </Menu>
    )
  }

  const columns = [
    {
      title: 'Report',
      dataIndex: 'report',
      key: 'report',
      render: (text: string, record: any) => {
        let [startDate, endDate] = record?.date_range
          ?.split(' - ')
          ?.map((date: string) => dayjs(date, 'DD-MM-YYYY').format('DD MMM YY'))
        if (record.date_range == 'NA') startDate = ''

        return (
          <div className="flex flex-col items-start justify-start">
            <div className="font-semibold">{text}</div>
            {startDate && endDate ? (
              <div className="text-gray-500 text-sm">{`${startDate} - ${endDate}`}</div>
            ) : startDate ? (
              <div className="text-gray-500 text-sm">{`${startDate}`}</div>
            ) : null}
          </div>
        )
      },
    },
    {
      title: 'Pin',
      dataIndex: 'pin',
      key: 'pin',
      width: 90,
      render: (pin: string, _: any, index: number) => (
        <div className="flex items-center justify-start gap-2">
          <span className="w-12 mt-1.5">{showPins[index] ? <CopyText text={pin} /> : '****'}</span>
          <Tooltip title={showPins[index] ? 'Hide pin' : 'View pin'}>
            <EyeOutlined
              onClick={() => {
                const updatedShowPins = [...showPins]
                updatedShowPins[index] = !updatedShowPins[index]
                setShowPins(updatedShowPins)
              }}
              style={{ cursor: 'pointer', marginRight: 8 }}
            />
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 120,
      render: (status: string) => (
        <div className="text-xs">
          {status === 'active' && (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Active
            </Tag>
          )}
          {status === 'expired' && (
            <Tag icon={<CloseCircleOutlined />} color="error">
              Expired
            </Tag>
          )}
          {status === 'expires_soon' && (
            <Tag icon={<ExclamationCircleOutlined />} color="warning">
              Expiring Soon
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'created_time',
      key: 'created_time',
      render: (text: string) => (
        <div className="flex flex-col justify-start items-start">
          <div className="text-black">{dayjs(text).format('DD MMM YYYY')}</div>
          <div className="text-gray-500 text-sm">
            <UpdatedDaysAgoLabel date={text} />
          </div>
        </div>
      ),
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expire_date',
      key: 'expire_date',
      render: (expiryDate: string) => (
        <div className="flex flex-col justify-start items-start">
          <div className="text-black">{dayjs(expiryDate).format('DD MMM YY, hh:mm A')}</div>
          {dayjs().startOf('day') <= dayjs(expiryDate).startOf('day') ? (
            <div className="text-gray-500 text-sm -ml-1.5">
              <ExpiringIn expiryDate={expiryDate} />
            </div>
          ) : null}
        </div>
      ),
    },
    {
      key: 'actions',
      render: (_: any, record: any) => {
        return (
          <Dropdown overlay={menu(record)} trigger={['click']} placement="bottomLeft">
            <Button type="default" size="small" className="dropdown-toggle dropdown-toggle-noarrow">
              <i className="fa-solid fa-ellipsis fa-xl"></i>
            </Button>
          </Dropdown>
        )
      },
    },
  ]

  return (
    <div>
      <div className="font-bold text-xl">
        Share History
        <Divider />
      </div>
      <Table
        rowKey="id"
        dataSource={shareHistory}
        columns={columns}
        loading={isLoading}
        pagination={{ pageSize: 10 }}
      />
    </div>
  )
}

export default ShareHistory
