import React, { useState, useRef, forwardRef, useImperativeHandle, createRef } from 'react'
import { Modal, Table, Button } from 'antd'
import { AlignType } from 'rc-table/lib/interface'

import { FormattedMessage } from 'react-intl'
import EnhancedDelete from 'components/modal/delete'
import InventoryForm from 'components/forms/inventoryForm'
import { formatNumber } from 'components/other/calculation'

interface Product {
  key: string
  product_id: number
  product_name: string
  variant_name?: string
  qty: number
  available_qty: number
  has_batches: number
  warehouse_id: number
  unit: string
  units: any[]
  conversion_rate: number
}

interface InsufficientItemsModalProps {
  visible: boolean
  onClose: () => void
  items: Product[]
  message?: string
  updateInsufficientModalItems: (data: { items: Product[] }) => void
  deleteInsufficientItem: (data: { key: string }) => void
}

export interface InsufficientItemsModalRef {
  refreshStock: () => void
}

const InsufficientItemsModal = forwardRef<InsufficientItemsModalRef, InsufficientItemsModalProps>(
  (
    { visible, onClose, items, message, updateInsufficientModalItems, deleteInsufficientItem },
    ref,
  ) => {
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(null)
    const formRef = useRef<any>(null)
    const deleteRef = useRef<any>(null)

    // Method to refresh stock after stock-in operation
    const refreshStock = async () => {
      if (!formRef.current || !selectedProduct) return

      const { quantity } = formRef.current.getFieldValues()
      const updatedItems = items
        .map(item =>
          item.product_id === selectedProduct.product_id
            ? { ...item, available_qty: item.available_qty + quantity }
            : item,
        )
        .filter(item => item.available_qty < item.qty)

      updateInsufficientModalItems({ items: updatedItems })

      if (updatedItems.length === 0) {
        onClose()
      }
    }

    useImperativeHandle(ref, () => ({
      refreshStock,
    }))

    const handleStockIn = (e: React.MouseEvent, product: Product) => {
      e.stopPropagation()
      setSelectedProduct(product)

      formRef.current?.onFill(
        {
          ...product,
          name: '',
          warehouse_id: items[0]?.warehouse_id,
        },
        true,
      )
    }

    const showDeleteModal = (product: Product) => {
      setSelectedProduct(product)
      deleteRef?.current?.showModal(product)
    }

    const columns = [
      {
        title: 'Product Name',
        dataIndex: 'product_name',
        key: 'product_name',
        render: (text: string, record: Product) => (
          <span>
            {record.product_name} {record.variant_name}
          </span>
        ),
      },
      {
        title: 'Billed Qty',
        dataIndex: 'qty',
        key: 'qty',
        render: (qty: number, record: Product) => {
          let primary_unit = record?.unit
          if (record?.units?.length > 0) {
            primary_unit = record?.units?.find((unit: any) => unit.primary_unit)?.primary_unit
          }

          const billed_qty = qty / (record?.conversion_rate ?? 1)
          return (
            <>
              <span className="font-semibold">{formatNumber(billed_qty, 0, 3)} </span>
              <span>{primary_unit}</span>
            </>
          )
        },
      },
      {
        title: 'Available Qty',
        dataIndex: 'available_qty',
        key: 'available_qty',
        render: (available_qty: number, record: Product) => {
          let primary_unit = record?.unit
          if (record?.units?.length > 0) {
            primary_unit = record?.units?.find((unit: any) => unit.primary_unit)?.primary_unit
          }

          return (
            <>
              <span className="font-semibold">{formatNumber(available_qty, 0, 3)}</span>{' '}
              <span>{primary_unit}</span>
            </>
          )
        },
      },
      {
        title: 'Actions',
        key: 'actions',
        align: 'right' as AlignType,
        render: (_: any, product: Product) => (
          <>
            <Button
              type="success"
              size="small"
              className="font-weight-bold mr-2"
              onClick={e => handleStockIn(e, product)}
            >
              <i className="fa-regular fa-circle-plus fa-lg mr-1" />
              <FormattedMessage id="button.stockIn" />
            </Button>
            <Button type="danger" size="small" onClick={() => showDeleteModal(product)}>
              <i className="fa-solid fa-trash-can" />
            </Button>
          </>
        ),
      },
    ]

    return (
      <>
        <Modal
          title={
            <>
              <i className="fad fa-info-circle mr-3 text-danger"></i>
              Insufficient Items
            </>
          }
          visible={visible}
          onCancel={onClose}
          footer={null}
          width={800}
        >
          {message}
          <Table dataSource={items} columns={columns} rowKey="key" />
        </Modal>

        {/* Inventory Form */}
        <InventoryForm ref={formRef} onAPICalled={refreshStock} identifier="insufficient_modal" />

        {/* Delete Confirmation Modal */}
        <EnhancedDelete
          ref={deleteRef}
          document_type="product"
          handleDelete={() =>
            selectedProduct && deleteInsufficientItem({ key: selectedProduct.key })
          }
        />
      </>
    )
  },
)

export default InsufficientItemsModal
