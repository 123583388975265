import { LoadingOutlined } from '@ant-design/icons'
import { Button, Card } from 'antd'
import { dotOptions } from 'components/other/utility'
import dayjs from 'dayjs'
import { history } from 'index'
import React from 'react'
import { connect, useSelector } from 'react-redux'
import { LettersPullUp } from 'components/animatedComponents/lettersPullUp'
import { get_range_name } from 'services/axios'
import store from 'store'
import { Skeleton } from 'antd'

function BusinessAnalytics() {
  const businessAnalytics = useSelector(state => state.document.businessAnalytics)
  let analytics = businessAnalytics?.analytics?.length > 0 ? businessAnalytics.analytics : []
  let analyticsLoading = useSelector(state => state.document.businessAnalyticsLoading)
  const user = useSelector(state => state.user)
  const company_details = useSelector(state => state.permissions.company_details)

  return store.get('paid') == 1 && analytics.length > 0 ? (
    //  && analytics.length > 0
    <Card size="analytics" className="border-radius-small mb-4 pb-0 h-24">
      <div className="flex justify-start">
        {// !analyticsLoading &&
        analytics?.map((item, index) => (
          <div>
            <div className="my-0 pr-5 mr-10">
              <div className="text-truncate">
                <div className="mr-2">
                  <span className="font-weight-bold font-size-15 text-gray-500 mr-2">
                    {item.title}
                  </span>

                  {item.diff_percent > 0 && (
                    <span
                      className={
                        item.diff_percent > 0
                          ? 'float-right text-forest'
                          : 'float-right text-danger'
                      }
                    >
                      <span className="font-size-14 font-weight-bold">
                        {item.diff_percent > 0 ? (
                          <i className="fa-solid fa-arrow-trend-up"></i>
                        ) : (
                          <i className="fa-solid fa-arrow-trend-down"></i>
                        )}{' '}
                        {item.diff_percent.toLocaleString('en-IN', dotOptions)}%
                      </span>
                    </span>
                  )}
                </div>
              </div>
              <div className="flex items-end flex-wrap">
                <div className="">
                  {/* <span className="font-size-14 fa-sm mr-1"></span> */}
                  <LettersPullUp
                    className="font-size-18 font-weight-bolder leading-tight"
                    text={
                      user.selectedCompany?.currency_symbol +
                      ' ' +
                      item.current.toLocaleString('en-IN', dotOptions)
                    }
                    y={3}
                  />
                </div>
              </div>
              <div></div>
            </div>
          </div>
        ))}
        {/* {analyticsLoading && (
          <p className="w-full flex justify-center mt-1">
            <LoadingOutlined style={{ margin: 0 }} className="font-size-22 text-primary" spin />
          </p>
        )} */}

        {/* {!analyticsLoading && ( */}
        <div className="ml-auto align-self-center">
          {/* {analyticsLoading ? (
            <Skeleton.Button active={analyticsLoading} size="small" />
          ) : ( */}
          <Button
            type="link"
            size="default"
            onClick={event => history.push('/analytics')}
            className="font-weight-bold px-0 mx-0"
          >
            <i className="fa-solid fa-sm fa-up-right-from-square mr-2"></i>Insights
          </Button>
          {/* // )} */}
        </div>
        {/* )} */}
      </div>

      {/* {!analyticsLoading && ( */}
      <div className="">
        <span
          className="font-size-11 text-gray-400 mt-2 font-weight-medium"
          style={{ position: 'absolute', left: 20, bottom: 5 }}
        >
          Showing data for{' '}
          <strong>
            {get_range_name(
              store.get('transactions_default_dates_' + company_details.company_id),
            ) != ''
              ? get_range_name(
                  store.get('transactions_default_dates_' + company_details.company_id),
                )
              : dayjs(
                  store
                    .get('transactions_default_dates_' + company_details.company_id)
                    ?.split(' - ')[0],
                  'DD-MM-YYYY',
                ).format('DD MMM YYYY') +
                ' to ' +
                dayjs(
                  store
                    .get('transactions_default_dates_' + company_details.company_id)
                    ?.split(' - ')[1],
                  'DD-MM-YYYY',
                ).format('DD MMM YYYY')}
          </strong>
        </span>
      </div>
      {/* )} */}
    </Card>
  ) : store.get('paid') == 1 && analytics.length == 0 ? (
    <Card size="analytics" className="border-radius-small mb-4 pb-0 h-24">
      {analyticsLoading && (
        <div>
          <div className="flex justify-start mb-2 w-75">
            <div>
              <div className="mt-0 pr-5 mr-5">
                <div className="text-truncate">
                  <div className="mr-2">
                    <Skeleton.Button active={analyticsLoading} className="width-64 height-14" />
                  </div>
                </div>
                <div className="flex items-end flex-wrap">
                  <Skeleton.Input active={analyticsLoading} className="height-16" />
                </div>
              </div>
            </div>
            <div>
              <div className="my-0 pr-5 mr-5">
                <div className="text-truncate">
                  <div className="mr-2">
                    <Skeleton.Button active={analyticsLoading} className="width-64 height-14" />
                  </div>
                </div>
                <div className="flex items-end flex-wrap">
                  <Skeleton.Input active={analyticsLoading} className="height-16" />
                </div>
              </div>
            </div>
            <div>
              <div className="my-0 pr-5 mr-5">
                <div className="text-truncate">
                  <div className="mr-2">
                    <Skeleton.Button active={analyticsLoading} className="width-64 height-14" />
                  </div>
                </div>
                <div className="flex items-end flex-wrap">
                  <Skeleton.Input active={analyticsLoading} className="height-16" />
                </div>
              </div>
            </div>
            <div className="w-25 ml-auto flex align-self-center justify-end">
              <Skeleton.Button active={analyticsLoading} className="width-64 height-16" />
            </div>
          </div>

          <div className="mt-1">
            <Skeleton.Button
              active={analyticsLoading}
              className="width-64 height-10"
              style={{ position: 'absolute', left: 20, bottom: 8 }}
            />
          </div>
        </div>
      )}
    </Card>
  ) : (
    <></>
  )
}

export default BusinessAnalytics
