import React, { useRef, useEffect, useCallback } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import 'quill-mention'
import 'quill-mention/dist/quill.mention.css'

interface MentionDataItem {
  id: string
  value: string
}

interface EmailTemplateEditorProps {
  value: string
  onChange: (value: string) => void
  placeholder: string
  mentionData: any
  template: any
}

const EmailTemplateEditor: React.FC<EmailTemplateEditorProps> = ({
  value,
  onChange,
  placeholder,
  mentionData,
  template,
}) => {
  const quillRef = useRef<ReactQuill>(null)
  const handleChange: any = (content: string, delta: any, source: any, editor: any) => {
    onChange(content)
  }

  useEffect(() => {
    const quill = quillRef.current?.getEditor()
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(value)
    }
  }, [template])

  useEffect(() => {
    const quill = quillRef.current?.getEditor()
    const editorElement = quill?.root

    if (editorElement) {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (
          event.key === 'ArrowUp' ||
          event.key === 'ArrowDown' ||
          event.key === 'Tab' ||
          event.key === 'Enter'
        ) {
          return
        }
        event.stopPropagation()
      }

      editorElement.addEventListener('keydown', handleKeyDown, true)

      return () => {
        editorElement.removeEventListener('keydown', handleKeyDown, true)
      }
    }
  }, [])

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
    ],
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ['@'],
      mentionListClass:
        'ql-mention-list max-h-40 overflow-y-auto bg-white shadow-md rounded-md z-50',
      listItemClass: 'ql-mention-list-item',
      source: useCallback(
        (
          searchTerm: string,
          renderList: (matches: MentionDataItem[], searchTerm: string) => void,
          mentionChar: string,
        ) => {
          let values: MentionDataItem[] = []

          if (mentionChar === '@') {
            values = mentionData
          }

          if (values) {
            const matches = values.filter(item =>
              item.value.toLowerCase().includes(searchTerm.toLowerCase()),
            )
            renderList(matches, searchTerm)
          } else {
            renderList([], searchTerm)
          }
        },
        [],
      ),
    },
  }

  return (
    <>
      <style>
        {`
          .ql-container {
            border-radius: 0.5rem;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
          .ql-editor {
            border-radius: 0.5rem;
          }
          .ql-toolbar {
            border-radius: 0.5rem;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
          .ql-mention-list {
            max-height: 300px;
            overflow-y: auto;
          }
          .ql-mention-list-item {
            display: flex;
            align-items: center;
            padding: 0em 1em;
            cursor: pointer;
            font-size: 0.875rem;
            line-height: 1.5rem;
            min-height: 1.75rem;
          }
          .ql-mention-list-item.selected {
            display: flex;
            align-items: center;
            padding: 0em 1em;
            cursor: pointer;
            background-color: #e5e7eb;
            font-size: 0.875rem;
          }
        `}
      </style>
      <ReactQuill
        ref={quillRef}
        onChange={handleChange}
        modules={modules}
        placeholder={placeholder}
        className="h-40 w-full"
      />
    </>
  )
}

export default React.memo(EmailTemplateEditor)
