import React, { forwardRef, useEffect } from 'react'
import { Card, Col, FormInstance, Input, InputNumber, notification, Row, Select, Table } from 'antd'

import { Document_Redux, DocumentTypes, State } from 'redux/types'
import dayjs, { Dayjs } from 'dayjs'
import { getAPI } from 'services/jwt'

interface SelectedData {
  total_amount: number
  amount_pending: number
  amount_paid: number
  customer: {
    customer_id: number
    vendor_id: number
    name: string
    phone: string
    email: string
  }
  type: string
  serial_number: string
  payment_date: Dayjs
  notes: string
  pay: number
}
interface AdvancePayments {
  amount: number
  amount_left: number
  amount_remaining: number
  doc_count: number
  document_title: string
  document_type: DocumentTypes
  party_id: number
  payment_mode: string
  record_time: string
  serial_number: string
  setteled_documents: any[]
  total_amount_paid: number
  amount_settle: number
  disable: boolean
  key: number
  settled_serial_number: string
  settled_document_type: string
  settled_doc_count: number
}
interface Props {
  user: any
  selectedData: SelectedData
  typeFlag: boolean
  document_type: string
  payment_type: 'in' | 'out'
  onApicalled: () => void
  advance_payments: AdvancePayments[]
  updateAdvancePayments: (data: AdvancePayments[]) => void
  setLoading: (loading: boolean) => void
}

const AdvanceSettlement = forwardRef((props: Props, ref) => {
  const formRef = React.useRef<FormInstance>(null)
  const [state, setPrimaryState] = React.useState({
    net_amount: 0,
    total_amount: 0,
    amountErrorMessage: '',
    type: '',
    tds_selected_section: 0,
    attachments: [],
    paymentModeChanged: false,
    view_document_details: false,
  })

  const setState = (obj: any) => {
    setPrimaryState({ ...state, ...obj })
  }

  const onSubmit = () => {
    let values = formRef.current?.getFieldsValue()
    values = {
      ...values,
      attachments: state.attachments,
      type: state.type,
      bank_id: -1,
    }
    onFinish(values)
  }

  const onFinish = async (values: SelectedData) => {
    props.setLoading(true)
    const req = {
      advance_payments: props.advance_payments.map(item => {
        return {
          amount_settled: item.amount_settle,
          doc_count: item.doc_count ?? 0,
          serial_number: item.serial_number,
          document_type: item.document_type,
          settled_doc: {
            doc_count: item.settled_doc_count,
            serial_number: item.settled_serial_number,
            document_type: item.settled_document_type,
          },
        }
      }),
    }

    const data = await getAPI('v3/payments', 'settle_documents', req)

    props.setLoading(false)
    if (data) {
      notification.success({
        message: 'Success',
        description: data.message,
      })

      props.onApicalled()
    }
    setState({ view_document_details: false })
  }

  React.useImperativeHandle(ref, () => ({
    onSubmit,
  }))
  const getTotal = (value: number, key: number, flag: boolean) => {
    return props.advance_payments.reduce(function(total, item) {
      if (item.key == key && flag) return Number(total) + Number(value)
      return Number(total) + Number(item.amount_settle)
    }, 0)
  }

  const onChangeAdvancePayment = (value: number, data: AdvancePayments) => {
    const newData = props.advance_payments

    const index = newData.findIndex(item => item.serial_number == data.serial_number)

    if (value == null) {
      newData[index].amount_settle = 0
    } else {
      const total = getTotal(value, data.key, true)

      if (total < props.selectedData.amount_pending) {
        newData[index].amount_settle = value
        newData.map(item => {
          item.disable = false
        })
      } else if (total == props.selectedData.amount_pending) {
        newData[index].amount_settle = value
        newData.map(item => {
          if (item.amount_settle == 0) item.disable = true
          else item.disable = false
        })
      } else {
        const newValue = props.selectedData.amount_pending - (total - value)

        if (newValue > newData[index].amount_remaining) {
          newData[index].amount_settle = newData[index].amount_remaining
        } else {
          newData[index].amount_settle = newValue
        }
        newData.map(item => {
          if (item.amount_settle == 0) item.disable = true
          else item.disable = false
        })
      }
    }

    setState({ advance_payments: newData })
    props.updateAdvancePayments(newData)
  }

  const advance_payments_columns = [
    {
      title: 'Payment',
      dataIndex: 'serial_number',
      key: '1',
      render: (text: string, data: any) => (
        <span>
          <span className="text-primary font-size-12 block">{data.document_title}</span>
          {text}
        </span>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: '2',
      render: (text: number, data: any) => <span>{text.toFixed(2)}</span>,
    },
    {
      title: 'Amount Remaining',
      dataIndex: 'amount_remaining',
      key: '3',
      render: (text: number, data: any) => <span>{text.toFixed(2)}</span>,
    },
    {
      title: 'Settle Amount',
      dataIndex: 'amount_settle',
      key: '4',
      render: (text: string, data: any) => (
        <InputNumber
          disabled={data.disable}
          value={text}
          min={0}
          max={data.amount}
          onChange={e => onChangeAdvancePayment(e, data)}
        />
      ),
    },
  ]

  const settledPaymentsColumns = [
    {
      title: 'Serial Number',
      dataIndex: 'doc_serial_number',
      key: '1',
    },
    {
      title: 'Amount',
      dataIndex: 'amount_paid',
    },
  ]

  return (
    <div>
      {props.selectedData.amount_pending > 0 && (
        <p className="mb-2">
          <span className="text-gray-500">Amount Pending</span>
          <span className="font-weight-bold ml-2">{props.selectedData.amount_pending}</span>
        </p>
      )}
      <Row>
        <Col span={24}>
          <Table
            className=""
            size="small"
            columns={advance_payments_columns}
            dataSource={props.advance_payments}
            expandable={{
              expandedRowRender: record => (
                <Card className="border-radius-small bg-lblue">
                  <span></span>
                  <Table
                    className="w-full"
                    size="small"
                    columns={settledPaymentsColumns}
                    dataSource={record.setteled_documents}
                    pagination={{ pageSize: 5, showSizeChanger: false }}
                  />
                </Card>
              ),
              rowExpandable: record => record.setteled_documents.length >= 1,
            }}
            // expandedRowKeys={this.state.expandedRowKeys}
            // onExpand={(expanded, record) => {
            //   if (expanded) {
            //     this.setState({
            //       expandedRowKeys: [record.key],
            //     })
            //   } else {
            //     this.setState({
            //       expandedRowKeys: [],
            //     })
            //   }
            // }}
          />
        </Col>
      </Row>
    </div>
  )
})

export default AdvanceSettlement
