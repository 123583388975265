import { Tooltip } from 'antd'
import { useMemo } from 'react'
import { getUpdatedPeriodAgo } from '../../utils/utilFunctions'

const UpdatedDaysAgoLabel = ({ date }) => {
  const { displayDate, isMoreThanAWeek } = useMemo(() => getUpdatedPeriodAgo(date), [date])

  return (
    <Tooltip title={isMoreThanAWeek ? '' : date} placement="bottom">
      <span className="cursor-pointer leading-tight">{displayDate}</span>
    </Tooltip>
  )
}

export default UpdatedDaysAgoLabel
