import { Image, Text, View } from '@react-pdf/renderer'
import InvoiceTableHeader from './invoiceTableHeader'
import InvoiceTableRow from './invoiceTableRow'
// import InvoiceTableRow from './tablerow'
import { getTotalQty } from 'components/other/calculation'
import { getInvoiceSettings, HIDE_PRICES } from '../utils'
import { getTotalAlternateQty, getTotalPrimaryQty, has_multiple_units } from '../export_condition'
import InvoiceHsnTable from '../invoiceHsnTable'
import InvoiceTableBlankSpace from './invoiceTableBlankSpace'
import InvoiceTableFooter from './invoiceTableFooter'
import { mergeItemsWithHeaders } from '../itemsHeader'
import { styles as getStyles } from './styles'

const InvoiceItemsTable = ({ invoice, type }) => {
  var styles = getStyles(invoice)
  let tableRowsCount = 5
  const options = { maximumFractionDigits: 2, minimumFractionDigits: 2 }
  let conversion_factor = 1
  let currency_symbol = invoice.company.currency_symbol
  const is_export = invoice.is_export == 1
  const show_3_decimals = invoice.invoice_settings.qty_decimals_3 == 1
  let currency_code = invoice.company.currency_code
  if (invoice.is_export == 1 || invoice.is_multi_currency == 1) {
    if (invoice.invoice_settings.show_both_currencies != true) {
      currency_symbol = invoice.export_details[0].currency_symbol
      conversion_factor = invoice.export_details[0].conversion_factor
    }
    currency_code = invoice.export_details[0].currency_code
  }
  const {
    has_multiple_unit_products,
    has_multiple_primary_units,
    has_multiple_alternate_units,
  } = has_multiple_units(invoice.items)

  invoice.itemsWithHeaders = mergeItemsWithHeaders(invoice)
  const { hide_qty, show_free_qty, show_discount_col, hide_hsn } = getInvoiceSettings(invoice)

  const no_of_default_cols =
    8 + (show_free_qty ? 0.9 : 0) - (hide_qty ? 0.9 : 0) - (hide_hsn ? 0.8 : 0) + show_discount_col
  const no_of_dc_cols = 3 - invoice.invoice_settings.hide_qty

  if (Object.keys(invoice).includes('einvoice') && invoice.einvoice.length > 0) {
    tableRowsCount = 3
  }
  return (
    <>
      <View
        style={{
          ...styles.invoiceItemsTable_tableContainer,
          ...(invoice.invoice_settings.full_page == true &&
            styles.invoiceItemsTable_tableContainerGrow),
        }}
      >
        <InvoiceTableHeader
          invoice={invoice}
          type={type}
          show_free_qty={show_free_qty}
          show_discount_col={show_discount_col}
          no_of_default_cols={no_of_default_cols}
          no_of_dc_cols={no_of_dc_cols}
        />
        <InvoiceTableRow
          items={invoice.items}
          data={invoice}
          invoice={invoice}
          type={type}
          show_free_qty={show_free_qty}
          show_discount_col={show_discount_col}
          no_of_default_cols={no_of_default_cols}
          no_of_dc_cols={no_of_dc_cols}
        />

        <View style={invoice.invoice_settings.full_page == true && { flexGrow: 1 }}>
          <InvoiceTableBlankSpace
            rowsCount={tableRowsCount - invoice.items.length}
            items={invoice.tax}
            netAmount={invoice.net_amount}
            invoice={invoice}
            total={invoice.total}
            type={type}
            show_free_qty={show_free_qty}
            show_discount_col={show_discount_col}
            no_of_default_cols={no_of_default_cols}
            no_of_dc_cols={no_of_dc_cols}
          />
        </View>

        {type != 'Delivery Challan' && type != HIDE_PRICES && (
          <>
            {(invoice.document_type != 'estimates' ||
              (invoice.document_type == 'estimates' && invoice.hide_totals == 0)) && (
              <InvoiceTableFooter
                items={invoice.tax}
                total={invoice.total}
                netAmount={invoice.net_amount}
                invoice={invoice}
                show_free_qty={show_free_qty}
                show_discount_col={show_discount_col}
                no_of_default_cols={no_of_default_cols}
              />
            )}
          </>
        )}
      </View>
      <View style={invoice.invoice_settings.full_page == false && styles.globalContainer}>
        {type != 'Delivery Challan' && type != HIDE_PRICES && (
          <View style={styles.invoiceItemsTable_amountinwords}>
            {invoice.document_type != 'estimates' && (
              <Text>
                <Text style={styles.invoiceItemsTable_amountinwords}>
                  Amount Chargeable (in words):
                  {'\u00A0'}
                  {invoice.total_amount_in_words}.
                </Text>
                <Text style={styles.invoiceItemsTable_italic}> E & O.E </Text>
              </Text>
            )}

            {invoice.document_type == 'estimates' && invoice.hide_totals == 0 && (
              <Text>
                <Text style={styles.invoiceItemsTable_amountinwords}>
                  Total amount (in words):
                  {'\u00A0'}
                  {invoice.total_amount_in_words}.
                </Text>
                <Text style={styles.invoiceItemsTable_italic}> E & O.E </Text>
              </Text>
            )}
          </View>
        )}
        {type != 'Delivery Challan' &&
          type != HIDE_PRICES &&
          invoice.with_tax == 1 &&
          invoice.is_export == 0 &&
          invoice.invoice_settings.show_hsn_details == 1 &&
          invoice.hide_totals == 0 && <InvoiceHsnTable invoice={invoice} type={type} />}

        {(type == 'Delivery Challan' || type == HIDE_PRICES) &&
          !has_multiple_unit_products &&
          invoice.invoice_settings.hide_qty == 0 && (
            <View>
              <Text style={[styles.invoiceItemsTable_dc_totals, styles.invoiceItemsTable_bold]}>
                Total Items / Qty : {invoice.items.length} /{' '}
                {getTotalQty(invoice.items, true).toLocaleString('en-IN', {
                  maximumFractionDigits: 3,
                  minimumFractionDigits: show_3_decimals ? 3 : 0,
                })}{' '}
              </Text>
            </View>
          )}

        {(type == 'Delivery Challan' || type == HIDE_PRICES) &&
          has_multiple_unit_products &&
          !has_multiple_alternate_units &&
          !has_multiple_primary_units &&
          invoice.invoice_settings.hide_qty == 0 && (
            <View>
              <Text style={[styles.invoiceItemsTable_dc_totals, styles.invoiceItemsTable_bold]}>
                Total Items / Qty : {invoice.items.length} /{' '}
                {getTotalAlternateQty(invoice.items).toLocaleString('en-IN', {
                  maximumFractionDigits: 3,
                  minimumFractionDigits: show_3_decimals ? 3 : 0,
                })}{' '}
                {invoice.items[0]?.item_unit}
                {' ('}
                {parseFloat(getTotalPrimaryQty(invoice.items)).toLocaleString('en-IN', {
                  maximumFractionDigits: 3,
                  minimumFractionDigits: show_3_decimals ? 3 : 0,
                })}{' '}
                {invoice.items[0]?.unit}
                {')'}
              </Text>
            </View>
          )}

        {type != 'Delivery Challan' && type != HIDE_PRICES && (
          <View style={styles.invoiceItemsTable_borderTop}>
            {(invoice.document_type == 'sales' || invoice.document_type == 'pos') &&
              invoice.amount_pending > 0 && (
                <>
                  <View style={styles.invoiceItemsTable_block}>
                    <Text style={styles.invoiceItemsTable_labels}>Amount Payable:</Text>
                    <Text style={styles.invoiceItemsTable_info}>
                      {currency_symbol}
                      {Number(
                        (invoice.amount_pending / conversion_factor).toFixed(2),
                      ).toLocaleString('en-IN', options)}
                    </Text>
                  </View>

                  {invoice.amount_paid > 0 &&
                    invoice.amount_paid < invoice.total[0].amount &&
                    invoice.is_tds != 1 && (
                      <View style={styles.invoiceItemsTable_block}>
                        <Text style={styles.invoiceItemsTable_labels}>Amount Paid:</Text>
                        <Text style={styles.invoiceItemsTable_info}>
                          {currency_symbol}
                          {'\u00A0'}
                          {Number(
                            (invoice.amount_paid / conversion_factor).toFixed(2),
                          ).toLocaleString('en-IN', options)}
                        </Text>
                      </View>
                    )}
                </>
              )}

            {/* Amount Fully PAID */}
            {(invoice.document_type == 'sales' ||
              invoice.document_type == 'purchases' ||
              invoice.document_type == 'sales_returns' ||
              invoice.document_type == 'purchase_returns') &&
              invoice.amount_paid == invoice.total[0].amount && (
                <View
                  style={{
                    flexDirection: 'row',
                    marginHorizontal: 'auto',
                    marginRight: 0,
                    marginBottom: 1.8,
                  }}
                >
                  <Image
                    style={styles.invoiceItemsTable_image}
                    src={{
                      uri: 'https://vx-erp-logos.s3.ap-south-1.amazonaws.com/checkmark.png',
                      method: 'GET',
                      headers: { 'Cache-Control': 'no-cache' },
                    }}
                    crossorigin="anonymous"
                  />
                  <Text
                    style={[styles.invoiceItemsTable_amountPaid, styles.invoiceItemsTable_bold]}
                  >
                    Amount Paid
                  </Text>
                </View>
              )}

            {/* Show Payments */}
            {invoice.invoice_settings.show_payments != 0 &&
              invoice.payments != '' &&
              invoice.payments.map(
                (item, i) =>
                  item.payment_mode != 'TDS' && (
                    <View key={i} style={styles.invoiceItemsTable_block}>
                      <Text style={styles.invoiceItemsTable_info}></Text>
                      <Text style={styles.invoiceItemsTable_payments}>
                        <Text>
                          <Text style={[styles.invoiceItemsTable_bold]}>
                            {currency_symbol}
                            {item.settled_amount / conversion_factor} Paid via {item.payment_mode}{' '}
                            on {item.payment_date}
                            {'\n'}
                          </Text>
                          <Text style={{ color: '#343435', fontSize: 8.1 }}>
                            {item.notes != '' && ' (' + item.notes + ')'}
                          </Text>
                        </Text>
                      </Text>
                    </View>
                  ),
              )}

            {/* Show Net Balance */}
            {invoice.is_export == 0 &&
              invoice.invoice_settings.show_net_balance != 0 &&
              invoice.customer.balance + invoice.customer.opening_balance < 0 && (
                <View style={[styles.invoiceItemsTable_balance]}>
                  <View style={styles.invoiceItemsTable_block}>
                    <View style={styles.invoiceItemsTable_borderBlock}></View>
                    <View
                      style={{
                        ...styles.invoiceItemsTable_borderBlock2,
                        borderTopWidth: 0.5,
                      }}
                    ></View>
                  </View>
                  <View style={styles.invoiceItemsTable_block}>
                    <Text style={styles.invoiceItemsTable_labels2}>Total Balance due:</Text>
                    <Text style={styles.invoiceItemsTable_info2}>
                      {currency_symbol}
                      {'\u00A0'}
                      {Math.abs(
                        (
                          (invoice.customer.balance + invoice.customer.opening_balance) /
                          conversion_factor
                        ).toFixed(2),
                      ).toLocaleString('en-IN', options)}
                    </Text>
                  </View>
                </View>
              )}
          </View>
        )}
      </View>
    </>
  )
}

export default InvoiceItemsTable
