import { Text, View } from '@react-pdf/renderer'
import { breakName } from 'components/other/utility'
import { getTotalQty } from 'components/other/calculation'
import React from 'react'
import { has_multiple_units } from '../export_condition'
import { styles as getStyles } from './styles'

const InvoiceTableFooter = ({
  items,
  total,
  netAmount,
  invoice,
  type,
  show_free_qty,
  show_discount_col,
  no_of_default_cols,
  no_of_dc_cols,
}) => {
  var styles = getStyles(invoice)

  var FONT_FACTOR = invoice.invoice_settings.font_size
  const options = { maximumFractionDigits: 2, minimumFractionDigits: 2 }
  const hide_hsn = invoice.invoice_settings.hide_hsn

  var single_col = (1 * 100) / (no_of_default_cols + invoice.custom_col_names.length)
  var font_size = (FONT_FACTOR * (9 * 8)) / (8 + invoice.custom_col_names.length)
  let conversion_factor = 1
  let currency_symbol = invoice.company.currency_symbol
  const is_export = invoice.is_export == 1
  if (
    (invoice.is_export == 1 || invoice.is_multi_currency) &&
    invoice.invoice_settings.show_both_currencies != true
  ) {
    currency_symbol = invoice.export_details[0].currency_symbol
    conversion_factor = invoice.export_details[0].conversion_factor
  }
  const {
    has_multiple_unit_products,
    has_multiple_primary_units,
    has_multiple_alternate_units,
  } = has_multiple_units(invoice.items)

  return (
    <View style={invoice.invoice_settings.full_page == false && styles.globalContainer}>
      {type != 'Delivery Challan' && (
        <View
          style={{
            ...styles.invoiceTableFooter_row,
            ...styles.global_borderTop,
            fontSize: font_size,
            borderBottomColor: '#1d1d1f',
          }}
        >
          <Text
            style={{
              ...styles.invoiceTableFooter_cell,
              textAlign: 'left',
              fontSize: font_size,
              width: `${0.3 * single_col}%`,
            }}
          >
            {'  '}
          </Text>

          <Text
            style={{
              ...styles.invoiceTableFooter_cell,
              ...styles.invoiceTableFooter_italic,
              fontSize: font_size,
              textAlign: 'right',
              width: `${3.4 * single_col}%`,
            }}
          >
            {' Total'}
          </Text>

          {invoice.custom_col_names.map((item, index) => (
            <Text
              key={index}
              style={{
                ...styles.invoiceTableFooter_cell,
                width: `${single_col}%`,
                fontSize: font_size,
              }}
            >
              {'  '}
            </Text>
          ))}

          {!hide_hsn && (
            <Text
              style={{
                ...styles.invoiceTableRow_cell,
                textAlign: 'right',
                fontSize: FONT_FACTOR > 1 ? 0.9 * font_size : font_size,
                width: `${0.8 * single_col}%`,
              }}
              hyphenationCallback={e => breakName(e)}
            >
              {' '}
            </Text>
          )}

          <Text
            style={{
              ...styles.invoiceTableRow_cell,
              ...styles.invoiceTableRow_italic,
              fontSize: font_size,
              textAlign: 'right',
              width: `${0.5 * single_col}%`,
            }}
          >
            {' '}
          </Text>

          {!has_multiple_unit_products
            ? invoice.invoice_settings.hide_qty == 0 && (
                <Text
                  style={{
                    ...styles.invoiceTableFooter_cell,
                    textAlign: 'right',
                    fontSize: font_size,
                    width: `${0.9 * single_col}%`,
                  }}
                >
                  {getTotalQty(invoice.items)}
                </Text>
              )
            : invoice.invoice_settings.hide_qty == 0 && (
                <Text
                  style={{
                    ...styles.invoiceTableFooter_cell,
                    textAlign: 'right',
                    fontSize: font_size,
                    width: `${0.9 * single_col}%`,
                  }}
                >
                  {'  '}
                </Text>
              )}

          {show_free_qty && (
            <Text
              style={{
                ...styles.invoiceTableFooter_cell,
                textAlign: 'right',
                fontSize: font_size,
                width: `${0.9 * single_col}%`,
              }}
            >
              {'  '}
            </Text>
          )}

          {/* unit price */}
          <Text
            style={{
              ...styles.invoiceTableRow_cell,
              textAlign: 'right',
              fontSize: font_size,
              width: `${1 * single_col}%`,
            }}
          >
            {' '}
          </Text>

          {show_discount_col && (
            <Text
              style={{
                ...styles.invoiceTableRow_cell,
                textAlign: 'right',
                fontSize: font_size,
                width: `${1 * single_col}%`,
              }}
            >
              {' '}
            </Text>
          )}

          {/* final column - Price */}
          <Text
            style={{
              ...styles.invoiceTableFooter_cell,
              ...styles.invoiceTableFooter_bold,
              textAlign: 'right',
              paddingRight: 4.5,
              borderRight: 'none',
              fontSize: font_size,
              width: `${1.1 * single_col}%`,
            }}
          >
            {currency_symbol}
            {Number((total[0].amount / conversion_factor).toFixed(2)).toLocaleString(
              'en-IN',
              options,
            )}
            {(invoice.is_export == 1 || invoice.is_multi_currency == 1) &&
            invoice.invoice_settings.show_both_currencies == true ? (
              <>
                {'\n'}
                <Text
                  style={{
                    ...styles.invoiceTableFooter_cell,
                    ...styles.invoiceTableFooter_bold,
                    textAlign: 'right',
                    paddingRight: 4.5,
                    borderRight: 'none',
                    fontSize: font_size,
                    width: `${1.1 * single_col}%`,
                  }}
                >
                  {invoice.export_details[0].currency_symbol}
                  {Number(
                    (total[0].amount / invoice.export_details[0].conversion_factor).toFixed(2),
                  ).toLocaleString('en-IN', options)}
                </Text>
              </>
            ) : (
              <></>
            )}
          </Text>
        </View>
      )}
      {invoice.is_tds == 1 && (
        <View style={styles.invoiceTableFooter_row3}>
          <Text style={[styles.invoiceTableFooter_description, styles.invoiceTableFooter_bold]}>
            TDS @ {invoice.tds_details[0].tax}% {invoice.tds_details[0].section}
          </Text>
          <Text style={[styles.invoiceTableFooter_total, styles.invoiceTableFooter_bold]}>
            {currency_symbol}
            {Number(
              (invoice.tds_details[0].tds_amount / conversion_factor).toFixed(2),
            ).toLocaleString('en-IN', options)}
          </Text>
        </View>
      )}
    </View>
  )
}

export default InvoiceTableFooter
