import { Button, Card, Divider, Modal, Select, Tooltip } from 'antd'
import FooterComponent from 'components/other/FooterComponent'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { get_pricing_plans } from 'redux/document/actions'
import { getAPI, getAPIData } from 'services/jwt'
import { trackEvent } from 'utils/netcore'

const emptyPlan = {
  title: '',
  web_title: '',
  colors: ['#5578F8', '#2754FF'],
  sub_title: '',
  description: '',
  features: [],
  plan_id: -1,
  plan: 2,
  prices: [
    {
      year: 1,
      price: 0,
      strike_amount: 0,
      per: '1 Year',
      offer: '',
      discount: '',
    },
    {
      year: 3,
      price: 0,
      strike_amount: 0,
      per: '',
      offer: '',
      discount: '',
    },
  ],
  monthly_prices: [
    {
      year: 1,
      price: 0,
      strike_amount: 0,
      per: '1 Year',
      offer: '',
      discount: '',
      billing_text: 'Billed Annually',
    },
    {
      year: 3,
      price: 0,
      strike_amount: 0,
      per: '',
      offer: '',
      discount: '',
      billing_text: 'Billed Annually',
    },
  ],
  custom: false,
  show_in_web: false,
  button: {
    text: '',
    type: '',
    onclick: '',
  },
  badge: {
    text: '',
    type: '',
    icon: '',
  },
}

const CustomPricing = forwardRef((props, ref) => {
  const { pricing_details } = useSelector(state => state.document)
  const { user } = useSelector(state => state)

  const [visible, setVisible] = useState(false)
  const [years, setYears] = useState(1)
  const [selected_plan_id, setselected_plan_id] = useState(1)

  const [selectedAddons, setselectedAddons] = useState([])
  const [no_users, setNoUsers] = useState(1)
  // const [plans, setPlansData] = useState([])

  const [selectedPlan, setSelectedPlan] = useState(emptyPlan)
  const dispatch = useDispatch()

  useEffect(() => {
    if (Object.keys(pricing_details).length == 0) {
      dispatch(get_pricing_plans())
    }
  }, [visible])
  useEffect(() => {
    const url = new URL(window.location.href)
    if (selectedPlan.plan_id != -1) {
      url.searchParams.set('plan', selectedPlan.plan_id)
      url.searchParams.set('years', years)
      window.history.pushState({}, '', url)
    }
  }, [years, selectedPlan])

  var user_pricing_details = {
    1: {
      base_price: 799,
      users_included: {
        1: 1,
        4: 2,
        5: 2,
        6: 2,
      },
      discounts: {
        '1': 0,
        '2': 9.887359198998748,
        '3': 17.39674593241552,
        '4': 24.90613266583229,
      },
      display_discounts: {
        '1': 0,
        '2': 10,
        '3': 20,
        '4': 25,
      },
    },
    3: {
      base_price: 2397,
      users_included: {
        1: 1,
        4: 2,
        5: 2,
        6: 2,
      },
      discounts: {
        '1': 33.33333333333333,
        '2': 39.92490613266583,
        '3': 45.93241551939925,
        '4': 50.438047559449316,
      },
      display_discounts: {
        '1': 30,
        '2': 40,
        '3': 45,
        '4': 50,
      },
    },
  }

  if (user?.selectedCompany?.country_code != 'IN') {
    user_pricing_details[1]['base_price'] = 100
    user_pricing_details[3]['base_price'] = 500
  }

  const online_store_price = 4200
  const online_store_custom_domain_price = 6200
  const recurring_invoices_price = 2200
  const dotOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
  const addOnsList = [
    // 'Online Store',
    // 'Online Store with Custom Domain',
    // 'Recurring Invoices',
    'Additional Users',
  ]

  const get_additional_users = (years, users) => {
    const pricingDetails = user_pricing_details[years]
    if (!pricingDetails || !pricingDetails.users_included) {
      return '0'
    }
    users -= pricingDetails.users_included[selected_plan_id]
    return users > 0 ? String(users) : '0'
  }

  const get_discount_string = (years, no_of_users) => {
    const pricingDetails = user_pricing_details[years]
    if (!pricingDetails || !pricingDetails.display_discounts) {
      return '0'
    }
    let discount = '0'
    let users = parseInt(no_of_users)
    users -= pricingDetails?.users_included[selected_plan_id]
    if (users > 0) {
      discount = pricingDetails?.display_discounts[users] || '0'
    }
    return String(discount)
  }

  const get_users_price = (years, no_users) => {
    const pricingDetails = user_pricing_details[years]
    if (!pricingDetails || !pricingDetails.users_included) {
      return 0
    }
    if (no_users) {
      let users = parseInt(no_users)
      users -= pricingDetails?.users_included[selected_plan_id]
      let discount = pricingDetails?.discounts[users] || 0
      return pricingDetails?.base_price * users * (1 - discount / 100)
    } else {
      return 0
    }
  }

  const get_users_strike_price = (years, no_users) => {
    const pricingDetails = user_pricing_details[years]
    if (!pricingDetails || !pricingDetails.users_included) {
      return 0
    }
    if (no_users) {
      let users = parseInt(no_users)
      users -= pricingDetails?.users_included[selected_plan_id]
      return pricingDetails?.base_price * users
    } else {
      return 0
    }
  }
  const addons_pricing = {
    'Online Store': years == 1 ? 4200 : 4200 * 2 + 500,
    'Online Store with Custom Domain': years == 1 ? 6200 : 6200 * 2 + 500,
    'Recurring Invoices': years == 1 ? 2200 : 2200 * 2 + 500,
    'Additional Users': get_users_price(years, no_users),
  }

  const addons_strike_pricing = {
    'Online Store': years == 1 ? 4200 : 4200 * 2 + 500,
    'Online Store with Custom Domain': years == 1 ? 6200 : 6200 * 2 + 500,
    'Recurring Invoices': years == 1 ? 2200 : 2200 * 2 + 500,
    'Additional Users': get_users_strike_price(years, no_users),
  }

  const showModal = (plan_id, num_years = 1) => {
    setVisible(true)
    setselected_plan_id(plan_id)
    setSelectedPlan(get_plan(plan_id))
    setYears(num_years)
    if (plan_id != 1) {
      setNoUsers(2)
    } else {
      setNoUsers(1)
    }
    setselectedAddons([])
    getAPI('pricing', 'get_interested_users', {
      plan_id,
      num_years,
    })
  }
  useImperativeHandle(ref, () => ({
    showModal,
  }))
  const get_plan = plan_id => {
    let plan = pricing_details.pricing_plans.filter(plan => plan.plan_id == plan_id)
    return plan ? plan[0] : emptyPlan
  }

  const get_total_amount = (is_extra = false, curYears) => {
    curYears = curYears || years
    let total_amount = 0
    if (selectedPlan.plan_id != -1) {
      const plan = selectedPlan.prices.filter(each => each.year == curYears)[0]
      if (is_extra) {
        total_amount += plan.price
      } else {
        total_amount += plan.actual_price
      }
      if (selectedAddons.includes('Additional Users')) {
        total_amount += addons_pricing['Additional Users']
      }
      if (
        selectedAddons.includes('Online Store') ||
        selectedAddons.includes('Online Store with Custom Domain')
      ) {
        if (selectedAddons.includes('Online Store with Custom Domain')) {
          total_amount += addons_pricing['Online Store with Custom Domain']
        } else {
          total_amount += addons_pricing['Online Store']
        }
      }
    }

    if (selectedAddons.includes('Recurring Invoices')) {
      total_amount += addons_pricing['Recurring Invoices']
    }
    return total_amount
  }

  const get_total_strike_amount = curYears => {
    curYears = curYears || years
    let total_amount = 0
    if (selectedPlan.plan_id != -1) {
      total_amount += selectedPlan.prices.filter(each => each.year == curYears)[0].strike_amount
      if (selectedAddons.includes('Additional Users')) {
        total_amount += addons_strike_pricing['Additional Users']
      }
      if (
        selectedAddons.includes('Online Store') ||
        selectedAddons.includes('Online Store with Custom Domain')
      ) {
        if (selectedAddons.includes('Online Store with Custom Domain')) {
          total_amount += addons_strike_pricing['Online Store with Custom Domain']
        } else {
          total_amount += addons_strike_pricing['Online Store']
        }
      }
    }

    if (selectedAddons.includes('Recurring Invoices')) {
      total_amount += addons_strike_pricing['Recurring Invoices']
    }
    return total_amount
  }

  const getExtraDiscount = curYears => {
    let discount = ''
    curYears = curYears || years
    if (selectedPlan.plan_id != -1) {
      let plan = selectedPlan?.prices?.filter(each => each.year == curYears)[0]
      if (plan?.extra_discount_amount > 0) {
        discount = plan.extra_discount_amount.toString()
      } else if (plan?.extra_discount_percent > 0) {
        discount = plan.extra_discount_percent.toString() + '%'
      }
    }
    return discount
  }

  const getExtraDiscountAmount = curYears => {
    curYears = curYears || years
    let discount = 0
    if (selectedPlan.plan_id != -1) {
      let plan = selectedPlan?.prices?.filter(each => each.year == curYears)[0]
      if (plan?.extra_discount_amount > 0) {
        discount = plan.extra_discount_amount
      } else if (plan?.extra_discount_percent > 0) {
        discount = Math.round((plan.actual_price * plan.extra_discount_percent) / 100)
      }
    }
    return discount.toLocaleString('en-IN', dotOptions)
  }

  const getExtraMonths = curYears => {
    curYears = curYears || years
    let months = 0
    if (selectedPlan.plan_id != -1) {
      let plan = selectedPlan?.prices?.filter(each => each.year == curYears)[0]
      if (plan?.extra_months > 0) {
        months = plan.extra_months
      }
    }
    return months
  }

  const handleChange = (addon, checked) => {
    const nextselectedAddons = checked
      ? [...selectedAddons, addon]
      : selectedAddons.filter(t => t !== addon)
    setselectedAddons(nextselectedAddons)
  }

  const handleInitiatePayment = () => {
    let addons = {
      additional_users: selectedAddons.includes('Additional Users') ? no_users : 0,
      online_store: selectedAddons.includes('Online Store') ? 1 : 0,
      online_store_with_custom_domain: selectedAddons.includes('online_store_with_custom_domain')
        ? 1
        : 0,
      recurring_invoices: selectedAddons.includes('Recurring Invoices') ? 1 : 0,
    }
    props.onSelectPlan(selectedPlan.plan_id, years, addons)
    setTimeout(() => {
      setVisible(false)
    }, 2000)

    trackEvent({
      eventName: 'button_click',
      eventAttributes: {
        click_name: 'initiate_payment',
        plan_id: selectedPlan.plan_id,
        plan_title: selectedPlan.title,
      },
    })
  }

  return (
    <Modal
      open={visible}
      className=""
      onCancel={() => {
        setVisible(false)
      }}
      maskClosable={false}
      width={'100%'}
      style={{ maxWidth: '1000px' }}
      centered
      footer={<FooterComponent />}
      closeIcon={<i className="fa-solid fa-close fa-lg" />}
      title={
        <div className="">
          {props.is_renewal ? (
            <div>
              <h4 className="font-weight-bolder mb-0 pb-0">
                Renew your plan
                <br />
                <span className="text-blue font-size-12">
                  {props.renewal_dates?.start_date} to{' '}
                  {years == 1
                    ? props.renewal_dates?.end_date_1_year
                    : props.renewal_dates?.end_date_3_years}
                </span>{' '}
                {!window.location.pathname.includes('pricing') && (
                  <a
                    href={window.location.origin + '/pricing'}
                    target="_blank"
                    className="text-blue font-size-12"
                    style={{ textDecoration: 'underline' }}
                  >
                    Check all Plans
                  </a>
                )}
              </h4>
            </div>
          ) : (
            <h4 className="font-weight-bolder">Upgrade to our premium plan</h4>
          )}
        </div>
      }
      wrapStyle={{ backdropFilter: 'blur(3px)' }}
    >
      <div className="">
        <Card className="p-0 card-no-padding">
          <div className="flex w-full">
            {!isMobile && (
              <div className="m-4 w-5/12">
                <h3 className="font-weight-bolder">{selectedPlan.title}</h3>
                <p className="font-size-18 text-gray-500 leading-none">{selectedPlan.sub_title}</p>
                <p className="font-size-18 font-weight-bold mt-6 mb-2">What's included?</p>
                <div className="flex flex-col items-start justify-center leading-8">
                  {selectedPlan.features.map((item, index) => {
                    return (
                      <span key={index} className="text-gray-600">
                        <i className="fa-solid fa-circle-check text-green font-size-18 mr-2"></i>
                        <span className="font-weight-medium">{item.title}</span>
                        <span className="text-gray-500 ml-2">{item.description}</span>
                      </span>
                    )
                  })}
                </div>
              </div>
            )}
            <div className="p-4 w-7/12 bg-gray-75">
              <div className="flex flex-col justify-between">
                {selectedPlan.monthly_prices.map((item, index) => {
                  return (
                    <Card
                      className={`cursor-pointer ${index > 0 ? 'mt-3' : ''} ${
                        years == item.year ? 'bg-lblue border-primary' : 'bg-white border-gray-400'
                      }`}
                      onClick={() => {
                        setYears(item.year)
                      }}
                    >
                      <div className="flex items-center">
                        {years == item.year ? (
                          <i
                            className="fa-solid fa-circle-check  fa-lg mt-2 mr-2"
                            style={{ color: 'blue' }}
                          ></i>
                        ) : (
                          <i className="fa-regular fa-circle  fa-lg mr-2 mt-2 text-gray-500"></i>
                        )}
                        <div className="w-full flex-grow-1 flex">
                          <span className="flex justify-between w-full">
                            <span className="font-weight-bold mt-2">
                              <span>{item.year == 1 ? '1 Year' : '3 Years'}</span>

                              <span className="font-size-11 font-weight-bold text-green-600 mb-5  px-2 py-1 rounded-lg font-weight-bold ml-2">
                                {item.discount}
                              </span>
                              {getExtraMonths(item.year) > 0 ? (
                                <span className="ml-2 text-green-600">
                                  +
                                  <span className="underline ml-2">
                                    {getExtraMonths(item.year)} Months Free
                                  </span>
                                </span>
                              ) : (
                                <span className="text-green-600 font-weight-bold">
                                  {' '}
                                  {item.offer}
                                </span>
                              )}
                            </span>
                            <div className="flex mt-2 ">
                              <span className="text-gray-500 font-size-14 mt-1">
                                <span className="font-size-13">
                                  {pricing_details.currency_symbol}
                                </span>
                                <del>{item.strike_amount}</del>
                              </span>

                              <span className="font-weight-bold ml-2 font-size-18 text-right">
                                <span className="font-size-14 mt-1 inline-block align-top">
                                  {pricing_details.currency_symbol}
                                </span>
                                {item.price.toLocaleString('en-IN', dotOptions)}{' '}
                              </span>
                            </div>
                          </span>
                        </div>
                      </div>
                      {item.year == 3 && (
                        <span className="font-size-11 bg-blue-500 px-2 rounded-tr-lg rounded-bl-lg text-white mt-1 absolute bottom-0 left-0 ">
                          🔥 Buy 2 years & Get 1 year FREE!
                        </span>
                      )}

                      <span className="font-size-14 mt-1 float-right">/{item.per}</span>
                    </Card>
                  )
                })}
              </div>
              <div className="ml-2 mt-8">
                <label className="font-weight-medium mt-5">Addons:</label>
                {addOnsList.map((addon, index) => {
                  return (
                    <>
                      <div className="flex justify-between w-full mb-2 mb-0" key={index}>
                        <div>
                          {
                            <Tooltip title="Remove">
                              <span
                                className="font-weight-normal cursor-pointer text-gray-500 mx-2"
                                onClick={() => {
                                  handleChange(addon, false)
                                }}
                              >
                                {/* remove icon */}
                                <i className="fa-solid fa-circle-minus text-gray-500"></i>
                              </span>
                            </Tooltip>
                          }
                          <span className="font-size-14 text-gray-500">{addon}</span>
                        </div>
                        {selectedAddons.includes(addon) ? (
                          <>
                            <h6>
                              {pricing_details.currency_symbol}{' '}
                              {Math.floor(
                                addons_pricing[addon] /
                                  (12 * years) /
                                  (parseFloat(get_additional_users(years, no_users)) || 1),
                              ).toLocaleString('en-IN', dotOptions)}{' '}
                              <span className="text-gray-600">/month/user</span>
                            </h6>
                          </>
                        ) : (
                          <span
                            className="font-size-13 cursor-pointer"
                            style={{
                              textDecoration: 'underline',
                            }}
                            onClick={() => {
                              handleChange(addon, true)
                            }}
                          >
                            Add
                          </span>
                        )}
                      </div>
                      {selectedAddons.includes(addon) && addon == 'Additional Users' && (
                        <>
                          <span className="label mt-3 text-gray-500 font-size-13">
                            No of Users:
                          </span>
                          <Select
                            onChange={e => {
                              if (selected_plan_id != 1 && e >= 2) {
                                setNoUsers(parseInt(e))
                              }
                              if (selected_plan_id == 1 && e > 1) {
                                setNoUsers(parseInt(e))
                              }
                            }}
                            value={no_users}
                            className="w-full mb-2"
                            dropdownRender={menu => (
                              <>
                                {menu}
                                <Divider />
                                <span className="text-gray-500 font-size-11 ml-2 mt-0">
                                  <a
                                    className="ml-2 text-blue"
                                    href="https://api.whatsapp.com/send?phone=918121335436&text=Hello%20Swipe!%20I%20need%20more%20than%204%20users"
                                    target="_blank"
                                  >
                                    Contact our support
                                  </a>{' '}
                                  for more than 4 users.
                                </span>
                              </>
                            )}
                          >
                            <Option value="1">
                              1
                              {get_discount_string(years, 1) != '0' && (
                                <span className="font-size-12 text-forest ml-1">
                                  ({get_discount_string(years, 1)}% OFF)
                                </span>
                              )}
                              {
                                <span className="font-size-12 text-forest ml-2">
                                  included in plan
                                </span>
                              }
                            </Option>
                            <Option value="2">
                              2
                              {get_discount_string(years, 2) != '0' && (
                                <span className="font-size-12 text-forest ml-1">
                                  ({get_discount_string(years, 2)}% OFF)
                                </span>
                              )}
                              {selected_plan_id != 1 && (
                                <span className="font-size-12 text-forest ml-2">
                                  included in plan
                                </span>
                              )}
                            </Option>
                            <Option value="3">
                              3
                              {get_discount_string(years, 3) != '0' && (
                                <span className="font-size-12 text-forest ml-1">
                                  ({get_discount_string(years, 3)}% OFF)
                                </span>
                              )}
                              {get_additional_users(years, 3) != '0' && (
                                <span className="float-right font-size-12 text-forest">
                                  + {get_additional_users(years, 3)}
                                </span>
                              )}
                            </Option>
                            <Option value="4">
                              4
                              {get_discount_string(years, 4) != '0' && (
                                <span className="font-size-12 text-forest ml-1">
                                  ({get_discount_string(years, 4)}% OFF)
                                </span>
                              )}
                              {get_additional_users(years, 4) != '0' && (
                                <span className="float-right font-size-12 text-forest">
                                  + {get_additional_users(years, 4)}
                                </span>
                              )}
                            </Option>
                          </Select>
                        </>
                      )}
                    </>
                  )
                })}
              </div>

              <div className="mt-2 ml-2 mt-16">
                <Divider className="mt-2" />
                <div className="flex flex-row-reverse mb-2">
                  <span className="font-size-12 text-gray-500 ">
                    {years == 1 ? 'Billed Annually' : 'Billed for 3 Years'}
                  </span>
                </div>
                <div className="flex justify-between">
                  <h6 className="font-size-18 font-weight-bold">
                    Total Price
                    <Tooltip
                      title={
                        <div className="text-white">
                          <p>
                            Plan Total:{' '}
                            {
                              selectedPlan?.prices?.filter(each => each.year == years)?.[0]
                                ?.actual_price
                            }
                          </p>
                          {selectedAddons.includes('Additional Users') && (
                            <p>
                              Additional Users: {Math.floor(addons_pricing['Additional Users'])}
                            </p>
                          )}
                        </div>
                      }
                    >
                      <i className="fa-solid fa-info-circle font-size-16 ml-2"></i>
                    </Tooltip>
                  </h6>

                  <h6 className="font-size-18 font-weight-bold">
                    <span className="mr-3 font-size-14 text-gray-500 line-through	">
                      {pricing_details.currency_symbol}
                      {''}
                      {get_total_strike_amount().toLocaleString('en-IN', dotOptions)}
                    </span>
                    <span className="text-gray-600">
                      {pricing_details.currency_symbol}
                      {''}
                      {get_total_amount().toLocaleString('en-IN', dotOptions)}
                    </span>
                  </h6>
                </div>
                {getExtraDiscount() != '' ? (
                  <>
                    <div className="flex justify-between w-full text-forest font-size-14">
                      <div className="font-weight-bold">
                        {/* <i className="fa-regular fa-badge-percent text-forest fa-lg mr-2"></i> */}
                        {/* 🎁 Welcome Offer! Extra {getExtraDiscount()} OFF */}
                        🎁 Super Swipe 2025 Offer! Extra {getExtraDiscount()} OFF
                      </div>
                      <div className="flex justify-end">
                        <span className="font-bold">
                          - <span className="font-size-12">{pricing_details.currency_symbol}</span>
                          {getExtraDiscountAmount()}
                        </span>
                      </div>
                    </div>
                    <Divider />
                    <div className="flex justify-between">
                      <h6 className="font-size-24 font-weight-bold">Pay</h6>

                      <h6 className="font-size-24 font-weight-bold">
                        {pricing_details.currency_symbol}
                        {''}
                        {get_total_amount(true).toLocaleString('en-IN', dotOptions)}
                      </h6>
                    </div>
                    <Divider />
                  </>
                ) : null}
                {getExtraMonths() > 0 ? (
                  <>
                    <div className="flex justify-between w-full text-forest font-size-14">
                      <div className="font-weight-bold">
                        {/* <i className="fa-regular fa-badge-percent text-forest fa-lg mr-2"></i> */}
                        {/* 🎁 Welcome Offer! Extra {getExtraMonths()} months FREE */}
                        🎁 Super Swipe 2025 Offer! Extra {getExtraMonths()} months FREE
                      </div>
                      {/* <div className="flex justify-end">
                        <span className="font-bold">
                          + {getExtraMonths()} Months Free
                        </span>
                      </div> */}
                    </div>
                    <Divider />
                  </>
                ) : null}
              </div>

              <div className="flex flex-col justify-between ml-2 mb-2 mt-auto">
                <div className="flex justify-between">
                  <span className="text-gray-500 font-size-14 font-weight-bold">
                    Inclusive of all taxes (18% GST)
                  </span>

                  <Button
                    type="primary"
                    size="large"
                    className="w-1/2 arrow-transition mb-0"
                    onClick={handleInitiatePayment}
                  >
                    Pay Now <i className="fa-solid fa-arrow-right ml-2"></i>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Card>
        <p className="text-gray-500 font-size-12 ml-2 end-0 mt-2">
          It is ok to change your mind. Get a refund if you place the request within 15 days of
          purchase!{' '}
          <a href="https://getswipe.in/terms" target="_blank" className="text-blue">
            Read the terms & conditions.
          </a>
        </p>
      </div>
    </Modal>
  )
})

export default CustomPricing
