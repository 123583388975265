import React, { useState, forwardRef, useImperativeHandle, useEffect, useMemo } from 'react'
import {
  Button,
  Drawer,
  Form,
  Input,
  AutoComplete,
  DatePicker,
  Typography,
  Divider,
  Select,
  message,
  Tooltip,
  Card,
  Badge,
  Tag,
} from 'antd'
import dayjs from 'dayjs'
import { getAPI } from 'services/jwt'
import { ShareSettingsProps } from './data/types'
import { PUBLIC_REPORTS_URL } from 'utils/constants'
import SectionHeader from 'components/other/sectionHeader'
import ExpiringIn from 'components/tags/ExpiringIn'

const { RangePicker } = DatePicker
const { Search } = Input

export interface ShareSettingsRef {
  openDrawer: () => void
  closeDrawer: () => void
}

const ShareSettings = forwardRef<ShareSettingsRef, ShareSettingsProps>(
  ({ report, publicFilters }, ref) => {
    // Region 1: Variables
    const dateFormat = 'DD-MM-YYYY'

    const {
      hideComponents = [],
      hasDatePicker,
      showBatchExpirySelection,
      business_companies = [],
      warehouses = [],
      showPartyTags,
      hasPartyBalanceTypeSelection,
      partyTagOptions = [],
      partyBalanceOptions = [],
      showProductTypeSelect,
      productTypeOptions = [],
    } = publicFilters ?? {}

    const [showShareDrawer, setShowShareDrawer] = useState(false)
    const [pin, setPin] = useState('')
    const [hashId, setHashId] = useState<string>('')
    const [shareLink, setShareLink] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    const [expiryDate, setExpiryDate] = useState(
      dayjs()
        .add(30, 'day')
        .format(dateFormat),
    )
    // EndRegion 1

    // Region 2: Functions
    const isSection1Hidden = useMemo(() => {
      return (
        hideComponents.includes('rangepicker') &&
        !hasDatePicker &&
        !publicFilters?.party_name &&
        publicFilters.partyBalanceType == 'All' &&
        !publicFilters.partyTags?.length &&
        !showBatchExpirySelection &&
        !publicFilters?.product_name &&
        publicFilters.productType == 'all' &&
        !publicFilters?.warehouse_id &&
        publicFilters?.company_ids?.length <= 1 &&
        publicFilters?.productTypeOptions
      )
    }, [
      publicFilters,
      hideComponents,
      hasDatePicker,
      warehouses,
      showPartyTags,
      showBatchExpirySelection,
      showProductTypeSelect,
    ])

    const isSection2Hidden = useMemo(() => {
      return !publicFilters?.filters || Object.keys(publicFilters.filters ?? {})?.length === 0
    }, [publicFilters.filters])

    const isFullReport = isSection1Hidden && isSection2Hidden

    const formatWord = (word: string = ''): string => {
      return word
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    }

    const generatePin = () => {
      const newPin = String(Math.floor(1000 + Math.random() * 9000))
      setPin(newPin)
    }

    const getBranchNames = (ids: number[]) => {
      return ids
        .map(id => {
          const company = business_companies?.find((comp: any) => comp.company_id === id)
          return company ? { label: company.company_name, value: id } : null
        })
        .filter(Boolean) as { label: string; value: number }[]
    }
    const branchOptions =
      publicFilters.company_ids?.length > 1 ? getBranchNames(publicFilters.company_ids) : []

    const handleCopy = () => {
      navigator.clipboard.writeText(`${PUBLIC_REPORTS_URL}${hashId}`)
      setShareLink(`${PUBLIC_REPORTS_URL}${hashId}`)
      setTimeout(() => setShareLink(''), 4500)
    }

    const handleCloseDrawer = () => {
      setShowShareDrawer(false)
      setHashId('')
    }

    const handleGenerateLink = async () => {
      if (!/^\d{4}$/.test(pin)) {
        message.error('Please enter a valid 4-digit PIN.')
        return
      }

      setLoading(true)
      try {
        const {
          warehouses,
          business_companies,
          productTypeOptions,
          partyBalanceOptions,
          ...rest
        } = publicFilters

        const payload = {
          publicFilters: rest,
          pin,
          report_type: report.type,
          report_name: report.menu_name,
          expire_date: expiryDate,
        }

        const response = await getAPI('v2/reports', 'create_public_report', payload)

        const { success, new_hash_id } = response as { success: boolean; new_hash_id: string }
        if (success) {
          setHashId(`${new_hash_id}`)
          message.success('Public link generated successfully!')
        } else {
          message.error('Failed to generate public link.')
        }
      } catch (error) {
        message.error('An error occurred while generating the link.')
      } finally {
        setLoading(false)
      }
    }
    // EndRegion 2

    // Region 3: Useffect and others
    useImperativeHandle(ref, () => ({
      openDrawer: () => setShowShareDrawer(true),
      closeDrawer: handleCloseDrawer,
    }))

    useEffect(() => {
      generatePin()
    }, [])
    // EndRegion 3

    return (
      <Drawer
        title={
          <div className="flex justify-between items-center">
            <div className="font-bold text-2xl">Report Sharing</div>
            <Button type="primary" onClick={handleCloseDrawer}>
              Close
            </Button>
          </div>
        }
        placement="right"
        onClose={handleCloseDrawer}
        destroyOnClose
        open={showShareDrawer}
        width={900}
        footer={
          <Button type="primary" onClick={handleCloseDrawer}>
            Close
          </Button>
        }
      >
        <div className="h-full">
          <SectionHeader
            title={
              <div className="text-xl text-gray-500 font-bold">
                {formatWord(report.type)}
                {report.type !== report.menu_name && report.menu_name != ''
                  ? ` - ${report.name}`
                  : ''}
              </div>
            }
          />

          {isFullReport ? (
            <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4">
              <p>Sharing full report.</p>
            </div>
          ) : null}

          <Form
            layout="vertical"
            className="mt-8"
            style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 250px)' }}
          >
            {!isSection1Hidden ? (
              <>
                <SectionHeader title="Filters" />
                <Card bordered={true} className="border-radius-small mb-4">
                  {/* Date Range */}
                  {publicFilters?.date_range &&
                    hideComponents &&
                    !hideComponents.includes('rangepicker') && (
                      <Form.Item label="Date Range">
                        <RangePicker
                          defaultValue={[
                            publicFilters.dates ? dayjs(publicFilters.dates[0], dateFormat) : null,
                            publicFilters.dates ? dayjs(publicFilters.dates[1], dateFormat) : null,
                          ]}
                          value={[
                            publicFilters.dates ? dayjs(publicFilters.dates[0], dateFormat) : null,
                            publicFilters.dates ? dayjs(publicFilters.dates[1], dateFormat) : null,
                          ]}
                          format={dateFormat}
                          disabled
                          style={{ width: '47%' }}
                        />
                      </Form.Item>
                    )}

                  {hasDatePicker == true && (
                    <Form.Item label="Selected Date">
                      <DatePicker
                        style={{
                          borderRadius: '5px',
                          cursor: 'pointer',
                          width: 200,
                        }}
                        allowClear={false}
                        format={dateFormat}
                        value={dayjs(publicFilters?.dates?.[0], dateFormat)}
                        disabled
                      />
                    </Form.Item>
                  )}

                  {/* Party Name */}
                  <div className="flex justify-between items-center w-full">
                    {publicFilters?.party_name || hasPartyBalanceTypeSelection || showPartyTags ? (
                      <div
                        className="flex justify-start items-center gap-1"
                        style={{ width: '47%' }}
                      >
                        {publicFilters?.party_name ? (
                          <Form.Item
                            label={report.party_type === 'customer' ? 'Customer' : 'Vendor'}
                            className={`${hasPartyBalanceTypeSelection ? 'w-4/5' : 'w-full'}`}
                          >
                            <AutoComplete
                              options={[{ value: publicFilters.party_name }]}
                              placeholder="Party"
                              value={publicFilters.party_name}
                              disabled
                            />
                          </Form.Item>
                        ) : null}

                        {hasPartyBalanceTypeSelection == true &&
                        publicFilters.partyBalanceType != 'All' ? (
                          <Form.Item label="Balance type">
                            <Select
                              showSearch
                              className="sm:w-96 w-1/5"
                              style={{ fontSize: '12px' }}
                              placeholder="Credit/Debit"
                              optionFilterProp="children"
                              // onChange={e => {
                              //   setState({ ...state, partyBalanceType: e })
                              // }}
                              value={publicFilters.partyBalanceType}
                              filterOption={(input: string, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              disabled
                            >
                              {partyBalanceOptions?.map((option, index) => (
                                <Select.Option value={option.value} key={index}>
                                  {option.label}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        ) : null}

                        {showPartyTags == true && publicFilters.partyTags?.length ? (
                          <Form.Item label="Party Tags">
                            <Select
                              showSearch
                              className="sm:w-96 w-full"
                              style={{ width: 150, fontSize: '12px' }}
                              placeholder="Select tags"
                              mode="multiple"
                              optionFilterProp="children"
                              // onChange={e => {
                              //   setState({ ...state, partyTags: e })
                              // }}
                              value={publicFilters.partyTags}
                              filterOption={(input: string, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              disabled
                            >
                              {partyTagOptions?.map((tag: any, index: number) => (
                                <Select.Option value={tag} key={index}>
                                  {tag}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        ) : null}
                      </div>
                    ) : null}

                    {/* Product Name with Variant Name */}
                    {publicFilters?.product_name || showProductTypeSelect ? (
                      <div
                        className="flex justify-start items-center gap-1"
                        style={{ width: '47%' }}
                      >
                        {publicFilters?.product_name ? (
                          <Form.Item
                            label="Product"
                            className={`${publicFilters.productType != 'all' ? 'w-4/5' : 'w-full'}`}
                          >
                            <AutoComplete
                              options={[
                                {
                                  value: `${publicFilters.product_name}${
                                    publicFilters.variant_name
                                      ? ` - ${publicFilters.variant_name}`
                                      : ''
                                  }`,
                                },
                              ]}
                              placeholder="Product"
                              value={`${publicFilters.product_name}${
                                publicFilters.variant_name ? ` - ${publicFilters.variant_name}` : ''
                              }`}
                              disabled
                            />
                          </Form.Item>
                        ) : null}

                        {showProductTypeSelect == true && publicFilters.productType != 'all' ? (
                          <Form.Item label="Type" className="w-1/5">
                            <Select
                              showSearch
                              className="sm:w-auto w-full"
                              style={{ fontSize: '12px' }}
                              placeholder="Product Type"
                              optionFilterProp="children"
                              // onChange={e => {
                              //   setState({ ...state, productType: e })
                              // }}
                              value={publicFilters.productType}
                              filterOption={(input: string, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              disabled
                            >
                              {productTypeOptions?.map((option, index) => (
                                <Select.Option value={option.value} key={index}>
                                  {option.label}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        ) : null}
                      </div>
                    ) : null}
                  </div>

                  <div className="flex justify-between items-center w-full">
                    {showBatchExpirySelection && (
                      <Form.Item label="Batch Expiry" style={{ width: '47%' }}>
                        <Select
                          value={publicFilters.batchExpiry}
                          style={{ width: '100%' }}
                          options={[
                            { value: -1, label: 'All batches' },
                            { value: 30, label: 'Expires in 30 Days' },
                            { value: 60, label: 'Expires in 60 Days' },
                            { value: 90, label: 'Expires in 90 Days' },
                            { value: 120, label: 'Expires in 120 Days' },
                            { value: 180, label: 'Expires in 180 Days' },
                            {
                              value: 0, // -1 is for expired batches
                              label: 'Expired Batches',
                            },
                          ]}
                          disabled
                        />
                      </Form.Item>
                    )}

                    {warehouses?.length > 0 &&
                      publicFilters.warehouse_id &&
                      publicFilters.warehouse_id > 0 && (
                        <Form.Item label="Selected Warehouse" style={{ width: '47%' }}>
                          <Select
                            suffixIcon={<i className="fa-regular fa-chevron-down"></i>}
                            optionFilterProp="children"
                            showSearch
                            allowClear={true}
                            style={{ width: '100%' }}
                            // onChange={value => setState({ ...state, warehouse_id: value })}
                            placeholder={'Select Warehouse'}
                            filterOption={(input, option) =>
                              // @ts-ignore
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            dropdownStyle={{ minWidth: '300px' }}
                            listHeight={200}
                            value={publicFilters.warehouse_id}
                            disabled
                          >
                            {warehouses?.map((warehouse: any) => (
                              <Select.Option value={warehouse.warehouse_id}>
                                {warehouse.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                  </div>

                  {publicFilters.company_ids?.length > 1 && (
                    <Form.Item label="Selected Branches">
                      <Select
                        mode="multiple"
                        options={branchOptions}
                        value={branchOptions?.map(option => option.value)}
                        disabled
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  )}
                </Card>
              </>
            ) : null}

            {!isSection2Hidden ? (
              <>
                <SectionHeader title="Other Filters" />
                <Card bordered={true} className="border-radius-small mb-4">
                  {Object.entries(publicFilters?.filters ?? {}).map(([key, values]) => (
                    <Form.Item key={key} label={formatWord(key)}>
                      <Select
                        mode="multiple"
                        options={(values as string[]).map((value: string) => ({
                          label: value,
                          value: value,
                        }))}
                        value={values}
                        disabled
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  ))}
                </Card>
              </>
            ) : null}
          </Form>
          <SectionHeader title="Generate Link" />
          <Card bordered={true} className="border-radius-small mb-4">
            <div className="mt-3">
              <Form layout="vertical">
                <Form.Item
                  label={
                    <div className="flex items-center justify-between gap-10">
                      <span> Expiry Date </span>
                      <ExpiringIn expiryDate={expiryDate} />
                    </div>
                  }
                  className="mt-1 mb-0"
                >
                  <DatePicker
                    style={{
                      borderRadius: '5px',
                      cursor: 'pointer',
                      width: 200,
                    }}
                    allowClear={false}
                    format={dateFormat}
                    value={dayjs(expiryDate, dateFormat)}
                    onChange={(date, dateString) => {
                      if (typeof dateString == 'string') setExpiryDate(dateString)
                    }}
                    disabledDate={currentDate => {
                      const start = dayjs()
                      const end = dayjs().add(30, 'day')
                      return !currentDate.isBetween(start, end, 'day', '[]')
                    }}
                  />
                </Form.Item>
                <Form.Item label="PIN" className="mt-1 -mb-1" required>
                  <div className="flex items-center gap-3">
                    <Input.OTP
                      length={4}
                      value={pin}
                      onChange={e => setPin(e)}
                      style={{ width: '150px', height: '8px' }}
                    />
                    <Tooltip title="Generate PIN">
                      <i
                        onClick={generatePin}
                        className="fa-solid fa-arrows-rotate hover:cursor-pointer ml-2"
                      />
                    </Tooltip>
                  </div>
                </Form.Item>
                <Form.Item label="Share link" className="w-1/2 mt-1 -mb-1">
                  <Search
                    value={hashId}
                    placeholder="*************"
                    addonBefore={PUBLIC_REPORTS_URL}
                    enterButton={
                      <>
                        {hashId ? (
                          <div onClick={handleCopy}>
                            {shareLink ? (
                              <>
                                <i
                                  className="fa-solid fa-circle-check fa-sm"
                                  style={{ color: '#0ad618' }}
                                />{' '}
                                Copied
                              </>
                            ) : (
                              <>
                                <i className="fa-regular fa-copy mr-1" /> Copy
                              </>
                            )}
                          </div>
                        ) : (
                          <div onClick={handleGenerateLink}>Generate</div>
                        )}
                      </>
                    }
                    readOnly
                  />
                </Form.Item>
              </Form>
            </div>
          </Card>
        </div>
      </Drawer>
    )
  },
)

export default ShareSettings
