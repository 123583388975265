export const CONSTANTS = {
  PRICING_BUTTON: ' Upgrade with Super 2025 Offer 🚀',
  // PRICING_BUTTON: 'Subscribe Now! 🚀',
  // GSTIN_REGEX: '^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[0-9A-Z]{1}[0-9A-Z]{1}$',
  GSTIN_REGEX: '^[0-9A-Z]{15}$',
  HELP_BUTTON_TEXT: 'Hello%20Swipe! I need help with',
  // HELP_BUTTON_TEXT: 'Hello, I am interested in Swipe Freedom Offer!',
}

export const ADMIN = 'admin'

export const STOCK_IN_STOCK_OUT_DOC_TYPES = [
  'invoice',
  'sales_return',
  'purchase_return',
  'purchase',
]
export const FLOATOPTIONS = { maximumFractionDigits: 2, minimumFractionDigits: 2 }

export const LEADS_SHEET_DAILY_TARGET = 150000
export const INVOICE = 'invoice'
export const PURCHASE = 'purchase'
export const DELIVERY_CHALLAN = 'delivery_challan'
export const PRODUCT = 'Product'
export const SERVICE = 'Service'

export const DOCUMENT_SHORTCUTS = {
  SL: { type: 'sales', title: 'Your Bill/Invoice' },
  PU: { type: 'purchases', title: 'Your Bill/Purchase' },
  SR: { type: 'sales_returns', title: 'Sales Return' },
  PR: { type: 'purchase_returns', title: 'Purchase Returns' },
  ES: { type: 'estimates', title: 'Estimate' },
  DC: { type: 'delivery_challans', title: 'Delivery Challan' },
  PF: { type: 'pro_forma_invoices', title: 'Pro Forma Invoice' },
  PO: { type: 'purchase_orders', title: 'Purchase Order' },
  OL: { type: 'online_order', title: 'Online Order' },
  SUB: { type: 'subscriptions', title: 'Subscription' },
  SO: { type: 'sales_order', title: 'Sales Order' },
}

export const RCM_ALLOWED_DOCUMENT_TYPES = [
  'invoice',
  'sales_return',
  'purchase_return',
  'subscription',
]

export const order_types = {
  delivery: 'Delivery',
  pick_up: 'Pickup',
}

export const templateMentionData = [
  {
    id: 'customer_name',
    value: 'Customer Name',
  },
  {
    id: 'company_mobile',
    value: 'Company Mobile',
  },
  {
    id: 'organization_name',
    value: 'Organization Name',
  },
  {
    id: 'company_name',
    value: 'Company Name',
  },
  {
    id: 'amount_paid',
    value: 'Amount Paid',
  },
  {
    id: 'amount_pending',
    value: 'Amount Pending',
  },
  {
    id: 'customer_balance_amt',
    value: 'Customer Balance',
  },
  {
    id: 'customer_email',
    value: 'Customer Email',
  },
  {
    id: 'customer_phone_no',
    value: 'Customer Phone No',
  },
  {
    id: 'document_date',
    value: 'Document Date',
  },
  {
    id: 'document_due_date',
    value: 'Document Due Date',
  },
  {
    id: 'serial_no',
    value: 'Serial No',
  },
]

export const FREE_USER_EINVOICE_LIMIT = 3
export const FREE_USER_EWAYBILL_LIMIT = 3
export const FREE_USER_EXPORT_LIMIT = 3
export const FREE_USER_PACKING_LIST_LIMIT = 3
export const FREE_USER_BANKRECON_LIMIT = 3
export const FREE_GSTR1_FILING_LIMIT = 3
export const FREE_GSTR2B_FETCH_LIMIT = 3
export const FREE_USER_CONVERT_LIMIT = 3
export const FREE_USER_SALES_ORDER_LIMIT = 3

export const AG_GRID_PRODUCTS_TABLE_COMPANIES = [
  481332,
  284200,
  28331,
  1131355,
  17141,
  340067,
  271394,
]

export const EWAYBILL_ERRORS = {
  HSN_MISSING: 'HSN Code is missing for few items.',
  DISPATCH_FROM_MISSING: '"Dispatch From" address is missing.',
  PINCODE_MISSING: 'Pincode is missing in "Dispatch From" address.',
  TRANSPORTER_ID_MISSING: 'Either of Transporter Id or Vehicle Number is required.',
}
export const EINVOICE_ERRORS = {
  HSN_MISSING: 'HSN Code is missing for few items.',
  INVALID_HSN_LENGTH: 'Please enter a valid HSN Code with a length of 4, 6, or 8 digits.',
  SELLER_DETAILS_MISSING(missingDetails) {
    if (missingDetails.length === 1) {
      return `${missingDetails[0]} is missing in Seller Details`
    }
    return 'The following are missing in Seller Details'
  },
  BUYER_DETAILS_MISSING(missingDetails) {
    if (missingDetails.length === 1) {
      return `${missingDetails[0]} is missing in Buyer Details`
    }
    return 'The following are missing in Buyer Details'
  },
}
export const USER_ACTIVITY_PERMISSIONS = [4, 5, 6, 26, 11, 13, 17]
export const HIDE_PAYOUTS_COMPANY_ID = 1420000
export const WHATSAPP_DOC_SHARE_COMPANY_ID = 1701750
export const SERIAL_NUMBER_REGEX = /^.{0,16}$/
export const BUSINESS_TYPE_PERMISSIONS = [
  'open',
  'dnp',
  'dnp2',
  'trash',
  '',
  'not interested',
  'call back',
]
export const BILLING_OPTIONS = [
  { value: 'pro', label: 'Pro Plan' },
  { value: 'jet', label: 'Jet Plan' },
  { value: 'rise', label: 'Rise Plan' },
  { value: 'einvoice', label: 'E-invoice Plan' },
]
export const PUBLIC_REPORTS_URL = 'https://swipe.pe/r/'

export const publicLayoutContent = {
  report: {
    title: 'Easily create invoices in 10 Seconds 🤩',
    description: 'and easily manage you business!',
  },
  default: {
    title: 'Easily create invoices in 10 Seconds 🤩',
    description: 'and share them with your customers!',
  },
}

export const DISABLEDTIME = () => {
  return { disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 21, 22, 23] }
}

export const initialSignatures = [
  { signature_name: 'No Signature', id: 0, image: '', is_default: 0 },
]
