import { Button, Form, Modal, Tabs } from 'antd'
import { Component } from 'react'

import BetaBadge from 'components/badges/beta'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Barcode from 'react-barcode'
import { connect } from 'react-redux'
import ReactToPrint from 'react-to-print'
import { get_barcode_settings } from 'redux/document/actions'
import store from 'store'
import BarcodeDetailsForm from '../../forms/BarcodeDetailsForm'
import './index.scss'

dayjs.extend(utc)

const { TabPane } = Tabs

class PrintBarcode extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    var props = this.props
    // var width = 12 / props.type

    const width = 12 / props.type // Calculate the width fraction

    const getColSpanClass = width => {
      switch (width) {
        case 12:
          return 'col-span-12'
        case 6:
          return 'col-span-6'
        case 4:
          return 'col-span-4'
        case 3:
          return 'col-span-3'
        case 2:
          return 'col-span-2'
        case 1:
          return 'col-span-1'
        default:
          return 'col-span-2' // Default to 2 column if not matched
      }
    }

    const isCompact = props.type === 4
    const productNameFontSize = store.get('product_name_font_size')
    const mrpFontSize = store.get('mrp_font_size')
    const barcodeWidth = isCompact ? 2 : 3
    const barcodeHeight = isCompact ? 50 : 75
    const barcodeFontSize = isCompact ? 14 : 20
    const display_price = props.barcode_settings?.show_price_with_tax
      ? props.barcodeData.price_with_tax
      : props.barcodeData.price

    let discount_percentage = props.barcodeData.discount
    if (props.barcodeData.discount_amount) {
      discount_percentage =
        (props.barcodeData.discount_amount / props.barcodeData.price_with_tax) * 100
    }

    return (
      <div className="barcode">
        <div className="grid grid-cols-12 gap-1">
          {[...Array(props.type)].map((item, i) => (
            <div
              key={i}
              className={`${getColSpanClass(width)} text-center m-0 px-1`}
              style={{ lineHeight: 1 }}
            >
              <p
                className="mb-1 text-truncate pt-1 text-center"
                style={{ fontSize: productNameFontSize }}
              >
                {props.barcodeData.product_name} {props.barcodeData.name}
              </p>
              {props.barcode_settings?.price_label?.length > 0 && (
                <p className="mb-0">
                  <span className="font-size-12" style={{ fontSize: mrpFontSize }}>
                    {props.barcode_settings?.price_label}
                  </span>
                  <span>
                    {props.barcodeData.discount != 0 && (
                      <span>
                        <del
                          className="text-gray-11 font-weight-bold ml-1"
                          style={{ fontSize: mrpFontSize }}
                        >
                          {display_price}
                        </del>
                      </span>
                    )}
                    <span
                      className="font-weight-bold font-size-12"
                      style={{ fontSize: mrpFontSize }}
                    >
                      <span className="ml-2 mr-1">
                        {this.props?.user?.selectedCompany?.currency_symbol}
                      </span>
                      {(display_price * (1 - discount_percentage / 100)).toFixed(2)}
                    </span>
                  </span>
                </p>
              )}

              {store.get('show_pkg_date') ? (
                <div className="flex gap-4 justify-center">
                  <h6 className="mb-0" style={{ fontSize: mrpFontSize }}>
                    PKD ON: {props.barcodeData?.pkg_date ?? dayjs().format('DD-MMM-YY')}
                  </h6>
                  {props.barcodeData?.exp_date && (
                    <h6 className="mb-0" style={{ fontSize: mrpFontSize }}>
                      EXP ON: {props.barcodeData?.exp_date}
                    </h6>
                  )}
                </div>
              ) : null}
              {props.barcodeData.barcode_id != null && (
                <Barcode
                  width={barcodeWidth}
                  height={barcodeHeight}
                  fontSize={barcodeFontSize}
                  value={props.barcodeData.barcode_id + ''}
                  className="flex m-auto"
                />
              )}
            </div>
          ))}
        </div>
      </div>
    )
  }
}

class PrintBarcode8x2 extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    var props = this.props
    const display_price = props.barcode_settings?.show_price_with_tax
      ? props.barcodeData.price_with_tax
      : props.barcodeData.price

    let discount_percentage = props.barcodeData.discount
    if (props.barcodeData.discount_amount) {
      discount_percentage =
        (props.barcodeData.discount_amount / props.barcodeData.price_with_tax) * 100
    }

    return (
      <div className="barcode">
        <div className="grid grid-cols-2 gap-2 pt-3 text-center">
          {[...Array(16)].map((item, i) => (
            <div key={i} className="text-center leading-none">
              <h6
                className="mb-1 print-font-3"
                style={{ fontSize: store.get('product_name_font_size') }}
              >
                {props.barcodeData.product_name} {props.barcodeData.name}
              </h6>
              {props.barcode_settings?.price_label?.length > 0 && (
                <h6>
                  <span className="print-font-3" style={{ fontSize: store.get('mrp_font_size') }}>
                    {props.barcode_settings?.price_label}:{' '}
                  </span>
                  <span>
                    {props.barcodeData.discount != 0 && (
                      <span>
                        <del
                          className="text-gray-11 font-weight-bold ml-1"
                          style={{ fontSize: store.get('mrp_font_size') }}
                        >
                          {display_price}
                        </del>
                      </span>
                    )}

                    <span
                      className="font-weight-bold font-size-12"
                      style={{ fontSize: store.get('mrp_font_size') }}
                    >
                      <span className="ml-2 mr-1">
                        {this.props?.user?.selectedCompany?.currency_symbol}
                      </span>
                      {(display_price * (1 - discount_percentage / 100)).toFixed(2)}
                    </span>
                  </span>
                </h6>
              )}
              {store.get('show_pkg_date') ? (
                <div className="flex gap-2 justify-center">
                  <h6
                    className={`mb-0 ${
                      props.barcodeData?.exp_date ? 'text-[10px]' : 'print-font-2'
                    }`}
                  >
                    PKD ON: {props.barcodeData?.pkg_date ?? dayjs().format('DD-MMM-YY')}
                  </h6>
                  {props.barcodeData?.exp_date && (
                    <h6 className="mb-0 text-[10px]">EXP ON: {props.barcodeData?.exp_date}</h6>
                  )}
                </div>
              ) : null}
              {props.barcodeData.barcode_id != null && (
                <Barcode
                  width={2}
                  height={75}
                  fontSize={21}
                  value={props.barcodeData.barcode_id + ''}
                  className="flex m-auto"
                />
              )}
            </div>
          ))}
        </div>

        {props.barcodeData.variants &&
          props.barcodeData.variants.map(
            (variant, v) =>
              variant.barcode_id != null &&
              variant.barcode_id != '' && (
                <div className="grid grid-cols-2 gap-2 pt-3 text-center">
                  {[...Array(16)].map((item, i) => (
                    <div key={i} className="text-center leading-none">
                      <h6
                        className="mb-1 print-font-3"
                        style={{ fontSize: store.get('product_name_font_size') }}
                      >
                        {variant.name}
                      </h6>
                      {props.barcode_settings?.price_label?.length > 0 && (
                        <h6>
                          <span
                            className="print-font-3"
                            style={{ fontSize: store.get('mrp_font_size') }}
                          >
                            {props.barcode_settings?.price_label}:{' '}
                          </span>
                          <span>
                            <span className="print-font-2 mr-1">
                              {this.props?.user?.selectedCompany?.currency_symbol}
                            </span>
                            <span
                              className="print-font-4 font-weight-bold"
                              style={{ fontSize: store.get('mrp_font_size') }}
                            >
                              {variant.is_price_with_tax == true
                                ? variant.price_with_tax
                                : variant.price}
                            </span>
                          </span>
                        </h6>
                      )}
                      {store.get('show_pkg_date') ? (
                        <div className="flex gap-1 justify-center">
                          <h6
                            className={`mb-0 ${
                              props.barcodeData?.exp_date ? 'text-[9px]' : 'print-font-2'
                            }`}
                          >
                            PKD ON: {props.barcodeData?.pkg_date ?? dayjs().format('DD-MMM-YY')}
                          </h6>
                          {props.barcodeData?.exp_date && (
                            <h6 className="mb-0 text-[9px]">
                              EXP ON: {props.barcodeData?.exp_date}
                            </h6>
                          )}
                        </div>
                      ) : null}
                      {variant.barcode_id != null && (
                        <Barcode
                          width={2}
                          height={75}
                          fontSize={21}
                          value={variant.barcode_id + ''}
                          className="flex m-auto"
                        />
                      )}
                    </div>
                  ))}
                </div>
              ),
          )}
      </div>
    )
  }
}

class PrintBarcode13x5 extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    var props = this.props
    const display_price = props.barcode_settings?.show_price_with_tax
      ? props.barcodeData.price_with_tax
      : props.barcodeData.price
    let discount_percentage = props.barcodeData.discount
    if (props.barcodeData.discount_amount) {
      discount_percentage =
        (props.barcodeData.discount_amount / props.barcodeData.price_with_tax) * 100
    }
    return (
      <div className="barcode">
        <div className="grid grid-cols-5 gap-2 pt-3 text-center">
          {[...Array(65)].map((item, i) => (
            <div key={i} className="text-center mb-3" style={{ lineHeight: 1, height: '1.044in' }}>
              <p className="mb-1 line-clamp-1 font-size-12 pt-1 text-center">
                {props.barcodeData.product_name} {props.barcodeData.name}
              </p>
              {props.barcode_settings?.price_label?.length > 0 && (
                <p className="mb-0">
                  <span className="font-size-12">{props.barcode_settings?.price_label}</span>
                  {props.barcodeData.discount != 0 && (
                    <span>
                      <del
                        className="text-gray-11 font-weight-bold ml-1"
                        style={{ fontSize: store.get('mrp_font_size') }}
                      >
                        {display_price}
                      </del>
                    </span>
                  )}

                  <span
                    className="font-weight-bold font-size-12"
                    style={{ fontSize: store.get('mrp_font_size') }}
                  >
                    <span className="ml-2 mr-1">
                      {this.props?.user?.selectedCompany?.currency_symbol}
                    </span>
                    {(display_price * (1 - discount_percentage / 100)).toFixed(2)}
                  </span>
                </p>
              )}
              {store.get('show_pkg_date') ? (
                <div className="mt-0.5 flex flex-col justify-center">
                  <h6
                    className={`mb-0 ${
                      props.barcodeData?.exp_date ? 'text-[10px]' : 'font-size-7'
                    }`}
                  >
                    PKD ON: {props.barcodeData?.pkg_date ?? dayjs().format('DD-MMM-YY')}
                  </h6>
                  {props.barcodeData?.exp_date && (
                    <h6 className="mb-0 text-[10px]">EXP ON: {props.barcodeData?.exp_date}</h6>
                  )}
                </div>
              ) : null}
              {props.barcodeData.barcode_id != null && (
                <Barcode
                  width={1.35}
                  height={35}
                  fontSize={10}
                  value={props.barcodeData.barcode_id + ''}
                  className="flex m-auto"
                />
              )}
            </div>
          ))}
        </div>

        {props.barcodeData.variants &&
          props.barcodeData.variants.map(
            (variant, v) =>
              variant.barcode_id != null &&
              variant.barcode_id != '' && (
                <div className="grid grid-cols-5 gap-2 pt-3 text-center">
                  {[...Array(65)].map((item, i) => (
                    <div
                      key={i}
                      className="text-center mb-3"
                      style={{ lineHeight: 1, height: '1.044in' }}
                    >
                      <p className="mb-1 text-truncate font-size-12 pt-1 text-center">
                        {variant.name}
                      </p>
                      {props.barcode_settings?.price_label?.length > 0 && (
                        <p className="mb-0">
                          <span className="font-size-12">
                            {props.barcode_settings?.price_label}
                          </span>
                          <span>
                            <span className="ml-2 mr-1 font-size-12">
                              {this.props?.user?.selectedCompany?.currency_symbol}
                            </span>
                            <span className="font-weight-bold font-size-12">
                              {variant.is_price_with_tax == true
                                ? variant.price_with_tax
                                : variant.price}
                            </span>
                          </span>
                        </p>
                      )}
                      {store.get('show_pkg_date') ? (
                        <div className="mt-0.5 flex flex-col justify-center">
                          <h6
                            className={`mb-0 ${
                              props.barcodeData?.exp_date ? 'text-[10px]' : 'font-size-7'
                            }`}
                          >
                            PKD ON: {props.barcodeData?.pkg_date ?? dayjs().format('DD-MMM-YY')}
                          </h6>
                          {props.barcodeData?.exp_date && (
                            <h6 className="mb-0 text-[10px]">
                              EXP ON: {props.barcodeData?.exp_date}
                            </h6>
                          )}
                        </div>
                      ) : null}
                      {variant.barcode_id != null && (
                        <Barcode
                          width={1.35}
                          height={35}
                          fontSize={10}
                          value={variant.barcode_id + ''}
                          className="flex m-auto"
                        />
                      )}
                    </div>
                  ))}
                </div>
              ),
          )}
      </div>
    )
  }
}

class BigBarcode extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    var props = this.props
    var width = 12 / props.type
    const display_price = props.barcode_settings?.show_price_with_tax
      ? props.barcodeData.price_with_tax
      : props.barcodeData.price

    let discount_percentage = props.barcodeData.discount
    if (props.barcodeData.discount_amount) {
      discount_percentage =
        (props.barcodeData.discount_amount / props.barcodeData.price_with_tax) * 100
    }

    return (
      <div className="barcode">
        <div className="grid grid-cols-1 px-3">
          <div className="text-left">
            <p
              className="mb-1 text-truncate pt-1 font-weight-bold"
              style={{ fontSize: store.get('product_name_font_size') }}
            >
              {props.barcodeData.product_name} {props.barcodeData.name}
            </p>
            {/*{props.barcodeData.description != '' && props.barcodeData.description != '<p><br></p>' && (
              <p className="mb-0">
                <span className="font-size-18">
                  {props.barcodeData.description
                    .replace(/<\s*br[^>]?>/, '\n')
                    .replace(/(<([^>]+)>)/g, '')}
                </span>
              </p>
            )}*/}
            {props.barcode_settings?.price_label?.length > 0 && (
              <p className="mb-0">
                <span className="font-weight-bold" style={{ fontSize: store.get('mrp_font_size') }}>
                  {props.barcode_settings?.price_label}
                </span>
                <span>
                  {props.barcodeData.discount != 0 && (
                    <span>
                      <del
                        className="text-gray-11 font-weight-bold ml-1"
                        style={{ fontSize: store.get('mrp_font_size') }}
                      >
                        {display_price}
                      </del>
                    </span>
                  )}

                  <span
                    className="font-weight-bold font-size-12"
                    style={{ fontSize: store.get('mrp_font_size') }}
                  >
                    <span className="ml-2 mr-1">
                      {this.props?.user?.selectedCompany?.currency_symbol}
                    </span>
                    {(display_price * (1 - discount_percentage / 100)).toFixed(2)}
                  </span>
                </span>
              </p>
            )}
            {store.get('show_pkg_date') ? (
              <div className="flex gap-4 font-size-7">
                <p className="mb-0">
                  PKD ON: {props.barcodeData?.pkg_date ?? dayjs().format('DD-MMM-YY')}
                </p>
                {props.barcodeData?.exp_date && (
                  <p className="mb-0">EXP ON: {props.barcodeData?.exp_date}</p>
                )}
              </div>
            ) : null}
            {props.barcodeData.barcode_id != null && (
              <Barcode
                width={3.6}
                height={81}
                fontSize={24}
                value={props.barcodeData.barcode_id + ''}
              />
            )}
            <p className="mb-0">
              <span className="font-size-18">By</span>
              <br />
              <span className="font-size-18">{store.get('organization_name')}</span>
            </p>
          </div>
        </div>

        {props.barcodeData.variants &&
          props.barcodeData.variants.map(
            (variant, v) =>
              variant.barcode_id != null &&
              variant.barcode_id != '' && (
                <div className="grid grid-cols-1 px-3">
                  <div className="text-left">
                    <p
                      className="mb-1 text-truncate pt-1 font-weight-bold"
                      style={{ fontSize: store.get('product_name_font_size') }}
                    >
                      {variant.name}
                    </p>
                    {/*{props.barcodeData.description != '' && props.barcodeData.description != '<p><br></p>' && (
               <p className="mb-0">
                 <span className="font-size-18">
                   {props.barcodeData.description
                     .replace(/<\s*br[^>]?>/, '\n')
                     .replace(/(<([^>]+)>)/g, '')}
                 </span>
               </p>
             )}*/}
                    {props.barcode_settings?.price_label?.length > 0 && (
                      <p className="mb-0">
                        <span
                          className="font-weight-bold"
                          style={{ fontSize: store.get('mrp_font_size') }}
                        >
                          {props.barcode_settings?.price_label}
                        </span>
                        <span>
                          {props.barcodeData.discount != 0 && (
                            <span>
                              <del
                                className="text-gray-11 font-weight-bold ml-1"
                                style={{ fontSize: store.get('mrp_font_size') }}
                              >
                                {props.barcodeData.price_with_tax}
                              </del>
                            </span>
                          )}

                          <span
                            className="font-weight-bold font-size-12"
                            style={{ fontSize: store.get('mrp_font_size') }}
                          >
                            <span className="ml-2 mr-1">
                              {this.props?.user?.selectedCompany?.currency_symbol}
                            </span>
                            {!props.barcodeData.discount_amount
                              ? (
                                  props.barcodeData.price_with_tax *
                                  (1 - props.barcodeData.discount / 100)
                                ).toFixed(2)
                              : (
                                  props.barcodeData.price_with_tax -
                                  props.barcodeData.discount_amount
                                ).toFixed(2)}
                          </span>
                        </span>
                      </p>
                    )}
                    {store.get('show_pkg_date') ? (
                      <div className="flex gap-4 font-size-7">
                        <p className="mb-0">
                          PKD ON: {props.barcodeData?.pkg_date ?? dayjs().format('DD-MMM-YY')}
                        </p>
                        {props.barcodeData?.exp_date && (
                          <p className="mb-0">EXP ON: {props.barcodeData?.exp_date}</p>
                        )}
                      </div>
                    ) : null}
                    {props.barcodeData.barcode_id != null && (
                      <Barcode
                        width={3.6}
                        height={81}
                        fontSize={24}
                        value={props.barcodeData.barcode_id + ''}
                        className="flex m-auto"
                      />
                    )}
                    <p className="mb-0">
                      <span className="font-size-18">By</span>
                      <br />
                      <span className="font-size-18">{store.get('organization_name')}</span>
                    </p>
                  </div>
                </div>
              ),
          )}
      </div>
    )
  }
}

class PrintBarcode8x4 extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    var props = this.props
    var width = 12 / props.type
    const display_price = props.barcode_settings?.show_price_with_tax
      ? props.barcodeData.price_with_tax
      : props.barcodeData.price

    let discount_percentage = props.barcodeData.discount
    if (props.barcodeData.discount_amount) {
      discount_percentage =
        (props.barcodeData.discount_amount / props.barcodeData.price_with_tax) * 100
    }

    return (
      <div className="barcode">
        <div className="grid grid-cols-4 gap-2 pt-3 text-center">
          {[...Array(40)].map((item, i) => (
            <div
              key={i}
              className="text-center pt-1 px-0"
              style={{ lineHeight: 1, height: '1.475in' }}
            >
              <p className="mb-1 font-size-16 pt-2 text-center text-truncate">
                {props.barcodeData.product_name} {props.barcodeData.name}
              </p>
              {props.barcode_settings?.price_label?.length > 0 && (
                <p className="mb-0">
                  <span className="font-size-16">{props.barcode_settings?.price_label}</span>
                  {props.barcodeData.discount != 0 && (
                    <span>
                      <del
                        className="text-gray-11 font-weight-bold ml-1"
                        style={{ fontSize: store.get('mrp_font_size') }}
                      >
                        {display_price}
                      </del>
                    </span>
                  )}

                  <span
                    className="font-weight-bold font-size-12"
                    style={{ fontSize: store.get('mrp_font_size') }}
                  >
                    <span className="ml-2 mr-1">
                      {this.props?.user?.selectedCompany?.currency_symbol}
                    </span>

                    {(display_price * (1 - discount_percentage / 100)).toFixed(2)}
                  </span>
                </p>
              )}
              {store.get('show_pkg_date') ? (
                <div className="mt-0 mb-0 flex gap-1 justify-center">
                  <h6
                    className={`mb-0 ${
                      props.barcodeData?.exp_date ? 'text-[9px]' : 'font-size-12'
                    }`}
                  >
                    PKD ON: {props.barcodeData?.pkg_date ?? dayjs().format('DD-MMM-YY')}
                  </h6>
                  {props.barcodeData?.exp_date && (
                    <h6 className="mb-0 text-[9px]">EXP ON: {props.barcodeData?.exp_date}</h6>
                  )}
                </div>
              ) : null}
              {props.barcodeData.barcode_id != null && (
                <Barcode
                  width={1.9}
                  height={48}
                  fontSize={14}
                  value={props.barcodeData.barcode_id + ''}
                  className="flex m-auto"
                />
              )}
            </div>
          ))}
        </div>

        {props.barcodeData.variants &&
          props.barcodeData.variants.map(
            (variant, v) =>
              variant.barcode_id != null &&
              variant.barcode_id != '' && (
                <div className="grid grid-cols-4 gap-2 pt-3 text-center">
                  {[...Array(32)].map((item, i) => (
                    <div
                      key={i}
                      className="text-center pt-1 px-0"
                      style={{ lineHeight: 1, height: '1.475in' }}
                    >
                      <p className="mb-1 font-size-16 pt-2 text-center text-truncate">
                        {variant.name}
                      </p>
                      {props.barcode_settings?.price_label?.length > 0 && (
                        <p className="mb-0">
                          <span className="font-size-16">
                            {props.barcode_settings?.price_label}
                          </span>
                          <span>
                            <span className="ml-2 mr-1">
                              {this.props?.user?.selectedCompany?.currency_symbol}
                            </span>
                            <span className="font-weight-bold font-size-16">
                              {variant.is_price_with_tax == true
                                ? variant.price_with_tax
                                : variant.price}
                            </span>
                          </span>
                        </p>
                      )}
                      {store.get('show_pkg_date') ? (
                        <div className="flex gap-1 justify-center">
                          <h6
                            className={`mb-0 ${
                              props.barcodeData?.exp_date ? 'text-[9px]' : 'font-size-12'
                            }`}
                          >
                            PKD ON: {props.barcodeData?.pkg_date ?? dayjs().format('DD-MMM-YY')}
                          </h6>
                          {props.barcodeData?.exp_date && (
                            <h6 className="mb-0 text-[9px]">
                              EXP ON: {props.barcodeData?.exp_date}
                            </h6>
                          )}
                        </div>
                      ) : null}
                      {variant.barcode_id != null && (
                        <Barcode
                          width={1.9}
                          height={48}
                          fontSize={14}
                          value={variant.barcode_id + ''}
                          className="flex m-auto"
                        />
                      )}
                    </div>
                  ))}
                </div>
              ),
          )}
      </div>
    )
  }
}

export class ProductBarcode extends Component {
  constructor(props) {
    super(props)
    this.state = {
      flag: false,
      ops: null,
      error: '',
      value: 2,
      barcodeData: { product_id: 123 + '' },
      showEditDetails: false,
    }
  }

  componentDidMount() {
    this.props.onRef(this)
    if (
      this.props.barcode_settings == undefined ||
      Object.keys(this.props.barcode_settings)?.length === 0
    ) {
      this.props.dispatch(get_barcode_settings())
    }
  }

  componentWillUnmount() {
    this.props.onRef(null)
  }

  showModal = async data => {
    this.setState({ barcodeData: data, backupData: { ...data } }, () =>
      this.setState({ flag: true }),
    )
  }

  handleCancel = () => {
    this.setState({ flag: false })
  }

  onChange = e => {
    this.setState({ value: e })
  }

  setShowEditDetails = val => {
    this.setState({ showEditDetails: val })
  }

  updateData = data => {
    this.setState({ barcodeData: data })
  }

  render() {
    return (
      <>
        {this.state.flag && (
          <BarcodeDetailsForm
            showDrawer={this.state.showEditDetails}
            setShowDrawer={this.setShowEditDetails}
            data={this.state.barcodeData}
            backupData={this.state.backupData}
            updateData={this.updateData}
          />
        )}
        <Modal
          title={
            <div className="pr-4 flex justify-between">
              <div>
                Barcode
                <BetaBadge />
              </div>
              <Button
                onClick={() => {
                  window.location.href = '/user?tab=barcode'
                }}
              >
                <i className="fa-solid fa-gear" />
              </Button>
            </div>
          }
          centered
          width={'100vw'}
          open={this.state.flag}
          onCancel={() => this.setState({ flag: false, barcodeData: { product_id: 123 + '' } })}
          closeIcon={<i className="fa-solid fa-lg fa-xmark"></i>}
          footer={
            <div className="flex flex-row justify-end">
              <>
                <Button
                  onClick={() => this.setShowEditDetails(true)}
                  type="warning"
                  style={{ marginRight: 10 }}
                >
                  <i className="fa fa-edit mr-2" />
                  Edit
                </Button>
              </>
              {this.state.value == 1 && (
                <>
                  <ReactToPrint
                    key={1}
                    trigger={() => (
                      <button style={{ visibility: 'hidden', display: 'none' }} id="print-button1">
                        Print
                      </button>
                    )}
                    content={() => this.componentRef1}
                  ></ReactToPrint>
                  <Form
                    key="submit"
                    onFinish={() => document.getElementById('print-button1').click()}
                  >
                    <Button htmlType="submit" type="primary">
                      Print
                    </Button>
                  </Form>
                </>
              )}
              {this.state.value == 2 && (
                <>
                  <ReactToPrint
                    key={1}
                    trigger={() => (
                      <button style={{ visibility: 'hidden', display: 'none' }} id="print-button2">
                        Print
                      </button>
                    )}
                    content={() => this.componentRef2}
                  ></ReactToPrint>
                  <Form
                    key="submit"
                    onFinish={() => document.getElementById('print-button2').click()}
                  >
                    <Button htmlType="submit" type="primary">
                      Print
                    </Button>
                  </Form>
                </>
              )}
              {this.state.value == 3 && (
                <>
                  <ReactToPrint
                    key={1}
                    trigger={() => (
                      <button style={{ visibility: 'hidden', display: 'none' }} id="print-button3">
                        Print
                      </button>
                    )}
                    content={() => this.componentRef3}
                  ></ReactToPrint>
                  <Form
                    key="submit"
                    onFinish={() => document.getElementById('print-button3').click()}
                  >
                    <Button htmlType="submit" type="primary">
                      Print
                    </Button>
                  </Form>
                </>
              )}
              {this.state.value == 4 && (
                <>
                  <ReactToPrint
                    key={1}
                    trigger={() => (
                      <button style={{ visibility: 'hidden', display: 'none' }} id="print-button4">
                        Print
                      </button>
                    )}
                    content={() => this.componentRef4}
                  ></ReactToPrint>
                  <Form
                    key="submit"
                    onFinish={() => document.getElementById('print-button4').click()}
                  >
                    <Button htmlType="submit" type="primary">
                      Print
                    </Button>
                  </Form>
                </>
              )}

              {this.state.value == 5 && (
                <>
                  <ReactToPrint
                    key={1}
                    trigger={() => (
                      <button style={{ visibility: 'hidden', display: 'none' }} id="print-button5">
                        Print
                      </button>
                    )}
                    content={() => this.componentRef5}
                  ></ReactToPrint>
                  <Form
                    key="submit"
                    onFinish={() => document.getElementById('print-button5').click()}
                  >
                    <Button htmlType="submit" type="primary">
                      Print
                    </Button>
                  </Form>
                </>
              )}
              {this.state.value == 6 && (
                <>
                  <ReactToPrint
                    key={1}
                    trigger={() => (
                      <button style={{ visibility: 'hidden', display: 'none' }} id="print-button6">
                        Print
                      </button>
                    )}
                    content={() => this.componentRef6}
                  ></ReactToPrint>
                  <Form
                    key="submit"
                    onFinish={() => document.getElementById('print-button6').click()}
                  >
                    <Button htmlType="submit" type="primary">
                      Print
                    </Button>
                  </Form>
                </>
              )}
              {this.state.value == 7 && (
                <>
                  <ReactToPrint
                    key={1}
                    trigger={() => (
                      <button style={{ visibility: 'hidden', display: 'none' }} id="print-button7">
                        Print
                      </button>
                    )}
                    content={() => this.componentRef7}
                  ></ReactToPrint>
                  <Form
                    key="submit"
                    onFinish={() => document.getElementById('print-button7').click()}
                  >
                    <Button htmlType="submit" type="primary">
                      Print
                    </Button>
                  </Form>
                </>
              )}
              {this.state.value == 8 && (
                <>
                  <ReactToPrint
                    key={1}
                    trigger={() => (
                      <button style={{ visibility: 'hidden', display: 'none' }} id="print-button8">
                        Print
                      </button>
                    )}
                    content={() => this.componentRef8}
                  ></ReactToPrint>
                  <Form
                    key="submit"
                    onFinish={() => document.getElementById('print-button8').click()}
                  >
                    <Button htmlType="submit" type="primary">
                      Print
                    </Button>
                  </Form>
                </>
              )}
            </div>
          }
        >
          <div>
            <Tabs defaultActiveKey="2" destroyInactiveTabPane={true} onChange={this.onChange}>
              <TabPane tab="1 x 2" key="2">
                <PrintBarcode
                  barcodeData={this.state.barcodeData}
                  type={2}
                  user={this.props.user}
                  ref={el => (this.componentRef2 = el)}
                  barcode_settings={this.props.barcode_settings}
                />
              </TabPane>
              <TabPane tab="1 x 4" key="4">
                <PrintBarcode
                  barcodeData={this.state.barcodeData}
                  type={4}
                  user={this.props.user}
                  ref={el => (this.componentRef4 = el)}
                  barcode_settings={this.props.barcode_settings}
                />
              </TabPane>
              <TabPane tab="1 x 1" key="1">
                <PrintBarcode
                  barcodeData={this.state.barcodeData}
                  type={1}
                  user={this.props.user}
                  ref={el => (this.componentRef1 = el)}
                  barcode_settings={this.props.barcode_settings}
                />
              </TabPane>
              <TabPane tab="1 x 3" key="3">
                <PrintBarcode
                  barcodeData={this.state.barcodeData}
                  type={3}
                  user={this.props.user}
                  ref={el => (this.componentRef3 = el)}
                  barcode_settings={this.props.barcode_settings}
                />
              </TabPane>
              <TabPane tab="A4 (8 x 2)" key="5">
                <PrintBarcode8x2
                  barcodeData={this.state.barcodeData}
                  type={2}
                  user={this.props.user}
                  ref={el => (this.componentRef5 = el)}
                  barcode_settings={this.props.barcode_settings}
                />
              </TabPane>
              <TabPane tab="A4 40 Labels (10 x 4)" key="6">
                <PrintBarcode8x4
                  barcodeData={this.state.barcodeData}
                  type={2}
                  user={this.props.user}
                  ref={el => (this.componentRef6 = el)}
                  barcode_settings={this.props.barcode_settings}
                />
              </TabPane>
              <TabPane tab="A4  65 Lables (13 x 5)" key="7">
                <PrintBarcode13x5
                  barcodeData={this.state.barcodeData}
                  type={2}
                  user={this.props.user}
                  ref={el => (this.componentRef7 = el)}
                  barcode_settings={this.props.barcode_settings}
                />
              </TabPane>
              <TabPane tab="Square Label" key="8">
                <BigBarcode
                  barcodeData={this.state.barcodeData}
                  type={2}
                  user={this.props.user}
                  ref={el => (this.componentRef8 = el)}
                  barcode_settings={this.props.barcode_settings}
                />
              </TabPane>
            </Tabs>
          </div>
        </Modal>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    barcode_settings: state.document.barcode_settings,
  }
}

export default connect(mapStateToProps)(ProductBarcode)
